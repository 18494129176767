import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  interval: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']>>;
  _eq?: InputMaybe<Array<Scalars['Int']>>;
  _gt?: InputMaybe<Array<Scalars['Int']>>;
  _gte?: InputMaybe<Array<Scalars['Int']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['Int']>>;
  _lte?: InputMaybe<Array<Scalars['Int']>>;
  _neq?: InputMaybe<Array<Scalars['Int']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "accounting_practiceexpense" */
export type Accounting_Practiceexpense = {
  __typename?: 'accounting_practiceexpense';
  /** An object relationship */
  accounting_recurringexpense?: Maybe<Accounting_Recurringexpense>;
  amount: Scalars['float8'];
  author_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  date: Scalars['date'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employees>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  note: Scalars['String'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  recurring_id?: Maybe<Scalars['bigint']>;
  related_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Aggregate = {
  __typename?: 'accounting_practiceexpense_aggregate';
  aggregate?: Maybe<Accounting_Practiceexpense_Aggregate_Fields>;
  nodes: Array<Accounting_Practiceexpense>;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp_Var_Samp>;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Avg = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr = {
  arguments: Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Max = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Min = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Sum = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Accounting_Practiceexpense_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Aggregate_Fields = {
  __typename?: 'accounting_practiceexpense_aggregate_fields';
  avg?: Maybe<Accounting_Practiceexpense_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Accounting_Practiceexpense_Max_Fields>;
  min?: Maybe<Accounting_Practiceexpense_Min_Fields>;
  stddev?: Maybe<Accounting_Practiceexpense_Stddev_Fields>;
  stddev_pop?: Maybe<Accounting_Practiceexpense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounting_Practiceexpense_Stddev_Samp_Fields>;
  sum?: Maybe<Accounting_Practiceexpense_Sum_Fields>;
  var_pop?: Maybe<Accounting_Practiceexpense_Var_Pop_Fields>;
  var_samp?: Maybe<Accounting_Practiceexpense_Var_Samp_Fields>;
  variance?: Maybe<Accounting_Practiceexpense_Variance_Fields>;
};


/** aggregate fields of "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Aggregate_Order_By = {
  avg?: InputMaybe<Accounting_Practiceexpense_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Accounting_Practiceexpense_Max_Order_By>;
  min?: InputMaybe<Accounting_Practiceexpense_Min_Order_By>;
  stddev?: InputMaybe<Accounting_Practiceexpense_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Accounting_Practiceexpense_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Accounting_Practiceexpense_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Accounting_Practiceexpense_Sum_Order_By>;
  var_pop?: InputMaybe<Accounting_Practiceexpense_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Accounting_Practiceexpense_Var_Samp_Order_By>;
  variance?: InputMaybe<Accounting_Practiceexpense_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Arr_Rel_Insert_Input = {
  data: Array<Accounting_Practiceexpense_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounting_Practiceexpense_On_Conflict>;
};

/** aggregate avg on columns */
export type Accounting_Practiceexpense_Avg_Fields = {
  __typename?: 'accounting_practiceexpense_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "accounting_practiceexpense". All fields are combined with a logical 'AND'. */
export type Accounting_Practiceexpense_Bool_Exp = {
  _and?: InputMaybe<Array<Accounting_Practiceexpense_Bool_Exp>>;
  _not?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  _or?: InputMaybe<Array<Accounting_Practiceexpense_Bool_Exp>>;
  accounting_recurringexpense?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  author_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  recurring_id?: InputMaybe<Bigint_Comparison_Exp>;
  related_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountingPracticeexpensePkey = 'accounting_practiceexpense_pkey'
}

/** input type for incrementing numeric columns in table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  author_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  recurring_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Insert_Input = {
  accounting_recurringexpense?: InputMaybe<Accounting_Recurringexpense_Obj_Rel_Insert_Input>;
  amount?: InputMaybe<Scalars['float8']>;
  author_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  recurring_id?: InputMaybe<Scalars['bigint']>;
  related_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Accounting_Practiceexpense_Max_Fields = {
  __typename?: 'accounting_practiceexpense_max_fields';
  amount?: Maybe<Scalars['float8']>;
  author_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  recurring_id?: Maybe<Scalars['bigint']>;
  related_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
  related_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounting_Practiceexpense_Min_Fields = {
  __typename?: 'accounting_practiceexpense_min_fields';
  amount?: Maybe<Scalars['float8']>;
  author_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  recurring_id?: Maybe<Scalars['bigint']>;
  related_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
  related_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Mutation_Response = {
  __typename?: 'accounting_practiceexpense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounting_Practiceexpense>;
};

/** on_conflict condition type for table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_On_Conflict = {
  constraint: Accounting_Practiceexpense_Constraint;
  update_columns?: Array<Accounting_Practiceexpense_Update_Column>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};

/** Ordering options when selecting data from "accounting_practiceexpense". */
export type Accounting_Practiceexpense_Order_By = {
  accounting_recurringexpense?: InputMaybe<Accounting_Recurringexpense_Order_By>;
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
  related_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounting_practiceexpense */
export type Accounting_Practiceexpense_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  RecurringId = 'recurring_id',
  /** column name */
  RelatedId = 'related_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_avg_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_corr_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_max_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_min_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_sum_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "accounting_practiceexpense_aggregate_bool_exp_var_samp_arguments_columns" columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Select_Column_Accounting_Practiceexpense_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  author_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  recurring_id?: InputMaybe<Scalars['bigint']>;
  related_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Accounting_Practiceexpense_Stddev_Fields = {
  __typename?: 'accounting_practiceexpense_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Accounting_Practiceexpense_Stddev_Pop_Fields = {
  __typename?: 'accounting_practiceexpense_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Accounting_Practiceexpense_Stddev_Samp_Fields = {
  __typename?: 'accounting_practiceexpense_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounting_Practiceexpense_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounting_Practiceexpense_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  author_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  recurring_id?: InputMaybe<Scalars['bigint']>;
  related_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Accounting_Practiceexpense_Sum_Fields = {
  __typename?: 'accounting_practiceexpense_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  author_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  recurring_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** update columns of table "accounting_practiceexpense" */
export enum Accounting_Practiceexpense_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  RecurringId = 'recurring_id',
  /** column name */
  RelatedId = 'related_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Accounting_Practiceexpense_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounting_Practiceexpense_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounting_Practiceexpense_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounting_Practiceexpense_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accounting_Practiceexpense_Var_Pop_Fields = {
  __typename?: 'accounting_practiceexpense_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Accounting_Practiceexpense_Var_Samp_Fields = {
  __typename?: 'accounting_practiceexpense_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Accounting_Practiceexpense_Variance_Fields = {
  __typename?: 'accounting_practiceexpense_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  recurring_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "accounting_practiceexpense" */
export type Accounting_Practiceexpense_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  recurring_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "accounting_recurringexpense" */
export type Accounting_Recurringexpense = {
  __typename?: 'accounting_recurringexpense';
  /** An array relationship */
  accounting_practiceexpenses: Array<Accounting_Practiceexpense>;
  /** An aggregate relationship */
  accounting_practiceexpenses_aggregate: Accounting_Practiceexpense_Aggregate;
  created_at: Scalars['timestamptz'];
  day: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  recurring_period: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  week: Scalars['Boolean'];
};


/** columns and relationships of "accounting_recurringexpense" */
export type Accounting_RecurringexpenseAccounting_PracticeexpensesArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


/** columns and relationships of "accounting_recurringexpense" */
export type Accounting_RecurringexpenseAccounting_Practiceexpenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};

/** aggregated selection of "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Aggregate = {
  __typename?: 'accounting_recurringexpense_aggregate';
  aggregate?: Maybe<Accounting_Recurringexpense_Aggregate_Fields>;
  nodes: Array<Accounting_Recurringexpense>;
};

/** aggregate fields of "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Aggregate_Fields = {
  __typename?: 'accounting_recurringexpense_aggregate_fields';
  avg?: Maybe<Accounting_Recurringexpense_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Accounting_Recurringexpense_Max_Fields>;
  min?: Maybe<Accounting_Recurringexpense_Min_Fields>;
  stddev?: Maybe<Accounting_Recurringexpense_Stddev_Fields>;
  stddev_pop?: Maybe<Accounting_Recurringexpense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounting_Recurringexpense_Stddev_Samp_Fields>;
  sum?: Maybe<Accounting_Recurringexpense_Sum_Fields>;
  var_pop?: Maybe<Accounting_Recurringexpense_Var_Pop_Fields>;
  var_samp?: Maybe<Accounting_Recurringexpense_Var_Samp_Fields>;
  variance?: Maybe<Accounting_Recurringexpense_Variance_Fields>;
};


/** aggregate fields of "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounting_Recurringexpense_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Accounting_Recurringexpense_Avg_Fields = {
  __typename?: 'accounting_recurringexpense_avg_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "accounting_recurringexpense". All fields are combined with a logical 'AND'. */
export type Accounting_Recurringexpense_Bool_Exp = {
  _and?: InputMaybe<Array<Accounting_Recurringexpense_Bool_Exp>>;
  _not?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
  _or?: InputMaybe<Array<Accounting_Recurringexpense_Bool_Exp>>;
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  recurring_period?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  week?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "accounting_recurringexpense" */
export enum Accounting_Recurringexpense_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountingRecurringexpensePkey = 'accounting_recurringexpense_pkey'
}

/** input type for incrementing numeric columns in table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Inc_Input = {
  day?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Insert_Input = {
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Accounting_Recurringexpense_Max_Fields = {
  __typename?: 'accounting_recurringexpense_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  recurring_period?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Accounting_Recurringexpense_Min_Fields = {
  __typename?: 'accounting_recurringexpense_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  recurring_period?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Mutation_Response = {
  __typename?: 'accounting_recurringexpense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounting_Recurringexpense>;
};

/** input type for inserting object relation for remote table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Obj_Rel_Insert_Input = {
  data: Accounting_Recurringexpense_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounting_Recurringexpense_On_Conflict>;
};

/** on_conflict condition type for table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_On_Conflict = {
  constraint: Accounting_Recurringexpense_Constraint;
  update_columns?: Array<Accounting_Recurringexpense_Update_Column>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};

/** Ordering options when selecting data from "accounting_recurringexpense". */
export type Accounting_Recurringexpense_Order_By = {
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recurring_period?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  week?: InputMaybe<Order_By>;
};

/** primary key columns input for table: accounting_recurringexpense */
export type Accounting_Recurringexpense_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "accounting_recurringexpense" */
export enum Accounting_Recurringexpense_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecurringPeriod = 'recurring_period',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Week = 'week'
}

/** input type for updating data in table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Accounting_Recurringexpense_Stddev_Fields = {
  __typename?: 'accounting_recurringexpense_stddev_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Accounting_Recurringexpense_Stddev_Pop_Fields = {
  __typename?: 'accounting_recurringexpense_stddev_pop_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Accounting_Recurringexpense_Stddev_Samp_Fields = {
  __typename?: 'accounting_recurringexpense_stddev_samp_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "accounting_recurringexpense" */
export type Accounting_Recurringexpense_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounting_Recurringexpense_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounting_Recurringexpense_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  recurring_period?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  week?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Accounting_Recurringexpense_Sum_Fields = {
  __typename?: 'accounting_recurringexpense_sum_fields';
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "accounting_recurringexpense" */
export enum Accounting_Recurringexpense_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  RecurringPeriod = 'recurring_period',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Week = 'week'
}

export type Accounting_Recurringexpense_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounting_Recurringexpense_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounting_Recurringexpense_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounting_Recurringexpense_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accounting_Recurringexpense_Var_Pop_Fields = {
  __typename?: 'accounting_recurringexpense_var_pop_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Accounting_Recurringexpense_Var_Samp_Fields = {
  __typename?: 'accounting_recurringexpense_var_samp_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Accounting_Recurringexpense_Variance_Fields = {
  __typename?: 'accounting_recurringexpense_variance_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "addresses" */
export type Addresses = {
  __typename?: 'addresses';
  apartment_number?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number: Scalars['String'];
  id: Scalars['bigint'];
  /** An array relationship */
  practice_branches: Array<Practice_Branches>;
  /** An aggregate relationship */
  practice_branches_aggregate: Practice_Branches_Aggregate;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  street: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip_code: Scalars['String'];
};


/** columns and relationships of "addresses" */
export type AddressesPractice_BranchesArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesPractice_Branches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


/** columns and relationships of "addresses" */
export type AddressesPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};

/** aggregated selection of "addresses" */
export type Addresses_Aggregate = {
  __typename?: 'addresses_aggregate';
  aggregate?: Maybe<Addresses_Aggregate_Fields>;
  nodes: Array<Addresses>;
};

/** aggregate fields of "addresses" */
export type Addresses_Aggregate_Fields = {
  __typename?: 'addresses_aggregate_fields';
  avg?: Maybe<Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Addresses_Max_Fields>;
  min?: Maybe<Addresses_Min_Fields>;
  stddev?: Maybe<Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Addresses_Sum_Fields>;
  var_pop?: Maybe<Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Addresses_Var_Samp_Fields>;
  variance?: Maybe<Addresses_Variance_Fields>;
};


/** aggregate fields of "addresses" */
export type Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Addresses_Avg_Fields = {
  __typename?: 'addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "addresses". All fields are combined with a logical 'AND'. */
export type Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Addresses_Bool_Exp>>;
  _not?: InputMaybe<Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Addresses_Bool_Exp>>;
  apartment_number?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  county?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  house_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_branches?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Bool_Exp>;
  practices?: InputMaybe<Practices_Bool_Exp>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Bool_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "addresses" */
export enum Addresses_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressesPkey = 'addresses_pkey'
}

/** input type for incrementing numeric columns in table "addresses" */
export type Addresses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "addresses" */
export type Addresses_Insert_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branches?: InputMaybe<Practice_Branches_Arr_Rel_Insert_Input>;
  practices?: InputMaybe<Practices_Arr_Rel_Insert_Input>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Addresses_Max_Fields = {
  __typename?: 'addresses_max_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Addresses_Min_Fields = {
  __typename?: 'addresses_min_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "addresses" */
export type Addresses_Mutation_Response = {
  __typename?: 'addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Addresses>;
};

/** input type for inserting object relation for remote table "addresses" */
export type Addresses_Obj_Rel_Insert_Input = {
  data: Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};

/** on_conflict condition type for table "addresses" */
export type Addresses_On_Conflict = {
  constraint: Addresses_Constraint;
  update_columns?: Array<Addresses_Update_Column>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "addresses". */
export type Addresses_Order_By = {
  apartment_number?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  house_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Order_By>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Order_By>;
  street?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: addresses */
export type Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "addresses" */
export enum Addresses_Select_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "addresses" */
export type Addresses_Set_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Addresses_Stddev_Fields = {
  __typename?: 'addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Addresses_Stddev_Pop_Fields = {
  __typename?: 'addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Addresses_Stddev_Samp_Fields = {
  __typename?: 'addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "addresses" */
export type Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Addresses_Stream_Cursor_Value_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Addresses_Sum_Fields = {
  __typename?: 'addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "addresses" */
export enum Addresses_Update_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

export type Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Addresses_Var_Pop_Fields = {
  __typename?: 'addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Addresses_Var_Samp_Fields = {
  __typename?: 'addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Addresses_Variance_Fields = {
  __typename?: 'addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "avatars" */
export type Avatars = {
  __typename?: 'avatars';
  content_type?: Maybe<Scalars['String']>;
  file: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "avatars" */
export type Avatars_Aggregate = {
  __typename?: 'avatars_aggregate';
  aggregate?: Maybe<Avatars_Aggregate_Fields>;
  nodes: Array<Avatars>;
};

/** aggregate fields of "avatars" */
export type Avatars_Aggregate_Fields = {
  __typename?: 'avatars_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Avatars_Max_Fields>;
  min?: Maybe<Avatars_Min_Fields>;
};


/** aggregate fields of "avatars" */
export type Avatars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Avatars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "avatars". All fields are combined with a logical 'AND'. */
export type Avatars_Bool_Exp = {
  _and?: InputMaybe<Array<Avatars_Bool_Exp>>;
  _not?: InputMaybe<Avatars_Bool_Exp>;
  _or?: InputMaybe<Array<Avatars_Bool_Exp>>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "avatars" */
export enum Avatars_Constraint {
  /** unique or primary key constraint on columns "id" */
  AvatarsPkey = 'avatars_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AvatarsUserIdKey = 'avatars_user_id_key'
}

/** input type for inserting data into table "avatars" */
export type Avatars_Insert_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Avatars_Max_Fields = {
  __typename?: 'avatars_max_fields';
  content_type?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Avatars_Min_Fields = {
  __typename?: 'avatars_min_fields';
  content_type?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "avatars" */
export type Avatars_Mutation_Response = {
  __typename?: 'avatars_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Avatars>;
};

/** input type for inserting object relation for remote table "avatars" */
export type Avatars_Obj_Rel_Insert_Input = {
  data: Avatars_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Avatars_On_Conflict>;
};

/** on_conflict condition type for table "avatars" */
export type Avatars_On_Conflict = {
  constraint: Avatars_Constraint;
  update_columns?: Array<Avatars_Update_Column>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};

/** Ordering options when selecting data from "avatars". */
export type Avatars_Order_By = {
  content_type?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: avatars */
export type Avatars_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "avatars" */
export enum Avatars_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "avatars" */
export type Avatars_Set_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "avatars" */
export type Avatars_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Avatars_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Avatars_Stream_Cursor_Value_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "avatars" */
export enum Avatars_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Avatars_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Avatars_Set_Input>;
  /** filter the rows which have to be updated */
  where: Avatars_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "contact_details" */
export type Contact_Details = {
  __typename?: 'contact_details';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['bigint'];
  phone: Scalars['String'];
  /** An array relationship */
  practice_branches: Array<Practice_Branches>;
  /** An aggregate relationship */
  practice_branches_aggregate: Practice_Branches_Aggregate;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "contact_details" */
export type Contact_DetailsPractice_BranchesArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "contact_details" */
export type Contact_DetailsPractice_Branches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "contact_details" */
export type Contact_DetailsPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


/** columns and relationships of "contact_details" */
export type Contact_DetailsPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};

/** aggregated selection of "contact_details" */
export type Contact_Details_Aggregate = {
  __typename?: 'contact_details_aggregate';
  aggregate?: Maybe<Contact_Details_Aggregate_Fields>;
  nodes: Array<Contact_Details>;
};

/** aggregate fields of "contact_details" */
export type Contact_Details_Aggregate_Fields = {
  __typename?: 'contact_details_aggregate_fields';
  avg?: Maybe<Contact_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Contact_Details_Max_Fields>;
  min?: Maybe<Contact_Details_Min_Fields>;
  stddev?: Maybe<Contact_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Details_Sum_Fields>;
  var_pop?: Maybe<Contact_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Details_Var_Samp_Fields>;
  variance?: Maybe<Contact_Details_Variance_Fields>;
};


/** aggregate fields of "contact_details" */
export type Contact_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Contact_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Contact_Details_Avg_Fields = {
  __typename?: 'contact_details_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "contact_details". All fields are combined with a logical 'AND'. */
export type Contact_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Contact_Details_Bool_Exp>>;
  _not?: InputMaybe<Contact_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Contact_Details_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  practice_branches?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Bool_Exp>;
  practices?: InputMaybe<Practices_Bool_Exp>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_details" */
export enum Contact_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactDetailsPkey = 'contact_details_pkey'
}

/** input type for incrementing numeric columns in table "contact_details" */
export type Contact_Details_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contact_details" */
export type Contact_Details_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  phone?: InputMaybe<Scalars['String']>;
  practice_branches?: InputMaybe<Practice_Branches_Arr_Rel_Insert_Input>;
  practices?: InputMaybe<Practices_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contact_Details_Max_Fields = {
  __typename?: 'contact_details_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Contact_Details_Min_Fields = {
  __typename?: 'contact_details_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "contact_details" */
export type Contact_Details_Mutation_Response = {
  __typename?: 'contact_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Details>;
};

/** input type for inserting object relation for remote table "contact_details" */
export type Contact_Details_Obj_Rel_Insert_Input = {
  data: Contact_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};

/** on_conflict condition type for table "contact_details" */
export type Contact_Details_On_Conflict = {
  constraint: Contact_Details_Constraint;
  update_columns?: Array<Contact_Details_Update_Column>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "contact_details". */
export type Contact_Details_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Order_By>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: contact_details */
export type Contact_Details_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "contact_details" */
export enum Contact_Details_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contact_details" */
export type Contact_Details_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contact_Details_Stddev_Fields = {
  __typename?: 'contact_details_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Contact_Details_Stddev_Pop_Fields = {
  __typename?: 'contact_details_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Contact_Details_Stddev_Samp_Fields = {
  __typename?: 'contact_details_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "contact_details" */
export type Contact_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Contact_Details_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Contact_Details_Sum_Fields = {
  __typename?: 'contact_details_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "contact_details" */
export enum Contact_Details_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Contact_Details_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Contact_Details_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Contact_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Contact_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Contact_Details_Var_Pop_Fields = {
  __typename?: 'contact_details_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Contact_Details_Var_Samp_Fields = {
  __typename?: 'contact_details_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Contact_Details_Variance_Fields = {
  __typename?: 'contact_details_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "couples" */
export type Couples = {
  __typename?: 'couples';
  couple_name: Scalars['String'];
  /** An array relationship */
  couples_patients: Array<Couples_Patients>;
  /** An aggregate relationship */
  couples_patients_aggregate: Couples_Patients_Aggregate;
  /** An array relationship */
  couples_therapists: Array<Couples_Therapists>;
  /** An aggregate relationship */
  couples_therapists_aggregate: Couples_Therapists_Aggregate;
  created_at: Scalars['timestamptz'];
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  event_periods: Array<Event_Period>;
  /** An aggregate relationship */
  event_periods_aggregate: Event_Period_Aggregate;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  last_edited_by?: Maybe<User>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  sms_notifications_active: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "couples" */
export type CouplesCouples_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesCouples_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesCouples_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesCouples_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesEvent_PeriodsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesEvent_Periods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "couples" */
export type CouplesEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** aggregated selection of "couples" */
export type Couples_Aggregate = {
  __typename?: 'couples_aggregate';
  aggregate?: Maybe<Couples_Aggregate_Fields>;
  nodes: Array<Couples>;
};

export type Couples_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Couples_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Couples_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Couples_Aggregate_Bool_Exp_Count>;
};

export type Couples_Aggregate_Bool_Exp_Bool_And = {
  arguments: Couples_Select_Column_Couples_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Couples_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Couples_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Couples_Select_Column_Couples_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Couples_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Couples_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Couples_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Couples_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "couples" */
export type Couples_Aggregate_Fields = {
  __typename?: 'couples_aggregate_fields';
  avg?: Maybe<Couples_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Couples_Max_Fields>;
  min?: Maybe<Couples_Min_Fields>;
  stddev?: Maybe<Couples_Stddev_Fields>;
  stddev_pop?: Maybe<Couples_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Couples_Stddev_Samp_Fields>;
  sum?: Maybe<Couples_Sum_Fields>;
  var_pop?: Maybe<Couples_Var_Pop_Fields>;
  var_samp?: Maybe<Couples_Var_Samp_Fields>;
  variance?: Maybe<Couples_Variance_Fields>;
};


/** aggregate fields of "couples" */
export type Couples_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Couples_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "couples" */
export type Couples_Aggregate_Order_By = {
  avg?: InputMaybe<Couples_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Couples_Max_Order_By>;
  min?: InputMaybe<Couples_Min_Order_By>;
  stddev?: InputMaybe<Couples_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Couples_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Couples_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Couples_Sum_Order_By>;
  var_pop?: InputMaybe<Couples_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Couples_Var_Samp_Order_By>;
  variance?: InputMaybe<Couples_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "couples" */
export type Couples_Arr_Rel_Insert_Input = {
  data: Array<Couples_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Couples_On_Conflict>;
};

/** aggregate avg on columns */
export type Couples_Avg_Fields = {
  __typename?: 'couples_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "couples" */
export type Couples_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "couples". All fields are combined with a logical 'AND'. */
export type Couples_Bool_Exp = {
  _and?: InputMaybe<Array<Couples_Bool_Exp>>;
  _not?: InputMaybe<Couples_Bool_Exp>;
  _or?: InputMaybe<Array<Couples_Bool_Exp>>;
  couple_name?: InputMaybe<String_Comparison_Exp>;
  couples_patients?: InputMaybe<Couples_Patients_Bool_Exp>;
  couples_patients_aggregate?: InputMaybe<Couples_Patients_Aggregate_Bool_Exp>;
  couples_therapists?: InputMaybe<Couples_Therapists_Bool_Exp>;
  couples_therapists_aggregate?: InputMaybe<Couples_Therapists_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_periods?: InputMaybe<Event_Period_Bool_Exp>;
  event_periods_aggregate?: InputMaybe<Event_Period_Aggregate_Bool_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_edited_by?: InputMaybe<User_Bool_Exp>;
  last_edited_by_id?: InputMaybe<String_Comparison_Exp>;
  sms_notifications_active?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "couples" */
export enum Couples_Constraint {
  /** unique or primary key constraint on columns "id" */
  CouplesPkey = 'couples_pkey'
}

/** input type for incrementing numeric columns in table "couples" */
export type Couples_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "couples" */
export type Couples_Insert_Input = {
  couple_name?: InputMaybe<Scalars['String']>;
  couples_patients?: InputMaybe<Couples_Patients_Arr_Rel_Insert_Input>;
  couples_therapists?: InputMaybe<Couples_Therapists_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event_periods?: InputMaybe<Event_Period_Arr_Rel_Insert_Input>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Couples_Max_Fields = {
  __typename?: 'couples_max_fields';
  couple_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "couples" */
export type Couples_Max_Order_By = {
  couple_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Couples_Min_Fields = {
  __typename?: 'couples_min_fields';
  couple_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "couples" */
export type Couples_Min_Order_By = {
  couple_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "couples" */
export type Couples_Mutation_Response = {
  __typename?: 'couples_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Couples>;
};

/** input type for inserting object relation for remote table "couples" */
export type Couples_Obj_Rel_Insert_Input = {
  data: Couples_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Couples_On_Conflict>;
};

/** on_conflict condition type for table "couples" */
export type Couples_On_Conflict = {
  constraint: Couples_Constraint;
  update_columns?: Array<Couples_Update_Column>;
  where?: InputMaybe<Couples_Bool_Exp>;
};

/** Ordering options when selecting data from "couples". */
export type Couples_Order_By = {
  couple_name?: InputMaybe<Order_By>;
  couples_patients_aggregate?: InputMaybe<Couples_Patients_Aggregate_Order_By>;
  couples_therapists_aggregate?: InputMaybe<Couples_Therapists_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_periods_aggregate?: InputMaybe<Event_Period_Aggregate_Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by?: InputMaybe<User_Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  sms_notifications_active?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "couples_patients" */
export type Couples_Patients = {
  __typename?: 'couples_patients';
  /** An object relationship */
  couple: Couples;
  couples_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
};

/** aggregated selection of "couples_patients" */
export type Couples_Patients_Aggregate = {
  __typename?: 'couples_patients_aggregate';
  aggregate?: Maybe<Couples_Patients_Aggregate_Fields>;
  nodes: Array<Couples_Patients>;
};

export type Couples_Patients_Aggregate_Bool_Exp = {
  count?: InputMaybe<Couples_Patients_Aggregate_Bool_Exp_Count>;
};

export type Couples_Patients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Couples_Patients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "couples_patients" */
export type Couples_Patients_Aggregate_Fields = {
  __typename?: 'couples_patients_aggregate_fields';
  avg?: Maybe<Couples_Patients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Couples_Patients_Max_Fields>;
  min?: Maybe<Couples_Patients_Min_Fields>;
  stddev?: Maybe<Couples_Patients_Stddev_Fields>;
  stddev_pop?: Maybe<Couples_Patients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Couples_Patients_Stddev_Samp_Fields>;
  sum?: Maybe<Couples_Patients_Sum_Fields>;
  var_pop?: Maybe<Couples_Patients_Var_Pop_Fields>;
  var_samp?: Maybe<Couples_Patients_Var_Samp_Fields>;
  variance?: Maybe<Couples_Patients_Variance_Fields>;
};


/** aggregate fields of "couples_patients" */
export type Couples_Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "couples_patients" */
export type Couples_Patients_Aggregate_Order_By = {
  avg?: InputMaybe<Couples_Patients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Couples_Patients_Max_Order_By>;
  min?: InputMaybe<Couples_Patients_Min_Order_By>;
  stddev?: InputMaybe<Couples_Patients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Couples_Patients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Couples_Patients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Couples_Patients_Sum_Order_By>;
  var_pop?: InputMaybe<Couples_Patients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Couples_Patients_Var_Samp_Order_By>;
  variance?: InputMaybe<Couples_Patients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "couples_patients" */
export type Couples_Patients_Arr_Rel_Insert_Input = {
  data: Array<Couples_Patients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Couples_Patients_On_Conflict>;
};

/** aggregate avg on columns */
export type Couples_Patients_Avg_Fields = {
  __typename?: 'couples_patients_avg_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "couples_patients" */
export type Couples_Patients_Avg_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "couples_patients". All fields are combined with a logical 'AND'. */
export type Couples_Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Couples_Patients_Bool_Exp>>;
  _not?: InputMaybe<Couples_Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Couples_Patients_Bool_Exp>>;
  couple?: InputMaybe<Couples_Bool_Exp>;
  couples_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "couples_patients" */
export enum Couples_Patients_Constraint {
  /** unique or primary key constraint on columns "patient_id", "couples_id" */
  CouplesPatientsCouplesIdPatientIdCb39c990Uniq = 'couples_patients_couples_id_patient_id_cb39c990_uniq',
  /** unique or primary key constraint on columns "id" */
  CouplesPatientsPkey = 'couples_patients_pkey'
}

/** input type for incrementing numeric columns in table "couples_patients" */
export type Couples_Patients_Inc_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "couples_patients" */
export type Couples_Patients_Insert_Input = {
  couple?: InputMaybe<Couples_Obj_Rel_Insert_Input>;
  couples_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Couples_Patients_Max_Fields = {
  __typename?: 'couples_patients_max_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "couples_patients" */
export type Couples_Patients_Max_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Couples_Patients_Min_Fields = {
  __typename?: 'couples_patients_min_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "couples_patients" */
export type Couples_Patients_Min_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "couples_patients" */
export type Couples_Patients_Mutation_Response = {
  __typename?: 'couples_patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Couples_Patients>;
};

/** on_conflict condition type for table "couples_patients" */
export type Couples_Patients_On_Conflict = {
  constraint: Couples_Patients_Constraint;
  update_columns?: Array<Couples_Patients_Update_Column>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "couples_patients". */
export type Couples_Patients_Order_By = {
  couple?: InputMaybe<Couples_Order_By>;
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couples_patients */
export type Couples_Patients_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "couples_patients" */
export enum Couples_Patients_Select_Column {
  /** column name */
  CouplesId = 'couples_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id'
}

/** input type for updating data in table "couples_patients" */
export type Couples_Patients_Set_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Couples_Patients_Stddev_Fields = {
  __typename?: 'couples_patients_stddev_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "couples_patients" */
export type Couples_Patients_Stddev_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Couples_Patients_Stddev_Pop_Fields = {
  __typename?: 'couples_patients_stddev_pop_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "couples_patients" */
export type Couples_Patients_Stddev_Pop_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Couples_Patients_Stddev_Samp_Fields = {
  __typename?: 'couples_patients_stddev_samp_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "couples_patients" */
export type Couples_Patients_Stddev_Samp_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "couples_patients" */
export type Couples_Patients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Couples_Patients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Couples_Patients_Stream_Cursor_Value_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Couples_Patients_Sum_Fields = {
  __typename?: 'couples_patients_sum_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "couples_patients" */
export type Couples_Patients_Sum_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "couples_patients" */
export enum Couples_Patients_Update_Column {
  /** column name */
  CouplesId = 'couples_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id'
}

export type Couples_Patients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Couples_Patients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Couples_Patients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Couples_Patients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Couples_Patients_Var_Pop_Fields = {
  __typename?: 'couples_patients_var_pop_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "couples_patients" */
export type Couples_Patients_Var_Pop_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Couples_Patients_Var_Samp_Fields = {
  __typename?: 'couples_patients_var_samp_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "couples_patients" */
export type Couples_Patients_Var_Samp_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Couples_Patients_Variance_Fields = {
  __typename?: 'couples_patients_variance_fields';
  couples_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "couples_patients" */
export type Couples_Patients_Variance_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couples */
export type Couples_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "couples" */
export enum Couples_Select_Column {
  /** column name */
  CoupleName = 'couple_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedOn = 'deactivated_on',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "couples_aggregate_bool_exp_bool_and_arguments_columns" columns of table "couples" */
export enum Couples_Select_Column_Couples_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active'
}

/** select "couples_aggregate_bool_exp_bool_or_arguments_columns" columns of table "couples" */
export enum Couples_Select_Column_Couples_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active'
}

/** input type for updating data in table "couples" */
export type Couples_Set_Input = {
  couple_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Couples_Stddev_Fields = {
  __typename?: 'couples_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "couples" */
export type Couples_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Couples_Stddev_Pop_Fields = {
  __typename?: 'couples_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "couples" */
export type Couples_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Couples_Stddev_Samp_Fields = {
  __typename?: 'couples_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "couples" */
export type Couples_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "couples" */
export type Couples_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Couples_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Couples_Stream_Cursor_Value_Input = {
  couple_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Couples_Sum_Fields = {
  __typename?: 'couples_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "couples" */
export type Couples_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "couples_therapists" */
export type Couples_Therapists = {
  __typename?: 'couples_therapists';
  /** An object relationship */
  couple: Couples;
  couples_id: Scalars['bigint'];
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "couples_therapists" */
export type Couples_Therapists_Aggregate = {
  __typename?: 'couples_therapists_aggregate';
  aggregate?: Maybe<Couples_Therapists_Aggregate_Fields>;
  nodes: Array<Couples_Therapists>;
};

export type Couples_Therapists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Couples_Therapists_Aggregate_Bool_Exp_Count>;
};

export type Couples_Therapists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Couples_Therapists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "couples_therapists" */
export type Couples_Therapists_Aggregate_Fields = {
  __typename?: 'couples_therapists_aggregate_fields';
  avg?: Maybe<Couples_Therapists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Couples_Therapists_Max_Fields>;
  min?: Maybe<Couples_Therapists_Min_Fields>;
  stddev?: Maybe<Couples_Therapists_Stddev_Fields>;
  stddev_pop?: Maybe<Couples_Therapists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Couples_Therapists_Stddev_Samp_Fields>;
  sum?: Maybe<Couples_Therapists_Sum_Fields>;
  var_pop?: Maybe<Couples_Therapists_Var_Pop_Fields>;
  var_samp?: Maybe<Couples_Therapists_Var_Samp_Fields>;
  variance?: Maybe<Couples_Therapists_Variance_Fields>;
};


/** aggregate fields of "couples_therapists" */
export type Couples_Therapists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "couples_therapists" */
export type Couples_Therapists_Aggregate_Order_By = {
  avg?: InputMaybe<Couples_Therapists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Couples_Therapists_Max_Order_By>;
  min?: InputMaybe<Couples_Therapists_Min_Order_By>;
  stddev?: InputMaybe<Couples_Therapists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Couples_Therapists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Couples_Therapists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Couples_Therapists_Sum_Order_By>;
  var_pop?: InputMaybe<Couples_Therapists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Couples_Therapists_Var_Samp_Order_By>;
  variance?: InputMaybe<Couples_Therapists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "couples_therapists" */
export type Couples_Therapists_Arr_Rel_Insert_Input = {
  data: Array<Couples_Therapists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Couples_Therapists_On_Conflict>;
};

/** aggregate avg on columns */
export type Couples_Therapists_Avg_Fields = {
  __typename?: 'couples_therapists_avg_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "couples_therapists" */
export type Couples_Therapists_Avg_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "couples_therapists". All fields are combined with a logical 'AND'. */
export type Couples_Therapists_Bool_Exp = {
  _and?: InputMaybe<Array<Couples_Therapists_Bool_Exp>>;
  _not?: InputMaybe<Couples_Therapists_Bool_Exp>;
  _or?: InputMaybe<Array<Couples_Therapists_Bool_Exp>>;
  couple?: InputMaybe<Couples_Bool_Exp>;
  couples_id?: InputMaybe<Bigint_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "couples_therapists" */
export enum Couples_Therapists_Constraint {
  /** unique or primary key constraint on columns "couples_id", "employee_id" */
  CouplesTherapistsCouplesIdEmployeeIdF2243e09Uniq = 'couples_therapists_couples_id_employee_id_f2243e09_uniq',
  /** unique or primary key constraint on columns "id" */
  CouplesTherapistsPkey = 'couples_therapists_pkey'
}

/** input type for incrementing numeric columns in table "couples_therapists" */
export type Couples_Therapists_Inc_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "couples_therapists" */
export type Couples_Therapists_Insert_Input = {
  couple?: InputMaybe<Couples_Obj_Rel_Insert_Input>;
  couples_id?: InputMaybe<Scalars['bigint']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Couples_Therapists_Max_Fields = {
  __typename?: 'couples_therapists_max_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "couples_therapists" */
export type Couples_Therapists_Max_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Couples_Therapists_Min_Fields = {
  __typename?: 'couples_therapists_min_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "couples_therapists" */
export type Couples_Therapists_Min_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "couples_therapists" */
export type Couples_Therapists_Mutation_Response = {
  __typename?: 'couples_therapists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Couples_Therapists>;
};

/** on_conflict condition type for table "couples_therapists" */
export type Couples_Therapists_On_Conflict = {
  constraint: Couples_Therapists_Constraint;
  update_columns?: Array<Couples_Therapists_Update_Column>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};

/** Ordering options when selecting data from "couples_therapists". */
export type Couples_Therapists_Order_By = {
  couple?: InputMaybe<Couples_Order_By>;
  couples_id?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: couples_therapists */
export type Couples_Therapists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "couples_therapists" */
export enum Couples_Therapists_Select_Column {
  /** column name */
  CouplesId = 'couples_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "couples_therapists" */
export type Couples_Therapists_Set_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Couples_Therapists_Stddev_Fields = {
  __typename?: 'couples_therapists_stddev_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "couples_therapists" */
export type Couples_Therapists_Stddev_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Couples_Therapists_Stddev_Pop_Fields = {
  __typename?: 'couples_therapists_stddev_pop_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "couples_therapists" */
export type Couples_Therapists_Stddev_Pop_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Couples_Therapists_Stddev_Samp_Fields = {
  __typename?: 'couples_therapists_stddev_samp_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "couples_therapists" */
export type Couples_Therapists_Stddev_Samp_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "couples_therapists" */
export type Couples_Therapists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Couples_Therapists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Couples_Therapists_Stream_Cursor_Value_Input = {
  couples_id?: InputMaybe<Scalars['bigint']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Couples_Therapists_Sum_Fields = {
  __typename?: 'couples_therapists_sum_fields';
  couples_id?: Maybe<Scalars['bigint']>;
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "couples_therapists" */
export type Couples_Therapists_Sum_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "couples_therapists" */
export enum Couples_Therapists_Update_Column {
  /** column name */
  CouplesId = 'couples_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id'
}

export type Couples_Therapists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Couples_Therapists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Couples_Therapists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Couples_Therapists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Couples_Therapists_Var_Pop_Fields = {
  __typename?: 'couples_therapists_var_pop_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "couples_therapists" */
export type Couples_Therapists_Var_Pop_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Couples_Therapists_Var_Samp_Fields = {
  __typename?: 'couples_therapists_var_samp_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "couples_therapists" */
export type Couples_Therapists_Var_Samp_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Couples_Therapists_Variance_Fields = {
  __typename?: 'couples_therapists_variance_fields';
  couples_id?: Maybe<Scalars['Float']>;
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "couples_therapists" */
export type Couples_Therapists_Variance_Order_By = {
  couples_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "couples" */
export enum Couples_Update_Column {
  /** column name */
  CoupleName = 'couple_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedOn = 'deactivated_on',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Couples_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Couples_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Couples_Set_Input>;
  /** filter the rows which have to be updated */
  where: Couples_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Couples_Var_Pop_Fields = {
  __typename?: 'couples_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "couples" */
export type Couples_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Couples_Var_Samp_Fields = {
  __typename?: 'couples_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "couples" */
export type Couples_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Couples_Variance_Fields = {
  __typename?: 'couples_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "couples" */
export type Couples_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['date']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['date']>>;
  _eq?: InputMaybe<Array<Scalars['date']>>;
  _gt?: InputMaybe<Array<Scalars['date']>>;
  _gte?: InputMaybe<Array<Scalars['date']>>;
  _in?: InputMaybe<Array<Array<Scalars['date']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['date']>>;
  _lte?: InputMaybe<Array<Scalars['date']>>;
  _neq?: InputMaybe<Array<Scalars['date']>>;
  _nin?: InputMaybe<Array<Array<Scalars['date']>>>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "ed_attachments" */
export type Ed_Attachments = {
  __typename?: 'ed_attachments';
  added_by_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  event: Event;
  event_id: Scalars['bigint'];
  file: Scalars['String'];
  id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ed_attachments" */
export type Ed_Attachments_Aggregate = {
  __typename?: 'ed_attachments_aggregate';
  aggregate?: Maybe<Ed_Attachments_Aggregate_Fields>;
  nodes: Array<Ed_Attachments>;
};

export type Ed_Attachments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ed_Attachments_Aggregate_Bool_Exp_Count>;
};

export type Ed_Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ed_Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ed_attachments" */
export type Ed_Attachments_Aggregate_Fields = {
  __typename?: 'ed_attachments_aggregate_fields';
  avg?: Maybe<Ed_Attachments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ed_Attachments_Max_Fields>;
  min?: Maybe<Ed_Attachments_Min_Fields>;
  stddev?: Maybe<Ed_Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Ed_Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ed_Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Ed_Attachments_Sum_Fields>;
  var_pop?: Maybe<Ed_Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Ed_Attachments_Var_Samp_Fields>;
  variance?: Maybe<Ed_Attachments_Variance_Fields>;
};


/** aggregate fields of "ed_attachments" */
export type Ed_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ed_attachments" */
export type Ed_Attachments_Aggregate_Order_By = {
  avg?: InputMaybe<Ed_Attachments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ed_Attachments_Max_Order_By>;
  min?: InputMaybe<Ed_Attachments_Min_Order_By>;
  stddev?: InputMaybe<Ed_Attachments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ed_Attachments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ed_Attachments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ed_Attachments_Sum_Order_By>;
  var_pop?: InputMaybe<Ed_Attachments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ed_Attachments_Var_Samp_Order_By>;
  variance?: InputMaybe<Ed_Attachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ed_attachments" */
export type Ed_Attachments_Arr_Rel_Insert_Input = {
  data: Array<Ed_Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Attachments_On_Conflict>;
};

/** aggregate avg on columns */
export type Ed_Attachments_Avg_Fields = {
  __typename?: 'ed_attachments_avg_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ed_attachments" */
export type Ed_Attachments_Avg_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ed_attachments". All fields are combined with a logical 'AND'. */
export type Ed_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Ed_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Ed_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Ed_Attachments_Bool_Exp>>;
  added_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  file?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ed_attachments" */
export enum Ed_Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  EdAttachmentsPkey = 'ed_attachments_pkey'
}

/** input type for incrementing numeric columns in table "ed_attachments" */
export type Ed_Attachments_Inc_Input = {
  added_by_id?: InputMaybe<Scalars['bigint']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ed_attachments" */
export type Ed_Attachments_Insert_Input = {
  added_by_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ed_Attachments_Max_Fields = {
  __typename?: 'ed_attachments_max_fields';
  added_by_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['bigint']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ed_attachments" */
export type Ed_Attachments_Max_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ed_Attachments_Min_Fields = {
  __typename?: 'ed_attachments_min_fields';
  added_by_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['bigint']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ed_attachments" */
export type Ed_Attachments_Min_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ed_attachments" */
export type Ed_Attachments_Mutation_Response = {
  __typename?: 'ed_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ed_Attachments>;
};

/** on_conflict condition type for table "ed_attachments" */
export type Ed_Attachments_On_Conflict = {
  constraint: Ed_Attachments_Constraint;
  update_columns?: Array<Ed_Attachments_Update_Column>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "ed_attachments". */
export type Ed_Attachments_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ed_attachments */
export type Ed_Attachments_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "ed_attachments" */
export enum Ed_Attachments_Select_Column {
  /** column name */
  AddedById = 'added_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ed_attachments" */
export type Ed_Attachments_Set_Input = {
  added_by_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Ed_Attachments_Stddev_Fields = {
  __typename?: 'ed_attachments_stddev_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ed_attachments" */
export type Ed_Attachments_Stddev_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ed_Attachments_Stddev_Pop_Fields = {
  __typename?: 'ed_attachments_stddev_pop_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ed_attachments" */
export type Ed_Attachments_Stddev_Pop_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ed_Attachments_Stddev_Samp_Fields = {
  __typename?: 'ed_attachments_stddev_samp_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ed_attachments" */
export type Ed_Attachments_Stddev_Samp_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ed_attachments" */
export type Ed_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ed_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ed_Attachments_Stream_Cursor_Value_Input = {
  added_by_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ed_Attachments_Sum_Fields = {
  __typename?: 'ed_attachments_sum_fields';
  added_by_id?: Maybe<Scalars['bigint']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "ed_attachments" */
export type Ed_Attachments_Sum_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ed_attachments" */
export enum Ed_Attachments_Update_Column {
  /** column name */
  AddedById = 'added_by_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Ed_Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ed_Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ed_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ed_Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ed_Attachments_Var_Pop_Fields = {
  __typename?: 'ed_attachments_var_pop_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ed_attachments" */
export type Ed_Attachments_Var_Pop_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ed_Attachments_Var_Samp_Fields = {
  __typename?: 'ed_attachments_var_samp_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ed_attachments" */
export type Ed_Attachments_Var_Samp_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ed_Attachments_Variance_Fields = {
  __typename?: 'ed_attachments_variance_fields';
  added_by_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ed_attachments" */
export type Ed_Attachments_Variance_Order_By = {
  added_by_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ed_group_presence" */
export type Ed_Group_Presence = {
  __typename?: 'ed_group_presence';
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  name: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "ed_group_presence" */
export type Ed_Group_PresenceEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "ed_group_presence" */
export type Ed_Group_PresenceEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** aggregated selection of "ed_group_presence" */
export type Ed_Group_Presence_Aggregate = {
  __typename?: 'ed_group_presence_aggregate';
  aggregate?: Maybe<Ed_Group_Presence_Aggregate_Fields>;
  nodes: Array<Ed_Group_Presence>;
};

/** aggregate fields of "ed_group_presence" */
export type Ed_Group_Presence_Aggregate_Fields = {
  __typename?: 'ed_group_presence_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ed_Group_Presence_Max_Fields>;
  min?: Maybe<Ed_Group_Presence_Min_Fields>;
};


/** aggregate fields of "ed_group_presence" */
export type Ed_Group_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ed_Group_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ed_group_presence". All fields are combined with a logical 'AND'. */
export type Ed_Group_Presence_Bool_Exp = {
  _and?: InputMaybe<Array<Ed_Group_Presence_Bool_Exp>>;
  _not?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
  _or?: InputMaybe<Array<Ed_Group_Presence_Bool_Exp>>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ed_group_presence" */
export enum Ed_Group_Presence_Constraint {
  /** unique or primary key constraint on columns "name" */
  EdGroupPresencePkey = 'ed_group_presence_pkey'
}

export enum Ed_Group_Presence_Enum {
  /** Did not happen */
  DidNotHappen = 'DID_NOT_HAPPEN',
  /** holiday */
  Holiday = 'HOLIDAY',
  /** Meeting cancelled */
  MeetingCancelled = 'MEETING_CANCELLED',
  /** Meeting cancelled by me */
  MeetingCancelledByMe = 'MEETING_CANCELLED_BY_ME',
  /** Meeting took place */
  MeetingTookPlace = 'MEETING_TOOK_PLACE',
  /** One party cancelled */
  OnePartyCancelled = 'ONE_PARTY_CANCELLED',
  /** Outside working hours */
  OutsideWorkingHours = 'OUTSIDE_WORKING_HOURS'
}

/** Boolean expression to compare columns of type "ed_group_presence_enum". All fields are combined with logical 'AND'. */
export type Ed_Group_Presence_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ed_Group_Presence_Enum>;
  _in?: InputMaybe<Array<Ed_Group_Presence_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ed_Group_Presence_Enum>;
  _nin?: InputMaybe<Array<Ed_Group_Presence_Enum>>;
};

/** input type for inserting data into table "ed_group_presence" */
export type Ed_Group_Presence_Insert_Input = {
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ed_Group_Presence_Max_Fields = {
  __typename?: 'ed_group_presence_max_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ed_Group_Presence_Min_Fields = {
  __typename?: 'ed_group_presence_min_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ed_group_presence" */
export type Ed_Group_Presence_Mutation_Response = {
  __typename?: 'ed_group_presence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ed_Group_Presence>;
};

/** input type for inserting object relation for remote table "ed_group_presence" */
export type Ed_Group_Presence_Obj_Rel_Insert_Input = {
  data: Ed_Group_Presence_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Group_Presence_On_Conflict>;
};

/** on_conflict condition type for table "ed_group_presence" */
export type Ed_Group_Presence_On_Conflict = {
  constraint: Ed_Group_Presence_Constraint;
  update_columns?: Array<Ed_Group_Presence_Update_Column>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};

/** Ordering options when selecting data from "ed_group_presence". */
export type Ed_Group_Presence_Order_By = {
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ed_group_presence */
export type Ed_Group_Presence_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ed_group_presence" */
export enum Ed_Group_Presence_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ed_group_presence" */
export type Ed_Group_Presence_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ed_group_presence" */
export type Ed_Group_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ed_Group_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ed_Group_Presence_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ed_group_presence" */
export enum Ed_Group_Presence_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Ed_Group_Presence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ed_Group_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ed_Group_Presence_Bool_Exp;
};

/** columns and relationships of "ed_participants" */
export type Ed_Participants = {
  __typename?: 'ed_participants';
  amount_paid?: Maybe<Scalars['numeric']>;
  amount_to_pay?: Maybe<Scalars['numeric']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  ed_payment_method?: Maybe<Ed_Payment_Method>;
  /** An object relationship */
  ed_presence?: Maybe<Ed_Presence>;
  /** An array relationship */
  event_participants: Array<Event_Participants>;
  /** An aggregate relationship */
  event_participants_aggregate: Event_Participants_Aggregate;
  /** An array relationship */
  event_period_participants: Array<Event_Period_Participants>;
  /** An aggregate relationship */
  event_period_participants_aggregate: Event_Period_Participants_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  payment: Scalars['String'];
  payment_method_id?: Maybe<Ed_Payment_Method_Enum>;
  presence_id?: Maybe<Ed_Presence_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "ed_participants" */
export type Ed_ParticipantsEvent_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


/** columns and relationships of "ed_participants" */
export type Ed_ParticipantsEvent_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


/** columns and relationships of "ed_participants" */
export type Ed_ParticipantsEvent_Period_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


/** columns and relationships of "ed_participants" */
export type Ed_ParticipantsEvent_Period_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};

/** aggregated selection of "ed_participants" */
export type Ed_Participants_Aggregate = {
  __typename?: 'ed_participants_aggregate';
  aggregate?: Maybe<Ed_Participants_Aggregate_Fields>;
  nodes: Array<Ed_Participants>;
};

export type Ed_Participants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ed_Participants_Aggregate_Bool_Exp_Count>;
};

export type Ed_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ed_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ed_participants" */
export type Ed_Participants_Aggregate_Fields = {
  __typename?: 'ed_participants_aggregate_fields';
  avg?: Maybe<Ed_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ed_Participants_Max_Fields>;
  min?: Maybe<Ed_Participants_Min_Fields>;
  stddev?: Maybe<Ed_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Ed_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ed_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Ed_Participants_Sum_Fields>;
  var_pop?: Maybe<Ed_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Ed_Participants_Var_Samp_Fields>;
  variance?: Maybe<Ed_Participants_Variance_Fields>;
};


/** aggregate fields of "ed_participants" */
export type Ed_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ed_participants" */
export type Ed_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<Ed_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ed_Participants_Max_Order_By>;
  min?: InputMaybe<Ed_Participants_Min_Order_By>;
  stddev?: InputMaybe<Ed_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ed_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ed_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ed_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<Ed_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ed_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<Ed_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ed_participants" */
export type Ed_Participants_Arr_Rel_Insert_Input = {
  data: Array<Ed_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Ed_Participants_Avg_Fields = {
  __typename?: 'ed_participants_avg_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ed_participants" */
export type Ed_Participants_Avg_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ed_participants". All fields are combined with a logical 'AND'. */
export type Ed_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<Ed_Participants_Bool_Exp>>;
  _not?: InputMaybe<Ed_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<Ed_Participants_Bool_Exp>>;
  amount_paid?: InputMaybe<Numeric_Comparison_Exp>;
  amount_to_pay?: InputMaybe<Numeric_Comparison_Exp>;
  cancelled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ed_payment_method?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
  ed_presence?: InputMaybe<Ed_Presence_Bool_Exp>;
  event_participants?: InputMaybe<Event_Participants_Bool_Exp>;
  event_participants_aggregate?: InputMaybe<Event_Participants_Aggregate_Bool_Exp>;
  event_period_participants?: InputMaybe<Event_Period_Participants_Bool_Exp>;
  event_period_participants_aggregate?: InputMaybe<Event_Period_Participants_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  payment?: InputMaybe<String_Comparison_Exp>;
  payment_method_id?: InputMaybe<Ed_Payment_Method_Enum_Comparison_Exp>;
  presence_id?: InputMaybe<Ed_Presence_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ed_participants" */
export enum Ed_Participants_Constraint {
  /** unique or primary key constraint on columns "id" */
  EdParticipantsPkey = 'ed_participants_pkey'
}

/** input type for incrementing numeric columns in table "ed_participants" */
export type Ed_Participants_Inc_Input = {
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_to_pay?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ed_participants" */
export type Ed_Participants_Insert_Input = {
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_to_pay?: InputMaybe<Scalars['numeric']>;
  cancelled_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  ed_payment_method?: InputMaybe<Ed_Payment_Method_Obj_Rel_Insert_Input>;
  ed_presence?: InputMaybe<Ed_Presence_Obj_Rel_Insert_Input>;
  event_participants?: InputMaybe<Event_Participants_Arr_Rel_Insert_Input>;
  event_period_participants?: InputMaybe<Event_Period_Participants_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Ed_Payment_Method_Enum>;
  presence_id?: InputMaybe<Ed_Presence_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ed_Participants_Max_Fields = {
  __typename?: 'ed_participants_max_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
  amount_to_pay?: Maybe<Scalars['numeric']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  payment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ed_participants" */
export type Ed_Participants_Max_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ed_Participants_Min_Fields = {
  __typename?: 'ed_participants_min_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
  amount_to_pay?: Maybe<Scalars['numeric']>;
  cancelled_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  payment?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ed_participants" */
export type Ed_Participants_Min_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ed_participants" */
export type Ed_Participants_Mutation_Response = {
  __typename?: 'ed_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ed_Participants>;
};

/** input type for inserting object relation for remote table "ed_participants" */
export type Ed_Participants_Obj_Rel_Insert_Input = {
  data: Ed_Participants_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Participants_On_Conflict>;
};

/** on_conflict condition type for table "ed_participants" */
export type Ed_Participants_On_Conflict = {
  constraint: Ed_Participants_Constraint;
  update_columns?: Array<Ed_Participants_Update_Column>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "ed_participants". */
export type Ed_Participants_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  ed_payment_method?: InputMaybe<Ed_Payment_Method_Order_By>;
  ed_presence?: InputMaybe<Ed_Presence_Order_By>;
  event_participants_aggregate?: InputMaybe<Event_Participants_Aggregate_Order_By>;
  event_period_participants_aggregate?: InputMaybe<Event_Period_Participants_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  presence_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ed_participants */
export type Ed_Participants_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "ed_participants" */
export enum Ed_Participants_Select_Column {
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountToPay = 'amount_to_pay',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Payment = 'payment',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PresenceId = 'presence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "ed_participants" */
export type Ed_Participants_Set_Input = {
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_to_pay?: InputMaybe<Scalars['numeric']>;
  cancelled_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Ed_Payment_Method_Enum>;
  presence_id?: InputMaybe<Ed_Presence_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Ed_Participants_Stddev_Fields = {
  __typename?: 'ed_participants_stddev_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ed_participants" */
export type Ed_Participants_Stddev_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ed_Participants_Stddev_Pop_Fields = {
  __typename?: 'ed_participants_stddev_pop_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ed_participants" */
export type Ed_Participants_Stddev_Pop_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ed_Participants_Stddev_Samp_Fields = {
  __typename?: 'ed_participants_stddev_samp_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ed_participants" */
export type Ed_Participants_Stddev_Samp_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ed_participants" */
export type Ed_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ed_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ed_Participants_Stream_Cursor_Value_Input = {
  amount_paid?: InputMaybe<Scalars['numeric']>;
  amount_to_pay?: InputMaybe<Scalars['numeric']>;
  cancelled_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Ed_Payment_Method_Enum>;
  presence_id?: InputMaybe<Ed_Presence_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ed_Participants_Sum_Fields = {
  __typename?: 'ed_participants_sum_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
  amount_to_pay?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "ed_participants" */
export type Ed_Participants_Sum_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "ed_participants" */
export enum Ed_Participants_Update_Column {
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  AmountToPay = 'amount_to_pay',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Payment = 'payment',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  PresenceId = 'presence_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Ed_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ed_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ed_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ed_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ed_Participants_Var_Pop_Fields = {
  __typename?: 'ed_participants_var_pop_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ed_participants" */
export type Ed_Participants_Var_Pop_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ed_Participants_Var_Samp_Fields = {
  __typename?: 'ed_participants_var_samp_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ed_participants" */
export type Ed_Participants_Var_Samp_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ed_Participants_Variance_Fields = {
  __typename?: 'ed_participants_variance_fields';
  amount_paid?: Maybe<Scalars['Float']>;
  amount_to_pay?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ed_participants" */
export type Ed_Participants_Variance_Order_By = {
  amount_paid?: InputMaybe<Order_By>;
  amount_to_pay?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ed_payment_method" */
export type Ed_Payment_Method = {
  __typename?: 'ed_payment_method';
  /** An array relationship */
  ed_participants: Array<Ed_Participants>;
  /** An aggregate relationship */
  ed_participants_aggregate: Ed_Participants_Aggregate;
  name: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "ed_payment_method" */
export type Ed_Payment_MethodEd_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


/** columns and relationships of "ed_payment_method" */
export type Ed_Payment_MethodEd_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};

/** aggregated selection of "ed_payment_method" */
export type Ed_Payment_Method_Aggregate = {
  __typename?: 'ed_payment_method_aggregate';
  aggregate?: Maybe<Ed_Payment_Method_Aggregate_Fields>;
  nodes: Array<Ed_Payment_Method>;
};

/** aggregate fields of "ed_payment_method" */
export type Ed_Payment_Method_Aggregate_Fields = {
  __typename?: 'ed_payment_method_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ed_Payment_Method_Max_Fields>;
  min?: Maybe<Ed_Payment_Method_Min_Fields>;
};


/** aggregate fields of "ed_payment_method" */
export type Ed_Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ed_Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ed_payment_method". All fields are combined with a logical 'AND'. */
export type Ed_Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Ed_Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Ed_Payment_Method_Bool_Exp>>;
  ed_participants?: InputMaybe<Ed_Participants_Bool_Exp>;
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ed_payment_method" */
export enum Ed_Payment_Method_Constraint {
  /** unique or primary key constraint on columns "name" */
  EdPaymentMethodPkey = 'ed_payment_method_pkey'
}

export enum Ed_Payment_Method_Enum {
  /** Bank transfer */
  BankTransfer = 'BANK_TRANSFER',
  /** BLIK */
  Blik = 'BLIK',
  /** Cash */
  Cash = 'CASH',
  /** Credit card */
  CreditCard = 'CREDIT_CARD',
  /** Other */
  Other = 'OTHER',
  /** Przelewy24 */
  Przelewy24 = 'PRZELEWY24'
}

/** Boolean expression to compare columns of type "ed_payment_method_enum". All fields are combined with logical 'AND'. */
export type Ed_Payment_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ed_Payment_Method_Enum>;
  _in?: InputMaybe<Array<Ed_Payment_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ed_Payment_Method_Enum>;
  _nin?: InputMaybe<Array<Ed_Payment_Method_Enum>>;
};

/** input type for inserting data into table "ed_payment_method" */
export type Ed_Payment_Method_Insert_Input = {
  ed_participants?: InputMaybe<Ed_Participants_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ed_Payment_Method_Max_Fields = {
  __typename?: 'ed_payment_method_max_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ed_Payment_Method_Min_Fields = {
  __typename?: 'ed_payment_method_min_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ed_payment_method" */
export type Ed_Payment_Method_Mutation_Response = {
  __typename?: 'ed_payment_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ed_Payment_Method>;
};

/** input type for inserting object relation for remote table "ed_payment_method" */
export type Ed_Payment_Method_Obj_Rel_Insert_Input = {
  data: Ed_Payment_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Payment_Method_On_Conflict>;
};

/** on_conflict condition type for table "ed_payment_method" */
export type Ed_Payment_Method_On_Conflict = {
  constraint: Ed_Payment_Method_Constraint;
  update_columns?: Array<Ed_Payment_Method_Update_Column>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "ed_payment_method". */
export type Ed_Payment_Method_Order_By = {
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ed_payment_method */
export type Ed_Payment_Method_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ed_payment_method" */
export enum Ed_Payment_Method_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ed_payment_method" */
export type Ed_Payment_Method_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ed_payment_method" */
export type Ed_Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ed_Payment_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ed_Payment_Method_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ed_payment_method" */
export enum Ed_Payment_Method_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Ed_Payment_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ed_Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ed_Payment_Method_Bool_Exp;
};

/** columns and relationships of "ed_presence" */
export type Ed_Presence = {
  __typename?: 'ed_presence';
  /** An array relationship */
  ed_participants: Array<Ed_Participants>;
  /** An aggregate relationship */
  ed_participants_aggregate: Ed_Participants_Aggregate;
  name: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "ed_presence" */
export type Ed_PresenceEd_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


/** columns and relationships of "ed_presence" */
export type Ed_PresenceEd_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};

/** aggregated selection of "ed_presence" */
export type Ed_Presence_Aggregate = {
  __typename?: 'ed_presence_aggregate';
  aggregate?: Maybe<Ed_Presence_Aggregate_Fields>;
  nodes: Array<Ed_Presence>;
};

/** aggregate fields of "ed_presence" */
export type Ed_Presence_Aggregate_Fields = {
  __typename?: 'ed_presence_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ed_Presence_Max_Fields>;
  min?: Maybe<Ed_Presence_Min_Fields>;
};


/** aggregate fields of "ed_presence" */
export type Ed_Presence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ed_Presence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ed_presence". All fields are combined with a logical 'AND'. */
export type Ed_Presence_Bool_Exp = {
  _and?: InputMaybe<Array<Ed_Presence_Bool_Exp>>;
  _not?: InputMaybe<Ed_Presence_Bool_Exp>;
  _or?: InputMaybe<Array<Ed_Presence_Bool_Exp>>;
  ed_participants?: InputMaybe<Ed_Participants_Bool_Exp>;
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ed_presence" */
export enum Ed_Presence_Constraint {
  /** unique or primary key constraint on columns "name" */
  EdPresencePkey = 'ed_presence_pkey'
}

export enum Ed_Presence_Enum {
  /** Did not happen */
  DidNotHappen = 'DID_NOT_HAPPEN',
  /** holiday */
  Holiday = 'HOLIDAY',
  /** Meeting cancelled by me */
  MeetingCancelledByMe = 'MEETING_CANCELLED_BY_ME',
  /** On time */
  OnTime = 'ON_TIME',
  /** Others */
  Others = 'OTHERS',
  /** Outside working hours */
  OutsideWorkingHours = 'OUTSIDE_WORKING_HOURS',
  /** Patient cancelled - not payable */
  PatientCancelledNotPayable = 'PATIENT_CANCELLED_NOT_PAYABLE',
  /** Patient cancelled - payable */
  PatientCancelledPayable = 'PATIENT_CANCELLED_PAYABLE'
}

/** Boolean expression to compare columns of type "ed_presence_enum". All fields are combined with logical 'AND'. */
export type Ed_Presence_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ed_Presence_Enum>;
  _in?: InputMaybe<Array<Ed_Presence_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ed_Presence_Enum>;
  _nin?: InputMaybe<Array<Ed_Presence_Enum>>;
};

/** input type for inserting data into table "ed_presence" */
export type Ed_Presence_Insert_Input = {
  ed_participants?: InputMaybe<Ed_Participants_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ed_Presence_Max_Fields = {
  __typename?: 'ed_presence_max_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ed_Presence_Min_Fields = {
  __typename?: 'ed_presence_min_fields';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ed_presence" */
export type Ed_Presence_Mutation_Response = {
  __typename?: 'ed_presence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ed_Presence>;
};

/** input type for inserting object relation for remote table "ed_presence" */
export type Ed_Presence_Obj_Rel_Insert_Input = {
  data: Ed_Presence_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ed_Presence_On_Conflict>;
};

/** on_conflict condition type for table "ed_presence" */
export type Ed_Presence_On_Conflict = {
  constraint: Ed_Presence_Constraint;
  update_columns?: Array<Ed_Presence_Update_Column>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};

/** Ordering options when selecting data from "ed_presence". */
export type Ed_Presence_Order_By = {
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ed_presence */
export type Ed_Presence_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ed_presence" */
export enum Ed_Presence_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ed_presence" */
export type Ed_Presence_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ed_presence" */
export type Ed_Presence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ed_Presence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ed_Presence_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ed_presence" */
export enum Ed_Presence_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Ed_Presence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ed_Presence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ed_Presence_Bool_Exp;
};

/** columns and relationships of "employees" */
export type Employees = {
  __typename?: 'employees';
  accepted_invitation: Scalars['Boolean'];
  /** An array relationship */
  accounting_practiceexpenses: Array<Accounting_Practiceexpense>;
  /** An aggregate relationship */
  accounting_practiceexpenses_aggregate: Accounting_Practiceexpense_Aggregate;
  acronym?: Maybe<Scalars['String']>;
  available_in_online_registration: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  /** An array relationship */
  couples_therapists: Array<Couples_Therapists>;
  /** An aggregate relationship */
  couples_therapists_aggregate: Couples_Therapists_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_patients: Array<Patients_Therapists>;
  /** An aggregate relationship */
  employee_patients_aggregate: Patients_Therapists_Aggregate;
  /** An array relationship */
  employeesFavoriteTherapistsByToEmployeeId: Array<Employees_Favorite_Therapists>;
  /** An aggregate relationship */
  employeesFavoriteTherapistsByToEmployeeId_aggregate: Employees_Favorite_Therapists_Aggregate;
  /** An array relationship */
  employees_favorite_therapists: Array<Employees_Favorite_Therapists>;
  /** An aggregate relationship */
  employees_favorite_therapists_aggregate: Employees_Favorite_Therapists_Aggregate;
  /** An array relationship */
  employees_preferred_rooms: Array<Employees_Preferred_Room>;
  /** An aggregate relationship */
  employees_preferred_rooms_aggregate: Employees_Preferred_Room_Aggregate;
  /** An array relationship */
  employees_roles: Array<Employees_Roles>;
  /** An aggregate relationship */
  employees_roles_aggregate: Employees_Roles_Aggregate;
  /** An array relationship */
  event_period_therapists: Array<Event_Period_Therapists>;
  /** An aggregate relationship */
  event_period_therapists_aggregate: Event_Period_Therapists_Aggregate;
  /** An array relationship */
  event_therapists: Array<Event_Therapists>;
  /** An aggregate relationship */
  event_therapists_aggregate: Event_Therapists_Aggregate;
  first_name: Scalars['String'];
  full_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  id: Scalars['bigint'];
  invitation_password?: Maybe<Scalars['String']>;
  invitation_uuid: Scalars['uuid'];
  last_name: Scalars['String'];
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phone_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  /** An array relationship */
  registrationMeetingrequestsByTherapistId: Array<Registration_Meetingrequest>;
  /** An aggregate relationship */
  registrationMeetingrequestsByTherapistId_aggregate: Registration_Meetingrequest_Aggregate;
  /** An array relationship */
  registration_meetingrequests: Array<Registration_Meetingrequest>;
  /** An aggregate relationship */
  registration_meetingrequests_aggregate: Registration_Meetingrequest_Aggregate;
  /** An array relationship */
  request_considerations: Array<Request_Considerations>;
  /** An aggregate relationship */
  request_considerations_aggregate: Request_Considerations_Aggregate;
  /** An array relationship */
  services_assigned_tos: Array<Services_Assigned_To>;
  /** An aggregate relationship */
  services_assigned_tos_aggregate: Services_Assigned_To_Aggregate;
  short_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  sms_notifications_smspacketpurchases: Array<Sms_Notifications_Smspacketpurchase>;
  /** An aggregate relationship */
  sms_notifications_smspacketpurchases_aggregate: Sms_Notifications_Smspacketpurchase_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  worktime_presets: Array<Worktime_Presets>;
  /** An aggregate relationship */
  worktime_presets_aggregate: Worktime_Presets_Aggregate;
};


/** columns and relationships of "employees" */
export type EmployeesAccounting_PracticeexpensesArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesAccounting_Practiceexpenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesCouples_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesCouples_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployee_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployee_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployeesFavoriteTherapistsByToEmployeeIdArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployeesFavoriteTherapistsByToEmployeeId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_Favorite_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_Favorite_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_Preferred_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_Preferred_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_RolesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEmployees_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEvent_Period_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEvent_Period_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEvent_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesEvent_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRegistrationMeetingrequestsByTherapistIdArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRegistrationMeetingrequestsByTherapistId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRegistration_MeetingrequestsArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRegistration_Meetingrequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRequest_ConsiderationsArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesRequest_Considerations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesServices_Assigned_TosArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesServices_Assigned_Tos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesSms_Notifications_SmspacketpurchasesArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesSms_Notifications_Smspacketpurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesWorktime_PresetsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeesWorktime_Presets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};

/** aggregated selection of "employees" */
export type Employees_Aggregate = {
  __typename?: 'employees_aggregate';
  aggregate?: Maybe<Employees_Aggregate_Fields>;
  nodes: Array<Employees>;
};

export type Employees_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Employees_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Employees_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Employees_Aggregate_Bool_Exp_Count>;
};

export type Employees_Aggregate_Bool_Exp_Bool_And = {
  arguments: Employees_Select_Column_Employees_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Employees_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Employees_Select_Column_Employees_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Employees_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employees" */
export type Employees_Aggregate_Fields = {
  __typename?: 'employees_aggregate_fields';
  avg?: Maybe<Employees_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employees_Max_Fields>;
  min?: Maybe<Employees_Min_Fields>;
  stddev?: Maybe<Employees_Stddev_Fields>;
  stddev_pop?: Maybe<Employees_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employees_Stddev_Samp_Fields>;
  sum?: Maybe<Employees_Sum_Fields>;
  var_pop?: Maybe<Employees_Var_Pop_Fields>;
  var_samp?: Maybe<Employees_Var_Samp_Fields>;
  variance?: Maybe<Employees_Variance_Fields>;
};


/** aggregate fields of "employees" */
export type Employees_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employees_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employees" */
export type Employees_Aggregate_Order_By = {
  avg?: InputMaybe<Employees_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employees_Max_Order_By>;
  min?: InputMaybe<Employees_Min_Order_By>;
  stddev?: InputMaybe<Employees_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employees_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employees_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employees_Sum_Order_By>;
  var_pop?: InputMaybe<Employees_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employees_Var_Samp_Order_By>;
  variance?: InputMaybe<Employees_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employees" */
export type Employees_Arr_Rel_Insert_Input = {
  data: Array<Employees_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employees_On_Conflict>;
};

/** aggregate avg on columns */
export type Employees_Avg_Fields = {
  __typename?: 'employees_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employees" */
export type Employees_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employees". All fields are combined with a logical 'AND'. */
export type Employees_Bool_Exp = {
  _and?: InputMaybe<Array<Employees_Bool_Exp>>;
  _not?: InputMaybe<Employees_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_Bool_Exp>>;
  accepted_invitation?: InputMaybe<Boolean_Comparison_Exp>;
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp>;
  acronym?: InputMaybe<String_Comparison_Exp>;
  available_in_online_registration?: InputMaybe<Boolean_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  couples_therapists?: InputMaybe<Couples_Therapists_Bool_Exp>;
  couples_therapists_aggregate?: InputMaybe<Couples_Therapists_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employee_patients?: InputMaybe<Patients_Therapists_Bool_Exp>;
  employee_patients_aggregate?: InputMaybe<Patients_Therapists_Aggregate_Bool_Exp>;
  employeesFavoriteTherapistsByToEmployeeId?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
  employeesFavoriteTherapistsByToEmployeeId_aggregate?: InputMaybe<Employees_Favorite_Therapists_Aggregate_Bool_Exp>;
  employees_favorite_therapists?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
  employees_favorite_therapists_aggregate?: InputMaybe<Employees_Favorite_Therapists_Aggregate_Bool_Exp>;
  employees_preferred_rooms?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
  employees_preferred_rooms_aggregate?: InputMaybe<Employees_Preferred_Room_Aggregate_Bool_Exp>;
  employees_roles?: InputMaybe<Employees_Roles_Bool_Exp>;
  employees_roles_aggregate?: InputMaybe<Employees_Roles_Aggregate_Bool_Exp>;
  event_period_therapists?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
  event_period_therapists_aggregate?: InputMaybe<Event_Period_Therapists_Aggregate_Bool_Exp>;
  event_therapists?: InputMaybe<Event_Therapists_Bool_Exp>;
  event_therapists_aggregate?: InputMaybe<Event_Therapists_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
  holidays?: InputMaybe<Holidays_Bool_Exp>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invitation_password?: InputMaybe<String_Comparison_Exp>;
  invitation_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  registrationMeetingrequestsByTherapistId?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  registrationMeetingrequestsByTherapistId_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Bool_Exp>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Bool_Exp>;
  request_considerations?: InputMaybe<Request_Considerations_Bool_Exp>;
  request_considerations_aggregate?: InputMaybe<Request_Considerations_Aggregate_Bool_Exp>;
  services_assigned_tos?: InputMaybe<Services_Assigned_To_Bool_Exp>;
  services_assigned_tos_aggregate?: InputMaybe<Services_Assigned_To_Aggregate_Bool_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  sms_notifications_smspacketpurchases?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
  sms_notifications_smspacketpurchases_aggregate?: InputMaybe<Sms_Notifications_Smspacketpurchase_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  worktime_presets?: InputMaybe<Worktime_Presets_Bool_Exp>;
  worktime_presets_aggregate?: InputMaybe<Worktime_Presets_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "employees" */
export enum Employees_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeesPkey = 'employees_pkey'
}

/** columns and relationships of "employees_favorite_therapists" */
export type Employees_Favorite_Therapists = {
  __typename?: 'employees_favorite_therapists';
  /** An object relationship */
  employee: Employees;
  /** An object relationship */
  employeeByToEmployeeId: Employees;
  from_employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  to_employee_id: Scalars['bigint'];
};

/** aggregated selection of "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Aggregate = {
  __typename?: 'employees_favorite_therapists_aggregate';
  aggregate?: Maybe<Employees_Favorite_Therapists_Aggregate_Fields>;
  nodes: Array<Employees_Favorite_Therapists>;
};

export type Employees_Favorite_Therapists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employees_Favorite_Therapists_Aggregate_Bool_Exp_Count>;
};

export type Employees_Favorite_Therapists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Aggregate_Fields = {
  __typename?: 'employees_favorite_therapists_aggregate_fields';
  avg?: Maybe<Employees_Favorite_Therapists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employees_Favorite_Therapists_Max_Fields>;
  min?: Maybe<Employees_Favorite_Therapists_Min_Fields>;
  stddev?: Maybe<Employees_Favorite_Therapists_Stddev_Fields>;
  stddev_pop?: Maybe<Employees_Favorite_Therapists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employees_Favorite_Therapists_Stddev_Samp_Fields>;
  sum?: Maybe<Employees_Favorite_Therapists_Sum_Fields>;
  var_pop?: Maybe<Employees_Favorite_Therapists_Var_Pop_Fields>;
  var_samp?: Maybe<Employees_Favorite_Therapists_Var_Samp_Fields>;
  variance?: Maybe<Employees_Favorite_Therapists_Variance_Fields>;
};


/** aggregate fields of "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Aggregate_Order_By = {
  avg?: InputMaybe<Employees_Favorite_Therapists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employees_Favorite_Therapists_Max_Order_By>;
  min?: InputMaybe<Employees_Favorite_Therapists_Min_Order_By>;
  stddev?: InputMaybe<Employees_Favorite_Therapists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employees_Favorite_Therapists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employees_Favorite_Therapists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employees_Favorite_Therapists_Sum_Order_By>;
  var_pop?: InputMaybe<Employees_Favorite_Therapists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employees_Favorite_Therapists_Var_Samp_Order_By>;
  variance?: InputMaybe<Employees_Favorite_Therapists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Arr_Rel_Insert_Input = {
  data: Array<Employees_Favorite_Therapists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employees_Favorite_Therapists_On_Conflict>;
};

/** aggregate avg on columns */
export type Employees_Favorite_Therapists_Avg_Fields = {
  __typename?: 'employees_favorite_therapists_avg_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Avg_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employees_favorite_therapists". All fields are combined with a logical 'AND'. */
export type Employees_Favorite_Therapists_Bool_Exp = {
  _and?: InputMaybe<Array<Employees_Favorite_Therapists_Bool_Exp>>;
  _not?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_Favorite_Therapists_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employeeByToEmployeeId?: InputMaybe<Employees_Bool_Exp>;
  from_employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  to_employee_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "employees_favorite_therapists" */
export enum Employees_Favorite_Therapists_Constraint {
  /** unique or primary key constraint on columns "to_employee_id", "from_employee_id" */
  EmployeesFavoriteTheraFromEmployeeIdToEmpl_0b3af8b5Uniq = 'employees_favorite_thera_from_employee_id_to_empl_0b3af8b5_uniq',
  /** unique or primary key constraint on columns "id" */
  EmployeesFavoriteTherapistsPkey = 'employees_favorite_therapists_pkey'
}

/** input type for incrementing numeric columns in table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Inc_Input = {
  from_employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_employee_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employeeByToEmployeeId?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  from_employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_employee_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Employees_Favorite_Therapists_Max_Fields = {
  __typename?: 'employees_favorite_therapists_max_fields';
  from_employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  to_employee_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Max_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employees_Favorite_Therapists_Min_Fields = {
  __typename?: 'employees_favorite_therapists_min_fields';
  from_employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  to_employee_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Min_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Mutation_Response = {
  __typename?: 'employees_favorite_therapists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees_Favorite_Therapists>;
};

/** on_conflict condition type for table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_On_Conflict = {
  constraint: Employees_Favorite_Therapists_Constraint;
  update_columns?: Array<Employees_Favorite_Therapists_Update_Column>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};

/** Ordering options when selecting data from "employees_favorite_therapists". */
export type Employees_Favorite_Therapists_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employeeByToEmployeeId?: InputMaybe<Employees_Order_By>;
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employees_favorite_therapists */
export type Employees_Favorite_Therapists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "employees_favorite_therapists" */
export enum Employees_Favorite_Therapists_Select_Column {
  /** column name */
  FromEmployeeId = 'from_employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToEmployeeId = 'to_employee_id'
}

/** input type for updating data in table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Set_Input = {
  from_employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_employee_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Employees_Favorite_Therapists_Stddev_Fields = {
  __typename?: 'employees_favorite_therapists_stddev_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Stddev_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employees_Favorite_Therapists_Stddev_Pop_Fields = {
  __typename?: 'employees_favorite_therapists_stddev_pop_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Stddev_Pop_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employees_Favorite_Therapists_Stddev_Samp_Fields = {
  __typename?: 'employees_favorite_therapists_stddev_samp_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Stddev_Samp_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_Favorite_Therapists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_Favorite_Therapists_Stream_Cursor_Value_Input = {
  from_employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  to_employee_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Employees_Favorite_Therapists_Sum_Fields = {
  __typename?: 'employees_favorite_therapists_sum_fields';
  from_employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  to_employee_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Sum_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** update columns of table "employees_favorite_therapists" */
export enum Employees_Favorite_Therapists_Update_Column {
  /** column name */
  FromEmployeeId = 'from_employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToEmployeeId = 'to_employee_id'
}

export type Employees_Favorite_Therapists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employees_Favorite_Therapists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employees_Favorite_Therapists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employees_Favorite_Therapists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employees_Favorite_Therapists_Var_Pop_Fields = {
  __typename?: 'employees_favorite_therapists_var_pop_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Var_Pop_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employees_Favorite_Therapists_Var_Samp_Fields = {
  __typename?: 'employees_favorite_therapists_var_samp_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Var_Samp_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employees_Favorite_Therapists_Variance_Fields = {
  __typename?: 'employees_favorite_therapists_variance_fields';
  from_employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  to_employee_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employees_favorite_therapists" */
export type Employees_Favorite_Therapists_Variance_Order_By = {
  from_employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_employee_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "employees" */
export type Employees_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "employees" */
export type Employees_Insert_Input = {
  accepted_invitation?: InputMaybe<Scalars['Boolean']>;
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Arr_Rel_Insert_Input>;
  acronym?: InputMaybe<Scalars['String']>;
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  couples_therapists?: InputMaybe<Couples_Therapists_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employee_patients?: InputMaybe<Patients_Therapists_Arr_Rel_Insert_Input>;
  employeesFavoriteTherapistsByToEmployeeId?: InputMaybe<Employees_Favorite_Therapists_Arr_Rel_Insert_Input>;
  employees_favorite_therapists?: InputMaybe<Employees_Favorite_Therapists_Arr_Rel_Insert_Input>;
  employees_preferred_rooms?: InputMaybe<Employees_Preferred_Room_Arr_Rel_Insert_Input>;
  employees_roles?: InputMaybe<Employees_Roles_Arr_Rel_Insert_Input>;
  event_period_therapists?: InputMaybe<Event_Period_Therapists_Arr_Rel_Insert_Input>;
  event_therapists?: InputMaybe<Event_Therapists_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  holidays?: InputMaybe<Holidays_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  invitation_password?: InputMaybe<Scalars['String']>;
  invitation_uuid?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  registrationMeetingrequestsByTherapistId?: InputMaybe<Registration_Meetingrequest_Arr_Rel_Insert_Input>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Arr_Rel_Insert_Input>;
  request_considerations?: InputMaybe<Request_Considerations_Arr_Rel_Insert_Input>;
  services_assigned_tos?: InputMaybe<Services_Assigned_To_Arr_Rel_Insert_Input>;
  short_description?: InputMaybe<Scalars['String']>;
  sms_notifications_smspacketpurchases?: InputMaybe<Sms_Notifications_Smspacketpurchase_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
  worktime_presets?: InputMaybe<Worktime_Presets_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Employees_Max_Fields = {
  __typename?: 'employees_max_fields';
  acronym?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  invitation_password?: Maybe<Scalars['String']>;
  invitation_uuid?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  short_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "employees" */
export type Employees_Max_Order_By = {
  acronym?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_password?: InputMaybe<Order_By>;
  invitation_uuid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employees_Min_Fields = {
  __typename?: 'employees_min_fields';
  acronym?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  invitation_password?: Maybe<Scalars['String']>;
  invitation_uuid?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  short_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "employees" */
export type Employees_Min_Order_By = {
  acronym?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_password?: InputMaybe<Order_By>;
  invitation_uuid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employees" */
export type Employees_Mutation_Response = {
  __typename?: 'employees_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees>;
};

/** input type for inserting object relation for remote table "employees" */
export type Employees_Obj_Rel_Insert_Input = {
  data: Employees_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employees_On_Conflict>;
};

/** on_conflict condition type for table "employees" */
export type Employees_On_Conflict = {
  constraint: Employees_Constraint;
  update_columns?: Array<Employees_Update_Column>;
  where?: InputMaybe<Employees_Bool_Exp>;
};

/** Ordering options when selecting data from "employees". */
export type Employees_Order_By = {
  accepted_invitation?: InputMaybe<Order_By>;
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Order_By>;
  acronym?: InputMaybe<Order_By>;
  available_in_online_registration?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  couples_therapists_aggregate?: InputMaybe<Couples_Therapists_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee_patients_aggregate?: InputMaybe<Patients_Therapists_Aggregate_Order_By>;
  employeesFavoriteTherapistsByToEmployeeId_aggregate?: InputMaybe<Employees_Favorite_Therapists_Aggregate_Order_By>;
  employees_favorite_therapists_aggregate?: InputMaybe<Employees_Favorite_Therapists_Aggregate_Order_By>;
  employees_preferred_rooms_aggregate?: InputMaybe<Employees_Preferred_Room_Aggregate_Order_By>;
  employees_roles_aggregate?: InputMaybe<Employees_Roles_Aggregate_Order_By>;
  event_period_therapists_aggregate?: InputMaybe<Event_Period_Therapists_Aggregate_Order_By>;
  event_therapists_aggregate?: InputMaybe<Event_Therapists_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitation_password?: InputMaybe<Order_By>;
  invitation_uuid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  registrationMeetingrequestsByTherapistId_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Order_By>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Order_By>;
  request_considerations_aggregate?: InputMaybe<Request_Considerations_Aggregate_Order_By>;
  services_assigned_tos_aggregate?: InputMaybe<Services_Assigned_To_Aggregate_Order_By>;
  short_description?: InputMaybe<Order_By>;
  sms_notifications_smspacketpurchases_aggregate?: InputMaybe<Sms_Notifications_Smspacketpurchase_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  worktime_presets_aggregate?: InputMaybe<Worktime_Presets_Aggregate_Order_By>;
};

/** primary key columns input for table: employees */
export type Employees_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "employees_preferred_room" */
export type Employees_Preferred_Room = {
  __typename?: 'employees_preferred_room';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  room: Rooms;
  room_id: Scalars['bigint'];
};

/** aggregated selection of "employees_preferred_room" */
export type Employees_Preferred_Room_Aggregate = {
  __typename?: 'employees_preferred_room_aggregate';
  aggregate?: Maybe<Employees_Preferred_Room_Aggregate_Fields>;
  nodes: Array<Employees_Preferred_Room>;
};

export type Employees_Preferred_Room_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employees_Preferred_Room_Aggregate_Bool_Exp_Count>;
};

export type Employees_Preferred_Room_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employees_preferred_room" */
export type Employees_Preferred_Room_Aggregate_Fields = {
  __typename?: 'employees_preferred_room_aggregate_fields';
  avg?: Maybe<Employees_Preferred_Room_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employees_Preferred_Room_Max_Fields>;
  min?: Maybe<Employees_Preferred_Room_Min_Fields>;
  stddev?: Maybe<Employees_Preferred_Room_Stddev_Fields>;
  stddev_pop?: Maybe<Employees_Preferred_Room_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employees_Preferred_Room_Stddev_Samp_Fields>;
  sum?: Maybe<Employees_Preferred_Room_Sum_Fields>;
  var_pop?: Maybe<Employees_Preferred_Room_Var_Pop_Fields>;
  var_samp?: Maybe<Employees_Preferred_Room_Var_Samp_Fields>;
  variance?: Maybe<Employees_Preferred_Room_Variance_Fields>;
};


/** aggregate fields of "employees_preferred_room" */
export type Employees_Preferred_Room_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employees_preferred_room" */
export type Employees_Preferred_Room_Aggregate_Order_By = {
  avg?: InputMaybe<Employees_Preferred_Room_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employees_Preferred_Room_Max_Order_By>;
  min?: InputMaybe<Employees_Preferred_Room_Min_Order_By>;
  stddev?: InputMaybe<Employees_Preferred_Room_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employees_Preferred_Room_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employees_Preferred_Room_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employees_Preferred_Room_Sum_Order_By>;
  var_pop?: InputMaybe<Employees_Preferred_Room_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employees_Preferred_Room_Var_Samp_Order_By>;
  variance?: InputMaybe<Employees_Preferred_Room_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employees_preferred_room" */
export type Employees_Preferred_Room_Arr_Rel_Insert_Input = {
  data: Array<Employees_Preferred_Room_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employees_Preferred_Room_On_Conflict>;
};

/** aggregate avg on columns */
export type Employees_Preferred_Room_Avg_Fields = {
  __typename?: 'employees_preferred_room_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employees_preferred_room". All fields are combined with a logical 'AND'. */
export type Employees_Preferred_Room_Bool_Exp = {
  _and?: InputMaybe<Array<Employees_Preferred_Room_Bool_Exp>>;
  _not?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_Preferred_Room_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  room?: InputMaybe<Rooms_Bool_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "employees_preferred_room" */
export enum Employees_Preferred_Room_Constraint {
  /** unique or primary key constraint on columns "employee_id", "room_id" */
  EmployeesPreferredRoomEmployeeIdRoomId_0cbe1d63Uniq = 'employees_preferred_room_employee_id_room_id_0cbe1d63_uniq',
  /** unique or primary key constraint on columns "id" */
  EmployeesPreferredRoomPkey = 'employees_preferred_room_pkey'
}

/** input type for incrementing numeric columns in table "employees_preferred_room" */
export type Employees_Preferred_Room_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "employees_preferred_room" */
export type Employees_Preferred_Room_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Employees_Preferred_Room_Max_Fields = {
  __typename?: 'employees_preferred_room_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employees_Preferred_Room_Min_Fields = {
  __typename?: 'employees_preferred_room_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employees_preferred_room" */
export type Employees_Preferred_Room_Mutation_Response = {
  __typename?: 'employees_preferred_room_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees_Preferred_Room>;
};

/** on_conflict condition type for table "employees_preferred_room" */
export type Employees_Preferred_Room_On_Conflict = {
  constraint: Employees_Preferred_Room_Constraint;
  update_columns?: Array<Employees_Preferred_Room_Update_Column>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};

/** Ordering options when selecting data from "employees_preferred_room". */
export type Employees_Preferred_Room_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room?: InputMaybe<Rooms_Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employees_preferred_room */
export type Employees_Preferred_Room_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "employees_preferred_room" */
export enum Employees_Preferred_Room_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id'
}

/** input type for updating data in table "employees_preferred_room" */
export type Employees_Preferred_Room_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Employees_Preferred_Room_Stddev_Fields = {
  __typename?: 'employees_preferred_room_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employees_Preferred_Room_Stddev_Pop_Fields = {
  __typename?: 'employees_preferred_room_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employees_Preferred_Room_Stddev_Samp_Fields = {
  __typename?: 'employees_preferred_room_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employees_preferred_room" */
export type Employees_Preferred_Room_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_Preferred_Room_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_Preferred_Room_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Employees_Preferred_Room_Sum_Fields = {
  __typename?: 'employees_preferred_room_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** update columns of table "employees_preferred_room" */
export enum Employees_Preferred_Room_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id'
}

export type Employees_Preferred_Room_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employees_Preferred_Room_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employees_Preferred_Room_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employees_Preferred_Room_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employees_Preferred_Room_Var_Pop_Fields = {
  __typename?: 'employees_preferred_room_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employees_Preferred_Room_Var_Samp_Fields = {
  __typename?: 'employees_preferred_room_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employees_Preferred_Room_Variance_Fields = {
  __typename?: 'employees_preferred_room_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employees_preferred_room" */
export type Employees_Preferred_Room_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "employees_roles" */
export type Employees_Roles = {
  __typename?: 'employees_roles';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  role: Roles;
  role_id: Roles_Enum;
};

/** aggregated selection of "employees_roles" */
export type Employees_Roles_Aggregate = {
  __typename?: 'employees_roles_aggregate';
  aggregate?: Maybe<Employees_Roles_Aggregate_Fields>;
  nodes: Array<Employees_Roles>;
};

export type Employees_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employees_Roles_Aggregate_Bool_Exp_Count>;
};

export type Employees_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employees_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employees_roles" */
export type Employees_Roles_Aggregate_Fields = {
  __typename?: 'employees_roles_aggregate_fields';
  avg?: Maybe<Employees_Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employees_Roles_Max_Fields>;
  min?: Maybe<Employees_Roles_Min_Fields>;
  stddev?: Maybe<Employees_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Employees_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employees_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Employees_Roles_Sum_Fields>;
  var_pop?: Maybe<Employees_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Employees_Roles_Var_Samp_Fields>;
  variance?: Maybe<Employees_Roles_Variance_Fields>;
};


/** aggregate fields of "employees_roles" */
export type Employees_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employees_roles" */
export type Employees_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<Employees_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employees_Roles_Max_Order_By>;
  min?: InputMaybe<Employees_Roles_Min_Order_By>;
  stddev?: InputMaybe<Employees_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employees_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employees_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employees_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<Employees_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employees_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<Employees_Roles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employees_roles" */
export type Employees_Roles_Arr_Rel_Insert_Input = {
  data: Array<Employees_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employees_Roles_On_Conflict>;
};

/** aggregate avg on columns */
export type Employees_Roles_Avg_Fields = {
  __typename?: 'employees_roles_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employees_roles" */
export type Employees_Roles_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employees_roles". All fields are combined with a logical 'AND'. */
export type Employees_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Employees_Roles_Bool_Exp>>;
  _not?: InputMaybe<Employees_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_Roles_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Roles_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "employees_roles" */
export enum Employees_Roles_Constraint {
  /** unique or primary key constraint on columns "employee_id", "role_id" */
  EmployeesRolesEmployeeIdRoleIdD15ac0bfUniq = 'employees_roles_employee_id_role_id_d15ac0bf_uniq',
  /** unique or primary key constraint on columns "id" */
  EmployeesRolesPkey = 'employees_roles_pkey'
}

/** input type for incrementing numeric columns in table "employees_roles" */
export type Employees_Roles_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "employees_roles" */
export type Employees_Roles_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Roles_Enum>;
};

/** aggregate max on columns */
export type Employees_Roles_Max_Fields = {
  __typename?: 'employees_roles_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "employees_roles" */
export type Employees_Roles_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employees_Roles_Min_Fields = {
  __typename?: 'employees_roles_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "employees_roles" */
export type Employees_Roles_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employees_roles" */
export type Employees_Roles_Mutation_Response = {
  __typename?: 'employees_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employees_Roles>;
};

/** on_conflict condition type for table "employees_roles" */
export type Employees_Roles_On_Conflict = {
  constraint: Employees_Roles_Constraint;
  update_columns?: Array<Employees_Roles_Update_Column>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "employees_roles". */
export type Employees_Roles_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employees_roles */
export type Employees_Roles_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "employees_roles" */
export enum Employees_Roles_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "employees_roles" */
export type Employees_Roles_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Roles_Enum>;
};

/** aggregate stddev on columns */
export type Employees_Roles_Stddev_Fields = {
  __typename?: 'employees_roles_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employees_roles" */
export type Employees_Roles_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employees_Roles_Stddev_Pop_Fields = {
  __typename?: 'employees_roles_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employees_roles" */
export type Employees_Roles_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employees_Roles_Stddev_Samp_Fields = {
  __typename?: 'employees_roles_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employees_roles" */
export type Employees_Roles_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employees_roles" */
export type Employees_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_Roles_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Roles_Enum>;
};

/** aggregate sum on columns */
export type Employees_Roles_Sum_Fields = {
  __typename?: 'employees_roles_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "employees_roles" */
export type Employees_Roles_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "employees_roles" */
export enum Employees_Roles_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id'
}

export type Employees_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employees_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employees_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employees_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employees_Roles_Var_Pop_Fields = {
  __typename?: 'employees_roles_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employees_roles" */
export type Employees_Roles_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employees_Roles_Var_Samp_Fields = {
  __typename?: 'employees_roles_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employees_roles" */
export type Employees_Roles_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employees_Roles_Variance_Fields = {
  __typename?: 'employees_roles_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employees_roles" */
export type Employees_Roles_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "employees" */
export enum Employees_Select_Column {
  /** column name */
  AcceptedInvitation = 'accepted_invitation',
  /** column name */
  Acronym = 'acronym',
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationPassword = 'invitation_password',
  /** column name */
  InvitationUuid = 'invitation_uuid',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "employees_aggregate_bool_exp_bool_and_arguments_columns" columns of table "employees" */
export enum Employees_Select_Column_Employees_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AcceptedInvitation = 'accepted_invitation',
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration'
}

/** select "employees_aggregate_bool_exp_bool_or_arguments_columns" columns of table "employees" */
export enum Employees_Select_Column_Employees_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AcceptedInvitation = 'accepted_invitation',
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration'
}

/** input type for updating data in table "employees" */
export type Employees_Set_Input = {
  accepted_invitation?: InputMaybe<Scalars['Boolean']>;
  acronym?: InputMaybe<Scalars['String']>;
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  invitation_password?: InputMaybe<Scalars['String']>;
  invitation_uuid?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Employees_Stddev_Fields = {
  __typename?: 'employees_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employees" */
export type Employees_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employees_Stddev_Pop_Fields = {
  __typename?: 'employees_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employees" */
export type Employees_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employees_Stddev_Samp_Fields = {
  __typename?: 'employees_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employees" */
export type Employees_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employees" */
export type Employees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_Stream_Cursor_Value_Input = {
  accepted_invitation?: InputMaybe<Scalars['Boolean']>;
  acronym?: InputMaybe<Scalars['String']>;
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  invitation_password?: InputMaybe<Scalars['String']>;
  invitation_uuid?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  short_description?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Employees_Sum_Fields = {
  __typename?: 'employees_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "employees" */
export type Employees_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** update columns of table "employees" */
export enum Employees_Update_Column {
  /** column name */
  AcceptedInvitation = 'accepted_invitation',
  /** column name */
  Acronym = 'acronym',
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  InvitationPassword = 'invitation_password',
  /** column name */
  InvitationUuid = 'invitation_uuid',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Employees_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employees_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employees_Set_Input>;
  /** filter the rows which have to be updated */
  where: Employees_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employees_Var_Pop_Fields = {
  __typename?: 'employees_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employees" */
export type Employees_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employees_Var_Samp_Fields = {
  __typename?: 'employees_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employees" */
export type Employees_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employees_Variance_Fields = {
  __typename?: 'employees_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employees" */
export type Employees_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "event" */
export type Event = {
  __typename?: 'event';
  /** An object relationship */
  couple?: Maybe<Couples>;
  couple_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  duration: Scalars['interval'];
  /** An array relationship */
  ed_attachments: Array<Ed_Attachments>;
  /** An aggregate relationship */
  ed_attachments_aggregate: Ed_Attachments_Aggregate;
  /** An object relationship */
  ed_group_presence?: Maybe<Ed_Group_Presence>;
  /** An array relationship */
  event_participants: Array<Event_Participants>;
  /** An aggregate relationship */
  event_participants_aggregate: Event_Participants_Aggregate;
  /** An object relationship */
  event_period?: Maybe<Event_Period>;
  /** An array relationship */
  event_services: Array<Event_Services>;
  /** An aggregate relationship */
  event_services_aggregate: Event_Services_Aggregate;
  /** An array relationship */
  event_therapists: Array<Event_Therapists>;
  /** An aggregate relationship */
  event_therapists_aggregate: Event_Therapists_Aggregate;
  group_presence_id?: Maybe<Ed_Group_Presence_Enum>;
  id: Scalars['bigint'];
  index: Scalars['Int'];
  /** An array relationship */
  invoices_v2_invoicelineitem_events: Array<Invoices_V2_Invoicelineitem_Events>;
  /** An aggregate relationship */
  invoices_v2_invoicelineitem_events_aggregate: Invoices_V2_Invoicelineitem_Events_Aggregate;
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  paid_on?: Maybe<Scalars['date']>;
  patient_note?: Maybe<Scalars['String']>;
  period_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  room: Rooms;
  room_id: Scalars['bigint'];
  secret_note: Scalars['String'];
  shifted: Scalars['Boolean'];
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  start: Scalars['timestamptz'];
  start_before_shift?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "event" */
export type EventEd_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEd_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventEvent_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventInvoices_V2_Invoicelineitem_EventsArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventInvoices_V2_Invoicelineitem_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "event" */
export type EventSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};

/** aggregated selection of "event" */
export type Event_Aggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<Event_Aggregate_Fields>;
  nodes: Array<Event>;
};

export type Event_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Event_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Event_Aggregate_Bool_Exp_Count>;
};

export type Event_Aggregate_Bool_Exp_Bool_And = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event" */
export type Event_Aggregate_Fields = {
  __typename?: 'event_aggregate_fields';
  avg?: Maybe<Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Max_Fields>;
  min?: Maybe<Event_Min_Fields>;
  stddev?: Maybe<Event_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Sum_Fields>;
  var_pop?: Maybe<Event_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Var_Samp_Fields>;
  variance?: Maybe<Event_Variance_Fields>;
};


/** aggregate fields of "event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Max_Order_By>;
  min?: InputMaybe<Event_Min_Order_By>;
  stddev?: InputMaybe<Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event" */
export type Event_Arr_Rel_Insert_Input = {
  data: Array<Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Avg_Fields = {
  __typename?: 'event_avg_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event" */
export type Event_Avg_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Bool_Exp>>;
  _not?: InputMaybe<Event_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Bool_Exp>>;
  couple?: InputMaybe<Couples_Bool_Exp>;
  couple_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Interval_Comparison_Exp>;
  ed_attachments?: InputMaybe<Ed_Attachments_Bool_Exp>;
  ed_attachments_aggregate?: InputMaybe<Ed_Attachments_Aggregate_Bool_Exp>;
  ed_group_presence?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
  event_participants?: InputMaybe<Event_Participants_Bool_Exp>;
  event_participants_aggregate?: InputMaybe<Event_Participants_Aggregate_Bool_Exp>;
  event_period?: InputMaybe<Event_Period_Bool_Exp>;
  event_services?: InputMaybe<Event_Services_Bool_Exp>;
  event_services_aggregate?: InputMaybe<Event_Services_Aggregate_Bool_Exp>;
  event_therapists?: InputMaybe<Event_Therapists_Bool_Exp>;
  event_therapists_aggregate?: InputMaybe<Event_Therapists_Aggregate_Bool_Exp>;
  group_presence_id?: InputMaybe<Ed_Group_Presence_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  invoices_v2_invoicelineitem_events?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
  invoices_v2_invoicelineitem_events_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Bool_Exp>;
  locked?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  paid_on?: InputMaybe<Date_Comparison_Exp>;
  patient_note?: InputMaybe<String_Comparison_Exp>;
  period_id?: InputMaybe<Bigint_Comparison_Exp>;
  room?: InputMaybe<Rooms_Bool_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
  secret_note?: InputMaybe<String_Comparison_Exp>;
  shifted?: InputMaybe<Boolean_Comparison_Exp>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Bool_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  start_before_shift?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event" */
export enum Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventPkey = 'event_pkey',
  /** unique or primary key constraint on columns "index", "period_id" */
  UniqueWithOptional = 'unique_with_optional'
}

/** input type for incrementing numeric columns in table "event" */
export type Event_Inc_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  index?: InputMaybe<Scalars['Int']>;
  period_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event" */
export type Event_Insert_Input = {
  couple?: InputMaybe<Couples_Obj_Rel_Insert_Input>;
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  ed_attachments?: InputMaybe<Ed_Attachments_Arr_Rel_Insert_Input>;
  ed_group_presence?: InputMaybe<Ed_Group_Presence_Obj_Rel_Insert_Input>;
  event_participants?: InputMaybe<Event_Participants_Arr_Rel_Insert_Input>;
  event_period?: InputMaybe<Event_Period_Obj_Rel_Insert_Input>;
  event_services?: InputMaybe<Event_Services_Arr_Rel_Insert_Input>;
  event_therapists?: InputMaybe<Event_Therapists_Arr_Rel_Insert_Input>;
  group_presence_id?: InputMaybe<Ed_Group_Presence_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  index?: InputMaybe<Scalars['Int']>;
  invoices_v2_invoicelineitem_events?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Arr_Rel_Insert_Input>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paid_on?: InputMaybe<Scalars['date']>;
  patient_note?: InputMaybe<Scalars['String']>;
  period_id?: InputMaybe<Scalars['bigint']>;
  room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['bigint']>;
  secret_note?: InputMaybe<Scalars['String']>;
  shifted?: InputMaybe<Scalars['Boolean']>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Arr_Rel_Insert_Input>;
  start?: InputMaybe<Scalars['timestamptz']>;
  start_before_shift?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Max_Fields = {
  __typename?: 'event_max_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  paid_on?: Maybe<Scalars['date']>;
  patient_note?: Maybe<Scalars['String']>;
  period_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  secret_note?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  start_before_shift?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  secret_note?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  start_before_shift?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  __typename?: 'event_min_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  paid_on?: Maybe<Scalars['date']>;
  patient_note?: Maybe<Scalars['String']>;
  period_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  secret_note?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  start_before_shift?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  secret_note?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  start_before_shift?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event" */
export type Event_Mutation_Response = {
  __typename?: 'event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event>;
};

/** input type for inserting object relation for remote table "event" */
export type Event_Obj_Rel_Insert_Input = {
  data: Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_On_Conflict>;
};

/** on_conflict condition type for table "event" */
export type Event_On_Conflict = {
  constraint: Event_Constraint;
  update_columns?: Array<Event_Update_Column>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  couple?: InputMaybe<Couples_Order_By>;
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  ed_attachments_aggregate?: InputMaybe<Ed_Attachments_Aggregate_Order_By>;
  ed_group_presence?: InputMaybe<Ed_Group_Presence_Order_By>;
  event_participants_aggregate?: InputMaybe<Event_Participants_Aggregate_Order_By>;
  event_period?: InputMaybe<Event_Period_Order_By>;
  event_services_aggregate?: InputMaybe<Event_Services_Aggregate_Order_By>;
  event_therapists_aggregate?: InputMaybe<Event_Therapists_Aggregate_Order_By>;
  group_presence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  invoices_v2_invoicelineitem_events_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Order_By>;
  locked?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room?: InputMaybe<Rooms_Order_By>;
  room_id?: InputMaybe<Order_By>;
  secret_note?: InputMaybe<Order_By>;
  shifted?: InputMaybe<Order_By>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Order_By>;
  start?: InputMaybe<Order_By>;
  start_before_shift?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_participants" */
export type Event_Participants = {
  __typename?: 'event_participants';
  /** An object relationship */
  ed_participant: Ed_Participants;
  /** An object relationship */
  event: Event;
  event_id: Scalars['bigint'];
  eventparticipants_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "event_participants" */
export type Event_Participants_Aggregate = {
  __typename?: 'event_participants_aggregate';
  aggregate?: Maybe<Event_Participants_Aggregate_Fields>;
  nodes: Array<Event_Participants>;
};

export type Event_Participants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Participants_Aggregate_Bool_Exp_Count>;
};

export type Event_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_participants" */
export type Event_Participants_Aggregate_Fields = {
  __typename?: 'event_participants_aggregate_fields';
  avg?: Maybe<Event_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Participants_Max_Fields>;
  min?: Maybe<Event_Participants_Min_Fields>;
  stddev?: Maybe<Event_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Participants_Sum_Fields>;
  var_pop?: Maybe<Event_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Participants_Var_Samp_Fields>;
  variance?: Maybe<Event_Participants_Variance_Fields>;
};


/** aggregate fields of "event_participants" */
export type Event_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_participants" */
export type Event_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Participants_Max_Order_By>;
  min?: InputMaybe<Event_Participants_Min_Order_By>;
  stddev?: InputMaybe<Event_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_participants" */
export type Event_Participants_Arr_Rel_Insert_Input = {
  data: Array<Event_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Participants_Avg_Fields = {
  __typename?: 'event_participants_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_participants" */
export type Event_Participants_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_participants". All fields are combined with a logical 'AND'. */
export type Event_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Participants_Bool_Exp>>;
  _not?: InputMaybe<Event_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Participants_Bool_Exp>>;
  ed_participant?: InputMaybe<Ed_Participants_Bool_Exp>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  eventparticipants_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_participants" */
export enum Event_Participants_Constraint {
  /** unique or primary key constraint on columns "eventparticipants_id", "event_id" */
  EventParticipantsEventIdEventparticipantsId_06ad72a9Uniq = 'event_participants_event_id_eventparticipants_id_06ad72a9_uniq',
  /** unique or primary key constraint on columns "id" */
  EventParticipantsPkey = 'event_participants_pkey'
}

/** input type for incrementing numeric columns in table "event_participants" */
export type Event_Participants_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_participants" */
export type Event_Participants_Insert_Input = {
  ed_participant?: InputMaybe<Ed_Participants_Obj_Rel_Insert_Input>;
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Participants_Max_Fields = {
  __typename?: 'event_participants_max_fields';
  event_id?: Maybe<Scalars['bigint']>;
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_participants" */
export type Event_Participants_Max_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Participants_Min_Fields = {
  __typename?: 'event_participants_min_fields';
  event_id?: Maybe<Scalars['bigint']>;
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_participants" */
export type Event_Participants_Min_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_participants" */
export type Event_Participants_Mutation_Response = {
  __typename?: 'event_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Participants>;
};

/** on_conflict condition type for table "event_participants" */
export type Event_Participants_On_Conflict = {
  constraint: Event_Participants_Constraint;
  update_columns?: Array<Event_Participants_Update_Column>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "event_participants". */
export type Event_Participants_Order_By = {
  ed_participant?: InputMaybe<Ed_Participants_Order_By>;
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_participants */
export type Event_Participants_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_participants" */
export enum Event_Participants_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventparticipantsId = 'eventparticipants_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "event_participants" */
export type Event_Participants_Set_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Participants_Stddev_Fields = {
  __typename?: 'event_participants_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_participants" */
export type Event_Participants_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Participants_Stddev_Pop_Fields = {
  __typename?: 'event_participants_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_participants" */
export type Event_Participants_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Participants_Stddev_Samp_Fields = {
  __typename?: 'event_participants_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_participants" */
export type Event_Participants_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_participants" */
export type Event_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Participants_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Participants_Sum_Fields = {
  __typename?: 'event_participants_sum_fields';
  event_id?: Maybe<Scalars['bigint']>;
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_participants" */
export type Event_Participants_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event_participants" */
export enum Event_Participants_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventparticipantsId = 'eventparticipants_id',
  /** column name */
  Id = 'id'
}

export type Event_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Participants_Var_Pop_Fields = {
  __typename?: 'event_participants_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_participants" */
export type Event_Participants_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Participants_Var_Samp_Fields = {
  __typename?: 'event_participants_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_participants" */
export type Event_Participants_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Participants_Variance_Fields = {
  __typename?: 'event_participants_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  eventparticipants_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_participants" */
export type Event_Participants_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_period" */
export type Event_Period = {
  __typename?: 'event_period';
  /** An object relationship */
  couple?: Maybe<Couples>;
  couple_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  day_of_the_week_event_start_dates: Scalars['jsonb'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dows: Array<Scalars['Int']>;
  duration: Scalars['interval'];
  end?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  event_period_participants: Array<Event_Period_Participants>;
  /** An aggregate relationship */
  event_period_participants_aggregate: Event_Period_Participants_Aggregate;
  /** An array relationship */
  event_period_services: Array<Event_Period_Services>;
  /** An aggregate relationship */
  event_period_services_aggregate: Event_Period_Services_Aggregate;
  /** An array relationship */
  event_period_therapists: Array<Event_Period_Therapists>;
  /** An aggregate relationship */
  event_period_therapists_aggregate: Event_Period_Therapists_Aggregate;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  id: Scalars['bigint'];
  interval: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  room: Rooms;
  room_id: Scalars['bigint'];
  start: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "event_period" */
export type Event_PeriodDay_Of_The_Week_Event_Start_DatesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvent_Period_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "event_period" */
export type Event_PeriodEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};

/** aggregated selection of "event_period" */
export type Event_Period_Aggregate = {
  __typename?: 'event_period_aggregate';
  aggregate?: Maybe<Event_Period_Aggregate_Fields>;
  nodes: Array<Event_Period>;
};

export type Event_Period_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Period_Aggregate_Bool_Exp_Count>;
};

export type Event_Period_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Period_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Period_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_period" */
export type Event_Period_Aggregate_Fields = {
  __typename?: 'event_period_aggregate_fields';
  avg?: Maybe<Event_Period_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Period_Max_Fields>;
  min?: Maybe<Event_Period_Min_Fields>;
  stddev?: Maybe<Event_Period_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Period_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Period_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Period_Sum_Fields>;
  var_pop?: Maybe<Event_Period_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Period_Var_Samp_Fields>;
  variance?: Maybe<Event_Period_Variance_Fields>;
};


/** aggregate fields of "event_period" */
export type Event_Period_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Period_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_period" */
export type Event_Period_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Period_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Period_Max_Order_By>;
  min?: InputMaybe<Event_Period_Min_Order_By>;
  stddev?: InputMaybe<Event_Period_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Period_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Period_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Period_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Period_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Period_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Period_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Period_Append_Input = {
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event_period" */
export type Event_Period_Arr_Rel_Insert_Input = {
  data: Array<Event_Period_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Period_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Period_Avg_Fields = {
  __typename?: 'event_period_avg_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_period" */
export type Event_Period_Avg_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_period". All fields are combined with a logical 'AND'. */
export type Event_Period_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Period_Bool_Exp>>;
  _not?: InputMaybe<Event_Period_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Period_Bool_Exp>>;
  couple?: InputMaybe<Couples_Bool_Exp>;
  couple_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day_of_the_week_event_start_dates?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dows?: InputMaybe<Int_Array_Comparison_Exp>;
  duration?: InputMaybe<Interval_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_period_participants?: InputMaybe<Event_Period_Participants_Bool_Exp>;
  event_period_participants_aggregate?: InputMaybe<Event_Period_Participants_Aggregate_Bool_Exp>;
  event_period_services?: InputMaybe<Event_Period_Services_Bool_Exp>;
  event_period_services_aggregate?: InputMaybe<Event_Period_Services_Aggregate_Bool_Exp>;
  event_period_therapists?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
  event_period_therapists_aggregate?: InputMaybe<Event_Period_Therapists_Aggregate_Bool_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  interval?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  room?: InputMaybe<Rooms_Bool_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_period" */
export enum Event_Period_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventPeriodPkey = 'event_period_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Period_Delete_At_Path_Input = {
  day_of_the_week_event_start_dates?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Period_Delete_Elem_Input = {
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Period_Delete_Key_Input = {
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "event_period" */
export type Event_Period_Inc_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  interval?: InputMaybe<Scalars['Int']>;
  room_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_period" */
export type Event_Period_Insert_Input = {
  couple?: InputMaybe<Couples_Obj_Rel_Insert_Input>;
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dows?: InputMaybe<Array<Scalars['Int']>>;
  duration?: InputMaybe<Scalars['interval']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  event_period_participants?: InputMaybe<Event_Period_Participants_Arr_Rel_Insert_Input>;
  event_period_services?: InputMaybe<Event_Period_Services_Arr_Rel_Insert_Input>;
  event_period_therapists?: InputMaybe<Event_Period_Therapists_Arr_Rel_Insert_Input>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  interval?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Period_Max_Fields = {
  __typename?: 'event_period_max_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dows?: Maybe<Array<Scalars['Int']>>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interval?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event_period" */
export type Event_Period_Max_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dows?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Period_Min_Fields = {
  __typename?: 'event_period_min_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  dows?: Maybe<Array<Scalars['Int']>>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  interval?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  room_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event_period" */
export type Event_Period_Min_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dows?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_period" */
export type Event_Period_Mutation_Response = {
  __typename?: 'event_period_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Period>;
};

/** input type for inserting object relation for remote table "event_period" */
export type Event_Period_Obj_Rel_Insert_Input = {
  data: Event_Period_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Period_On_Conflict>;
};

/** on_conflict condition type for table "event_period" */
export type Event_Period_On_Conflict = {
  constraint: Event_Period_Constraint;
  update_columns?: Array<Event_Period_Update_Column>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};

/** Ordering options when selecting data from "event_period". */
export type Event_Period_Order_By = {
  couple?: InputMaybe<Couples_Order_By>;
  couple_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day_of_the_week_event_start_dates?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dows?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  event_period_participants_aggregate?: InputMaybe<Event_Period_Participants_Aggregate_Order_By>;
  event_period_services_aggregate?: InputMaybe<Event_Period_Services_Aggregate_Order_By>;
  event_period_therapists_aggregate?: InputMaybe<Event_Period_Therapists_Aggregate_Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  room?: InputMaybe<Rooms_Order_By>;
  room_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_period_participants" */
export type Event_Period_Participants = {
  __typename?: 'event_period_participants';
  /** An object relationship */
  ed_participant: Ed_Participants;
  /** An object relationship */
  event_period: Event_Period;
  eventparticipants_id: Scalars['bigint'];
  eventperiod_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "event_period_participants" */
export type Event_Period_Participants_Aggregate = {
  __typename?: 'event_period_participants_aggregate';
  aggregate?: Maybe<Event_Period_Participants_Aggregate_Fields>;
  nodes: Array<Event_Period_Participants>;
};

export type Event_Period_Participants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Period_Participants_Aggregate_Bool_Exp_Count>;
};

export type Event_Period_Participants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Period_Participants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_period_participants" */
export type Event_Period_Participants_Aggregate_Fields = {
  __typename?: 'event_period_participants_aggregate_fields';
  avg?: Maybe<Event_Period_Participants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Period_Participants_Max_Fields>;
  min?: Maybe<Event_Period_Participants_Min_Fields>;
  stddev?: Maybe<Event_Period_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Period_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Period_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Period_Participants_Sum_Fields>;
  var_pop?: Maybe<Event_Period_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Period_Participants_Var_Samp_Fields>;
  variance?: Maybe<Event_Period_Participants_Variance_Fields>;
};


/** aggregate fields of "event_period_participants" */
export type Event_Period_Participants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_period_participants" */
export type Event_Period_Participants_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Period_Participants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Period_Participants_Max_Order_By>;
  min?: InputMaybe<Event_Period_Participants_Min_Order_By>;
  stddev?: InputMaybe<Event_Period_Participants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Period_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Period_Participants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Period_Participants_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Period_Participants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Period_Participants_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Period_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_period_participants" */
export type Event_Period_Participants_Arr_Rel_Insert_Input = {
  data: Array<Event_Period_Participants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Period_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Period_Participants_Avg_Fields = {
  __typename?: 'event_period_participants_avg_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_period_participants" */
export type Event_Period_Participants_Avg_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_period_participants". All fields are combined with a logical 'AND'. */
export type Event_Period_Participants_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Period_Participants_Bool_Exp>>;
  _not?: InputMaybe<Event_Period_Participants_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Period_Participants_Bool_Exp>>;
  ed_participant?: InputMaybe<Ed_Participants_Bool_Exp>;
  event_period?: InputMaybe<Event_Period_Bool_Exp>;
  eventparticipants_id?: InputMaybe<Bigint_Comparison_Exp>;
  eventperiod_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_period_participants" */
export enum Event_Period_Participants_Constraint {
  /** unique or primary key constraint on columns "eventparticipants_id", "eventperiod_id" */
  EventPeriodParticipantEventperiodIdEventpart_431fe4a2Uniq = 'event_period_participant_eventperiod_id_eventpart_431fe4a2_uniq',
  /** unique or primary key constraint on columns "id" */
  EventPeriodParticipantsPkey = 'event_period_participants_pkey'
}

/** input type for incrementing numeric columns in table "event_period_participants" */
export type Event_Period_Participants_Inc_Input = {
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_period_participants" */
export type Event_Period_Participants_Insert_Input = {
  ed_participant?: InputMaybe<Ed_Participants_Obj_Rel_Insert_Input>;
  event_period?: InputMaybe<Event_Period_Obj_Rel_Insert_Input>;
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Period_Participants_Max_Fields = {
  __typename?: 'event_period_participants_max_fields';
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_period_participants" */
export type Event_Period_Participants_Max_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Period_Participants_Min_Fields = {
  __typename?: 'event_period_participants_min_fields';
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_period_participants" */
export type Event_Period_Participants_Min_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_period_participants" */
export type Event_Period_Participants_Mutation_Response = {
  __typename?: 'event_period_participants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Period_Participants>;
};

/** on_conflict condition type for table "event_period_participants" */
export type Event_Period_Participants_On_Conflict = {
  constraint: Event_Period_Participants_Constraint;
  update_columns?: Array<Event_Period_Participants_Update_Column>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};

/** Ordering options when selecting data from "event_period_participants". */
export type Event_Period_Participants_Order_By = {
  ed_participant?: InputMaybe<Ed_Participants_Order_By>;
  event_period?: InputMaybe<Event_Period_Order_By>;
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_period_participants */
export type Event_Period_Participants_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_period_participants" */
export enum Event_Period_Participants_Select_Column {
  /** column name */
  EventparticipantsId = 'eventparticipants_id',
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "event_period_participants" */
export type Event_Period_Participants_Set_Input = {
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Period_Participants_Stddev_Fields = {
  __typename?: 'event_period_participants_stddev_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_period_participants" */
export type Event_Period_Participants_Stddev_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Period_Participants_Stddev_Pop_Fields = {
  __typename?: 'event_period_participants_stddev_pop_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_period_participants" */
export type Event_Period_Participants_Stddev_Pop_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Period_Participants_Stddev_Samp_Fields = {
  __typename?: 'event_period_participants_stddev_samp_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_period_participants" */
export type Event_Period_Participants_Stddev_Samp_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_period_participants" */
export type Event_Period_Participants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Period_Participants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Period_Participants_Stream_Cursor_Value_Input = {
  eventparticipants_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Period_Participants_Sum_Fields = {
  __typename?: 'event_period_participants_sum_fields';
  eventparticipants_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_period_participants" */
export type Event_Period_Participants_Sum_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event_period_participants" */
export enum Event_Period_Participants_Update_Column {
  /** column name */
  EventparticipantsId = 'eventparticipants_id',
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id'
}

export type Event_Period_Participants_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Period_Participants_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Period_Participants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Period_Participants_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Period_Participants_Var_Pop_Fields = {
  __typename?: 'event_period_participants_var_pop_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_period_participants" */
export type Event_Period_Participants_Var_Pop_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Period_Participants_Var_Samp_Fields = {
  __typename?: 'event_period_participants_var_samp_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_period_participants" */
export type Event_Period_Participants_Var_Samp_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Period_Participants_Variance_Fields = {
  __typename?: 'event_period_participants_variance_fields';
  eventparticipants_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_period_participants" */
export type Event_Period_Participants_Variance_Order_By = {
  eventparticipants_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_period */
export type Event_Period_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Period_Prepend_Input = {
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "event_period" */
export enum Event_Period_Select_Column {
  /** column name */
  CoupleId = 'couple_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfTheWeekEventStartDates = 'day_of_the_week_event_start_dates',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dows = 'dows',
  /** column name */
  Duration = 'duration',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  Name = 'name',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "event_period_services" */
export type Event_Period_Services = {
  __typename?: 'event_period_services';
  /** An object relationship */
  event_period: Event_Period;
  eventperiod_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['bigint'];
};

/** aggregated selection of "event_period_services" */
export type Event_Period_Services_Aggregate = {
  __typename?: 'event_period_services_aggregate';
  aggregate?: Maybe<Event_Period_Services_Aggregate_Fields>;
  nodes: Array<Event_Period_Services>;
};

export type Event_Period_Services_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Period_Services_Aggregate_Bool_Exp_Count>;
};

export type Event_Period_Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Period_Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_period_services" */
export type Event_Period_Services_Aggregate_Fields = {
  __typename?: 'event_period_services_aggregate_fields';
  avg?: Maybe<Event_Period_Services_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Period_Services_Max_Fields>;
  min?: Maybe<Event_Period_Services_Min_Fields>;
  stddev?: Maybe<Event_Period_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Period_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Period_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Period_Services_Sum_Fields>;
  var_pop?: Maybe<Event_Period_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Period_Services_Var_Samp_Fields>;
  variance?: Maybe<Event_Period_Services_Variance_Fields>;
};


/** aggregate fields of "event_period_services" */
export type Event_Period_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_period_services" */
export type Event_Period_Services_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Period_Services_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Period_Services_Max_Order_By>;
  min?: InputMaybe<Event_Period_Services_Min_Order_By>;
  stddev?: InputMaybe<Event_Period_Services_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Period_Services_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Period_Services_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Period_Services_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Period_Services_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Period_Services_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Period_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_period_services" */
export type Event_Period_Services_Arr_Rel_Insert_Input = {
  data: Array<Event_Period_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Period_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Period_Services_Avg_Fields = {
  __typename?: 'event_period_services_avg_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_period_services" */
export type Event_Period_Services_Avg_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_period_services". All fields are combined with a logical 'AND'. */
export type Event_Period_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Period_Services_Bool_Exp>>;
  _not?: InputMaybe<Event_Period_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Period_Services_Bool_Exp>>;
  event_period?: InputMaybe<Event_Period_Bool_Exp>;
  eventperiod_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_period_services" */
export enum Event_Period_Services_Constraint {
  /** unique or primary key constraint on columns "service_id", "eventperiod_id" */
  EventPeriodServicesEventperiodIdServiceId_9ca206e5Uniq = 'event_period_services_eventperiod_id_service_id_9ca206e5_uniq',
  /** unique or primary key constraint on columns "id" */
  EventPeriodServicesPkey = 'event_period_services_pkey'
}

/** input type for incrementing numeric columns in table "event_period_services" */
export type Event_Period_Services_Inc_Input = {
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_period_services" */
export type Event_Period_Services_Insert_Input = {
  event_period?: InputMaybe<Event_Period_Obj_Rel_Insert_Input>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Period_Services_Max_Fields = {
  __typename?: 'event_period_services_max_fields';
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_period_services" */
export type Event_Period_Services_Max_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Period_Services_Min_Fields = {
  __typename?: 'event_period_services_min_fields';
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_period_services" */
export type Event_Period_Services_Min_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_period_services" */
export type Event_Period_Services_Mutation_Response = {
  __typename?: 'event_period_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Period_Services>;
};

/** on_conflict condition type for table "event_period_services" */
export type Event_Period_Services_On_Conflict = {
  constraint: Event_Period_Services_Constraint;
  update_columns?: Array<Event_Period_Services_Update_Column>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "event_period_services". */
export type Event_Period_Services_Order_By = {
  event_period?: InputMaybe<Event_Period_Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_period_services */
export type Event_Period_Services_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_period_services" */
export enum Event_Period_Services_Select_Column {
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "event_period_services" */
export type Event_Period_Services_Set_Input = {
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Period_Services_Stddev_Fields = {
  __typename?: 'event_period_services_stddev_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_period_services" */
export type Event_Period_Services_Stddev_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Period_Services_Stddev_Pop_Fields = {
  __typename?: 'event_period_services_stddev_pop_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_period_services" */
export type Event_Period_Services_Stddev_Pop_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Period_Services_Stddev_Samp_Fields = {
  __typename?: 'event_period_services_stddev_samp_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_period_services" */
export type Event_Period_Services_Stddev_Samp_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_period_services" */
export type Event_Period_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Period_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Period_Services_Stream_Cursor_Value_Input = {
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Period_Services_Sum_Fields = {
  __typename?: 'event_period_services_sum_fields';
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_period_services" */
export type Event_Period_Services_Sum_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** update columns of table "event_period_services" */
export enum Event_Period_Services_Update_Column {
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

export type Event_Period_Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Period_Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Period_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Period_Services_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Period_Services_Var_Pop_Fields = {
  __typename?: 'event_period_services_var_pop_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_period_services" */
export type Event_Period_Services_Var_Pop_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Period_Services_Var_Samp_Fields = {
  __typename?: 'event_period_services_var_samp_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_period_services" */
export type Event_Period_Services_Var_Samp_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Period_Services_Variance_Fields = {
  __typename?: 'event_period_services_variance_fields';
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_period_services" */
export type Event_Period_Services_Variance_Order_By = {
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** input type for updating data in table "event_period" */
export type Event_Period_Set_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dows?: InputMaybe<Array<Scalars['Int']>>;
  duration?: InputMaybe<Scalars['interval']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  interval?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Period_Stddev_Fields = {
  __typename?: 'event_period_stddev_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_period" */
export type Event_Period_Stddev_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Period_Stddev_Pop_Fields = {
  __typename?: 'event_period_stddev_pop_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_period" */
export type Event_Period_Stddev_Pop_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Period_Stddev_Samp_Fields = {
  __typename?: 'event_period_stddev_samp_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_period" */
export type Event_Period_Stddev_Samp_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_period" */
export type Event_Period_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Period_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Period_Stream_Cursor_Value_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day_of_the_week_event_start_dates?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  dows?: InputMaybe<Array<Scalars['Int']>>;
  duration?: InputMaybe<Scalars['interval']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  interval?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Period_Sum_Fields = {
  __typename?: 'event_period_sum_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  interval?: Maybe<Scalars['Int']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_period" */
export type Event_Period_Sum_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_period_therapists" */
export type Event_Period_Therapists = {
  __typename?: 'event_period_therapists';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  /** An object relationship */
  event_period: Event_Period;
  eventperiod_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "event_period_therapists" */
export type Event_Period_Therapists_Aggregate = {
  __typename?: 'event_period_therapists_aggregate';
  aggregate?: Maybe<Event_Period_Therapists_Aggregate_Fields>;
  nodes: Array<Event_Period_Therapists>;
};

export type Event_Period_Therapists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Period_Therapists_Aggregate_Bool_Exp_Count>;
};

export type Event_Period_Therapists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_period_therapists" */
export type Event_Period_Therapists_Aggregate_Fields = {
  __typename?: 'event_period_therapists_aggregate_fields';
  avg?: Maybe<Event_Period_Therapists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Period_Therapists_Max_Fields>;
  min?: Maybe<Event_Period_Therapists_Min_Fields>;
  stddev?: Maybe<Event_Period_Therapists_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Period_Therapists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Period_Therapists_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Period_Therapists_Sum_Fields>;
  var_pop?: Maybe<Event_Period_Therapists_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Period_Therapists_Var_Samp_Fields>;
  variance?: Maybe<Event_Period_Therapists_Variance_Fields>;
};


/** aggregate fields of "event_period_therapists" */
export type Event_Period_Therapists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_period_therapists" */
export type Event_Period_Therapists_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Period_Therapists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Period_Therapists_Max_Order_By>;
  min?: InputMaybe<Event_Period_Therapists_Min_Order_By>;
  stddev?: InputMaybe<Event_Period_Therapists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Period_Therapists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Period_Therapists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Period_Therapists_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Period_Therapists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Period_Therapists_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Period_Therapists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_period_therapists" */
export type Event_Period_Therapists_Arr_Rel_Insert_Input = {
  data: Array<Event_Period_Therapists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Period_Therapists_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Period_Therapists_Avg_Fields = {
  __typename?: 'event_period_therapists_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_period_therapists". All fields are combined with a logical 'AND'. */
export type Event_Period_Therapists_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Period_Therapists_Bool_Exp>>;
  _not?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Period_Therapists_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  event_period?: InputMaybe<Event_Period_Bool_Exp>;
  eventperiod_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_period_therapists" */
export enum Event_Period_Therapists_Constraint {
  /** unique or primary key constraint on columns "employee_id", "eventperiod_id" */
  EventPeriodTherapistsEventperiodIdEmployeeE93163bfUniq = 'event_period_therapists_eventperiod_id_employee__e93163bf_uniq',
  /** unique or primary key constraint on columns "id" */
  EventPeriodTherapistsPkey = 'event_period_therapists_pkey'
}

/** input type for incrementing numeric columns in table "event_period_therapists" */
export type Event_Period_Therapists_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_period_therapists" */
export type Event_Period_Therapists_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  event_period?: InputMaybe<Event_Period_Obj_Rel_Insert_Input>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Period_Therapists_Max_Fields = {
  __typename?: 'event_period_therapists_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Period_Therapists_Min_Fields = {
  __typename?: 'event_period_therapists_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_period_therapists" */
export type Event_Period_Therapists_Mutation_Response = {
  __typename?: 'event_period_therapists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Period_Therapists>;
};

/** on_conflict condition type for table "event_period_therapists" */
export type Event_Period_Therapists_On_Conflict = {
  constraint: Event_Period_Therapists_Constraint;
  update_columns?: Array<Event_Period_Therapists_Update_Column>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};

/** Ordering options when selecting data from "event_period_therapists". */
export type Event_Period_Therapists_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  event_period?: InputMaybe<Event_Period_Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_period_therapists */
export type Event_Period_Therapists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_period_therapists" */
export enum Event_Period_Therapists_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "event_period_therapists" */
export type Event_Period_Therapists_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Period_Therapists_Stddev_Fields = {
  __typename?: 'event_period_therapists_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Period_Therapists_Stddev_Pop_Fields = {
  __typename?: 'event_period_therapists_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Period_Therapists_Stddev_Samp_Fields = {
  __typename?: 'event_period_therapists_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_period_therapists" */
export type Event_Period_Therapists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Period_Therapists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Period_Therapists_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  eventperiod_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Period_Therapists_Sum_Fields = {
  __typename?: 'event_period_therapists_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  eventperiod_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event_period_therapists" */
export enum Event_Period_Therapists_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventperiodId = 'eventperiod_id',
  /** column name */
  Id = 'id'
}

export type Event_Period_Therapists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Period_Therapists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Period_Therapists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Period_Therapists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Period_Therapists_Var_Pop_Fields = {
  __typename?: 'event_period_therapists_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Period_Therapists_Var_Samp_Fields = {
  __typename?: 'event_period_therapists_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Period_Therapists_Variance_Fields = {
  __typename?: 'event_period_therapists_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  eventperiod_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_period_therapists" */
export type Event_Period_Therapists_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  eventperiod_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event_period" */
export enum Event_Period_Update_Column {
  /** column name */
  CoupleId = 'couple_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DayOfTheWeekEventStartDates = 'day_of_the_week_event_start_dates',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dows = 'dows',
  /** column name */
  Duration = 'duration',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  Name = 'name',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Event_Period_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Event_Period_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Event_Period_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Event_Period_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Event_Period_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Period_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Event_Period_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Period_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Period_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Period_Var_Pop_Fields = {
  __typename?: 'event_period_var_pop_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_period" */
export type Event_Period_Var_Pop_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Period_Var_Samp_Fields = {
  __typename?: 'event_period_var_samp_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_period" */
export type Event_Period_Var_Samp_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Period_Variance_Fields = {
  __typename?: 'event_period_variance_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_period" */
export type Event_Period_Variance_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interval?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event */
export type Event_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  CoupleId = 'couple_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  GroupPresenceId = 'group_presence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PaidOn = 'paid_on',
  /** column name */
  PatientNote = 'patient_note',
  /** column name */
  PeriodId = 'period_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SecretNote = 'secret_note',
  /** column name */
  Shifted = 'shifted',
  /** column name */
  Start = 'start',
  /** column name */
  StartBeforeShift = 'start_before_shift',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Locked = 'locked',
  /** column name */
  Shifted = 'shifted'
}

/** select "event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event" */
export enum Event_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Locked = 'locked',
  /** column name */
  Shifted = 'shifted'
}

/** columns and relationships of "event_services" */
export type Event_Services = {
  __typename?: 'event_services';
  /** An object relationship */
  event: Event;
  event_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['bigint'];
};

/** aggregated selection of "event_services" */
export type Event_Services_Aggregate = {
  __typename?: 'event_services_aggregate';
  aggregate?: Maybe<Event_Services_Aggregate_Fields>;
  nodes: Array<Event_Services>;
};

export type Event_Services_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Services_Aggregate_Bool_Exp_Count>;
};

export type Event_Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_services" */
export type Event_Services_Aggregate_Fields = {
  __typename?: 'event_services_aggregate_fields';
  avg?: Maybe<Event_Services_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Services_Max_Fields>;
  min?: Maybe<Event_Services_Min_Fields>;
  stddev?: Maybe<Event_Services_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Services_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Services_Sum_Fields>;
  var_pop?: Maybe<Event_Services_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Services_Var_Samp_Fields>;
  variance?: Maybe<Event_Services_Variance_Fields>;
};


/** aggregate fields of "event_services" */
export type Event_Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_services" */
export type Event_Services_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Services_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Services_Max_Order_By>;
  min?: InputMaybe<Event_Services_Min_Order_By>;
  stddev?: InputMaybe<Event_Services_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Services_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Services_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Services_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Services_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Services_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_services" */
export type Event_Services_Arr_Rel_Insert_Input = {
  data: Array<Event_Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Services_Avg_Fields = {
  __typename?: 'event_services_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_services" */
export type Event_Services_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_services". All fields are combined with a logical 'AND'. */
export type Event_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Services_Bool_Exp>>;
  _not?: InputMaybe<Event_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Services_Bool_Exp>>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_services" */
export enum Event_Services_Constraint {
  /** unique or primary key constraint on columns "service_id", "event_id" */
  EventServicesEventIdServiceIdCc453365Uniq = 'event_services_event_id_service_id_cc453365_uniq',
  /** unique or primary key constraint on columns "id" */
  EventServicesPkey = 'event_services_pkey'
}

/** input type for incrementing numeric columns in table "event_services" */
export type Event_Services_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_services" */
export type Event_Services_Insert_Input = {
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Services_Max_Fields = {
  __typename?: 'event_services_max_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_services" */
export type Event_Services_Max_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Services_Min_Fields = {
  __typename?: 'event_services_min_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_services" */
export type Event_Services_Min_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_services" */
export type Event_Services_Mutation_Response = {
  __typename?: 'event_services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Services>;
};

/** on_conflict condition type for table "event_services" */
export type Event_Services_On_Conflict = {
  constraint: Event_Services_Constraint;
  update_columns?: Array<Event_Services_Update_Column>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};

/** Ordering options when selecting data from "event_services". */
export type Event_Services_Order_By = {
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_services */
export type Event_Services_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_services" */
export enum Event_Services_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "event_services" */
export type Event_Services_Set_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Services_Stddev_Fields = {
  __typename?: 'event_services_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_services" */
export type Event_Services_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Services_Stddev_Pop_Fields = {
  __typename?: 'event_services_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_services" */
export type Event_Services_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Services_Stddev_Samp_Fields = {
  __typename?: 'event_services_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_services" */
export type Event_Services_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_services" */
export type Event_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Services_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Services_Sum_Fields = {
  __typename?: 'event_services_sum_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_services" */
export type Event_Services_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** update columns of table "event_services" */
export enum Event_Services_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

export type Event_Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Services_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Services_Var_Pop_Fields = {
  __typename?: 'event_services_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_services" */
export type Event_Services_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Services_Var_Samp_Fields = {
  __typename?: 'event_services_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_services" */
export type Event_Services_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Services_Variance_Fields = {
  __typename?: 'event_services_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_services" */
export type Event_Services_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** input type for updating data in table "event" */
export type Event_Set_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  group_presence_id?: InputMaybe<Ed_Group_Presence_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  index?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paid_on?: InputMaybe<Scalars['date']>;
  patient_note?: InputMaybe<Scalars['String']>;
  period_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  secret_note?: InputMaybe<Scalars['String']>;
  shifted?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  start_before_shift?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Event_Stddev_Fields = {
  __typename?: 'event_stddev_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event" */
export type Event_Stddev_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Stddev_Pop_Fields = {
  __typename?: 'event_stddev_pop_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event" */
export type Event_Stddev_Pop_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Stddev_Samp_Fields = {
  __typename?: 'event_stddev_samp_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event" */
export type Event_Stddev_Samp_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event" */
export type Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Stream_Cursor_Value_Input = {
  couple_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  group_presence_id?: InputMaybe<Ed_Group_Presence_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  index?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  paid_on?: InputMaybe<Scalars['date']>;
  patient_note?: InputMaybe<Scalars['String']>;
  period_id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  secret_note?: InputMaybe<Scalars['String']>;
  shifted?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  start_before_shift?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Event_Sum_Fields = {
  __typename?: 'event_sum_fields';
  couple_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  index?: Maybe<Scalars['Int']>;
  period_id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event" */
export type Event_Sum_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_therapists" */
export type Event_Therapists = {
  __typename?: 'event_therapists';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  /** An object relationship */
  event: Event;
  event_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "event_therapists" */
export type Event_Therapists_Aggregate = {
  __typename?: 'event_therapists_aggregate';
  aggregate?: Maybe<Event_Therapists_Aggregate_Fields>;
  nodes: Array<Event_Therapists>;
};

export type Event_Therapists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Event_Therapists_Aggregate_Bool_Exp_Count>;
};

export type Event_Therapists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Event_Therapists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "event_therapists" */
export type Event_Therapists_Aggregate_Fields = {
  __typename?: 'event_therapists_aggregate_fields';
  avg?: Maybe<Event_Therapists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Event_Therapists_Max_Fields>;
  min?: Maybe<Event_Therapists_Min_Fields>;
  stddev?: Maybe<Event_Therapists_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Therapists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Therapists_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Therapists_Sum_Fields>;
  var_pop?: Maybe<Event_Therapists_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Therapists_Var_Samp_Fields>;
  variance?: Maybe<Event_Therapists_Variance_Fields>;
};


/** aggregate fields of "event_therapists" */
export type Event_Therapists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_therapists" */
export type Event_Therapists_Aggregate_Order_By = {
  avg?: InputMaybe<Event_Therapists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Event_Therapists_Max_Order_By>;
  min?: InputMaybe<Event_Therapists_Min_Order_By>;
  stddev?: InputMaybe<Event_Therapists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Event_Therapists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Event_Therapists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Event_Therapists_Sum_Order_By>;
  var_pop?: InputMaybe<Event_Therapists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Event_Therapists_Var_Samp_Order_By>;
  variance?: InputMaybe<Event_Therapists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_therapists" */
export type Event_Therapists_Arr_Rel_Insert_Input = {
  data: Array<Event_Therapists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Event_Therapists_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Therapists_Avg_Fields = {
  __typename?: 'event_therapists_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_therapists" */
export type Event_Therapists_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_therapists". All fields are combined with a logical 'AND'. */
export type Event_Therapists_Bool_Exp = {
  _and?: InputMaybe<Array<Event_Therapists_Bool_Exp>>;
  _not?: InputMaybe<Event_Therapists_Bool_Exp>;
  _or?: InputMaybe<Array<Event_Therapists_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_therapists" */
export enum Event_Therapists_Constraint {
  /** unique or primary key constraint on columns "employee_id", "event_id" */
  EventTherapistsEventIdEmployeeIdAb194a6fUniq = 'event_therapists_event_id_employee_id_ab194a6f_uniq',
  /** unique or primary key constraint on columns "id" */
  EventTherapistsPkey = 'event_therapists_pkey'
}

/** input type for incrementing numeric columns in table "event_therapists" */
export type Event_Therapists_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "event_therapists" */
export type Event_Therapists_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Event_Therapists_Max_Fields = {
  __typename?: 'event_therapists_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event_therapists" */
export type Event_Therapists_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Therapists_Min_Fields = {
  __typename?: 'event_therapists_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event_therapists" */
export type Event_Therapists_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "event_therapists" */
export type Event_Therapists_Mutation_Response = {
  __typename?: 'event_therapists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Therapists>;
};

/** on_conflict condition type for table "event_therapists" */
export type Event_Therapists_On_Conflict = {
  constraint: Event_Therapists_Constraint;
  update_columns?: Array<Event_Therapists_Update_Column>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};

/** Ordering options when selecting data from "event_therapists". */
export type Event_Therapists_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: event_therapists */
export type Event_Therapists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "event_therapists" */
export enum Event_Therapists_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "event_therapists" */
export type Event_Therapists_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Event_Therapists_Stddev_Fields = {
  __typename?: 'event_therapists_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_therapists" */
export type Event_Therapists_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Therapists_Stddev_Pop_Fields = {
  __typename?: 'event_therapists_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_therapists" */
export type Event_Therapists_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Therapists_Stddev_Samp_Fields = {
  __typename?: 'event_therapists_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_therapists" */
export type Event_Therapists_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "event_therapists" */
export type Event_Therapists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_Therapists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_Therapists_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Event_Therapists_Sum_Fields = {
  __typename?: 'event_therapists_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_therapists" */
export type Event_Therapists_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event_therapists" */
export enum Event_Therapists_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id'
}

export type Event_Therapists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Therapists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Therapists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Therapists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Therapists_Var_Pop_Fields = {
  __typename?: 'event_therapists_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_therapists" */
export type Event_Therapists_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Therapists_Var_Samp_Fields = {
  __typename?: 'event_therapists_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_therapists" */
export type Event_Therapists_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Therapists_Variance_Fields = {
  __typename?: 'event_therapists_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_therapists" */
export type Event_Therapists_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "event" */
export enum Event_Update_Column {
  /** column name */
  CoupleId = 'couple_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  GroupPresenceId = 'group_presence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Locked = 'locked',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PaidOn = 'paid_on',
  /** column name */
  PatientNote = 'patient_note',
  /** column name */
  PeriodId = 'period_id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  SecretNote = 'secret_note',
  /** column name */
  Shifted = 'shifted',
  /** column name */
  Start = 'start',
  /** column name */
  StartBeforeShift = 'start_before_shift',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Event_Var_Pop_Fields = {
  __typename?: 'event_var_pop_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event" */
export type Event_Var_Pop_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Var_Samp_Fields = {
  __typename?: 'event_var_samp_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event" */
export type Event_Var_Samp_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Variance_Fields = {
  __typename?: 'event_variance_fields';
  couple_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  period_id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event" */
export type Event_Variance_Order_By = {
  couple_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  period_id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig = {
  __typename?: 'gdpr_gdprconfig';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_category: Scalars['String'];
  document_category_child: Scalars['String'];
  id: Scalars['bigint'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  send_automated_gdpr_mails: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Aggregate = {
  __typename?: 'gdpr_gdprconfig_aggregate';
  aggregate?: Maybe<Gdpr_Gdprconfig_Aggregate_Fields>;
  nodes: Array<Gdpr_Gdprconfig>;
};

/** aggregate fields of "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Aggregate_Fields = {
  __typename?: 'gdpr_gdprconfig_aggregate_fields';
  avg?: Maybe<Gdpr_Gdprconfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Gdprconfig_Max_Fields>;
  min?: Maybe<Gdpr_Gdprconfig_Min_Fields>;
  stddev?: Maybe<Gdpr_Gdprconfig_Stddev_Fields>;
  stddev_pop?: Maybe<Gdpr_Gdprconfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gdpr_Gdprconfig_Stddev_Samp_Fields>;
  sum?: Maybe<Gdpr_Gdprconfig_Sum_Fields>;
  var_pop?: Maybe<Gdpr_Gdprconfig_Var_Pop_Fields>;
  var_samp?: Maybe<Gdpr_Gdprconfig_Var_Samp_Fields>;
  variance?: Maybe<Gdpr_Gdprconfig_Variance_Fields>;
};


/** aggregate fields of "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdpr_Gdprconfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gdpr_Gdprconfig_Avg_Fields = {
  __typename?: 'gdpr_gdprconfig_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gdpr_gdprconfig". All fields are combined with a logical 'AND'. */
export type Gdpr_Gdprconfig_Bool_Exp = {
  _and?: InputMaybe<Array<Gdpr_Gdprconfig_Bool_Exp>>;
  _not?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
  _or?: InputMaybe<Array<Gdpr_Gdprconfig_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_category?: InputMaybe<String_Comparison_Exp>;
  document_category_child?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  send_automated_gdpr_mails?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_gdprconfig" */
export enum Gdpr_Gdprconfig_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprGdprconfigPkey = 'gdpr_gdprconfig_pkey',
  /** unique or primary key constraint on columns "practice_branch_id" */
  GdprGdprconfigPracticeBranchIdKey = 'gdpr_gdprconfig_practice_branch_id_key'
}

/** input type for incrementing numeric columns in table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_category?: InputMaybe<Scalars['String']>;
  document_category_child?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  send_automated_gdpr_mails?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gdpr_Gdprconfig_Max_Fields = {
  __typename?: 'gdpr_gdprconfig_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_category?: Maybe<Scalars['String']>;
  document_category_child?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Gdpr_Gdprconfig_Min_Fields = {
  __typename?: 'gdpr_gdprconfig_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_category?: Maybe<Scalars['String']>;
  document_category_child?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Mutation_Response = {
  __typename?: 'gdpr_gdprconfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Gdprconfig>;
};

/** input type for inserting object relation for remote table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Obj_Rel_Insert_Input = {
  data: Gdpr_Gdprconfig_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gdpr_Gdprconfig_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_On_Conflict = {
  constraint: Gdpr_Gdprconfig_Constraint;
  update_columns?: Array<Gdpr_Gdprconfig_Update_Column>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_gdprconfig". */
export type Gdpr_Gdprconfig_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_category?: InputMaybe<Order_By>;
  document_category_child?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  send_automated_gdpr_mails?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gdpr_gdprconfig */
export type Gdpr_Gdprconfig_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "gdpr_gdprconfig" */
export enum Gdpr_Gdprconfig_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentCategory = 'document_category',
  /** column name */
  DocumentCategoryChild = 'document_category_child',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SendAutomatedGdprMails = 'send_automated_gdpr_mails',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_category?: InputMaybe<Scalars['String']>;
  document_category_child?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  send_automated_gdpr_mails?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gdpr_Gdprconfig_Stddev_Fields = {
  __typename?: 'gdpr_gdprconfig_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gdpr_Gdprconfig_Stddev_Pop_Fields = {
  __typename?: 'gdpr_gdprconfig_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gdpr_Gdprconfig_Stddev_Samp_Fields = {
  __typename?: 'gdpr_gdprconfig_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gdpr_gdprconfig" */
export type Gdpr_Gdprconfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Gdprconfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Gdprconfig_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_category?: InputMaybe<Scalars['String']>;
  document_category_child?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  send_automated_gdpr_mails?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gdpr_Gdprconfig_Sum_Fields = {
  __typename?: 'gdpr_gdprconfig_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "gdpr_gdprconfig" */
export enum Gdpr_Gdprconfig_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentCategory = 'document_category',
  /** column name */
  DocumentCategoryChild = 'document_category_child',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SendAutomatedGdprMails = 'send_automated_gdpr_mails',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Gdprconfig_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gdpr_Gdprconfig_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdpr_Gdprconfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gdpr_Gdprconfig_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gdpr_Gdprconfig_Var_Pop_Fields = {
  __typename?: 'gdpr_gdprconfig_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gdpr_Gdprconfig_Var_Samp_Fields = {
  __typename?: 'gdpr_gdprconfig_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gdpr_Gdprconfig_Variance_Fields = {
  __typename?: 'gdpr_gdprconfig_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument = {
  __typename?: 'gdpr_gdprdocument';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_id: Scalars['bigint'];
  downloaded_pdf: Scalars['Boolean'];
  /** An object relationship */
  gdpr_gdprdocumenttype: Gdpr_Gdprdocumenttype;
  id: Scalars['bigint'];
  opened_date?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  patient_token?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice_branch?: Maybe<Practice_Branches>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  sent_date?: Maybe<Scalars['timestamptz']>;
  signed_date?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Aggregate = {
  __typename?: 'gdpr_gdprdocument_aggregate';
  aggregate?: Maybe<Gdpr_Gdprdocument_Aggregate_Fields>;
  nodes: Array<Gdpr_Gdprdocument>;
};

export type Gdpr_Gdprdocument_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp_Count>;
};

export type Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_And = {
  arguments: Gdpr_Gdprdocument_Select_Column_Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Gdpr_Gdprdocument_Select_Column_Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Gdpr_Gdprdocument_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Aggregate_Fields = {
  __typename?: 'gdpr_gdprdocument_aggregate_fields';
  avg?: Maybe<Gdpr_Gdprdocument_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Gdprdocument_Max_Fields>;
  min?: Maybe<Gdpr_Gdprdocument_Min_Fields>;
  stddev?: Maybe<Gdpr_Gdprdocument_Stddev_Fields>;
  stddev_pop?: Maybe<Gdpr_Gdprdocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gdpr_Gdprdocument_Stddev_Samp_Fields>;
  sum?: Maybe<Gdpr_Gdprdocument_Sum_Fields>;
  var_pop?: Maybe<Gdpr_Gdprdocument_Var_Pop_Fields>;
  var_samp?: Maybe<Gdpr_Gdprdocument_Var_Samp_Fields>;
  variance?: Maybe<Gdpr_Gdprdocument_Variance_Fields>;
};


/** aggregate fields of "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Aggregate_Order_By = {
  avg?: InputMaybe<Gdpr_Gdprdocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gdpr_Gdprdocument_Max_Order_By>;
  min?: InputMaybe<Gdpr_Gdprdocument_Min_Order_By>;
  stddev?: InputMaybe<Gdpr_Gdprdocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Gdpr_Gdprdocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Gdpr_Gdprdocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Gdpr_Gdprdocument_Sum_Order_By>;
  var_pop?: InputMaybe<Gdpr_Gdprdocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Gdpr_Gdprdocument_Var_Samp_Order_By>;
  variance?: InputMaybe<Gdpr_Gdprdocument_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Arr_Rel_Insert_Input = {
  data: Array<Gdpr_Gdprdocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Gdpr_Gdprdocument_On_Conflict>;
};

/** aggregate avg on columns */
export type Gdpr_Gdprdocument_Avg_Fields = {
  __typename?: 'gdpr_gdprdocument_avg_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Avg_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gdpr_gdprdocument". All fields are combined with a logical 'AND'. */
export type Gdpr_Gdprdocument_Bool_Exp = {
  _and?: InputMaybe<Array<Gdpr_Gdprdocument_Bool_Exp>>;
  _not?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  _or?: InputMaybe<Array<Gdpr_Gdprdocument_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_id?: InputMaybe<Bigint_Comparison_Exp>;
  downloaded_pdf?: InputMaybe<Boolean_Comparison_Exp>;
  gdpr_gdprdocumenttype?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  opened_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  patient_token?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  sent_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  signed_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_gdprdocument" */
export enum Gdpr_Gdprdocument_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprGdprdocumentPkey = 'gdpr_gdprdocument_pkey'
}

/** input type for incrementing numeric columns in table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Inc_Input = {
  document_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_id?: InputMaybe<Scalars['bigint']>;
  downloaded_pdf?: InputMaybe<Scalars['Boolean']>;
  gdpr_gdprdocumenttype?: InputMaybe<Gdpr_Gdprdocumenttype_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  opened_date?: InputMaybe<Scalars['timestamptz']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  patient_token?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sent_date?: InputMaybe<Scalars['timestamptz']>;
  signed_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Gdpr_Gdprdocument_Max_Fields = {
  __typename?: 'gdpr_gdprdocument_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  opened_date?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  patient_token?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  sent_date?: Maybe<Scalars['timestamptz']>;
  signed_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  opened_date?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_token?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sent_date?: InputMaybe<Order_By>;
  signed_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Gdpr_Gdprdocument_Min_Fields = {
  __typename?: 'gdpr_gdprdocument_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  document_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  opened_date?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  patient_token?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  sent_date?: Maybe<Scalars['timestamptz']>;
  signed_date?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  opened_date?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_token?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sent_date?: InputMaybe<Order_By>;
  signed_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Mutation_Response = {
  __typename?: 'gdpr_gdprdocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Gdprdocument>;
};

/** on_conflict condition type for table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_On_Conflict = {
  constraint: Gdpr_Gdprdocument_Constraint;
  update_columns?: Array<Gdpr_Gdprdocument_Update_Column>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_gdprdocument". */
export type Gdpr_Gdprdocument_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  downloaded_pdf?: InputMaybe<Order_By>;
  gdpr_gdprdocumenttype?: InputMaybe<Gdpr_Gdprdocumenttype_Order_By>;
  id?: InputMaybe<Order_By>;
  opened_date?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_token?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sent_date?: InputMaybe<Order_By>;
  signed_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gdpr_gdprdocument */
export type Gdpr_Gdprdocument_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "gdpr_gdprdocument" */
export enum Gdpr_Gdprdocument_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DownloadedPdf = 'downloaded_pdf',
  /** column name */
  Id = 'id',
  /** column name */
  OpenedDate = 'opened_date',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientToken = 'patient_token',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SentDate = 'sent_date',
  /** column name */
  SignedDate = 'signed_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "gdpr_gdprdocument_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gdpr_gdprdocument" */
export enum Gdpr_Gdprdocument_Select_Column_Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DownloadedPdf = 'downloaded_pdf'
}

/** select "gdpr_gdprdocument_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gdpr_gdprdocument" */
export enum Gdpr_Gdprdocument_Select_Column_Gdpr_Gdprdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DownloadedPdf = 'downloaded_pdf'
}

/** input type for updating data in table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_id?: InputMaybe<Scalars['bigint']>;
  downloaded_pdf?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  opened_date?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  patient_token?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sent_date?: InputMaybe<Scalars['timestamptz']>;
  signed_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gdpr_Gdprdocument_Stddev_Fields = {
  __typename?: 'gdpr_gdprdocument_stddev_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Stddev_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gdpr_Gdprdocument_Stddev_Pop_Fields = {
  __typename?: 'gdpr_gdprdocument_stddev_pop_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Stddev_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gdpr_Gdprdocument_Stddev_Samp_Fields = {
  __typename?: 'gdpr_gdprdocument_stddev_samp_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Stddev_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Gdprdocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Gdprdocument_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  document_id?: InputMaybe<Scalars['bigint']>;
  downloaded_pdf?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  opened_date?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  patient_token?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sent_date?: InputMaybe<Scalars['timestamptz']>;
  signed_date?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Gdpr_Gdprdocument_Sum_Fields = {
  __typename?: 'gdpr_gdprdocument_sum_fields';
  document_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Sum_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** update columns of table "gdpr_gdprdocument" */
export enum Gdpr_Gdprdocument_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DownloadedPdf = 'downloaded_pdf',
  /** column name */
  Id = 'id',
  /** column name */
  OpenedDate = 'opened_date',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientToken = 'patient_token',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SentDate = 'sent_date',
  /** column name */
  SignedDate = 'signed_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Gdprdocument_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gdpr_Gdprdocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdpr_Gdprdocument_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gdpr_Gdprdocument_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gdpr_Gdprdocument_Var_Pop_Fields = {
  __typename?: 'gdpr_gdprdocument_var_pop_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Var_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gdpr_Gdprdocument_Var_Samp_Fields = {
  __typename?: 'gdpr_gdprdocument_var_samp_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Var_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Gdpr_Gdprdocument_Variance_Fields = {
  __typename?: 'gdpr_gdprdocument_variance_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gdpr_gdprdocument" */
export type Gdpr_Gdprdocument_Variance_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype = {
  __typename?: 'gdpr_gdprdocumenttype';
  added_date: Scalars['timestamptz'];
  category: Scalars['String'];
  deleted_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  gdpr_gdprdocuments: Array<Gdpr_Gdprdocument>;
  /** An aggregate relationship */
  gdpr_gdprdocuments_aggregate: Gdpr_Gdprdocument_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "gdpr_gdprdocumenttype" */
export type Gdpr_GdprdocumenttypeGdpr_GdprdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


/** columns and relationships of "gdpr_gdprdocumenttype" */
export type Gdpr_GdprdocumenttypeGdpr_Gdprdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};

/** aggregated selection of "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Aggregate = {
  __typename?: 'gdpr_gdprdocumenttype_aggregate';
  aggregate?: Maybe<Gdpr_Gdprdocumenttype_Aggregate_Fields>;
  nodes: Array<Gdpr_Gdprdocumenttype>;
};

/** aggregate fields of "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Aggregate_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_aggregate_fields';
  avg?: Maybe<Gdpr_Gdprdocumenttype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Gdprdocumenttype_Max_Fields>;
  min?: Maybe<Gdpr_Gdprdocumenttype_Min_Fields>;
  stddev?: Maybe<Gdpr_Gdprdocumenttype_Stddev_Fields>;
  stddev_pop?: Maybe<Gdpr_Gdprdocumenttype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gdpr_Gdprdocumenttype_Stddev_Samp_Fields>;
  sum?: Maybe<Gdpr_Gdprdocumenttype_Sum_Fields>;
  var_pop?: Maybe<Gdpr_Gdprdocumenttype_Var_Pop_Fields>;
  var_samp?: Maybe<Gdpr_Gdprdocumenttype_Var_Samp_Fields>;
  variance?: Maybe<Gdpr_Gdprdocumenttype_Variance_Fields>;
};


/** aggregate fields of "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Gdpr_Gdprdocumenttype_Avg_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gdpr_gdprdocumenttype". All fields are combined with a logical 'AND'. */
export type Gdpr_Gdprdocumenttype_Bool_Exp = {
  _and?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Bool_Exp>>;
  _not?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
  _or?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Bool_Exp>>;
  added_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  deleted_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_gdprdocumenttype" */
export enum Gdpr_Gdprdocumenttype_Constraint {
  /** unique or primary key constraint on columns "category", "name" */
  GdprGdprdocumenttypeNameCategory_445e2e13Uniq = 'gdpr_gdprdocumenttype_name_category_445e2e13_uniq',
  /** unique or primary key constraint on columns "id" */
  GdprGdprdocumenttypePkey = 'gdpr_gdprdocumenttype_pkey'
}

/** input type for incrementing numeric columns in table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Insert_Input = {
  added_date?: InputMaybe<Scalars['timestamptz']>;
  category?: InputMaybe<Scalars['String']>;
  deleted_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Gdprdocumenttype_Max_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_max_fields';
  added_date?: Maybe<Scalars['timestamptz']>;
  category?: Maybe<Scalars['String']>;
  deleted_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Gdprdocumenttype_Min_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_min_fields';
  added_date?: Maybe<Scalars['timestamptz']>;
  category?: Maybe<Scalars['String']>;
  deleted_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Mutation_Response = {
  __typename?: 'gdpr_gdprdocumenttype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Gdprdocumenttype>;
};

/** input type for inserting object relation for remote table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Obj_Rel_Insert_Input = {
  data: Gdpr_Gdprdocumenttype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gdpr_Gdprdocumenttype_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_On_Conflict = {
  constraint: Gdpr_Gdprdocumenttype_Constraint;
  update_columns?: Array<Gdpr_Gdprdocumenttype_Update_Column>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_gdprdocumenttype". */
export type Gdpr_Gdprdocumenttype_Order_By = {
  added_date?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  deleted_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gdpr_gdprdocumenttype */
export type Gdpr_Gdprdocumenttype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "gdpr_gdprdocumenttype" */
export enum Gdpr_Gdprdocumenttype_Select_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Category = 'category',
  /** column name */
  DeletedDate = 'deleted_date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Set_Input = {
  added_date?: InputMaybe<Scalars['timestamptz']>;
  category?: InputMaybe<Scalars['String']>;
  deleted_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Gdpr_Gdprdocumenttype_Stddev_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gdpr_Gdprdocumenttype_Stddev_Pop_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gdpr_Gdprdocumenttype_Stddev_Samp_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gdpr_gdprdocumenttype" */
export type Gdpr_Gdprdocumenttype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Gdprdocumenttype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Gdprdocumenttype_Stream_Cursor_Value_Input = {
  added_date?: InputMaybe<Scalars['timestamptz']>;
  category?: InputMaybe<Scalars['String']>;
  deleted_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Gdpr_Gdprdocumenttype_Sum_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "gdpr_gdprdocumenttype" */
export enum Gdpr_Gdprdocumenttype_Update_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Category = 'category',
  /** column name */
  DeletedDate = 'deleted_date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Gdpr_Gdprdocumenttype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Gdpr_Gdprdocumenttype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gdpr_Gdprdocumenttype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gdpr_Gdprdocumenttype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gdpr_Gdprdocumenttype_Var_Pop_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gdpr_Gdprdocumenttype_Var_Samp_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gdpr_Gdprdocumenttype_Variance_Fields = {
  __typename?: 'gdpr_gdprdocumenttype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "group_members" */
export type Group_Members = {
  __typename?: 'group_members';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group: Groups;
  group_id: Scalars['bigint'];
  id: Scalars['bigint'];
  membership_added_on: Scalars['timestamptz'];
  membership_ending_on?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "group_members" */
export type Group_Members_Aggregate = {
  __typename?: 'group_members_aggregate';
  aggregate?: Maybe<Group_Members_Aggregate_Fields>;
  nodes: Array<Group_Members>;
};

export type Group_Members_Aggregate_Bool_Exp = {
  count?: InputMaybe<Group_Members_Aggregate_Bool_Exp_Count>;
};

export type Group_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Group_Members_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_Fields = {
  __typename?: 'group_members_aggregate_fields';
  avg?: Maybe<Group_Members_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Group_Members_Max_Fields>;
  min?: Maybe<Group_Members_Min_Fields>;
  stddev?: Maybe<Group_Members_Stddev_Fields>;
  stddev_pop?: Maybe<Group_Members_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Group_Members_Stddev_Samp_Fields>;
  sum?: Maybe<Group_Members_Sum_Fields>;
  var_pop?: Maybe<Group_Members_Var_Pop_Fields>;
  var_samp?: Maybe<Group_Members_Var_Samp_Fields>;
  variance?: Maybe<Group_Members_Variance_Fields>;
};


/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_members" */
export type Group_Members_Aggregate_Order_By = {
  avg?: InputMaybe<Group_Members_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Group_Members_Max_Order_By>;
  min?: InputMaybe<Group_Members_Min_Order_By>;
  stddev?: InputMaybe<Group_Members_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Group_Members_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Group_Members_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Group_Members_Sum_Order_By>;
  var_pop?: InputMaybe<Group_Members_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Group_Members_Var_Samp_Order_By>;
  variance?: InputMaybe<Group_Members_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "group_members" */
export type Group_Members_Arr_Rel_Insert_Input = {
  data: Array<Group_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};

/** aggregate avg on columns */
export type Group_Members_Avg_Fields = {
  __typename?: 'group_members_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "group_members" */
export type Group_Members_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "group_members". All fields are combined with a logical 'AND'. */
export type Group_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  _not?: InputMaybe<Group_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  membership_added_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  membership_ending_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_members" */
export enum Group_Members_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupMembersPkey = 'group_members_pkey'
}

/** input type for incrementing numeric columns in table "group_members" */
export type Group_Members_Inc_Input = {
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "group_members" */
export type Group_Members_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  membership_added_on?: InputMaybe<Scalars['timestamptz']>;
  membership_ending_on?: InputMaybe<Scalars['timestamptz']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Group_Members_Max_Fields = {
  __typename?: 'group_members_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  membership_added_on?: Maybe<Scalars['timestamptz']>;
  membership_ending_on?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "group_members" */
export type Group_Members_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership_added_on?: InputMaybe<Order_By>;
  membership_ending_on?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Members_Min_Fields = {
  __typename?: 'group_members_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  membership_added_on?: Maybe<Scalars['timestamptz']>;
  membership_ending_on?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "group_members" */
export type Group_Members_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership_added_on?: InputMaybe<Order_By>;
  membership_ending_on?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_members" */
export type Group_Members_Mutation_Response = {
  __typename?: 'group_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Members>;
};

/** on_conflict condition type for table "group_members" */
export type Group_Members_On_Conflict = {
  constraint: Group_Members_Constraint;
  update_columns?: Array<Group_Members_Update_Column>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "group_members". */
export type Group_Members_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  membership_added_on?: InputMaybe<Order_By>;
  membership_ending_on?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_members */
export type Group_Members_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "group_members" */
export enum Group_Members_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipAddedOn = 'membership_added_on',
  /** column name */
  MembershipEndingOn = 'membership_ending_on',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "group_members" */
export type Group_Members_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  membership_added_on?: InputMaybe<Scalars['timestamptz']>;
  membership_ending_on?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Group_Members_Stddev_Fields = {
  __typename?: 'group_members_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "group_members" */
export type Group_Members_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Group_Members_Stddev_Pop_Fields = {
  __typename?: 'group_members_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "group_members" */
export type Group_Members_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Group_Members_Stddev_Samp_Fields = {
  __typename?: 'group_members_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "group_members" */
export type Group_Members_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "group_members" */
export type Group_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Members_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  membership_added_on?: InputMaybe<Scalars['timestamptz']>;
  membership_ending_on?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Group_Members_Sum_Fields = {
  __typename?: 'group_members_sum_fields';
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "group_members" */
export type Group_Members_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "group_members" */
export enum Group_Members_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipAddedOn = 'membership_added_on',
  /** column name */
  MembershipEndingOn = 'membership_ending_on',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Group_Members_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Group_Members_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Members_Set_Input>;
  /** filter the rows which have to be updated */
  where: Group_Members_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Group_Members_Var_Pop_Fields = {
  __typename?: 'group_members_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "group_members" */
export type Group_Members_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Group_Members_Var_Samp_Fields = {
  __typename?: 'group_members_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "group_members" */
export type Group_Members_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Group_Members_Variance_Fields = {
  __typename?: 'group_members_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "group_members" */
export type Group_Members_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  last_edited_by?: Maybe<User>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  therapist?: Maybe<Employees>;
  therapist_id?: Maybe<Scalars['bigint']>;
  therapy_finish_date?: Maybe<Scalars['date']>;
  therapy_start_date: Scalars['date'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "groups" */
export type GroupsGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Groups_Aggregate_Bool_Exp_Count>;
};

export type Groups_Aggregate_Bool_Exp_Bool_And = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  avg?: Maybe<Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
  stddev?: Maybe<Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Groups_Sum_Fields>;
  var_pop?: Maybe<Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Groups_Var_Samp_Fields>;
  variance?: Maybe<Groups_Variance_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
  stddev?: InputMaybe<Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Groups_Avg_Fields = {
  __typename?: 'groups_avg_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "groups" */
export type Groups_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group_members?: InputMaybe<Group_Members_Bool_Exp>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_edited_by?: InputMaybe<User_Bool_Exp>;
  last_edited_by_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  therapist?: InputMaybe<Employees_Bool_Exp>;
  therapist_id?: InputMaybe<Bigint_Comparison_Exp>;
  therapy_finish_date?: InputMaybe<Date_Comparison_Exp>;
  therapy_start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey'
}

/** input type for incrementing numeric columns in table "groups" */
export type Groups_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  group_members?: InputMaybe<Group_Members_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  therapist?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  therapy_finish_date?: InputMaybe<Scalars['date']>;
  therapy_start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  therapy_finish_date?: Maybe<Scalars['date']>;
  therapy_start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  therapy_finish_date?: InputMaybe<Order_By>;
  therapy_start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  therapy_finish_date?: Maybe<Scalars['date']>;
  therapy_start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  therapy_finish_date?: InputMaybe<Order_By>;
  therapy_start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_edited_by?: InputMaybe<User_Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  therapist?: InputMaybe<Employees_Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  therapy_finish_date?: InputMaybe<Order_By>;
  therapy_start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  Name = 'name',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  TherapyFinishDate = 'therapy_finish_date',
  /** column name */
  TherapyStartDate = 'therapy_start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  therapy_finish_date?: InputMaybe<Scalars['date']>;
  therapy_start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Groups_Stddev_Fields = {
  __typename?: 'groups_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "groups" */
export type Groups_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Groups_Stddev_Pop_Fields = {
  __typename?: 'groups_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "groups" */
export type Groups_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Groups_Stddev_Samp_Fields = {
  __typename?: 'groups_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "groups" */
export type Groups_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  therapy_finish_date?: InputMaybe<Scalars['date']>;
  therapy_start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Groups_Sum_Fields = {
  __typename?: 'groups_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  therapist_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "groups" */
export type Groups_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  Name = 'name',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  TherapyFinishDate = 'therapy_finish_date',
  /** column name */
  TherapyStartDate = 'therapy_start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Groups_Var_Pop_Fields = {
  __typename?: 'groups_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "groups" */
export type Groups_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Groups_Var_Samp_Fields = {
  __typename?: 'groups_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "groups" */
export type Groups_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Groups_Variance_Fields = {
  __typename?: 'groups_variance_fields';
  id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "groups" */
export type Groups_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "holiday_reasons" */
export type Holiday_Reasons = {
  __typename?: 'holiday_reasons';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  id: Scalars['bigint'];
  kind: Scalars['String'];
  name: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  /** An array relationship */
  national_holidays: Array<National_Holidays>;
  /** An aggregate relationship */
  national_holidays_aggregate: National_Holidays_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "holiday_reasons" */
export type Holiday_ReasonsHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "holiday_reasons" */
export type Holiday_ReasonsHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "holiday_reasons" */
export type Holiday_ReasonsNational_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


/** columns and relationships of "holiday_reasons" */
export type Holiday_ReasonsNational_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};

/** aggregated selection of "holiday_reasons" */
export type Holiday_Reasons_Aggregate = {
  __typename?: 'holiday_reasons_aggregate';
  aggregate?: Maybe<Holiday_Reasons_Aggregate_Fields>;
  nodes: Array<Holiday_Reasons>;
};

/** aggregate fields of "holiday_reasons" */
export type Holiday_Reasons_Aggregate_Fields = {
  __typename?: 'holiday_reasons_aggregate_fields';
  avg?: Maybe<Holiday_Reasons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Holiday_Reasons_Max_Fields>;
  min?: Maybe<Holiday_Reasons_Min_Fields>;
  stddev?: Maybe<Holiday_Reasons_Stddev_Fields>;
  stddev_pop?: Maybe<Holiday_Reasons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Holiday_Reasons_Stddev_Samp_Fields>;
  sum?: Maybe<Holiday_Reasons_Sum_Fields>;
  var_pop?: Maybe<Holiday_Reasons_Var_Pop_Fields>;
  var_samp?: Maybe<Holiday_Reasons_Var_Samp_Fields>;
  variance?: Maybe<Holiday_Reasons_Variance_Fields>;
};


/** aggregate fields of "holiday_reasons" */
export type Holiday_Reasons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Holiday_Reasons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Holiday_Reasons_Avg_Fields = {
  __typename?: 'holiday_reasons_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "holiday_reasons". All fields are combined with a logical 'AND'. */
export type Holiday_Reasons_Bool_Exp = {
  _and?: InputMaybe<Array<Holiday_Reasons_Bool_Exp>>;
  _not?: InputMaybe<Holiday_Reasons_Bool_Exp>;
  _or?: InputMaybe<Array<Holiday_Reasons_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_en?: InputMaybe<String_Comparison_Exp>;
  description_pl?: InputMaybe<String_Comparison_Exp>;
  holidays?: InputMaybe<Holidays_Bool_Exp>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_pl?: InputMaybe<String_Comparison_Exp>;
  national_holidays?: InputMaybe<National_Holidays_Bool_Exp>;
  national_holidays_aggregate?: InputMaybe<National_Holidays_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "holiday_reasons" */
export enum Holiday_Reasons_Constraint {
  /** unique or primary key constraint on columns "id" */
  HolidayReasonsPkey = 'holiday_reasons_pkey',
  /** unique or primary key constraint on columns "kind", "name" */
  UniqueHolidayNamePerKind = 'unique_holiday_name_per_kind'
}

/** input type for incrementing numeric columns in table "holiday_reasons" */
export type Holiday_Reasons_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "holiday_reasons" */
export type Holiday_Reasons_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  holidays?: InputMaybe<Holidays_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  national_holidays?: InputMaybe<National_Holidays_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Holiday_Reasons_Max_Fields = {
  __typename?: 'holiday_reasons_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Holiday_Reasons_Min_Fields = {
  __typename?: 'holiday_reasons_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "holiday_reasons" */
export type Holiday_Reasons_Mutation_Response = {
  __typename?: 'holiday_reasons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Holiday_Reasons>;
};

/** input type for inserting object relation for remote table "holiday_reasons" */
export type Holiday_Reasons_Obj_Rel_Insert_Input = {
  data: Holiday_Reasons_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Holiday_Reasons_On_Conflict>;
};

/** on_conflict condition type for table "holiday_reasons" */
export type Holiday_Reasons_On_Conflict = {
  constraint: Holiday_Reasons_Constraint;
  update_columns?: Array<Holiday_Reasons_Update_Column>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};

/** Ordering options when selecting data from "holiday_reasons". */
export type Holiday_Reasons_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_en?: InputMaybe<Order_By>;
  description_pl?: InputMaybe<Order_By>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_pl?: InputMaybe<Order_By>;
  national_holidays_aggregate?: InputMaybe<National_Holidays_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: holiday_reasons */
export type Holiday_Reasons_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "holiday_reasons" */
export enum Holiday_Reasons_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionPl = 'description_pl',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NamePl = 'name_pl',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "holiday_reasons" */
export type Holiday_Reasons_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Holiday_Reasons_Stddev_Fields = {
  __typename?: 'holiday_reasons_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Holiday_Reasons_Stddev_Pop_Fields = {
  __typename?: 'holiday_reasons_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Holiday_Reasons_Stddev_Samp_Fields = {
  __typename?: 'holiday_reasons_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "holiday_reasons" */
export type Holiday_Reasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Holiday_Reasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Holiday_Reasons_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Holiday_Reasons_Sum_Fields = {
  __typename?: 'holiday_reasons_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "holiday_reasons" */
export enum Holiday_Reasons_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionPl = 'description_pl',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NamePl = 'name_pl',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Holiday_Reasons_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Holiday_Reasons_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Holiday_Reasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Holiday_Reasons_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Holiday_Reasons_Var_Pop_Fields = {
  __typename?: 'holiday_reasons_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Holiday_Reasons_Var_Samp_Fields = {
  __typename?: 'holiday_reasons_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Holiday_Reasons_Variance_Fields = {
  __typename?: 'holiday_reasons_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "holidays" */
export type Holidays = {
  __typename?: 'holidays';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employees>;
  employee_id?: Maybe<Scalars['bigint']>;
  end: Scalars['timestamptz'];
  /** An object relationship */
  holiday_reason: Holiday_Reasons;
  id: Scalars['bigint'];
  note: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  /** An object relationship */
  practice_branch?: Maybe<Practice_Branches>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id: Scalars['bigint'];
  reason_id: Scalars['bigint'];
  start: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "holidays" */
export type Holidays_Aggregate = {
  __typename?: 'holidays_aggregate';
  aggregate?: Maybe<Holidays_Aggregate_Fields>;
  nodes: Array<Holidays>;
};

export type Holidays_Aggregate_Bool_Exp = {
  count?: InputMaybe<Holidays_Aggregate_Bool_Exp_Count>;
};

export type Holidays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Holidays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "holidays" */
export type Holidays_Aggregate_Fields = {
  __typename?: 'holidays_aggregate_fields';
  avg?: Maybe<Holidays_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Holidays_Max_Fields>;
  min?: Maybe<Holidays_Min_Fields>;
  stddev?: Maybe<Holidays_Stddev_Fields>;
  stddev_pop?: Maybe<Holidays_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Holidays_Stddev_Samp_Fields>;
  sum?: Maybe<Holidays_Sum_Fields>;
  var_pop?: Maybe<Holidays_Var_Pop_Fields>;
  var_samp?: Maybe<Holidays_Var_Samp_Fields>;
  variance?: Maybe<Holidays_Variance_Fields>;
};


/** aggregate fields of "holidays" */
export type Holidays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "holidays" */
export type Holidays_Aggregate_Order_By = {
  avg?: InputMaybe<Holidays_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Holidays_Max_Order_By>;
  min?: InputMaybe<Holidays_Min_Order_By>;
  stddev?: InputMaybe<Holidays_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Holidays_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Holidays_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Holidays_Sum_Order_By>;
  var_pop?: InputMaybe<Holidays_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Holidays_Var_Samp_Order_By>;
  variance?: InputMaybe<Holidays_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "holidays" */
export type Holidays_Arr_Rel_Insert_Input = {
  data: Array<Holidays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Holidays_On_Conflict>;
};

/** aggregate avg on columns */
export type Holidays_Avg_Fields = {
  __typename?: 'holidays_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "holidays" */
export type Holidays_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "holidays". All fields are combined with a logical 'AND'. */
export type Holidays_Bool_Exp = {
  _and?: InputMaybe<Array<Holidays_Bool_Exp>>;
  _not?: InputMaybe<Holidays_Bool_Exp>;
  _or?: InputMaybe<Array<Holidays_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  reason_id?: InputMaybe<Bigint_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "holidays" */
export enum Holidays_Constraint {
  /** unique or primary key constraint on columns "id" */
  HolidaysPkey = 'holidays_pkey'
}

/** input type for incrementing numeric columns in table "holidays" */
export type Holidays_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "holidays" */
export type Holidays_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['String']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Holidays_Max_Fields = {
  __typename?: 'holidays_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['bigint']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  note?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "holidays" */
export type Holidays_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Holidays_Min_Fields = {
  __typename?: 'holidays_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['bigint']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  note?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "holidays" */
export type Holidays_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "holidays" */
export type Holidays_Mutation_Response = {
  __typename?: 'holidays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Holidays>;
};

/** on_conflict condition type for table "holidays" */
export type Holidays_On_Conflict = {
  constraint: Holidays_Constraint;
  update_columns?: Array<Holidays_Update_Column>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};

/** Ordering options when selecting data from "holidays". */
export type Holidays_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: holidays */
export type Holidays_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "holidays" */
export enum Holidays_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ReasonId = 'reason_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "holidays" */
export type Holidays_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Holidays_Stddev_Fields = {
  __typename?: 'holidays_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "holidays" */
export type Holidays_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Holidays_Stddev_Pop_Fields = {
  __typename?: 'holidays_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "holidays" */
export type Holidays_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Holidays_Stddev_Samp_Fields = {
  __typename?: 'holidays_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "holidays" */
export type Holidays_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "holidays" */
export type Holidays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Holidays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Holidays_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Holidays_Sum_Fields = {
  __typename?: 'holidays_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "holidays" */
export type Holidays_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** update columns of table "holidays" */
export enum Holidays_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ReasonId = 'reason_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Holidays_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Holidays_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Holidays_Set_Input>;
  /** filter the rows which have to be updated */
  where: Holidays_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Holidays_Var_Pop_Fields = {
  __typename?: 'holidays_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "holidays" */
export type Holidays_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Holidays_Var_Samp_Fields = {
  __typename?: 'holidays_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "holidays" */
export type Holidays_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Holidays_Variance_Fields = {
  __typename?: 'holidays_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "holidays" */
export type Holidays_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type Interval_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['interval']>;
  _gt?: InputMaybe<Scalars['interval']>;
  _gte?: InputMaybe<Scalars['interval']>;
  _in?: InputMaybe<Array<Scalars['interval']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['interval']>;
  _lte?: InputMaybe<Scalars['interval']>;
  _neq?: InputMaybe<Scalars['interval']>;
  _nin?: InputMaybe<Array<Scalars['interval']>>;
};

/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo = {
  __typename?: 'invoices_v2_billinginfo';
  bank?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An array relationship */
  invoices_v2_invoices: Array<Invoices_V2_Invoice>;
  /** An aggregate relationship */
  invoices_v2_invoices_aggregate: Invoices_V2_Invoice_Aggregate;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phone?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  street?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoInvoices_V2_InvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoInvoices_V2_Invoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_billinginfo" */
export type Invoices_V2_BillinginfoPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};

/** aggregated selection of "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Aggregate = {
  __typename?: 'invoices_v2_billinginfo_aggregate';
  aggregate?: Maybe<Invoices_V2_Billinginfo_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Billinginfo>;
};

/** aggregate fields of "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Aggregate_Fields = {
  __typename?: 'invoices_v2_billinginfo_aggregate_fields';
  avg?: Maybe<Invoices_V2_Billinginfo_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Billinginfo_Max_Fields>;
  min?: Maybe<Invoices_V2_Billinginfo_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Billinginfo_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Billinginfo_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Billinginfo_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Billinginfo_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Billinginfo_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Billinginfo_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Billinginfo_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Billinginfo_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Invoices_V2_Billinginfo_Avg_Fields = {
  __typename?: 'invoices_v2_billinginfo_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "invoices_v2_billinginfo". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Billinginfo_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Billinginfo_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Billinginfo_Bool_Exp>>;
  bank?: InputMaybe<String_Comparison_Exp>;
  bank_account?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_invoices?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  invoices_v2_invoices_aggregate?: InputMaybe<Invoices_V2_Invoice_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  post_code?: InputMaybe<String_Comparison_Exp>;
  practices?: InputMaybe<Practices_Bool_Exp>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Bool_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  tax_no?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_billinginfo" */
export enum Invoices_V2_Billinginfo_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesV2BillinginfoPkey = 'invoices_v2_billinginfo_pkey'
}

/** input type for incrementing numeric columns in table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Insert_Input = {
  bank?: InputMaybe<Scalars['String']>;
  bank_account?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_invoices?: InputMaybe<Invoices_V2_Invoice_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  practices?: InputMaybe<Practices_Arr_Rel_Insert_Input>;
  street?: InputMaybe<Scalars['String']>;
  tax_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoices_V2_Billinginfo_Max_Fields = {
  __typename?: 'invoices_v2_billinginfo_max_fields';
  bank?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Invoices_V2_Billinginfo_Min_Fields = {
  __typename?: 'invoices_v2_billinginfo_min_fields';
  bank?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  post_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Mutation_Response = {
  __typename?: 'invoices_v2_billinginfo_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Billinginfo>;
};

/** input type for inserting object relation for remote table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Obj_Rel_Insert_Input = {
  data: Invoices_V2_Billinginfo_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Billinginfo_On_Conflict>;
};

/** on_conflict condition type for table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_On_Conflict = {
  constraint: Invoices_V2_Billinginfo_Constraint;
  update_columns?: Array<Invoices_V2_Billinginfo_Update_Column>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_billinginfo". */
export type Invoices_V2_Billinginfo_Order_By = {
  bank?: InputMaybe<Order_By>;
  bank_account?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_v2_invoices_aggregate?: InputMaybe<Invoices_V2_Invoice_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  post_code?: InputMaybe<Order_By>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Order_By>;
  street?: InputMaybe<Order_By>;
  tax_no?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices_v2_billinginfo */
export type Invoices_V2_Billinginfo_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_billinginfo" */
export enum Invoices_V2_Billinginfo_Select_Column {
  /** column name */
  Bank = 'bank',
  /** column name */
  BankAccount = 'bank_account',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Street = 'street',
  /** column name */
  TaxNo = 'tax_no',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Set_Input = {
  bank?: InputMaybe<Scalars['String']>;
  bank_account?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  tax_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Billinginfo_Stddev_Fields = {
  __typename?: 'invoices_v2_billinginfo_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Billinginfo_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_billinginfo_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Billinginfo_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_billinginfo_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "invoices_v2_billinginfo" */
export type Invoices_V2_Billinginfo_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Billinginfo_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Billinginfo_Stream_Cursor_Value_Input = {
  bank?: InputMaybe<Scalars['String']>;
  bank_account?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  post_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  tax_no?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Billinginfo_Sum_Fields = {
  __typename?: 'invoices_v2_billinginfo_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "invoices_v2_billinginfo" */
export enum Invoices_V2_Billinginfo_Update_Column {
  /** column name */
  Bank = 'bank',
  /** column name */
  BankAccount = 'bank_account',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  PostCode = 'post_code',
  /** column name */
  Street = 'street',
  /** column name */
  TaxNo = 'tax_no',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoices_V2_Billinginfo_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Billinginfo_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Billinginfo_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Billinginfo_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Billinginfo_Var_Pop_Fields = {
  __typename?: 'invoices_v2_billinginfo_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Billinginfo_Var_Samp_Fields = {
  __typename?: 'invoices_v2_billinginfo_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Invoices_V2_Billinginfo_Variance_Fields = {
  __typename?: 'invoices_v2_billinginfo_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "invoices_v2_invoice" */
export type Invoices_V2_Invoice = {
  __typename?: 'invoices_v2_invoice';
  add_client_tax: Scalars['Boolean'];
  client_billing_info_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  /** An object relationship */
  invoices_v2_billinginfo: Invoices_V2_Billinginfo;
  /** An array relationship */
  invoices_v2_invoicelineitems: Array<Invoices_V2_Invoicelineitem>;
  /** An aggregate relationship */
  invoices_v2_invoicelineitems_aggregate: Invoices_V2_Invoicelineitem_Aggregate;
  /** An array relationship */
  invoices_v2_invoicesenttos: Array<Invoices_V2_Invoicesentto>;
  /** An aggregate relationship */
  invoices_v2_invoicesenttos_aggregate: Invoices_V2_Invoicesentto_Aggregate;
  kind: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  payment: Scalars['String'];
  payment_to: Scalars['timestamptz'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  sell_date: Scalars['timestamptz'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  view_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "invoices_v2_invoice" */
export type Invoices_V2_InvoiceInvoices_V2_InvoicelineitemsArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_invoice" */
export type Invoices_V2_InvoiceInvoices_V2_Invoicelineitems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_invoice" */
export type Invoices_V2_InvoiceInvoices_V2_InvoicesenttosArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_invoice" */
export type Invoices_V2_InvoiceInvoices_V2_Invoicesenttos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};

/** aggregated selection of "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Aggregate = {
  __typename?: 'invoices_v2_invoice_aggregate';
  aggregate?: Maybe<Invoices_V2_Invoice_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Invoice>;
};

export type Invoices_V2_Invoice_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoices_V2_Invoice_Aggregate_Bool_Exp_Count>;
};

export type Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoices_V2_Invoice_Select_Column_Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoices_V2_Invoice_Select_Column_Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoices_V2_Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Aggregate_Fields = {
  __typename?: 'invoices_v2_invoice_aggregate_fields';
  avg?: Maybe<Invoices_V2_Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Invoice_Max_Fields>;
  min?: Maybe<Invoices_V2_Invoice_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Invoice_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoices_V2_Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoices_V2_Invoice_Max_Order_By>;
  min?: InputMaybe<Invoices_V2_Invoice_Min_Order_By>;
  stddev?: InputMaybe<Invoices_V2_Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoices_V2_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoices_V2_Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoices_V2_Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Invoices_V2_Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoices_V2_Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoices_V2_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoices_V2_Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_V2_Invoice_Avg_Fields = {
  __typename?: 'invoices_v2_invoice_avg_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Avg_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices_v2_invoice". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Invoice_Bool_Exp>>;
  add_client_tax?: InputMaybe<Boolean_Comparison_Exp>;
  client_billing_info_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
  invoices_v2_invoicelineitems?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
  invoices_v2_invoicelineitems_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp>;
  invoices_v2_invoicesenttos?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
  invoices_v2_invoicesenttos_aggregate?: InputMaybe<Invoices_V2_Invoicesentto_Aggregate_Bool_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  payment?: InputMaybe<String_Comparison_Exp>;
  payment_to?: InputMaybe<Timestamptz_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  sell_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  view_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_invoice" */
export enum Invoices_V2_Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesPkey = 'invoices_pkey',
  /** unique or primary key constraint on columns "external_id" */
  InvoicesV2InvoiceExternalIdBb5e2272Uniq = 'invoices_v2_invoice_external_id_bb5e2272_uniq'
}

/** input type for incrementing numeric columns in table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Inc_Input = {
  client_billing_info_id?: InputMaybe<Scalars['bigint']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Insert_Input = {
  add_client_tax?: InputMaybe<Scalars['Boolean']>;
  client_billing_info_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Obj_Rel_Insert_Input>;
  invoices_v2_invoicelineitems?: InputMaybe<Invoices_V2_Invoicelineitem_Arr_Rel_Insert_Input>;
  invoices_v2_invoicesenttos?: InputMaybe<Invoices_V2_Invoicesentto_Arr_Rel_Insert_Input>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_to?: InputMaybe<Scalars['timestamptz']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sell_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invoices_V2_Invoice_Max_Fields = {
  __typename?: 'invoices_v2_invoice_max_fields';
  client_billing_info_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  payment_to?: Maybe<Scalars['timestamptz']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  sell_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Max_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  payment_to?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sell_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_V2_Invoice_Min_Fields = {
  __typename?: 'invoices_v2_invoice_min_fields';
  client_billing_info_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  payment?: Maybe<Scalars['String']>;
  payment_to?: Maybe<Scalars['timestamptz']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  sell_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  view_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Min_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  payment_to?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sell_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Mutation_Response = {
  __typename?: 'invoices_v2_invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Invoice>;
};

/** input type for inserting object relation for remote table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Obj_Rel_Insert_Input = {
  data: Invoices_V2_Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_On_Conflict = {
  constraint: Invoices_V2_Invoice_Constraint;
  update_columns?: Array<Invoices_V2_Invoice_Update_Column>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_invoice". */
export type Invoices_V2_Invoice_Order_By = {
  add_client_tax?: InputMaybe<Order_By>;
  client_billing_info_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Order_By>;
  invoices_v2_invoicelineitems_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Aggregate_Order_By>;
  invoices_v2_invoicesenttos_aggregate?: InputMaybe<Invoices_V2_Invoicesentto_Aggregate_Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  payment?: InputMaybe<Order_By>;
  payment_to?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  sell_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  view_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices_v2_invoice */
export type Invoices_V2_Invoice_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_invoice" */
export enum Invoices_V2_Invoice_Select_Column {
  /** column name */
  AddClientTax = 'add_client_tax',
  /** column name */
  ClientBillingInfoId = 'client_billing_info_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Number = 'number',
  /** column name */
  Payment = 'payment',
  /** column name */
  PaymentTo = 'payment_to',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SellDate = 'sell_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewUrl = 'view_url'
}

/** select "invoices_v2_invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoices_v2_invoice" */
export enum Invoices_V2_Invoice_Select_Column_Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AddClientTax = 'add_client_tax'
}

/** select "invoices_v2_invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoices_v2_invoice" */
export enum Invoices_V2_Invoice_Select_Column_Invoices_V2_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AddClientTax = 'add_client_tax'
}

/** input type for updating data in table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Set_Input = {
  add_client_tax?: InputMaybe<Scalars['Boolean']>;
  client_billing_info_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_to?: InputMaybe<Scalars['timestamptz']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sell_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Invoice_Stddev_Fields = {
  __typename?: 'invoices_v2_invoice_stddev_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Stddev_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_invoice_stddev_pop_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Stddev_Pop_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_invoice_stddev_samp_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Stddev_Samp_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Invoice_Stream_Cursor_Value_Input = {
  add_client_tax?: InputMaybe<Scalars['Boolean']>;
  client_billing_info_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<Scalars['String']>;
  payment_to?: InputMaybe<Scalars['timestamptz']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  sell_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  view_url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Invoice_Sum_Fields = {
  __typename?: 'invoices_v2_invoice_sum_fields';
  client_billing_info_id?: Maybe<Scalars['bigint']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Sum_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** update columns of table "invoices_v2_invoice" */
export enum Invoices_V2_Invoice_Update_Column {
  /** column name */
  AddClientTax = 'add_client_tax',
  /** column name */
  ClientBillingInfoId = 'client_billing_info_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Number = 'number',
  /** column name */
  Payment = 'payment',
  /** column name */
  PaymentTo = 'payment_to',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  SellDate = 'sell_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ViewUrl = 'view_url'
}

export type Invoices_V2_Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Invoice_Var_Pop_Fields = {
  __typename?: 'invoices_v2_invoice_var_pop_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Var_Pop_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Invoice_Var_Samp_Fields = {
  __typename?: 'invoices_v2_invoice_var_samp_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Var_Samp_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_V2_Invoice_Variance_Fields = {
  __typename?: 'invoices_v2_invoice_variance_fields';
  client_billing_info_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices_v2_invoice" */
export type Invoices_V2_Invoice_Variance_Order_By = {
  client_billing_info_id?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig = {
  __typename?: 'invoices_v2_invoiceconfig';
  active: Scalars['Boolean'];
  api_token?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  subdomain: Scalars['String'];
  temporary_password?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  webhook_api_token?: Maybe<Scalars['String']>;
};

/** aggregated selection of "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Aggregate = {
  __typename?: 'invoices_v2_invoiceconfig_aggregate';
  aggregate?: Maybe<Invoices_V2_Invoiceconfig_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Invoiceconfig>;
};

/** aggregate fields of "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Aggregate_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_aggregate_fields';
  avg?: Maybe<Invoices_V2_Invoiceconfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Invoiceconfig_Max_Fields>;
  min?: Maybe<Invoices_V2_Invoiceconfig_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Invoiceconfig_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Invoiceconfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Invoiceconfig_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Invoiceconfig_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Invoiceconfig_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Invoiceconfig_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Invoiceconfig_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Invoices_V2_Invoiceconfig_Avg_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "invoices_v2_invoiceconfig". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Invoiceconfig_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  api_token?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  subdomain?: InputMaybe<String_Comparison_Exp>;
  temporary_password?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  webhook_api_token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_invoiceconfig" */
export enum Invoices_V2_Invoiceconfig_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesV2InvoiceconfigPkey = 'invoices_v2_invoiceconfig_pkey',
  /** unique or primary key constraint on columns "practice_id" */
  InvoicesV2InvoiceconfigPracticeIdKey = 'invoices_v2_invoiceconfig_practice_id_key',
  /** unique or primary key constraint on columns "subdomain" */
  InvoicesV2InvoiceconfigSubdomainC45331d5Uniq = 'invoices_v2_invoiceconfig_subdomain_c45331d5_uniq'
}

/** input type for incrementing numeric columns in table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  api_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  subdomain?: InputMaybe<Scalars['String']>;
  temporary_password?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_api_token?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Invoices_V2_Invoiceconfig_Max_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_max_fields';
  api_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  subdomain?: Maybe<Scalars['String']>;
  temporary_password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webhook_api_token?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Invoices_V2_Invoiceconfig_Min_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_min_fields';
  api_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  subdomain?: Maybe<Scalars['String']>;
  temporary_password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webhook_api_token?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Mutation_Response = {
  __typename?: 'invoices_v2_invoiceconfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Invoiceconfig>;
};

/** input type for inserting object relation for remote table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Obj_Rel_Insert_Input = {
  data: Invoices_V2_Invoiceconfig_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoiceconfig_On_Conflict>;
};

/** on_conflict condition type for table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_On_Conflict = {
  constraint: Invoices_V2_Invoiceconfig_Constraint;
  update_columns?: Array<Invoices_V2_Invoiceconfig_Update_Column>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_invoiceconfig". */
export type Invoices_V2_Invoiceconfig_Order_By = {
  active?: InputMaybe<Order_By>;
  api_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  subdomain?: InputMaybe<Order_By>;
  temporary_password?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhook_api_token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices_v2_invoiceconfig */
export type Invoices_V2_Invoiceconfig_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_invoiceconfig" */
export enum Invoices_V2_Invoiceconfig_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApiToken = 'api_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  TemporaryPassword = 'temporary_password',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookApiToken = 'webhook_api_token'
}

/** input type for updating data in table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  api_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  subdomain?: InputMaybe<Scalars['String']>;
  temporary_password?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_api_token?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Invoiceconfig_Stddev_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Invoiceconfig_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Invoiceconfig_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "invoices_v2_invoiceconfig" */
export type Invoices_V2_Invoiceconfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Invoiceconfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Invoiceconfig_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  api_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  subdomain?: InputMaybe<Scalars['String']>;
  temporary_password?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_api_token?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Invoiceconfig_Sum_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "invoices_v2_invoiceconfig" */
export enum Invoices_V2_Invoiceconfig_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ApiToken = 'api_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Subdomain = 'subdomain',
  /** column name */
  TemporaryPassword = 'temporary_password',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookApiToken = 'webhook_api_token'
}

export type Invoices_V2_Invoiceconfig_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Invoiceconfig_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Invoiceconfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Invoiceconfig_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Invoiceconfig_Var_Pop_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Invoiceconfig_Var_Samp_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Invoices_V2_Invoiceconfig_Variance_Fields = {
  __typename?: 'invoices_v2_invoiceconfig_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem = {
  __typename?: 'invoices_v2_invoicelineitem';
  created_at: Scalars['timestamptz'];
  custom_quantity?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id: Scalars['bigint'];
  invoice_id: Scalars['bigint'];
  /** An object relationship */
  invoices_v2_invoice: Invoices_V2_Invoice;
  /** An array relationship */
  invoices_v2_invoicelineitem_events: Array<Invoices_V2_Invoicelineitem_Events>;
  /** An aggregate relationship */
  invoices_v2_invoicelineitem_events_aggregate: Invoices_V2_Invoicelineitem_Events_Aggregate;
  item_price: Scalars['numeric'];
  name: Scalars['String'];
  tax: Scalars['numeric'];
  tax_free: Scalars['Boolean'];
  tax_not_applicable: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "invoices_v2_invoicelineitem" */
export type Invoices_V2_InvoicelineitemInvoices_V2_Invoicelineitem_EventsArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


/** columns and relationships of "invoices_v2_invoicelineitem" */
export type Invoices_V2_InvoicelineitemInvoices_V2_Invoicelineitem_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};

/** aggregated selection of "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Aggregate = {
  __typename?: 'invoices_v2_invoicelineitem_aggregate';
  aggregate?: Maybe<Invoices_V2_Invoicelineitem_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Invoicelineitem>;
};

export type Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Count>;
};

export type Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoices_V2_Invoicelineitem_Select_Column_Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoices_V2_Invoicelineitem_Select_Column_Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Aggregate_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_aggregate_fields';
  avg?: Maybe<Invoices_V2_Invoicelineitem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Invoicelineitem_Max_Fields>;
  min?: Maybe<Invoices_V2_Invoicelineitem_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Invoicelineitem_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Invoicelineitem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Invoicelineitem_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Invoicelineitem_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Invoicelineitem_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Invoicelineitem_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Invoicelineitem_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Aggregate_Order_By = {
  avg?: InputMaybe<Invoices_V2_Invoicelineitem_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoices_V2_Invoicelineitem_Max_Order_By>;
  min?: InputMaybe<Invoices_V2_Invoicelineitem_Min_Order_By>;
  stddev?: InputMaybe<Invoices_V2_Invoicelineitem_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoices_V2_Invoicelineitem_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoices_V2_Invoicelineitem_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoices_V2_Invoicelineitem_Sum_Order_By>;
  var_pop?: InputMaybe<Invoices_V2_Invoicelineitem_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoices_V2_Invoicelineitem_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoices_V2_Invoicelineitem_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Arr_Rel_Insert_Input = {
  data: Array<Invoices_V2_Invoicelineitem_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_V2_Invoicelineitem_Avg_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_avg_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Avg_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices_v2_invoicelineitem". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Invoicelineitem_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_quantity?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoice_id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  invoices_v2_invoicelineitem_events?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
  invoices_v2_invoicelineitem_events_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Bool_Exp>;
  item_price?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tax?: InputMaybe<Numeric_Comparison_Exp>;
  tax_free?: InputMaybe<Boolean_Comparison_Exp>;
  tax_not_applicable?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_invoicelineitem" */
export enum Invoices_V2_Invoicelineitem_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesV2InvoicelineitemPkey = 'invoices_v2_invoicelineitem_pkey'
}

/** columns and relationships of "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events = {
  __typename?: 'invoices_v2_invoicelineitem_events';
  /** An object relationship */
  event: Event;
  event_id: Scalars['bigint'];
  id: Scalars['bigint'];
  invoicelineitem_id: Scalars['bigint'];
  /** An object relationship */
  invoices_v2_invoicelineitem: Invoices_V2_Invoicelineitem;
};

/** aggregated selection of "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Aggregate = {
  __typename?: 'invoices_v2_invoicelineitem_events_aggregate';
  aggregate?: Maybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Invoicelineitem_Events>;
};

export type Invoices_V2_Invoicelineitem_Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Bool_Exp_Count>;
};

export type Invoices_V2_Invoicelineitem_Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Aggregate_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_aggregate_fields';
  avg?: Maybe<Invoices_V2_Invoicelineitem_Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Invoicelineitem_Events_Max_Fields>;
  min?: Maybe<Invoices_V2_Invoicelineitem_Events_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Invoicelineitem_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Invoicelineitem_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Invoicelineitem_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Invoicelineitem_Events_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Invoicelineitem_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Invoicelineitem_Events_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Invoicelineitem_Events_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Aggregate_Order_By = {
  avg?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Max_Order_By>;
  min?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Min_Order_By>;
  stddev?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Sum_Order_By>;
  var_pop?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Arr_Rel_Insert_Input = {
  data: Array<Invoices_V2_Invoicelineitem_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_V2_Invoicelineitem_Events_Avg_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices_v2_invoicelineitem_events". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Invoicelineitem_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Bool_Exp>>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoicelineitem_id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_invoicelineitem?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_invoicelineitem_events" */
export enum Invoices_V2_Invoicelineitem_Events_Constraint {
  /** unique or primary key constraint on columns "event_id", "invoicelineitem_id" */
  InvoicesV2InvoicelineiInvoicelineitemIdEvent_94b20d50Uniq = 'invoices_v2_invoicelinei_invoicelineitem_id_event_94b20d50_uniq',
  /** unique or primary key constraint on columns "id" */
  InvoicesV2InvoicelineitemEventsPkey = 'invoices_v2_invoicelineitem_events_pkey'
}

/** input type for incrementing numeric columns in table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicelineitem_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Insert_Input = {
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicelineitem_id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_invoicelineitem?: InputMaybe<Invoices_V2_Invoicelineitem_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Invoices_V2_Invoicelineitem_Events_Max_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_max_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicelineitem_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Max_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_V2_Invoicelineitem_Events_Min_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_min_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicelineitem_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Min_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Mutation_Response = {
  __typename?: 'invoices_v2_invoicelineitem_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Invoicelineitem_Events>;
};

/** on_conflict condition type for table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_On_Conflict = {
  constraint: Invoices_V2_Invoicelineitem_Events_Constraint;
  update_columns?: Array<Invoices_V2_Invoicelineitem_Events_Update_Column>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_invoicelineitem_events". */
export type Invoices_V2_Invoicelineitem_Events_Order_By = {
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
  invoices_v2_invoicelineitem?: InputMaybe<Invoices_V2_Invoicelineitem_Order_By>;
};

/** primary key columns input for table: invoices_v2_invoicelineitem_events */
export type Invoices_V2_Invoicelineitem_Events_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_invoicelineitem_events" */
export enum Invoices_V2_Invoicelineitem_Events_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicelineitemId = 'invoicelineitem_id'
}

/** input type for updating data in table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Set_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicelineitem_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Invoicelineitem_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Invoicelineitem_Events_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoicelineitem_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Invoicelineitem_Events_Sum_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_sum_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  invoicelineitem_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** update columns of table "invoices_v2_invoicelineitem_events" */
export enum Invoices_V2_Invoicelineitem_Events_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoicelineitemId = 'invoicelineitem_id'
}

export type Invoices_V2_Invoicelineitem_Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Invoicelineitem_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Invoicelineitem_Events_Var_Pop_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Invoicelineitem_Events_Var_Samp_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_V2_Invoicelineitem_Events_Variance_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_events_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoicelineitem_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices_v2_invoicelineitem_events" */
export type Invoices_V2_Invoicelineitem_Events_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoicelineitem_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Inc_Input = {
  custom_quantity?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  item_price?: InputMaybe<Scalars['numeric']>;
  tax?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quantity?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Obj_Rel_Insert_Input>;
  invoices_v2_invoicelineitem_events?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Arr_Rel_Insert_Input>;
  item_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tax_free?: InputMaybe<Scalars['Boolean']>;
  tax_not_applicable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoices_V2_Invoicelineitem_Max_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_quantity?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
  item_price?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_quantity?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_V2_Invoicelineitem_Min_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_quantity?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
  item_price?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_quantity?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Mutation_Response = {
  __typename?: 'invoices_v2_invoicelineitem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Invoicelineitem>;
};

/** input type for inserting object relation for remote table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Obj_Rel_Insert_Input = {
  data: Invoices_V2_Invoicelineitem_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_On_Conflict>;
};

/** on_conflict condition type for table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_On_Conflict = {
  constraint: Invoices_V2_Invoicelineitem_Constraint;
  update_columns?: Array<Invoices_V2_Invoicelineitem_Update_Column>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_invoicelineitem". */
export type Invoices_V2_Invoicelineitem_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_quantity?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Order_By>;
  invoices_v2_invoicelineitem_events_aggregate?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Aggregate_Order_By>;
  item_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
  tax_free?: InputMaybe<Order_By>;
  tax_not_applicable?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices_v2_invoicelineitem */
export type Invoices_V2_Invoicelineitem_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_invoicelineitem" */
export enum Invoices_V2_Invoicelineitem_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomQuantity = 'custom_quantity',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  Name = 'name',
  /** column name */
  Tax = 'tax',
  /** column name */
  TaxFree = 'tax_free',
  /** column name */
  TaxNotApplicable = 'tax_not_applicable',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "invoices_v2_invoicelineitem_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoices_v2_invoicelineitem" */
export enum Invoices_V2_Invoicelineitem_Select_Column_Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  TaxFree = 'tax_free',
  /** column name */
  TaxNotApplicable = 'tax_not_applicable'
}

/** select "invoices_v2_invoicelineitem_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoices_v2_invoicelineitem" */
export enum Invoices_V2_Invoicelineitem_Select_Column_Invoices_V2_Invoicelineitem_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  TaxFree = 'tax_free',
  /** column name */
  TaxNotApplicable = 'tax_not_applicable'
}

/** input type for updating data in table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quantity?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  item_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tax_free?: InputMaybe<Scalars['Boolean']>;
  tax_not_applicable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Invoicelineitem_Stddev_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_stddev_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Stddev_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Invoicelineitem_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_stddev_pop_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Stddev_Pop_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Invoicelineitem_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_stddev_samp_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Stddev_Samp_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Invoicelineitem_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Invoicelineitem_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quantity?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  item_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Scalars['numeric']>;
  tax_free?: InputMaybe<Scalars['Boolean']>;
  tax_not_applicable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Invoicelineitem_Sum_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_sum_fields';
  custom_quantity?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
  item_price?: Maybe<Scalars['numeric']>;
  tax?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Sum_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** update columns of table "invoices_v2_invoicelineitem" */
export enum Invoices_V2_Invoicelineitem_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomQuantity = 'custom_quantity',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ItemPrice = 'item_price',
  /** column name */
  Name = 'name',
  /** column name */
  Tax = 'tax',
  /** column name */
  TaxFree = 'tax_free',
  /** column name */
  TaxNotApplicable = 'tax_not_applicable',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoices_V2_Invoicelineitem_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Invoicelineitem_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Invoicelineitem_Var_Pop_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_var_pop_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Var_Pop_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Invoicelineitem_Var_Samp_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_var_samp_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Var_Samp_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_V2_Invoicelineitem_Variance_Fields = {
  __typename?: 'invoices_v2_invoicelineitem_variance_fields';
  custom_quantity?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
  item_price?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices_v2_invoicelineitem" */
export type Invoices_V2_Invoicelineitem_Variance_Order_By = {
  custom_quantity?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  item_price?: InputMaybe<Order_By>;
  tax?: InputMaybe<Order_By>;
};

/** columns and relationships of "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto = {
  __typename?: 'invoices_v2_invoicesentto';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  emails: Array<Scalars['String']>;
  id: Scalars['bigint'];
  invoice_id: Scalars['bigint'];
  /** An object relationship */
  invoices_v2_invoice: Invoices_V2_Invoice;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Aggregate = {
  __typename?: 'invoices_v2_invoicesentto_aggregate';
  aggregate?: Maybe<Invoices_V2_Invoicesentto_Aggregate_Fields>;
  nodes: Array<Invoices_V2_Invoicesentto>;
};

export type Invoices_V2_Invoicesentto_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoices_V2_Invoicesentto_Aggregate_Bool_Exp_Count>;
};

export type Invoices_V2_Invoicesentto_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Aggregate_Fields = {
  __typename?: 'invoices_v2_invoicesentto_aggregate_fields';
  avg?: Maybe<Invoices_V2_Invoicesentto_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoices_V2_Invoicesentto_Max_Fields>;
  min?: Maybe<Invoices_V2_Invoicesentto_Min_Fields>;
  stddev?: Maybe<Invoices_V2_Invoicesentto_Stddev_Fields>;
  stddev_pop?: Maybe<Invoices_V2_Invoicesentto_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoices_V2_Invoicesentto_Stddev_Samp_Fields>;
  sum?: Maybe<Invoices_V2_Invoicesentto_Sum_Fields>;
  var_pop?: Maybe<Invoices_V2_Invoicesentto_Var_Pop_Fields>;
  var_samp?: Maybe<Invoices_V2_Invoicesentto_Var_Samp_Fields>;
  variance?: Maybe<Invoices_V2_Invoicesentto_Variance_Fields>;
};


/** aggregate fields of "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Aggregate_Order_By = {
  avg?: InputMaybe<Invoices_V2_Invoicesentto_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoices_V2_Invoicesentto_Max_Order_By>;
  min?: InputMaybe<Invoices_V2_Invoicesentto_Min_Order_By>;
  stddev?: InputMaybe<Invoices_V2_Invoicesentto_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoices_V2_Invoicesentto_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoices_V2_Invoicesentto_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoices_V2_Invoicesentto_Sum_Order_By>;
  var_pop?: InputMaybe<Invoices_V2_Invoicesentto_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoices_V2_Invoicesentto_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoices_V2_Invoicesentto_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Arr_Rel_Insert_Input = {
  data: Array<Invoices_V2_Invoicesentto_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoices_V2_Invoicesentto_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoices_V2_Invoicesentto_Avg_Fields = {
  __typename?: 'invoices_v2_invoicesentto_avg_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoices_v2_invoicesentto". All fields are combined with a logical 'AND'. */
export type Invoices_V2_Invoicesentto_Bool_Exp = {
  _and?: InputMaybe<Array<Invoices_V2_Invoicesentto_Bool_Exp>>;
  _not?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
  _or?: InputMaybe<Array<Invoices_V2_Invoicesentto_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  emails?: InputMaybe<String_Array_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoice_id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoices_v2_invoicesentto" */
export enum Invoices_V2_Invoicesentto_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoicesV2InvoicesenttoPkey = 'invoices_v2_invoicesentto_pkey'
}

/** input type for incrementing numeric columns in table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Invoices_V2_Invoicesentto_Max_Fields = {
  __typename?: 'invoices_v2_invoicesentto_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  emails?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emails?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoices_V2_Invoicesentto_Min_Fields = {
  __typename?: 'invoices_v2_invoicesentto_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  emails?: Maybe<Array<Scalars['String']>>;
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emails?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Mutation_Response = {
  __typename?: 'invoices_v2_invoicesentto_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoices_V2_Invoicesentto>;
};

/** on_conflict condition type for table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_On_Conflict = {
  constraint: Invoices_V2_Invoicesentto_Constraint;
  update_columns?: Array<Invoices_V2_Invoicesentto_Update_Column>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};

/** Ordering options when selecting data from "invoices_v2_invoicesentto". */
export type Invoices_V2_Invoicesentto_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  emails?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoices_v2_invoice?: InputMaybe<Invoices_V2_Invoice_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoices_v2_invoicesentto */
export type Invoices_V2_Invoicesentto_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "invoices_v2_invoicesentto" */
export enum Invoices_V2_Invoicesentto_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Emails = 'emails',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Invoices_V2_Invoicesentto_Stddev_Fields = {
  __typename?: 'invoices_v2_invoicesentto_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoices_V2_Invoicesentto_Stddev_Pop_Fields = {
  __typename?: 'invoices_v2_invoicesentto_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoices_V2_Invoicesentto_Stddev_Samp_Fields = {
  __typename?: 'invoices_v2_invoicesentto_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoices_V2_Invoicesentto_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoices_V2_Invoicesentto_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['bigint']>;
  invoice_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Invoices_V2_Invoicesentto_Sum_Fields = {
  __typename?: 'invoices_v2_invoicesentto_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  invoice_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** update columns of table "invoices_v2_invoicesentto" */
export enum Invoices_V2_Invoicesentto_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Emails = 'emails',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoices_V2_Invoicesentto_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoices_V2_Invoicesentto_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoices_V2_Invoicesentto_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoices_V2_Invoicesentto_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoices_V2_Invoicesentto_Var_Pop_Fields = {
  __typename?: 'invoices_v2_invoicesentto_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoices_V2_Invoicesentto_Var_Samp_Fields = {
  __typename?: 'invoices_v2_invoicesentto_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoices_V2_Invoicesentto_Variance_Fields = {
  __typename?: 'invoices_v2_invoicesentto_variance_fields';
  id?: Maybe<Scalars['Float']>;
  invoice_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoices_v2_invoicesentto" */
export type Invoices_V2_Invoicesentto_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "accounting_practiceexpense" */
  delete_accounting_practiceexpense?: Maybe<Accounting_Practiceexpense_Mutation_Response>;
  /** delete single row from the table: "accounting_practiceexpense" */
  delete_accounting_practiceexpense_by_pk?: Maybe<Accounting_Practiceexpense>;
  /** delete data from the table: "accounting_recurringexpense" */
  delete_accounting_recurringexpense?: Maybe<Accounting_Recurringexpense_Mutation_Response>;
  /** delete single row from the table: "accounting_recurringexpense" */
  delete_accounting_recurringexpense_by_pk?: Maybe<Accounting_Recurringexpense>;
  /** delete data from the table: "addresses" */
  delete_addresses?: Maybe<Addresses_Mutation_Response>;
  /** delete single row from the table: "addresses" */
  delete_addresses_by_pk?: Maybe<Addresses>;
  /** delete data from the table: "avatars" */
  delete_avatars?: Maybe<Avatars_Mutation_Response>;
  /** delete single row from the table: "avatars" */
  delete_avatars_by_pk?: Maybe<Avatars>;
  /** delete data from the table: "contact_details" */
  delete_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** delete single row from the table: "contact_details" */
  delete_contact_details_by_pk?: Maybe<Contact_Details>;
  /** delete data from the table: "couples" */
  delete_couples?: Maybe<Couples_Mutation_Response>;
  /** delete single row from the table: "couples" */
  delete_couples_by_pk?: Maybe<Couples>;
  /** delete data from the table: "couples_patients" */
  delete_couples_patients?: Maybe<Couples_Patients_Mutation_Response>;
  /** delete single row from the table: "couples_patients" */
  delete_couples_patients_by_pk?: Maybe<Couples_Patients>;
  /** delete data from the table: "couples_therapists" */
  delete_couples_therapists?: Maybe<Couples_Therapists_Mutation_Response>;
  /** delete single row from the table: "couples_therapists" */
  delete_couples_therapists_by_pk?: Maybe<Couples_Therapists>;
  /** delete data from the table: "ed_attachments" */
  delete_ed_attachments?: Maybe<Ed_Attachments_Mutation_Response>;
  /** delete single row from the table: "ed_attachments" */
  delete_ed_attachments_by_pk?: Maybe<Ed_Attachments>;
  /** delete data from the table: "ed_group_presence" */
  delete_ed_group_presence?: Maybe<Ed_Group_Presence_Mutation_Response>;
  /** delete single row from the table: "ed_group_presence" */
  delete_ed_group_presence_by_pk?: Maybe<Ed_Group_Presence>;
  /** delete data from the table: "ed_participants" */
  delete_ed_participants?: Maybe<Ed_Participants_Mutation_Response>;
  /** delete single row from the table: "ed_participants" */
  delete_ed_participants_by_pk?: Maybe<Ed_Participants>;
  /** delete data from the table: "ed_payment_method" */
  delete_ed_payment_method?: Maybe<Ed_Payment_Method_Mutation_Response>;
  /** delete single row from the table: "ed_payment_method" */
  delete_ed_payment_method_by_pk?: Maybe<Ed_Payment_Method>;
  /** delete data from the table: "ed_presence" */
  delete_ed_presence?: Maybe<Ed_Presence_Mutation_Response>;
  /** delete single row from the table: "ed_presence" */
  delete_ed_presence_by_pk?: Maybe<Ed_Presence>;
  /** delete data from the table: "employees" */
  delete_employees?: Maybe<Employees_Mutation_Response>;
  /** delete single row from the table: "employees" */
  delete_employees_by_pk?: Maybe<Employees>;
  /** delete data from the table: "employees_favorite_therapists" */
  delete_employees_favorite_therapists?: Maybe<Employees_Favorite_Therapists_Mutation_Response>;
  /** delete single row from the table: "employees_favorite_therapists" */
  delete_employees_favorite_therapists_by_pk?: Maybe<Employees_Favorite_Therapists>;
  /** delete data from the table: "employees_preferred_room" */
  delete_employees_preferred_room?: Maybe<Employees_Preferred_Room_Mutation_Response>;
  /** delete single row from the table: "employees_preferred_room" */
  delete_employees_preferred_room_by_pk?: Maybe<Employees_Preferred_Room>;
  /** delete data from the table: "employees_roles" */
  delete_employees_roles?: Maybe<Employees_Roles_Mutation_Response>;
  /** delete single row from the table: "employees_roles" */
  delete_employees_roles_by_pk?: Maybe<Employees_Roles>;
  /** delete data from the table: "event" */
  delete_event?: Maybe<Event_Mutation_Response>;
  /** delete single row from the table: "event" */
  delete_event_by_pk?: Maybe<Event>;
  /** delete data from the table: "event_participants" */
  delete_event_participants?: Maybe<Event_Participants_Mutation_Response>;
  /** delete single row from the table: "event_participants" */
  delete_event_participants_by_pk?: Maybe<Event_Participants>;
  /** delete data from the table: "event_period" */
  delete_event_period?: Maybe<Event_Period_Mutation_Response>;
  /** delete single row from the table: "event_period" */
  delete_event_period_by_pk?: Maybe<Event_Period>;
  /** delete data from the table: "event_period_participants" */
  delete_event_period_participants?: Maybe<Event_Period_Participants_Mutation_Response>;
  /** delete single row from the table: "event_period_participants" */
  delete_event_period_participants_by_pk?: Maybe<Event_Period_Participants>;
  /** delete data from the table: "event_period_services" */
  delete_event_period_services?: Maybe<Event_Period_Services_Mutation_Response>;
  /** delete single row from the table: "event_period_services" */
  delete_event_period_services_by_pk?: Maybe<Event_Period_Services>;
  /** delete data from the table: "event_period_therapists" */
  delete_event_period_therapists?: Maybe<Event_Period_Therapists_Mutation_Response>;
  /** delete single row from the table: "event_period_therapists" */
  delete_event_period_therapists_by_pk?: Maybe<Event_Period_Therapists>;
  /** delete data from the table: "event_services" */
  delete_event_services?: Maybe<Event_Services_Mutation_Response>;
  /** delete single row from the table: "event_services" */
  delete_event_services_by_pk?: Maybe<Event_Services>;
  /** delete data from the table: "event_therapists" */
  delete_event_therapists?: Maybe<Event_Therapists_Mutation_Response>;
  /** delete single row from the table: "event_therapists" */
  delete_event_therapists_by_pk?: Maybe<Event_Therapists>;
  /** delete data from the table: "gdpr_gdprconfig" */
  delete_gdpr_gdprconfig?: Maybe<Gdpr_Gdprconfig_Mutation_Response>;
  /** delete single row from the table: "gdpr_gdprconfig" */
  delete_gdpr_gdprconfig_by_pk?: Maybe<Gdpr_Gdprconfig>;
  /** delete data from the table: "gdpr_gdprdocument" */
  delete_gdpr_gdprdocument?: Maybe<Gdpr_Gdprdocument_Mutation_Response>;
  /** delete single row from the table: "gdpr_gdprdocument" */
  delete_gdpr_gdprdocument_by_pk?: Maybe<Gdpr_Gdprdocument>;
  /** delete data from the table: "gdpr_gdprdocumenttype" */
  delete_gdpr_gdprdocumenttype?: Maybe<Gdpr_Gdprdocumenttype_Mutation_Response>;
  /** delete single row from the table: "gdpr_gdprdocumenttype" */
  delete_gdpr_gdprdocumenttype_by_pk?: Maybe<Gdpr_Gdprdocumenttype>;
  /** delete data from the table: "group_members" */
  delete_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** delete single row from the table: "group_members" */
  delete_group_members_by_pk?: Maybe<Group_Members>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "holiday_reasons" */
  delete_holiday_reasons?: Maybe<Holiday_Reasons_Mutation_Response>;
  /** delete single row from the table: "holiday_reasons" */
  delete_holiday_reasons_by_pk?: Maybe<Holiday_Reasons>;
  /** delete data from the table: "holidays" */
  delete_holidays?: Maybe<Holidays_Mutation_Response>;
  /** delete single row from the table: "holidays" */
  delete_holidays_by_pk?: Maybe<Holidays>;
  /** delete data from the table: "invoices_v2_billinginfo" */
  delete_invoices_v2_billinginfo?: Maybe<Invoices_V2_Billinginfo_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_billinginfo" */
  delete_invoices_v2_billinginfo_by_pk?: Maybe<Invoices_V2_Billinginfo>;
  /** delete data from the table: "invoices_v2_invoice" */
  delete_invoices_v2_invoice?: Maybe<Invoices_V2_Invoice_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_invoice" */
  delete_invoices_v2_invoice_by_pk?: Maybe<Invoices_V2_Invoice>;
  /** delete data from the table: "invoices_v2_invoiceconfig" */
  delete_invoices_v2_invoiceconfig?: Maybe<Invoices_V2_Invoiceconfig_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_invoiceconfig" */
  delete_invoices_v2_invoiceconfig_by_pk?: Maybe<Invoices_V2_Invoiceconfig>;
  /** delete data from the table: "invoices_v2_invoicelineitem" */
  delete_invoices_v2_invoicelineitem?: Maybe<Invoices_V2_Invoicelineitem_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_invoicelineitem" */
  delete_invoices_v2_invoicelineitem_by_pk?: Maybe<Invoices_V2_Invoicelineitem>;
  /** delete data from the table: "invoices_v2_invoicelineitem_events" */
  delete_invoices_v2_invoicelineitem_events?: Maybe<Invoices_V2_Invoicelineitem_Events_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_invoicelineitem_events" */
  delete_invoices_v2_invoicelineitem_events_by_pk?: Maybe<Invoices_V2_Invoicelineitem_Events>;
  /** delete data from the table: "invoices_v2_invoicesentto" */
  delete_invoices_v2_invoicesentto?: Maybe<Invoices_V2_Invoicesentto_Mutation_Response>;
  /** delete single row from the table: "invoices_v2_invoicesentto" */
  delete_invoices_v2_invoicesentto_by_pk?: Maybe<Invoices_V2_Invoicesentto>;
  /** delete data from the table: "national_holidays" */
  delete_national_holidays?: Maybe<National_Holidays_Mutation_Response>;
  /** delete single row from the table: "national_holidays" */
  delete_national_holidays_by_pk?: Maybe<National_Holidays>;
  /** delete data from the table: "patient_addresses" */
  delete_patient_addresses?: Maybe<Patient_Addresses_Mutation_Response>;
  /** delete single row from the table: "patient_addresses" */
  delete_patient_addresses_by_pk?: Maybe<Patient_Addresses>;
  /** delete data from the table: "patient_educator" */
  delete_patient_educator?: Maybe<Patient_Educator_Mutation_Response>;
  /** delete single row from the table: "patient_educator" */
  delete_patient_educator_by_pk?: Maybe<Patient_Educator>;
  /** delete data from the table: "patient_guardian_addresses" */
  delete_patient_guardian_addresses?: Maybe<Patient_Guardian_Addresses_Mutation_Response>;
  /** delete single row from the table: "patient_guardian_addresses" */
  delete_patient_guardian_addresses_by_pk?: Maybe<Patient_Guardian_Addresses>;
  /** delete data from the table: "patient_guardians" */
  delete_patient_guardians?: Maybe<Patient_Guardians_Mutation_Response>;
  /** delete single row from the table: "patient_guardians" */
  delete_patient_guardians_by_pk?: Maybe<Patient_Guardians>;
  /** delete data from the table: "patient_psychotherapies" */
  delete_patient_psychotherapies?: Maybe<Patient_Psychotherapies_Mutation_Response>;
  /** delete single row from the table: "patient_psychotherapies" */
  delete_patient_psychotherapies_by_pk?: Maybe<Patient_Psychotherapies>;
  /** delete data from the table: "patient_si" */
  delete_patient_si?: Maybe<Patient_Si_Mutation_Response>;
  /** delete single row from the table: "patient_si" */
  delete_patient_si_by_pk?: Maybe<Patient_Si>;
  /** delete data from the table: "patient_speech_therapies" */
  delete_patient_speech_therapies?: Maybe<Patient_Speech_Therapies_Mutation_Response>;
  /** delete single row from the table: "patient_speech_therapies" */
  delete_patient_speech_therapies_by_pk?: Maybe<Patient_Speech_Therapies>;
  /** delete data from the table: "patients" */
  delete_patients?: Maybe<Patients_Mutation_Response>;
  /** delete single row from the table: "patients" */
  delete_patients_by_pk?: Maybe<Patients>;
  /** delete data from the table: "patients_therapists" */
  delete_patients_therapists?: Maybe<Patients_Therapists_Mutation_Response>;
  /** delete single row from the table: "patients_therapists" */
  delete_patients_therapists_by_pk?: Maybe<Patients_Therapists>;
  /** delete data from the table: "practice_branches" */
  delete_practice_branches?: Maybe<Practice_Branches_Mutation_Response>;
  /** delete single row from the table: "practice_branches" */
  delete_practice_branches_by_pk?: Maybe<Practice_Branches>;
  /** delete data from the table: "practice_logos" */
  delete_practice_logos?: Maybe<Practice_Logos_Mutation_Response>;
  /** delete single row from the table: "practice_logos" */
  delete_practice_logos_by_pk?: Maybe<Practice_Logos>;
  /** delete data from the table: "practices" */
  delete_practices?: Maybe<Practices_Mutation_Response>;
  /** delete single row from the table: "practices" */
  delete_practices_by_pk?: Maybe<Practices>;
  /** delete data from the table: "registration_authsmsconfirmation" */
  delete_registration_authsmsconfirmation?: Maybe<Registration_Authsmsconfirmation_Mutation_Response>;
  /** delete single row from the table: "registration_authsmsconfirmation" */
  delete_registration_authsmsconfirmation_by_pk?: Maybe<Registration_Authsmsconfirmation>;
  /** delete data from the table: "registration_meetingrequest" */
  delete_registration_meetingrequest?: Maybe<Registration_Meetingrequest_Mutation_Response>;
  /** delete single row from the table: "registration_meetingrequest" */
  delete_registration_meetingrequest_by_pk?: Maybe<Registration_Meetingrequest>;
  /** delete data from the table: "registration_onlineregistrationconfig" */
  delete_registration_onlineregistrationconfig?: Maybe<Registration_Onlineregistrationconfig_Mutation_Response>;
  /** delete single row from the table: "registration_onlineregistrationconfig" */
  delete_registration_onlineregistrationconfig_by_pk?: Maybe<Registration_Onlineregistrationconfig>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "request_considerations" */
  delete_request_considerations?: Maybe<Request_Considerations_Mutation_Response>;
  /** delete single row from the table: "request_considerations" */
  delete_request_considerations_by_pk?: Maybe<Request_Considerations>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "rooms" */
  delete_rooms?: Maybe<Rooms_Mutation_Response>;
  /** delete single row from the table: "rooms" */
  delete_rooms_by_pk?: Maybe<Rooms>;
  /** delete data from the table: "services" */
  delete_services?: Maybe<Services_Mutation_Response>;
  /** delete data from the table: "services_assigned_to" */
  delete_services_assigned_to?: Maybe<Services_Assigned_To_Mutation_Response>;
  /** delete single row from the table: "services_assigned_to" */
  delete_services_assigned_to_by_pk?: Maybe<Services_Assigned_To>;
  /** delete single row from the table: "services" */
  delete_services_by_pk?: Maybe<Services>;
  /** delete data from the table: "services_restricted_rooms" */
  delete_services_restricted_rooms?: Maybe<Services_Restricted_Rooms_Mutation_Response>;
  /** delete single row from the table: "services_restricted_rooms" */
  delete_services_restricted_rooms_by_pk?: Maybe<Services_Restricted_Rooms>;
  /** delete data from the table: "sms_notifications_defaultsmstemplate" */
  delete_sms_notifications_defaultsmstemplate?: Maybe<Sms_Notifications_Defaultsmstemplate_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_defaultsmstemplate" */
  delete_sms_notifications_defaultsmstemplate_by_pk?: Maybe<Sms_Notifications_Defaultsmstemplate>;
  /** delete data from the table: "sms_notifications_sentsms" */
  delete_sms_notifications_sentsms?: Maybe<Sms_Notifications_Sentsms_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_sentsms" */
  delete_sms_notifications_sentsms_by_pk?: Maybe<Sms_Notifications_Sentsms>;
  /** delete data from the table: "sms_notifications_smsconfig" */
  delete_sms_notifications_smsconfig?: Maybe<Sms_Notifications_Smsconfig_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_smsconfig" */
  delete_sms_notifications_smsconfig_by_pk?: Maybe<Sms_Notifications_Smsconfig>;
  /** delete data from the table: "sms_notifications_smspacketpurchase" */
  delete_sms_notifications_smspacketpurchase?: Maybe<Sms_Notifications_Smspacketpurchase_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_smspacketpurchase" */
  delete_sms_notifications_smspacketpurchase_by_pk?: Maybe<Sms_Notifications_Smspacketpurchase>;
  /** delete data from the table: "sms_notifications_smstemplate" */
  delete_sms_notifications_smstemplate?: Maybe<Sms_Notifications_Smstemplate_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_smstemplate" */
  delete_sms_notifications_smstemplate_by_pk?: Maybe<Sms_Notifications_Smstemplate>;
  /** delete data from the table: "sms_notifications_smstemplatevariable" */
  delete_sms_notifications_smstemplatevariable?: Maybe<Sms_Notifications_Smstemplatevariable_Mutation_Response>;
  /** delete single row from the table: "sms_notifications_smstemplatevariable" */
  delete_sms_notifications_smstemplatevariable_by_pk?: Maybe<Sms_Notifications_Smstemplatevariable>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_groups" */
  delete_user_groups?: Maybe<User_Groups_Mutation_Response>;
  /** delete single row from the table: "user_groups" */
  delete_user_groups_by_pk?: Maybe<User_Groups>;
  /** delete data from the table: "user_identity" */
  delete_user_identity?: Maybe<User_Identity_Mutation_Response>;
  /** delete single row from the table: "user_identity" */
  delete_user_identity_by_pk?: Maybe<User_Identity>;
  /** delete data from the table: "user_user_permissions" */
  delete_user_user_permissions?: Maybe<User_User_Permissions_Mutation_Response>;
  /** delete single row from the table: "user_user_permissions" */
  delete_user_user_permissions_by_pk?: Maybe<User_User_Permissions>;
  /** delete data from the table: "workdays" */
  delete_workdays?: Maybe<Workdays_Mutation_Response>;
  /** delete single row from the table: "workdays" */
  delete_workdays_by_pk?: Maybe<Workdays>;
  /** delete data from the table: "worktime_configs" */
  delete_worktime_configs?: Maybe<Worktime_Configs_Mutation_Response>;
  /** delete single row from the table: "worktime_configs" */
  delete_worktime_configs_by_pk?: Maybe<Worktime_Configs>;
  /** delete data from the table: "worktime_exceptions" */
  delete_worktime_exceptions?: Maybe<Worktime_Exceptions_Mutation_Response>;
  /** delete single row from the table: "worktime_exceptions" */
  delete_worktime_exceptions_by_pk?: Maybe<Worktime_Exceptions>;
  /** delete data from the table: "worktime_exceptions_work_times" */
  delete_worktime_exceptions_work_times?: Maybe<Worktime_Exceptions_Work_Times_Mutation_Response>;
  /** delete single row from the table: "worktime_exceptions_work_times" */
  delete_worktime_exceptions_work_times_by_pk?: Maybe<Worktime_Exceptions_Work_Times>;
  /** delete data from the table: "worktime_presets" */
  delete_worktime_presets?: Maybe<Worktime_Presets_Mutation_Response>;
  /** delete single row from the table: "worktime_presets" */
  delete_worktime_presets_by_pk?: Maybe<Worktime_Presets>;
  /** delete data from the table: "worktimes" */
  delete_worktimes?: Maybe<Worktimes_Mutation_Response>;
  /** delete single row from the table: "worktimes" */
  delete_worktimes_by_pk?: Maybe<Worktimes>;
  /** insert data into the table: "accounting_practiceexpense" */
  insert_accounting_practiceexpense?: Maybe<Accounting_Practiceexpense_Mutation_Response>;
  /** insert a single row into the table: "accounting_practiceexpense" */
  insert_accounting_practiceexpense_one?: Maybe<Accounting_Practiceexpense>;
  /** insert data into the table: "accounting_recurringexpense" */
  insert_accounting_recurringexpense?: Maybe<Accounting_Recurringexpense_Mutation_Response>;
  /** insert a single row into the table: "accounting_recurringexpense" */
  insert_accounting_recurringexpense_one?: Maybe<Accounting_Recurringexpense>;
  /** insert data into the table: "addresses" */
  insert_addresses?: Maybe<Addresses_Mutation_Response>;
  /** insert a single row into the table: "addresses" */
  insert_addresses_one?: Maybe<Addresses>;
  /** insert data into the table: "avatars" */
  insert_avatars?: Maybe<Avatars_Mutation_Response>;
  /** insert a single row into the table: "avatars" */
  insert_avatars_one?: Maybe<Avatars>;
  /** insert data into the table: "contact_details" */
  insert_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** insert a single row into the table: "contact_details" */
  insert_contact_details_one?: Maybe<Contact_Details>;
  /** insert data into the table: "couples" */
  insert_couples?: Maybe<Couples_Mutation_Response>;
  /** insert a single row into the table: "couples" */
  insert_couples_one?: Maybe<Couples>;
  /** insert data into the table: "couples_patients" */
  insert_couples_patients?: Maybe<Couples_Patients_Mutation_Response>;
  /** insert a single row into the table: "couples_patients" */
  insert_couples_patients_one?: Maybe<Couples_Patients>;
  /** insert data into the table: "couples_therapists" */
  insert_couples_therapists?: Maybe<Couples_Therapists_Mutation_Response>;
  /** insert a single row into the table: "couples_therapists" */
  insert_couples_therapists_one?: Maybe<Couples_Therapists>;
  /** insert data into the table: "ed_attachments" */
  insert_ed_attachments?: Maybe<Ed_Attachments_Mutation_Response>;
  /** insert a single row into the table: "ed_attachments" */
  insert_ed_attachments_one?: Maybe<Ed_Attachments>;
  /** insert data into the table: "ed_group_presence" */
  insert_ed_group_presence?: Maybe<Ed_Group_Presence_Mutation_Response>;
  /** insert a single row into the table: "ed_group_presence" */
  insert_ed_group_presence_one?: Maybe<Ed_Group_Presence>;
  /** insert data into the table: "ed_participants" */
  insert_ed_participants?: Maybe<Ed_Participants_Mutation_Response>;
  /** insert a single row into the table: "ed_participants" */
  insert_ed_participants_one?: Maybe<Ed_Participants>;
  /** insert data into the table: "ed_payment_method" */
  insert_ed_payment_method?: Maybe<Ed_Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "ed_payment_method" */
  insert_ed_payment_method_one?: Maybe<Ed_Payment_Method>;
  /** insert data into the table: "ed_presence" */
  insert_ed_presence?: Maybe<Ed_Presence_Mutation_Response>;
  /** insert a single row into the table: "ed_presence" */
  insert_ed_presence_one?: Maybe<Ed_Presence>;
  /** insert data into the table: "employees" */
  insert_employees?: Maybe<Employees_Mutation_Response>;
  /** insert data into the table: "employees_favorite_therapists" */
  insert_employees_favorite_therapists?: Maybe<Employees_Favorite_Therapists_Mutation_Response>;
  /** insert a single row into the table: "employees_favorite_therapists" */
  insert_employees_favorite_therapists_one?: Maybe<Employees_Favorite_Therapists>;
  /** insert a single row into the table: "employees" */
  insert_employees_one?: Maybe<Employees>;
  /** insert data into the table: "employees_preferred_room" */
  insert_employees_preferred_room?: Maybe<Employees_Preferred_Room_Mutation_Response>;
  /** insert a single row into the table: "employees_preferred_room" */
  insert_employees_preferred_room_one?: Maybe<Employees_Preferred_Room>;
  /** insert data into the table: "employees_roles" */
  insert_employees_roles?: Maybe<Employees_Roles_Mutation_Response>;
  /** insert a single row into the table: "employees_roles" */
  insert_employees_roles_one?: Maybe<Employees_Roles>;
  /** insert data into the table: "event" */
  insert_event?: Maybe<Event_Mutation_Response>;
  /** insert a single row into the table: "event" */
  insert_event_one?: Maybe<Event>;
  /** insert data into the table: "event_participants" */
  insert_event_participants?: Maybe<Event_Participants_Mutation_Response>;
  /** insert a single row into the table: "event_participants" */
  insert_event_participants_one?: Maybe<Event_Participants>;
  /** insert data into the table: "event_period" */
  insert_event_period?: Maybe<Event_Period_Mutation_Response>;
  /** insert a single row into the table: "event_period" */
  insert_event_period_one?: Maybe<Event_Period>;
  /** insert data into the table: "event_period_participants" */
  insert_event_period_participants?: Maybe<Event_Period_Participants_Mutation_Response>;
  /** insert a single row into the table: "event_period_participants" */
  insert_event_period_participants_one?: Maybe<Event_Period_Participants>;
  /** insert data into the table: "event_period_services" */
  insert_event_period_services?: Maybe<Event_Period_Services_Mutation_Response>;
  /** insert a single row into the table: "event_period_services" */
  insert_event_period_services_one?: Maybe<Event_Period_Services>;
  /** insert data into the table: "event_period_therapists" */
  insert_event_period_therapists?: Maybe<Event_Period_Therapists_Mutation_Response>;
  /** insert a single row into the table: "event_period_therapists" */
  insert_event_period_therapists_one?: Maybe<Event_Period_Therapists>;
  /** insert data into the table: "event_services" */
  insert_event_services?: Maybe<Event_Services_Mutation_Response>;
  /** insert a single row into the table: "event_services" */
  insert_event_services_one?: Maybe<Event_Services>;
  /** insert data into the table: "event_therapists" */
  insert_event_therapists?: Maybe<Event_Therapists_Mutation_Response>;
  /** insert a single row into the table: "event_therapists" */
  insert_event_therapists_one?: Maybe<Event_Therapists>;
  /** insert data into the table: "gdpr_gdprconfig" */
  insert_gdpr_gdprconfig?: Maybe<Gdpr_Gdprconfig_Mutation_Response>;
  /** insert a single row into the table: "gdpr_gdprconfig" */
  insert_gdpr_gdprconfig_one?: Maybe<Gdpr_Gdprconfig>;
  /** insert data into the table: "gdpr_gdprdocument" */
  insert_gdpr_gdprdocument?: Maybe<Gdpr_Gdprdocument_Mutation_Response>;
  /** insert a single row into the table: "gdpr_gdprdocument" */
  insert_gdpr_gdprdocument_one?: Maybe<Gdpr_Gdprdocument>;
  /** insert data into the table: "gdpr_gdprdocumenttype" */
  insert_gdpr_gdprdocumenttype?: Maybe<Gdpr_Gdprdocumenttype_Mutation_Response>;
  /** insert a single row into the table: "gdpr_gdprdocumenttype" */
  insert_gdpr_gdprdocumenttype_one?: Maybe<Gdpr_Gdprdocumenttype>;
  /** insert data into the table: "group_members" */
  insert_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** insert a single row into the table: "group_members" */
  insert_group_members_one?: Maybe<Group_Members>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "holiday_reasons" */
  insert_holiday_reasons?: Maybe<Holiday_Reasons_Mutation_Response>;
  /** insert a single row into the table: "holiday_reasons" */
  insert_holiday_reasons_one?: Maybe<Holiday_Reasons>;
  /** insert data into the table: "holidays" */
  insert_holidays?: Maybe<Holidays_Mutation_Response>;
  /** insert a single row into the table: "holidays" */
  insert_holidays_one?: Maybe<Holidays>;
  /** insert data into the table: "invoices_v2_billinginfo" */
  insert_invoices_v2_billinginfo?: Maybe<Invoices_V2_Billinginfo_Mutation_Response>;
  /** insert a single row into the table: "invoices_v2_billinginfo" */
  insert_invoices_v2_billinginfo_one?: Maybe<Invoices_V2_Billinginfo>;
  /** insert data into the table: "invoices_v2_invoice" */
  insert_invoices_v2_invoice?: Maybe<Invoices_V2_Invoice_Mutation_Response>;
  /** insert a single row into the table: "invoices_v2_invoice" */
  insert_invoices_v2_invoice_one?: Maybe<Invoices_V2_Invoice>;
  /** insert data into the table: "invoices_v2_invoiceconfig" */
  insert_invoices_v2_invoiceconfig?: Maybe<Invoices_V2_Invoiceconfig_Mutation_Response>;
  /** insert a single row into the table: "invoices_v2_invoiceconfig" */
  insert_invoices_v2_invoiceconfig_one?: Maybe<Invoices_V2_Invoiceconfig>;
  /** insert data into the table: "invoices_v2_invoicelineitem" */
  insert_invoices_v2_invoicelineitem?: Maybe<Invoices_V2_Invoicelineitem_Mutation_Response>;
  /** insert data into the table: "invoices_v2_invoicelineitem_events" */
  insert_invoices_v2_invoicelineitem_events?: Maybe<Invoices_V2_Invoicelineitem_Events_Mutation_Response>;
  /** insert a single row into the table: "invoices_v2_invoicelineitem_events" */
  insert_invoices_v2_invoicelineitem_events_one?: Maybe<Invoices_V2_Invoicelineitem_Events>;
  /** insert a single row into the table: "invoices_v2_invoicelineitem" */
  insert_invoices_v2_invoicelineitem_one?: Maybe<Invoices_V2_Invoicelineitem>;
  /** insert data into the table: "invoices_v2_invoicesentto" */
  insert_invoices_v2_invoicesentto?: Maybe<Invoices_V2_Invoicesentto_Mutation_Response>;
  /** insert a single row into the table: "invoices_v2_invoicesentto" */
  insert_invoices_v2_invoicesentto_one?: Maybe<Invoices_V2_Invoicesentto>;
  /** insert data into the table: "national_holidays" */
  insert_national_holidays?: Maybe<National_Holidays_Mutation_Response>;
  /** insert a single row into the table: "national_holidays" */
  insert_national_holidays_one?: Maybe<National_Holidays>;
  /** insert data into the table: "patient_addresses" */
  insert_patient_addresses?: Maybe<Patient_Addresses_Mutation_Response>;
  /** insert a single row into the table: "patient_addresses" */
  insert_patient_addresses_one?: Maybe<Patient_Addresses>;
  /** insert data into the table: "patient_educator" */
  insert_patient_educator?: Maybe<Patient_Educator_Mutation_Response>;
  /** insert a single row into the table: "patient_educator" */
  insert_patient_educator_one?: Maybe<Patient_Educator>;
  /** insert data into the table: "patient_guardian_addresses" */
  insert_patient_guardian_addresses?: Maybe<Patient_Guardian_Addresses_Mutation_Response>;
  /** insert a single row into the table: "patient_guardian_addresses" */
  insert_patient_guardian_addresses_one?: Maybe<Patient_Guardian_Addresses>;
  /** insert data into the table: "patient_guardians" */
  insert_patient_guardians?: Maybe<Patient_Guardians_Mutation_Response>;
  /** insert a single row into the table: "patient_guardians" */
  insert_patient_guardians_one?: Maybe<Patient_Guardians>;
  /** insert data into the table: "patient_psychotherapies" */
  insert_patient_psychotherapies?: Maybe<Patient_Psychotherapies_Mutation_Response>;
  /** insert a single row into the table: "patient_psychotherapies" */
  insert_patient_psychotherapies_one?: Maybe<Patient_Psychotherapies>;
  /** insert data into the table: "patient_si" */
  insert_patient_si?: Maybe<Patient_Si_Mutation_Response>;
  /** insert a single row into the table: "patient_si" */
  insert_patient_si_one?: Maybe<Patient_Si>;
  /** insert data into the table: "patient_speech_therapies" */
  insert_patient_speech_therapies?: Maybe<Patient_Speech_Therapies_Mutation_Response>;
  /** insert a single row into the table: "patient_speech_therapies" */
  insert_patient_speech_therapies_one?: Maybe<Patient_Speech_Therapies>;
  /** insert data into the table: "patients" */
  insert_patients?: Maybe<Patients_Mutation_Response>;
  /** insert a single row into the table: "patients" */
  insert_patients_one?: Maybe<Patients>;
  /** insert data into the table: "patients_therapists" */
  insert_patients_therapists?: Maybe<Patients_Therapists_Mutation_Response>;
  /** insert a single row into the table: "patients_therapists" */
  insert_patients_therapists_one?: Maybe<Patients_Therapists>;
  /** insert data into the table: "practice_branches" */
  insert_practice_branches?: Maybe<Practice_Branches_Mutation_Response>;
  /** insert a single row into the table: "practice_branches" */
  insert_practice_branches_one?: Maybe<Practice_Branches>;
  /** insert data into the table: "practice_logos" */
  insert_practice_logos?: Maybe<Practice_Logos_Mutation_Response>;
  /** insert a single row into the table: "practice_logos" */
  insert_practice_logos_one?: Maybe<Practice_Logos>;
  /** insert data into the table: "practices" */
  insert_practices?: Maybe<Practices_Mutation_Response>;
  /** insert a single row into the table: "practices" */
  insert_practices_one?: Maybe<Practices>;
  /** insert data into the table: "registration_authsmsconfirmation" */
  insert_registration_authsmsconfirmation?: Maybe<Registration_Authsmsconfirmation_Mutation_Response>;
  /** insert a single row into the table: "registration_authsmsconfirmation" */
  insert_registration_authsmsconfirmation_one?: Maybe<Registration_Authsmsconfirmation>;
  /** insert data into the table: "registration_meetingrequest" */
  insert_registration_meetingrequest?: Maybe<Registration_Meetingrequest_Mutation_Response>;
  /** insert a single row into the table: "registration_meetingrequest" */
  insert_registration_meetingrequest_one?: Maybe<Registration_Meetingrequest>;
  /** insert data into the table: "registration_onlineregistrationconfig" */
  insert_registration_onlineregistrationconfig?: Maybe<Registration_Onlineregistrationconfig_Mutation_Response>;
  /** insert a single row into the table: "registration_onlineregistrationconfig" */
  insert_registration_onlineregistrationconfig_one?: Maybe<Registration_Onlineregistrationconfig>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "request_considerations" */
  insert_request_considerations?: Maybe<Request_Considerations_Mutation_Response>;
  /** insert a single row into the table: "request_considerations" */
  insert_request_considerations_one?: Maybe<Request_Considerations>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "rooms" */
  insert_rooms?: Maybe<Rooms_Mutation_Response>;
  /** insert a single row into the table: "rooms" */
  insert_rooms_one?: Maybe<Rooms>;
  /** insert data into the table: "services" */
  insert_services?: Maybe<Services_Mutation_Response>;
  /** insert data into the table: "services_assigned_to" */
  insert_services_assigned_to?: Maybe<Services_Assigned_To_Mutation_Response>;
  /** insert a single row into the table: "services_assigned_to" */
  insert_services_assigned_to_one?: Maybe<Services_Assigned_To>;
  /** insert a single row into the table: "services" */
  insert_services_one?: Maybe<Services>;
  /** insert data into the table: "services_restricted_rooms" */
  insert_services_restricted_rooms?: Maybe<Services_Restricted_Rooms_Mutation_Response>;
  /** insert a single row into the table: "services_restricted_rooms" */
  insert_services_restricted_rooms_one?: Maybe<Services_Restricted_Rooms>;
  /** insert data into the table: "sms_notifications_defaultsmstemplate" */
  insert_sms_notifications_defaultsmstemplate?: Maybe<Sms_Notifications_Defaultsmstemplate_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_defaultsmstemplate" */
  insert_sms_notifications_defaultsmstemplate_one?: Maybe<Sms_Notifications_Defaultsmstemplate>;
  /** insert data into the table: "sms_notifications_sentsms" */
  insert_sms_notifications_sentsms?: Maybe<Sms_Notifications_Sentsms_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_sentsms" */
  insert_sms_notifications_sentsms_one?: Maybe<Sms_Notifications_Sentsms>;
  /** insert data into the table: "sms_notifications_smsconfig" */
  insert_sms_notifications_smsconfig?: Maybe<Sms_Notifications_Smsconfig_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_smsconfig" */
  insert_sms_notifications_smsconfig_one?: Maybe<Sms_Notifications_Smsconfig>;
  /** insert data into the table: "sms_notifications_smspacketpurchase" */
  insert_sms_notifications_smspacketpurchase?: Maybe<Sms_Notifications_Smspacketpurchase_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_smspacketpurchase" */
  insert_sms_notifications_smspacketpurchase_one?: Maybe<Sms_Notifications_Smspacketpurchase>;
  /** insert data into the table: "sms_notifications_smstemplate" */
  insert_sms_notifications_smstemplate?: Maybe<Sms_Notifications_Smstemplate_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_smstemplate" */
  insert_sms_notifications_smstemplate_one?: Maybe<Sms_Notifications_Smstemplate>;
  /** insert data into the table: "sms_notifications_smstemplatevariable" */
  insert_sms_notifications_smstemplatevariable?: Maybe<Sms_Notifications_Smstemplatevariable_Mutation_Response>;
  /** insert a single row into the table: "sms_notifications_smstemplatevariable" */
  insert_sms_notifications_smstemplatevariable_one?: Maybe<Sms_Notifications_Smstemplatevariable>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_groups" */
  insert_user_groups?: Maybe<User_Groups_Mutation_Response>;
  /** insert a single row into the table: "user_groups" */
  insert_user_groups_one?: Maybe<User_Groups>;
  /** insert data into the table: "user_identity" */
  insert_user_identity?: Maybe<User_Identity_Mutation_Response>;
  /** insert a single row into the table: "user_identity" */
  insert_user_identity_one?: Maybe<User_Identity>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_user_permissions" */
  insert_user_user_permissions?: Maybe<User_User_Permissions_Mutation_Response>;
  /** insert a single row into the table: "user_user_permissions" */
  insert_user_user_permissions_one?: Maybe<User_User_Permissions>;
  /** insert data into the table: "workdays" */
  insert_workdays?: Maybe<Workdays_Mutation_Response>;
  /** insert a single row into the table: "workdays" */
  insert_workdays_one?: Maybe<Workdays>;
  /** insert data into the table: "worktime_configs" */
  insert_worktime_configs?: Maybe<Worktime_Configs_Mutation_Response>;
  /** insert a single row into the table: "worktime_configs" */
  insert_worktime_configs_one?: Maybe<Worktime_Configs>;
  /** insert data into the table: "worktime_exceptions" */
  insert_worktime_exceptions?: Maybe<Worktime_Exceptions_Mutation_Response>;
  /** insert a single row into the table: "worktime_exceptions" */
  insert_worktime_exceptions_one?: Maybe<Worktime_Exceptions>;
  /** insert data into the table: "worktime_exceptions_work_times" */
  insert_worktime_exceptions_work_times?: Maybe<Worktime_Exceptions_Work_Times_Mutation_Response>;
  /** insert a single row into the table: "worktime_exceptions_work_times" */
  insert_worktime_exceptions_work_times_one?: Maybe<Worktime_Exceptions_Work_Times>;
  /** insert data into the table: "worktime_presets" */
  insert_worktime_presets?: Maybe<Worktime_Presets_Mutation_Response>;
  /** insert a single row into the table: "worktime_presets" */
  insert_worktime_presets_one?: Maybe<Worktime_Presets>;
  /** insert data into the table: "worktimes" */
  insert_worktimes?: Maybe<Worktimes_Mutation_Response>;
  /** insert a single row into the table: "worktimes" */
  insert_worktimes_one?: Maybe<Worktimes>;
  /** update data of the table: "accounting_practiceexpense" */
  update_accounting_practiceexpense?: Maybe<Accounting_Practiceexpense_Mutation_Response>;
  /** update single row of the table: "accounting_practiceexpense" */
  update_accounting_practiceexpense_by_pk?: Maybe<Accounting_Practiceexpense>;
  /** update multiples rows of table: "accounting_practiceexpense" */
  update_accounting_practiceexpense_many?: Maybe<Array<Maybe<Accounting_Practiceexpense_Mutation_Response>>>;
  /** update data of the table: "accounting_recurringexpense" */
  update_accounting_recurringexpense?: Maybe<Accounting_Recurringexpense_Mutation_Response>;
  /** update single row of the table: "accounting_recurringexpense" */
  update_accounting_recurringexpense_by_pk?: Maybe<Accounting_Recurringexpense>;
  /** update multiples rows of table: "accounting_recurringexpense" */
  update_accounting_recurringexpense_many?: Maybe<Array<Maybe<Accounting_Recurringexpense_Mutation_Response>>>;
  /** update data of the table: "addresses" */
  update_addresses?: Maybe<Addresses_Mutation_Response>;
  /** update single row of the table: "addresses" */
  update_addresses_by_pk?: Maybe<Addresses>;
  /** update multiples rows of table: "addresses" */
  update_addresses_many?: Maybe<Array<Maybe<Addresses_Mutation_Response>>>;
  /** update data of the table: "avatars" */
  update_avatars?: Maybe<Avatars_Mutation_Response>;
  /** update single row of the table: "avatars" */
  update_avatars_by_pk?: Maybe<Avatars>;
  /** update multiples rows of table: "avatars" */
  update_avatars_many?: Maybe<Array<Maybe<Avatars_Mutation_Response>>>;
  /** update data of the table: "contact_details" */
  update_contact_details?: Maybe<Contact_Details_Mutation_Response>;
  /** update single row of the table: "contact_details" */
  update_contact_details_by_pk?: Maybe<Contact_Details>;
  /** update multiples rows of table: "contact_details" */
  update_contact_details_many?: Maybe<Array<Maybe<Contact_Details_Mutation_Response>>>;
  /** update data of the table: "couples" */
  update_couples?: Maybe<Couples_Mutation_Response>;
  /** update single row of the table: "couples" */
  update_couples_by_pk?: Maybe<Couples>;
  /** update multiples rows of table: "couples" */
  update_couples_many?: Maybe<Array<Maybe<Couples_Mutation_Response>>>;
  /** update data of the table: "couples_patients" */
  update_couples_patients?: Maybe<Couples_Patients_Mutation_Response>;
  /** update single row of the table: "couples_patients" */
  update_couples_patients_by_pk?: Maybe<Couples_Patients>;
  /** update multiples rows of table: "couples_patients" */
  update_couples_patients_many?: Maybe<Array<Maybe<Couples_Patients_Mutation_Response>>>;
  /** update data of the table: "couples_therapists" */
  update_couples_therapists?: Maybe<Couples_Therapists_Mutation_Response>;
  /** update single row of the table: "couples_therapists" */
  update_couples_therapists_by_pk?: Maybe<Couples_Therapists>;
  /** update multiples rows of table: "couples_therapists" */
  update_couples_therapists_many?: Maybe<Array<Maybe<Couples_Therapists_Mutation_Response>>>;
  /** update data of the table: "ed_attachments" */
  update_ed_attachments?: Maybe<Ed_Attachments_Mutation_Response>;
  /** update single row of the table: "ed_attachments" */
  update_ed_attachments_by_pk?: Maybe<Ed_Attachments>;
  /** update multiples rows of table: "ed_attachments" */
  update_ed_attachments_many?: Maybe<Array<Maybe<Ed_Attachments_Mutation_Response>>>;
  /** update data of the table: "ed_group_presence" */
  update_ed_group_presence?: Maybe<Ed_Group_Presence_Mutation_Response>;
  /** update single row of the table: "ed_group_presence" */
  update_ed_group_presence_by_pk?: Maybe<Ed_Group_Presence>;
  /** update multiples rows of table: "ed_group_presence" */
  update_ed_group_presence_many?: Maybe<Array<Maybe<Ed_Group_Presence_Mutation_Response>>>;
  /** update data of the table: "ed_participants" */
  update_ed_participants?: Maybe<Ed_Participants_Mutation_Response>;
  /** update single row of the table: "ed_participants" */
  update_ed_participants_by_pk?: Maybe<Ed_Participants>;
  /** update multiples rows of table: "ed_participants" */
  update_ed_participants_many?: Maybe<Array<Maybe<Ed_Participants_Mutation_Response>>>;
  /** update data of the table: "ed_payment_method" */
  update_ed_payment_method?: Maybe<Ed_Payment_Method_Mutation_Response>;
  /** update single row of the table: "ed_payment_method" */
  update_ed_payment_method_by_pk?: Maybe<Ed_Payment_Method>;
  /** update multiples rows of table: "ed_payment_method" */
  update_ed_payment_method_many?: Maybe<Array<Maybe<Ed_Payment_Method_Mutation_Response>>>;
  /** update data of the table: "ed_presence" */
  update_ed_presence?: Maybe<Ed_Presence_Mutation_Response>;
  /** update single row of the table: "ed_presence" */
  update_ed_presence_by_pk?: Maybe<Ed_Presence>;
  /** update multiples rows of table: "ed_presence" */
  update_ed_presence_many?: Maybe<Array<Maybe<Ed_Presence_Mutation_Response>>>;
  /** update data of the table: "employees" */
  update_employees?: Maybe<Employees_Mutation_Response>;
  /** update single row of the table: "employees" */
  update_employees_by_pk?: Maybe<Employees>;
  /** update data of the table: "employees_favorite_therapists" */
  update_employees_favorite_therapists?: Maybe<Employees_Favorite_Therapists_Mutation_Response>;
  /** update single row of the table: "employees_favorite_therapists" */
  update_employees_favorite_therapists_by_pk?: Maybe<Employees_Favorite_Therapists>;
  /** update multiples rows of table: "employees_favorite_therapists" */
  update_employees_favorite_therapists_many?: Maybe<Array<Maybe<Employees_Favorite_Therapists_Mutation_Response>>>;
  /** update multiples rows of table: "employees" */
  update_employees_many?: Maybe<Array<Maybe<Employees_Mutation_Response>>>;
  /** update data of the table: "employees_preferred_room" */
  update_employees_preferred_room?: Maybe<Employees_Preferred_Room_Mutation_Response>;
  /** update single row of the table: "employees_preferred_room" */
  update_employees_preferred_room_by_pk?: Maybe<Employees_Preferred_Room>;
  /** update multiples rows of table: "employees_preferred_room" */
  update_employees_preferred_room_many?: Maybe<Array<Maybe<Employees_Preferred_Room_Mutation_Response>>>;
  /** update data of the table: "employees_roles" */
  update_employees_roles?: Maybe<Employees_Roles_Mutation_Response>;
  /** update single row of the table: "employees_roles" */
  update_employees_roles_by_pk?: Maybe<Employees_Roles>;
  /** update multiples rows of table: "employees_roles" */
  update_employees_roles_many?: Maybe<Array<Maybe<Employees_Roles_Mutation_Response>>>;
  /** update data of the table: "event" */
  update_event?: Maybe<Event_Mutation_Response>;
  /** update single row of the table: "event" */
  update_event_by_pk?: Maybe<Event>;
  /** update multiples rows of table: "event" */
  update_event_many?: Maybe<Array<Maybe<Event_Mutation_Response>>>;
  /** update data of the table: "event_participants" */
  update_event_participants?: Maybe<Event_Participants_Mutation_Response>;
  /** update single row of the table: "event_participants" */
  update_event_participants_by_pk?: Maybe<Event_Participants>;
  /** update multiples rows of table: "event_participants" */
  update_event_participants_many?: Maybe<Array<Maybe<Event_Participants_Mutation_Response>>>;
  /** update data of the table: "event_period" */
  update_event_period?: Maybe<Event_Period_Mutation_Response>;
  /** update single row of the table: "event_period" */
  update_event_period_by_pk?: Maybe<Event_Period>;
  /** update multiples rows of table: "event_period" */
  update_event_period_many?: Maybe<Array<Maybe<Event_Period_Mutation_Response>>>;
  /** update data of the table: "event_period_participants" */
  update_event_period_participants?: Maybe<Event_Period_Participants_Mutation_Response>;
  /** update single row of the table: "event_period_participants" */
  update_event_period_participants_by_pk?: Maybe<Event_Period_Participants>;
  /** update multiples rows of table: "event_period_participants" */
  update_event_period_participants_many?: Maybe<Array<Maybe<Event_Period_Participants_Mutation_Response>>>;
  /** update data of the table: "event_period_services" */
  update_event_period_services?: Maybe<Event_Period_Services_Mutation_Response>;
  /** update single row of the table: "event_period_services" */
  update_event_period_services_by_pk?: Maybe<Event_Period_Services>;
  /** update multiples rows of table: "event_period_services" */
  update_event_period_services_many?: Maybe<Array<Maybe<Event_Period_Services_Mutation_Response>>>;
  /** update data of the table: "event_period_therapists" */
  update_event_period_therapists?: Maybe<Event_Period_Therapists_Mutation_Response>;
  /** update single row of the table: "event_period_therapists" */
  update_event_period_therapists_by_pk?: Maybe<Event_Period_Therapists>;
  /** update multiples rows of table: "event_period_therapists" */
  update_event_period_therapists_many?: Maybe<Array<Maybe<Event_Period_Therapists_Mutation_Response>>>;
  /** update data of the table: "event_services" */
  update_event_services?: Maybe<Event_Services_Mutation_Response>;
  /** update single row of the table: "event_services" */
  update_event_services_by_pk?: Maybe<Event_Services>;
  /** update multiples rows of table: "event_services" */
  update_event_services_many?: Maybe<Array<Maybe<Event_Services_Mutation_Response>>>;
  /** update data of the table: "event_therapists" */
  update_event_therapists?: Maybe<Event_Therapists_Mutation_Response>;
  /** update single row of the table: "event_therapists" */
  update_event_therapists_by_pk?: Maybe<Event_Therapists>;
  /** update multiples rows of table: "event_therapists" */
  update_event_therapists_many?: Maybe<Array<Maybe<Event_Therapists_Mutation_Response>>>;
  /** update data of the table: "gdpr_gdprconfig" */
  update_gdpr_gdprconfig?: Maybe<Gdpr_Gdprconfig_Mutation_Response>;
  /** update single row of the table: "gdpr_gdprconfig" */
  update_gdpr_gdprconfig_by_pk?: Maybe<Gdpr_Gdprconfig>;
  /** update multiples rows of table: "gdpr_gdprconfig" */
  update_gdpr_gdprconfig_many?: Maybe<Array<Maybe<Gdpr_Gdprconfig_Mutation_Response>>>;
  /** update data of the table: "gdpr_gdprdocument" */
  update_gdpr_gdprdocument?: Maybe<Gdpr_Gdprdocument_Mutation_Response>;
  /** update single row of the table: "gdpr_gdprdocument" */
  update_gdpr_gdprdocument_by_pk?: Maybe<Gdpr_Gdprdocument>;
  /** update multiples rows of table: "gdpr_gdprdocument" */
  update_gdpr_gdprdocument_many?: Maybe<Array<Maybe<Gdpr_Gdprdocument_Mutation_Response>>>;
  /** update data of the table: "gdpr_gdprdocumenttype" */
  update_gdpr_gdprdocumenttype?: Maybe<Gdpr_Gdprdocumenttype_Mutation_Response>;
  /** update single row of the table: "gdpr_gdprdocumenttype" */
  update_gdpr_gdprdocumenttype_by_pk?: Maybe<Gdpr_Gdprdocumenttype>;
  /** update multiples rows of table: "gdpr_gdprdocumenttype" */
  update_gdpr_gdprdocumenttype_many?: Maybe<Array<Maybe<Gdpr_Gdprdocumenttype_Mutation_Response>>>;
  /** update data of the table: "group_members" */
  update_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** update single row of the table: "group_members" */
  update_group_members_by_pk?: Maybe<Group_Members>;
  /** update multiples rows of table: "group_members" */
  update_group_members_many?: Maybe<Array<Maybe<Group_Members_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "holiday_reasons" */
  update_holiday_reasons?: Maybe<Holiday_Reasons_Mutation_Response>;
  /** update single row of the table: "holiday_reasons" */
  update_holiday_reasons_by_pk?: Maybe<Holiday_Reasons>;
  /** update multiples rows of table: "holiday_reasons" */
  update_holiday_reasons_many?: Maybe<Array<Maybe<Holiday_Reasons_Mutation_Response>>>;
  /** update data of the table: "holidays" */
  update_holidays?: Maybe<Holidays_Mutation_Response>;
  /** update single row of the table: "holidays" */
  update_holidays_by_pk?: Maybe<Holidays>;
  /** update multiples rows of table: "holidays" */
  update_holidays_many?: Maybe<Array<Maybe<Holidays_Mutation_Response>>>;
  /** update data of the table: "invoices_v2_billinginfo" */
  update_invoices_v2_billinginfo?: Maybe<Invoices_V2_Billinginfo_Mutation_Response>;
  /** update single row of the table: "invoices_v2_billinginfo" */
  update_invoices_v2_billinginfo_by_pk?: Maybe<Invoices_V2_Billinginfo>;
  /** update multiples rows of table: "invoices_v2_billinginfo" */
  update_invoices_v2_billinginfo_many?: Maybe<Array<Maybe<Invoices_V2_Billinginfo_Mutation_Response>>>;
  /** update data of the table: "invoices_v2_invoice" */
  update_invoices_v2_invoice?: Maybe<Invoices_V2_Invoice_Mutation_Response>;
  /** update single row of the table: "invoices_v2_invoice" */
  update_invoices_v2_invoice_by_pk?: Maybe<Invoices_V2_Invoice>;
  /** update multiples rows of table: "invoices_v2_invoice" */
  update_invoices_v2_invoice_many?: Maybe<Array<Maybe<Invoices_V2_Invoice_Mutation_Response>>>;
  /** update data of the table: "invoices_v2_invoiceconfig" */
  update_invoices_v2_invoiceconfig?: Maybe<Invoices_V2_Invoiceconfig_Mutation_Response>;
  /** update single row of the table: "invoices_v2_invoiceconfig" */
  update_invoices_v2_invoiceconfig_by_pk?: Maybe<Invoices_V2_Invoiceconfig>;
  /** update multiples rows of table: "invoices_v2_invoiceconfig" */
  update_invoices_v2_invoiceconfig_many?: Maybe<Array<Maybe<Invoices_V2_Invoiceconfig_Mutation_Response>>>;
  /** update data of the table: "invoices_v2_invoicelineitem" */
  update_invoices_v2_invoicelineitem?: Maybe<Invoices_V2_Invoicelineitem_Mutation_Response>;
  /** update single row of the table: "invoices_v2_invoicelineitem" */
  update_invoices_v2_invoicelineitem_by_pk?: Maybe<Invoices_V2_Invoicelineitem>;
  /** update data of the table: "invoices_v2_invoicelineitem_events" */
  update_invoices_v2_invoicelineitem_events?: Maybe<Invoices_V2_Invoicelineitem_Events_Mutation_Response>;
  /** update single row of the table: "invoices_v2_invoicelineitem_events" */
  update_invoices_v2_invoicelineitem_events_by_pk?: Maybe<Invoices_V2_Invoicelineitem_Events>;
  /** update multiples rows of table: "invoices_v2_invoicelineitem_events" */
  update_invoices_v2_invoicelineitem_events_many?: Maybe<Array<Maybe<Invoices_V2_Invoicelineitem_Events_Mutation_Response>>>;
  /** update multiples rows of table: "invoices_v2_invoicelineitem" */
  update_invoices_v2_invoicelineitem_many?: Maybe<Array<Maybe<Invoices_V2_Invoicelineitem_Mutation_Response>>>;
  /** update data of the table: "invoices_v2_invoicesentto" */
  update_invoices_v2_invoicesentto?: Maybe<Invoices_V2_Invoicesentto_Mutation_Response>;
  /** update single row of the table: "invoices_v2_invoicesentto" */
  update_invoices_v2_invoicesentto_by_pk?: Maybe<Invoices_V2_Invoicesentto>;
  /** update multiples rows of table: "invoices_v2_invoicesentto" */
  update_invoices_v2_invoicesentto_many?: Maybe<Array<Maybe<Invoices_V2_Invoicesentto_Mutation_Response>>>;
  /** update data of the table: "national_holidays" */
  update_national_holidays?: Maybe<National_Holidays_Mutation_Response>;
  /** update single row of the table: "national_holidays" */
  update_national_holidays_by_pk?: Maybe<National_Holidays>;
  /** update multiples rows of table: "national_holidays" */
  update_national_holidays_many?: Maybe<Array<Maybe<National_Holidays_Mutation_Response>>>;
  /** update data of the table: "patient_addresses" */
  update_patient_addresses?: Maybe<Patient_Addresses_Mutation_Response>;
  /** update single row of the table: "patient_addresses" */
  update_patient_addresses_by_pk?: Maybe<Patient_Addresses>;
  /** update multiples rows of table: "patient_addresses" */
  update_patient_addresses_many?: Maybe<Array<Maybe<Patient_Addresses_Mutation_Response>>>;
  /** update data of the table: "patient_educator" */
  update_patient_educator?: Maybe<Patient_Educator_Mutation_Response>;
  /** update single row of the table: "patient_educator" */
  update_patient_educator_by_pk?: Maybe<Patient_Educator>;
  /** update multiples rows of table: "patient_educator" */
  update_patient_educator_many?: Maybe<Array<Maybe<Patient_Educator_Mutation_Response>>>;
  /** update data of the table: "patient_guardian_addresses" */
  update_patient_guardian_addresses?: Maybe<Patient_Guardian_Addresses_Mutation_Response>;
  /** update single row of the table: "patient_guardian_addresses" */
  update_patient_guardian_addresses_by_pk?: Maybe<Patient_Guardian_Addresses>;
  /** update multiples rows of table: "patient_guardian_addresses" */
  update_patient_guardian_addresses_many?: Maybe<Array<Maybe<Patient_Guardian_Addresses_Mutation_Response>>>;
  /** update data of the table: "patient_guardians" */
  update_patient_guardians?: Maybe<Patient_Guardians_Mutation_Response>;
  /** update single row of the table: "patient_guardians" */
  update_patient_guardians_by_pk?: Maybe<Patient_Guardians>;
  /** update multiples rows of table: "patient_guardians" */
  update_patient_guardians_many?: Maybe<Array<Maybe<Patient_Guardians_Mutation_Response>>>;
  /** update data of the table: "patient_psychotherapies" */
  update_patient_psychotherapies?: Maybe<Patient_Psychotherapies_Mutation_Response>;
  /** update single row of the table: "patient_psychotherapies" */
  update_patient_psychotherapies_by_pk?: Maybe<Patient_Psychotherapies>;
  /** update multiples rows of table: "patient_psychotherapies" */
  update_patient_psychotherapies_many?: Maybe<Array<Maybe<Patient_Psychotherapies_Mutation_Response>>>;
  /** update data of the table: "patient_si" */
  update_patient_si?: Maybe<Patient_Si_Mutation_Response>;
  /** update single row of the table: "patient_si" */
  update_patient_si_by_pk?: Maybe<Patient_Si>;
  /** update multiples rows of table: "patient_si" */
  update_patient_si_many?: Maybe<Array<Maybe<Patient_Si_Mutation_Response>>>;
  /** update data of the table: "patient_speech_therapies" */
  update_patient_speech_therapies?: Maybe<Patient_Speech_Therapies_Mutation_Response>;
  /** update single row of the table: "patient_speech_therapies" */
  update_patient_speech_therapies_by_pk?: Maybe<Patient_Speech_Therapies>;
  /** update multiples rows of table: "patient_speech_therapies" */
  update_patient_speech_therapies_many?: Maybe<Array<Maybe<Patient_Speech_Therapies_Mutation_Response>>>;
  /** update data of the table: "patients" */
  update_patients?: Maybe<Patients_Mutation_Response>;
  /** update single row of the table: "patients" */
  update_patients_by_pk?: Maybe<Patients>;
  /** update multiples rows of table: "patients" */
  update_patients_many?: Maybe<Array<Maybe<Patients_Mutation_Response>>>;
  /** update data of the table: "patients_therapists" */
  update_patients_therapists?: Maybe<Patients_Therapists_Mutation_Response>;
  /** update single row of the table: "patients_therapists" */
  update_patients_therapists_by_pk?: Maybe<Patients_Therapists>;
  /** update multiples rows of table: "patients_therapists" */
  update_patients_therapists_many?: Maybe<Array<Maybe<Patients_Therapists_Mutation_Response>>>;
  /** update data of the table: "practice_branches" */
  update_practice_branches?: Maybe<Practice_Branches_Mutation_Response>;
  /** update single row of the table: "practice_branches" */
  update_practice_branches_by_pk?: Maybe<Practice_Branches>;
  /** update multiples rows of table: "practice_branches" */
  update_practice_branches_many?: Maybe<Array<Maybe<Practice_Branches_Mutation_Response>>>;
  /** update data of the table: "practice_logos" */
  update_practice_logos?: Maybe<Practice_Logos_Mutation_Response>;
  /** update single row of the table: "practice_logos" */
  update_practice_logos_by_pk?: Maybe<Practice_Logos>;
  /** update multiples rows of table: "practice_logos" */
  update_practice_logos_many?: Maybe<Array<Maybe<Practice_Logos_Mutation_Response>>>;
  /** update data of the table: "practices" */
  update_practices?: Maybe<Practices_Mutation_Response>;
  /** update single row of the table: "practices" */
  update_practices_by_pk?: Maybe<Practices>;
  /** update multiples rows of table: "practices" */
  update_practices_many?: Maybe<Array<Maybe<Practices_Mutation_Response>>>;
  /** update data of the table: "registration_authsmsconfirmation" */
  update_registration_authsmsconfirmation?: Maybe<Registration_Authsmsconfirmation_Mutation_Response>;
  /** update single row of the table: "registration_authsmsconfirmation" */
  update_registration_authsmsconfirmation_by_pk?: Maybe<Registration_Authsmsconfirmation>;
  /** update multiples rows of table: "registration_authsmsconfirmation" */
  update_registration_authsmsconfirmation_many?: Maybe<Array<Maybe<Registration_Authsmsconfirmation_Mutation_Response>>>;
  /** update data of the table: "registration_meetingrequest" */
  update_registration_meetingrequest?: Maybe<Registration_Meetingrequest_Mutation_Response>;
  /** update single row of the table: "registration_meetingrequest" */
  update_registration_meetingrequest_by_pk?: Maybe<Registration_Meetingrequest>;
  /** update multiples rows of table: "registration_meetingrequest" */
  update_registration_meetingrequest_many?: Maybe<Array<Maybe<Registration_Meetingrequest_Mutation_Response>>>;
  /** update data of the table: "registration_onlineregistrationconfig" */
  update_registration_onlineregistrationconfig?: Maybe<Registration_Onlineregistrationconfig_Mutation_Response>;
  /** update single row of the table: "registration_onlineregistrationconfig" */
  update_registration_onlineregistrationconfig_by_pk?: Maybe<Registration_Onlineregistrationconfig>;
  /** update multiples rows of table: "registration_onlineregistrationconfig" */
  update_registration_onlineregistrationconfig_many?: Maybe<Array<Maybe<Registration_Onlineregistrationconfig_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "request_considerations" */
  update_request_considerations?: Maybe<Request_Considerations_Mutation_Response>;
  /** update single row of the table: "request_considerations" */
  update_request_considerations_by_pk?: Maybe<Request_Considerations>;
  /** update multiples rows of table: "request_considerations" */
  update_request_considerations_many?: Maybe<Array<Maybe<Request_Considerations_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "rooms" */
  update_rooms?: Maybe<Rooms_Mutation_Response>;
  /** update single row of the table: "rooms" */
  update_rooms_by_pk?: Maybe<Rooms>;
  /** update multiples rows of table: "rooms" */
  update_rooms_many?: Maybe<Array<Maybe<Rooms_Mutation_Response>>>;
  /** update data of the table: "services" */
  update_services?: Maybe<Services_Mutation_Response>;
  /** update data of the table: "services_assigned_to" */
  update_services_assigned_to?: Maybe<Services_Assigned_To_Mutation_Response>;
  /** update single row of the table: "services_assigned_to" */
  update_services_assigned_to_by_pk?: Maybe<Services_Assigned_To>;
  /** update multiples rows of table: "services_assigned_to" */
  update_services_assigned_to_many?: Maybe<Array<Maybe<Services_Assigned_To_Mutation_Response>>>;
  /** update single row of the table: "services" */
  update_services_by_pk?: Maybe<Services>;
  /** update multiples rows of table: "services" */
  update_services_many?: Maybe<Array<Maybe<Services_Mutation_Response>>>;
  /** update data of the table: "services_restricted_rooms" */
  update_services_restricted_rooms?: Maybe<Services_Restricted_Rooms_Mutation_Response>;
  /** update single row of the table: "services_restricted_rooms" */
  update_services_restricted_rooms_by_pk?: Maybe<Services_Restricted_Rooms>;
  /** update multiples rows of table: "services_restricted_rooms" */
  update_services_restricted_rooms_many?: Maybe<Array<Maybe<Services_Restricted_Rooms_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_defaultsmstemplate" */
  update_sms_notifications_defaultsmstemplate?: Maybe<Sms_Notifications_Defaultsmstemplate_Mutation_Response>;
  /** update single row of the table: "sms_notifications_defaultsmstemplate" */
  update_sms_notifications_defaultsmstemplate_by_pk?: Maybe<Sms_Notifications_Defaultsmstemplate>;
  /** update multiples rows of table: "sms_notifications_defaultsmstemplate" */
  update_sms_notifications_defaultsmstemplate_many?: Maybe<Array<Maybe<Sms_Notifications_Defaultsmstemplate_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_sentsms" */
  update_sms_notifications_sentsms?: Maybe<Sms_Notifications_Sentsms_Mutation_Response>;
  /** update single row of the table: "sms_notifications_sentsms" */
  update_sms_notifications_sentsms_by_pk?: Maybe<Sms_Notifications_Sentsms>;
  /** update multiples rows of table: "sms_notifications_sentsms" */
  update_sms_notifications_sentsms_many?: Maybe<Array<Maybe<Sms_Notifications_Sentsms_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_smsconfig" */
  update_sms_notifications_smsconfig?: Maybe<Sms_Notifications_Smsconfig_Mutation_Response>;
  /** update single row of the table: "sms_notifications_smsconfig" */
  update_sms_notifications_smsconfig_by_pk?: Maybe<Sms_Notifications_Smsconfig>;
  /** update multiples rows of table: "sms_notifications_smsconfig" */
  update_sms_notifications_smsconfig_many?: Maybe<Array<Maybe<Sms_Notifications_Smsconfig_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_smspacketpurchase" */
  update_sms_notifications_smspacketpurchase?: Maybe<Sms_Notifications_Smspacketpurchase_Mutation_Response>;
  /** update single row of the table: "sms_notifications_smspacketpurchase" */
  update_sms_notifications_smspacketpurchase_by_pk?: Maybe<Sms_Notifications_Smspacketpurchase>;
  /** update multiples rows of table: "sms_notifications_smspacketpurchase" */
  update_sms_notifications_smspacketpurchase_many?: Maybe<Array<Maybe<Sms_Notifications_Smspacketpurchase_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_smstemplate" */
  update_sms_notifications_smstemplate?: Maybe<Sms_Notifications_Smstemplate_Mutation_Response>;
  /** update single row of the table: "sms_notifications_smstemplate" */
  update_sms_notifications_smstemplate_by_pk?: Maybe<Sms_Notifications_Smstemplate>;
  /** update multiples rows of table: "sms_notifications_smstemplate" */
  update_sms_notifications_smstemplate_many?: Maybe<Array<Maybe<Sms_Notifications_Smstemplate_Mutation_Response>>>;
  /** update data of the table: "sms_notifications_smstemplatevariable" */
  update_sms_notifications_smstemplatevariable?: Maybe<Sms_Notifications_Smstemplatevariable_Mutation_Response>;
  /** update single row of the table: "sms_notifications_smstemplatevariable" */
  update_sms_notifications_smstemplatevariable_by_pk?: Maybe<Sms_Notifications_Smstemplatevariable>;
  /** update multiples rows of table: "sms_notifications_smstemplatevariable" */
  update_sms_notifications_smstemplatevariable_many?: Maybe<Array<Maybe<Sms_Notifications_Smstemplatevariable_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_groups" */
  update_user_groups?: Maybe<User_Groups_Mutation_Response>;
  /** update single row of the table: "user_groups" */
  update_user_groups_by_pk?: Maybe<User_Groups>;
  /** update multiples rows of table: "user_groups" */
  update_user_groups_many?: Maybe<Array<Maybe<User_Groups_Mutation_Response>>>;
  /** update data of the table: "user_identity" */
  update_user_identity?: Maybe<User_Identity_Mutation_Response>;
  /** update single row of the table: "user_identity" */
  update_user_identity_by_pk?: Maybe<User_Identity>;
  /** update multiples rows of table: "user_identity" */
  update_user_identity_many?: Maybe<Array<Maybe<User_Identity_Mutation_Response>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_user_permissions" */
  update_user_user_permissions?: Maybe<User_User_Permissions_Mutation_Response>;
  /** update single row of the table: "user_user_permissions" */
  update_user_user_permissions_by_pk?: Maybe<User_User_Permissions>;
  /** update multiples rows of table: "user_user_permissions" */
  update_user_user_permissions_many?: Maybe<Array<Maybe<User_User_Permissions_Mutation_Response>>>;
  /** update data of the table: "workdays" */
  update_workdays?: Maybe<Workdays_Mutation_Response>;
  /** update single row of the table: "workdays" */
  update_workdays_by_pk?: Maybe<Workdays>;
  /** update multiples rows of table: "workdays" */
  update_workdays_many?: Maybe<Array<Maybe<Workdays_Mutation_Response>>>;
  /** update data of the table: "worktime_configs" */
  update_worktime_configs?: Maybe<Worktime_Configs_Mutation_Response>;
  /** update single row of the table: "worktime_configs" */
  update_worktime_configs_by_pk?: Maybe<Worktime_Configs>;
  /** update multiples rows of table: "worktime_configs" */
  update_worktime_configs_many?: Maybe<Array<Maybe<Worktime_Configs_Mutation_Response>>>;
  /** update data of the table: "worktime_exceptions" */
  update_worktime_exceptions?: Maybe<Worktime_Exceptions_Mutation_Response>;
  /** update single row of the table: "worktime_exceptions" */
  update_worktime_exceptions_by_pk?: Maybe<Worktime_Exceptions>;
  /** update multiples rows of table: "worktime_exceptions" */
  update_worktime_exceptions_many?: Maybe<Array<Maybe<Worktime_Exceptions_Mutation_Response>>>;
  /** update data of the table: "worktime_exceptions_work_times" */
  update_worktime_exceptions_work_times?: Maybe<Worktime_Exceptions_Work_Times_Mutation_Response>;
  /** update single row of the table: "worktime_exceptions_work_times" */
  update_worktime_exceptions_work_times_by_pk?: Maybe<Worktime_Exceptions_Work_Times>;
  /** update multiples rows of table: "worktime_exceptions_work_times" */
  update_worktime_exceptions_work_times_many?: Maybe<Array<Maybe<Worktime_Exceptions_Work_Times_Mutation_Response>>>;
  /** update data of the table: "worktime_presets" */
  update_worktime_presets?: Maybe<Worktime_Presets_Mutation_Response>;
  /** update single row of the table: "worktime_presets" */
  update_worktime_presets_by_pk?: Maybe<Worktime_Presets>;
  /** update multiples rows of table: "worktime_presets" */
  update_worktime_presets_many?: Maybe<Array<Maybe<Worktime_Presets_Mutation_Response>>>;
  /** update data of the table: "worktimes" */
  update_worktimes?: Maybe<Worktimes_Mutation_Response>;
  /** update single row of the table: "worktimes" */
  update_worktimes_by_pk?: Maybe<Worktimes>;
  /** update multiples rows of table: "worktimes" */
  update_worktimes_many?: Maybe<Array<Maybe<Worktimes_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Accounting_PracticeexpenseArgs = {
  where: Accounting_Practiceexpense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounting_Practiceexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Accounting_RecurringexpenseArgs = {
  where: Accounting_Recurringexpense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounting_Recurringexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_AddressesArgs = {
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_AvatarsArgs = {
  where: Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Avatars_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contact_DetailsArgs = {
  where: Contact_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CouplesArgs = {
  where: Couples_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Couples_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Couples_PatientsArgs = {
  where: Couples_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Couples_Patients_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Couples_TherapistsArgs = {
  where: Couples_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Couples_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Ed_AttachmentsArgs = {
  where: Ed_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ed_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Ed_Group_PresenceArgs = {
  where: Ed_Group_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ed_Group_Presence_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ed_ParticipantsArgs = {
  where: Ed_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ed_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Ed_Payment_MethodArgs = {
  where: Ed_Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ed_Payment_Method_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ed_PresenceArgs = {
  where: Ed_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ed_Presence_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeesArgs = {
  where: Employees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employees_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Employees_Favorite_TherapistsArgs = {
  where: Employees_Favorite_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employees_Favorite_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Employees_Preferred_RoomArgs = {
  where: Employees_Preferred_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employees_Preferred_Room_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Employees_RolesArgs = {
  where: Employees_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employees_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_EventArgs = {
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_ParticipantsArgs = {
  where: Event_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_PeriodArgs = {
  where: Event_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_ParticipantsArgs = {
  where: Event_Period_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_ServicesArgs = {
  where: Event_Period_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_TherapistsArgs = {
  where: Event_Period_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Period_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_ServicesArgs = {
  where: Event_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Event_TherapistsArgs = {
  where: Event_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_GdprconfigArgs = {
  where: Gdpr_Gdprconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Gdprconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_GdprdocumentArgs = {
  where: Gdpr_Gdprdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Gdprdocument_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_GdprdocumenttypeArgs = {
  where: Gdpr_Gdprdocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Gdprdocumenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Group_MembersArgs = {
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Members_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Holiday_ReasonsArgs = {
  where: Holiday_Reasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Holiday_Reasons_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_HolidaysArgs = {
  where: Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Holidays_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_BillinginfoArgs = {
  where: Invoices_V2_Billinginfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Billinginfo_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_InvoiceArgs = {
  where: Invoices_V2_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoice_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_InvoiceconfigArgs = {
  where: Invoices_V2_Invoiceconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoiceconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_InvoicelineitemArgs = {
  where: Invoices_V2_Invoicelineitem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoicelineitem_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoicelineitem_EventsArgs = {
  where: Invoices_V2_Invoicelineitem_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoicelineitem_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_InvoicesenttoArgs = {
  where: Invoices_V2_Invoicesentto_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Invoices_V2_Invoicesentto_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_National_HolidaysArgs = {
  where: National_Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_National_Holidays_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_AddressesArgs = {
  where: Patient_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_EducatorArgs = {
  where: Patient_Educator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Educator_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_Guardian_AddressesArgs = {
  where: Patient_Guardian_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Guardian_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_GuardiansArgs = {
  where: Patient_Guardians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Guardians_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_PsychotherapiesArgs = {
  where: Patient_Psychotherapies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Psychotherapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_SiArgs = {
  where: Patient_Si_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Si_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_Speech_TherapiesArgs = {
  where: Patient_Speech_Therapies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Speech_Therapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PatientsArgs = {
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patients_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Patients_TherapistsArgs = {
  where: Patients_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patients_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Practice_BranchesArgs = {
  where: Practice_Branches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practice_Branches_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Practice_LogosArgs = {
  where: Practice_Logos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practice_Logos_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PracticesArgs = {
  where: Practices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practices_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Registration_AuthsmsconfirmationArgs = {
  where: Registration_Authsmsconfirmation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registration_Authsmsconfirmation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Registration_MeetingrequestArgs = {
  where: Registration_Meetingrequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registration_Meetingrequest_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Registration_OnlineregistrationconfigArgs = {
  where: Registration_Onlineregistrationconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Registration_Onlineregistrationconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Request_ConsiderationsArgs = {
  where: Request_Considerations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Request_Considerations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  slug: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_RoomsArgs = {
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rooms_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ServicesArgs = {
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_Assigned_ToArgs = {
  where: Services_Assigned_To_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_Assigned_To_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Services_Restricted_RoomsArgs = {
  where: Services_Restricted_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_Restricted_Rooms_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_DefaultsmstemplateArgs = {
  where: Sms_Notifications_Defaultsmstemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Defaultsmstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_SentsmsArgs = {
  where: Sms_Notifications_Sentsms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Sentsms_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_SmsconfigArgs = {
  where: Sms_Notifications_Smsconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Smsconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_SmspacketpurchaseArgs = {
  where: Sms_Notifications_Smspacketpurchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Smspacketpurchase_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_SmstemplateArgs = {
  where: Sms_Notifications_Smstemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Smstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_SmstemplatevariableArgs = {
  where: Sms_Notifications_Smstemplatevariable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Notifications_Smstemplatevariable_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_GroupsArgs = {
  where: User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_IdentityArgs = {
  where: User_Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Identity_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_User_PermissionsArgs = {
  where: User_User_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_WorkdaysArgs = {
  where: Workdays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workdays_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Worktime_ConfigsArgs = {
  where: Worktime_Configs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worktime_Configs_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Worktime_ExceptionsArgs = {
  where: Worktime_Exceptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worktime_Exceptions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Worktime_Exceptions_Work_TimesArgs = {
  where: Worktime_Exceptions_Work_Times_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worktime_Exceptions_Work_Times_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Worktime_PresetsArgs = {
  where: Worktime_Presets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worktime_Presets_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_WorktimesArgs = {
  where: Worktimes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Worktimes_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootInsert_Accounting_PracticeexpenseArgs = {
  objects: Array<Accounting_Practiceexpense_Insert_Input>;
  on_conflict?: InputMaybe<Accounting_Practiceexpense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounting_Practiceexpense_OneArgs = {
  object: Accounting_Practiceexpense_Insert_Input;
  on_conflict?: InputMaybe<Accounting_Practiceexpense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounting_RecurringexpenseArgs = {
  objects: Array<Accounting_Recurringexpense_Insert_Input>;
  on_conflict?: InputMaybe<Accounting_Recurringexpense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounting_Recurringexpense_OneArgs = {
  object: Accounting_Recurringexpense_Insert_Input;
  on_conflict?: InputMaybe<Accounting_Recurringexpense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AddressesArgs = {
  objects: Array<Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Addresses_OneArgs = {
  object: Addresses_Insert_Input;
  on_conflict?: InputMaybe<Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AvatarsArgs = {
  objects: Array<Avatars_Insert_Input>;
  on_conflict?: InputMaybe<Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Avatars_OneArgs = {
  object: Avatars_Insert_Input;
  on_conflict?: InputMaybe<Avatars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_DetailsArgs = {
  objects: Array<Contact_Details_Insert_Input>;
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Details_OneArgs = {
  object: Contact_Details_Insert_Input;
  on_conflict?: InputMaybe<Contact_Details_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CouplesArgs = {
  objects: Array<Couples_Insert_Input>;
  on_conflict?: InputMaybe<Couples_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Couples_OneArgs = {
  object: Couples_Insert_Input;
  on_conflict?: InputMaybe<Couples_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Couples_PatientsArgs = {
  objects: Array<Couples_Patients_Insert_Input>;
  on_conflict?: InputMaybe<Couples_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Couples_Patients_OneArgs = {
  object: Couples_Patients_Insert_Input;
  on_conflict?: InputMaybe<Couples_Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Couples_TherapistsArgs = {
  objects: Array<Couples_Therapists_Insert_Input>;
  on_conflict?: InputMaybe<Couples_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Couples_Therapists_OneArgs = {
  object: Couples_Therapists_Insert_Input;
  on_conflict?: InputMaybe<Couples_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_AttachmentsArgs = {
  objects: Array<Ed_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Ed_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Attachments_OneArgs = {
  object: Ed_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Ed_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Group_PresenceArgs = {
  objects: Array<Ed_Group_Presence_Insert_Input>;
  on_conflict?: InputMaybe<Ed_Group_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Group_Presence_OneArgs = {
  object: Ed_Group_Presence_Insert_Input;
  on_conflict?: InputMaybe<Ed_Group_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_ParticipantsArgs = {
  objects: Array<Ed_Participants_Insert_Input>;
  on_conflict?: InputMaybe<Ed_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Participants_OneArgs = {
  object: Ed_Participants_Insert_Input;
  on_conflict?: InputMaybe<Ed_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Payment_MethodArgs = {
  objects: Array<Ed_Payment_Method_Insert_Input>;
  on_conflict?: InputMaybe<Ed_Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Payment_Method_OneArgs = {
  object: Ed_Payment_Method_Insert_Input;
  on_conflict?: InputMaybe<Ed_Payment_Method_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_PresenceArgs = {
  objects: Array<Ed_Presence_Insert_Input>;
  on_conflict?: InputMaybe<Ed_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ed_Presence_OneArgs = {
  object: Ed_Presence_Insert_Input;
  on_conflict?: InputMaybe<Ed_Presence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeesArgs = {
  objects: Array<Employees_Insert_Input>;
  on_conflict?: InputMaybe<Employees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_Favorite_TherapistsArgs = {
  objects: Array<Employees_Favorite_Therapists_Insert_Input>;
  on_conflict?: InputMaybe<Employees_Favorite_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_Favorite_Therapists_OneArgs = {
  object: Employees_Favorite_Therapists_Insert_Input;
  on_conflict?: InputMaybe<Employees_Favorite_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_OneArgs = {
  object: Employees_Insert_Input;
  on_conflict?: InputMaybe<Employees_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_Preferred_RoomArgs = {
  objects: Array<Employees_Preferred_Room_Insert_Input>;
  on_conflict?: InputMaybe<Employees_Preferred_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_Preferred_Room_OneArgs = {
  object: Employees_Preferred_Room_Insert_Input;
  on_conflict?: InputMaybe<Employees_Preferred_Room_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_RolesArgs = {
  objects: Array<Employees_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Employees_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employees_Roles_OneArgs = {
  object: Employees_Roles_Insert_Input;
  on_conflict?: InputMaybe<Employees_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventArgs = {
  objects: Array<Event_Insert_Input>;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OneArgs = {
  object: Event_Insert_Input;
  on_conflict?: InputMaybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_ParticipantsArgs = {
  objects: Array<Event_Participants_Insert_Input>;
  on_conflict?: InputMaybe<Event_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Participants_OneArgs = {
  object: Event_Participants_Insert_Input;
  on_conflict?: InputMaybe<Event_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_PeriodArgs = {
  objects: Array<Event_Period_Insert_Input>;
  on_conflict?: InputMaybe<Event_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_OneArgs = {
  object: Event_Period_Insert_Input;
  on_conflict?: InputMaybe<Event_Period_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_ParticipantsArgs = {
  objects: Array<Event_Period_Participants_Insert_Input>;
  on_conflict?: InputMaybe<Event_Period_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_Participants_OneArgs = {
  object: Event_Period_Participants_Insert_Input;
  on_conflict?: InputMaybe<Event_Period_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_ServicesArgs = {
  objects: Array<Event_Period_Services_Insert_Input>;
  on_conflict?: InputMaybe<Event_Period_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_Services_OneArgs = {
  object: Event_Period_Services_Insert_Input;
  on_conflict?: InputMaybe<Event_Period_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_TherapistsArgs = {
  objects: Array<Event_Period_Therapists_Insert_Input>;
  on_conflict?: InputMaybe<Event_Period_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Period_Therapists_OneArgs = {
  object: Event_Period_Therapists_Insert_Input;
  on_conflict?: InputMaybe<Event_Period_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_ServicesArgs = {
  objects: Array<Event_Services_Insert_Input>;
  on_conflict?: InputMaybe<Event_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Services_OneArgs = {
  object: Event_Services_Insert_Input;
  on_conflict?: InputMaybe<Event_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_TherapistsArgs = {
  objects: Array<Event_Therapists_Insert_Input>;
  on_conflict?: InputMaybe<Event_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Therapists_OneArgs = {
  object: Event_Therapists_Insert_Input;
  on_conflict?: InputMaybe<Event_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_GdprconfigArgs = {
  objects: Array<Gdpr_Gdprconfig_Insert_Input>;
  on_conflict?: InputMaybe<Gdpr_Gdprconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Gdprconfig_OneArgs = {
  object: Gdpr_Gdprconfig_Insert_Input;
  on_conflict?: InputMaybe<Gdpr_Gdprconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_GdprdocumentArgs = {
  objects: Array<Gdpr_Gdprdocument_Insert_Input>;
  on_conflict?: InputMaybe<Gdpr_Gdprdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Gdprdocument_OneArgs = {
  object: Gdpr_Gdprdocument_Insert_Input;
  on_conflict?: InputMaybe<Gdpr_Gdprdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_GdprdocumenttypeArgs = {
  objects: Array<Gdpr_Gdprdocumenttype_Insert_Input>;
  on_conflict?: InputMaybe<Gdpr_Gdprdocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Gdprdocumenttype_OneArgs = {
  object: Gdpr_Gdprdocumenttype_Insert_Input;
  on_conflict?: InputMaybe<Gdpr_Gdprdocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_MembersArgs = {
  objects: Array<Group_Members_Insert_Input>;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Members_OneArgs = {
  object: Group_Members_Insert_Input;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Holiday_ReasonsArgs = {
  objects: Array<Holiday_Reasons_Insert_Input>;
  on_conflict?: InputMaybe<Holiday_Reasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Holiday_Reasons_OneArgs = {
  object: Holiday_Reasons_Insert_Input;
  on_conflict?: InputMaybe<Holiday_Reasons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_HolidaysArgs = {
  objects: Array<Holidays_Insert_Input>;
  on_conflict?: InputMaybe<Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Holidays_OneArgs = {
  object: Holidays_Insert_Input;
  on_conflict?: InputMaybe<Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_BillinginfoArgs = {
  objects: Array<Invoices_V2_Billinginfo_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Billinginfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Billinginfo_OneArgs = {
  object: Invoices_V2_Billinginfo_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Billinginfo_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_InvoiceArgs = {
  objects: Array<Invoices_V2_Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoice_OneArgs = {
  object: Invoices_V2_Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Invoice_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_InvoiceconfigArgs = {
  objects: Array<Invoices_V2_Invoiceconfig_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Invoiceconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoiceconfig_OneArgs = {
  object: Invoices_V2_Invoiceconfig_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Invoiceconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_InvoicelineitemArgs = {
  objects: Array<Invoices_V2_Invoicelineitem_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoicelineitem_EventsArgs = {
  objects: Array<Invoices_V2_Invoicelineitem_Events_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoicelineitem_Events_OneArgs = {
  object: Invoices_V2_Invoicelineitem_Events_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoicelineitem_OneArgs = {
  object: Invoices_V2_Invoicelineitem_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Invoicelineitem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_InvoicesenttoArgs = {
  objects: Array<Invoices_V2_Invoicesentto_Insert_Input>;
  on_conflict?: InputMaybe<Invoices_V2_Invoicesentto_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Invoices_V2_Invoicesentto_OneArgs = {
  object: Invoices_V2_Invoicesentto_Insert_Input;
  on_conflict?: InputMaybe<Invoices_V2_Invoicesentto_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_National_HolidaysArgs = {
  objects: Array<National_Holidays_Insert_Input>;
  on_conflict?: InputMaybe<National_Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_National_Holidays_OneArgs = {
  object: National_Holidays_Insert_Input;
  on_conflict?: InputMaybe<National_Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_AddressesArgs = {
  objects: Array<Patient_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Addresses_OneArgs = {
  object: Patient_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Patient_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_EducatorArgs = {
  objects: Array<Patient_Educator_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Educator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Educator_OneArgs = {
  object: Patient_Educator_Insert_Input;
  on_conflict?: InputMaybe<Patient_Educator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Guardian_AddressesArgs = {
  objects: Array<Patient_Guardian_Addresses_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Guardian_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Guardian_Addresses_OneArgs = {
  object: Patient_Guardian_Addresses_Insert_Input;
  on_conflict?: InputMaybe<Patient_Guardian_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_GuardiansArgs = {
  objects: Array<Patient_Guardians_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Guardians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Guardians_OneArgs = {
  object: Patient_Guardians_Insert_Input;
  on_conflict?: InputMaybe<Patient_Guardians_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_PsychotherapiesArgs = {
  objects: Array<Patient_Psychotherapies_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Psychotherapies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Psychotherapies_OneArgs = {
  object: Patient_Psychotherapies_Insert_Input;
  on_conflict?: InputMaybe<Patient_Psychotherapies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_SiArgs = {
  objects: Array<Patient_Si_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Si_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Si_OneArgs = {
  object: Patient_Si_Insert_Input;
  on_conflict?: InputMaybe<Patient_Si_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Speech_TherapiesArgs = {
  objects: Array<Patient_Speech_Therapies_Insert_Input>;
  on_conflict?: InputMaybe<Patient_Speech_Therapies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Speech_Therapies_OneArgs = {
  object: Patient_Speech_Therapies_Insert_Input;
  on_conflict?: InputMaybe<Patient_Speech_Therapies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientsArgs = {
  objects: Array<Patients_Insert_Input>;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_OneArgs = {
  object: Patients_Insert_Input;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_TherapistsArgs = {
  objects: Array<Patients_Therapists_Insert_Input>;
  on_conflict?: InputMaybe<Patients_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_Therapists_OneArgs = {
  object: Patients_Therapists_Insert_Input;
  on_conflict?: InputMaybe<Patients_Therapists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practice_BranchesArgs = {
  objects: Array<Practice_Branches_Insert_Input>;
  on_conflict?: InputMaybe<Practice_Branches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practice_Branches_OneArgs = {
  object: Practice_Branches_Insert_Input;
  on_conflict?: InputMaybe<Practice_Branches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practice_LogosArgs = {
  objects: Array<Practice_Logos_Insert_Input>;
  on_conflict?: InputMaybe<Practice_Logos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practice_Logos_OneArgs = {
  object: Practice_Logos_Insert_Input;
  on_conflict?: InputMaybe<Practice_Logos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PracticesArgs = {
  objects: Array<Practices_Insert_Input>;
  on_conflict?: InputMaybe<Practices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practices_OneArgs = {
  object: Practices_Insert_Input;
  on_conflict?: InputMaybe<Practices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_AuthsmsconfirmationArgs = {
  objects: Array<Registration_Authsmsconfirmation_Insert_Input>;
  on_conflict?: InputMaybe<Registration_Authsmsconfirmation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_Authsmsconfirmation_OneArgs = {
  object: Registration_Authsmsconfirmation_Insert_Input;
  on_conflict?: InputMaybe<Registration_Authsmsconfirmation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_MeetingrequestArgs = {
  objects: Array<Registration_Meetingrequest_Insert_Input>;
  on_conflict?: InputMaybe<Registration_Meetingrequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_Meetingrequest_OneArgs = {
  object: Registration_Meetingrequest_Insert_Input;
  on_conflict?: InputMaybe<Registration_Meetingrequest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_OnlineregistrationconfigArgs = {
  objects: Array<Registration_Onlineregistrationconfig_Insert_Input>;
  on_conflict?: InputMaybe<Registration_Onlineregistrationconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Registration_Onlineregistrationconfig_OneArgs = {
  object: Registration_Onlineregistrationconfig_Insert_Input;
  on_conflict?: InputMaybe<Registration_Onlineregistrationconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_ConsiderationsArgs = {
  objects: Array<Request_Considerations_Insert_Input>;
  on_conflict?: InputMaybe<Request_Considerations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_Considerations_OneArgs = {
  object: Request_Considerations_Insert_Input;
  on_conflict?: InputMaybe<Request_Considerations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoomsArgs = {
  objects: Array<Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rooms_OneArgs = {
  object: Rooms_Insert_Input;
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServicesArgs = {
  objects: Array<Services_Insert_Input>;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_Assigned_ToArgs = {
  objects: Array<Services_Assigned_To_Insert_Input>;
  on_conflict?: InputMaybe<Services_Assigned_To_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_Assigned_To_OneArgs = {
  object: Services_Assigned_To_Insert_Input;
  on_conflict?: InputMaybe<Services_Assigned_To_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_OneArgs = {
  object: Services_Insert_Input;
  on_conflict?: InputMaybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_Restricted_RoomsArgs = {
  objects: Array<Services_Restricted_Rooms_Insert_Input>;
  on_conflict?: InputMaybe<Services_Restricted_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_Restricted_Rooms_OneArgs = {
  object: Services_Restricted_Rooms_Insert_Input;
  on_conflict?: InputMaybe<Services_Restricted_Rooms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_DefaultsmstemplateArgs = {
  objects: Array<Sms_Notifications_Defaultsmstemplate_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Defaultsmstemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Defaultsmstemplate_OneArgs = {
  object: Sms_Notifications_Defaultsmstemplate_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Defaultsmstemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_SentsmsArgs = {
  objects: Array<Sms_Notifications_Sentsms_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Sentsms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Sentsms_OneArgs = {
  object: Sms_Notifications_Sentsms_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Sentsms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_SmsconfigArgs = {
  objects: Array<Sms_Notifications_Smsconfig_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Smsconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Smsconfig_OneArgs = {
  object: Sms_Notifications_Smsconfig_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Smsconfig_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_SmspacketpurchaseArgs = {
  objects: Array<Sms_Notifications_Smspacketpurchase_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Smspacketpurchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Smspacketpurchase_OneArgs = {
  object: Sms_Notifications_Smspacketpurchase_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Smspacketpurchase_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_SmstemplateArgs = {
  objects: Array<Sms_Notifications_Smstemplate_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Smstemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Smstemplate_OneArgs = {
  object: Sms_Notifications_Smstemplate_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Smstemplate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_SmstemplatevariableArgs = {
  objects: Array<Sms_Notifications_Smstemplatevariable_Insert_Input>;
  on_conflict?: InputMaybe<Sms_Notifications_Smstemplatevariable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Notifications_Smstemplatevariable_OneArgs = {
  object: Sms_Notifications_Smstemplatevariable_Insert_Input;
  on_conflict?: InputMaybe<Sms_Notifications_Smstemplatevariable_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_GroupsArgs = {
  objects: Array<User_Groups_Insert_Input>;
  on_conflict?: InputMaybe<User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Groups_OneArgs = {
  object: User_Groups_Insert_Input;
  on_conflict?: InputMaybe<User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_IdentityArgs = {
  objects: Array<User_Identity_Insert_Input>;
  on_conflict?: InputMaybe<User_Identity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Identity_OneArgs = {
  object: User_Identity_Insert_Input;
  on_conflict?: InputMaybe<User_Identity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_User_PermissionsArgs = {
  objects: Array<User_User_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<User_User_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_User_Permissions_OneArgs = {
  object: User_User_Permissions_Insert_Input;
  on_conflict?: InputMaybe<User_User_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkdaysArgs = {
  objects: Array<Workdays_Insert_Input>;
  on_conflict?: InputMaybe<Workdays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workdays_OneArgs = {
  object: Workdays_Insert_Input;
  on_conflict?: InputMaybe<Workdays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_ConfigsArgs = {
  objects: Array<Worktime_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Worktime_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_Configs_OneArgs = {
  object: Worktime_Configs_Insert_Input;
  on_conflict?: InputMaybe<Worktime_Configs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_ExceptionsArgs = {
  objects: Array<Worktime_Exceptions_Insert_Input>;
  on_conflict?: InputMaybe<Worktime_Exceptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_Exceptions_OneArgs = {
  object: Worktime_Exceptions_Insert_Input;
  on_conflict?: InputMaybe<Worktime_Exceptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_Exceptions_Work_TimesArgs = {
  objects: Array<Worktime_Exceptions_Work_Times_Insert_Input>;
  on_conflict?: InputMaybe<Worktime_Exceptions_Work_Times_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_Exceptions_Work_Times_OneArgs = {
  object: Worktime_Exceptions_Work_Times_Insert_Input;
  on_conflict?: InputMaybe<Worktime_Exceptions_Work_Times_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_PresetsArgs = {
  objects: Array<Worktime_Presets_Insert_Input>;
  on_conflict?: InputMaybe<Worktime_Presets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktime_Presets_OneArgs = {
  object: Worktime_Presets_Insert_Input;
  on_conflict?: InputMaybe<Worktime_Presets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorktimesArgs = {
  objects: Array<Worktimes_Insert_Input>;
  on_conflict?: InputMaybe<Worktimes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Worktimes_OneArgs = {
  object: Worktimes_Insert_Input;
  on_conflict?: InputMaybe<Worktimes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_PracticeexpenseArgs = {
  _inc?: InputMaybe<Accounting_Practiceexpense_Inc_Input>;
  _set?: InputMaybe<Accounting_Practiceexpense_Set_Input>;
  where: Accounting_Practiceexpense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_Practiceexpense_By_PkArgs = {
  _inc?: InputMaybe<Accounting_Practiceexpense_Inc_Input>;
  _set?: InputMaybe<Accounting_Practiceexpense_Set_Input>;
  pk_columns: Accounting_Practiceexpense_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_Practiceexpense_ManyArgs = {
  updates: Array<Accounting_Practiceexpense_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_RecurringexpenseArgs = {
  _inc?: InputMaybe<Accounting_Recurringexpense_Inc_Input>;
  _set?: InputMaybe<Accounting_Recurringexpense_Set_Input>;
  where: Accounting_Recurringexpense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_Recurringexpense_By_PkArgs = {
  _inc?: InputMaybe<Accounting_Recurringexpense_Inc_Input>;
  _set?: InputMaybe<Accounting_Recurringexpense_Set_Input>;
  pk_columns: Accounting_Recurringexpense_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounting_Recurringexpense_ManyArgs = {
  updates: Array<Accounting_Recurringexpense_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressesArgs = {
  _inc?: InputMaybe<Addresses_Inc_Input>;
  _set?: InputMaybe<Addresses_Set_Input>;
  where: Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_By_PkArgs = {
  _inc?: InputMaybe<Addresses_Inc_Input>;
  _set?: InputMaybe<Addresses_Set_Input>;
  pk_columns: Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Addresses_ManyArgs = {
  updates: Array<Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AvatarsArgs = {
  _set?: InputMaybe<Avatars_Set_Input>;
  where: Avatars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Avatars_By_PkArgs = {
  _set?: InputMaybe<Avatars_Set_Input>;
  pk_columns: Avatars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Avatars_ManyArgs = {
  updates: Array<Avatars_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_DetailsArgs = {
  _inc?: InputMaybe<Contact_Details_Inc_Input>;
  _set?: InputMaybe<Contact_Details_Set_Input>;
  where: Contact_Details_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Details_By_PkArgs = {
  _inc?: InputMaybe<Contact_Details_Inc_Input>;
  _set?: InputMaybe<Contact_Details_Set_Input>;
  pk_columns: Contact_Details_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Details_ManyArgs = {
  updates: Array<Contact_Details_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CouplesArgs = {
  _inc?: InputMaybe<Couples_Inc_Input>;
  _set?: InputMaybe<Couples_Set_Input>;
  where: Couples_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_By_PkArgs = {
  _inc?: InputMaybe<Couples_Inc_Input>;
  _set?: InputMaybe<Couples_Set_Input>;
  pk_columns: Couples_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_ManyArgs = {
  updates: Array<Couples_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_PatientsArgs = {
  _inc?: InputMaybe<Couples_Patients_Inc_Input>;
  _set?: InputMaybe<Couples_Patients_Set_Input>;
  where: Couples_Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_Patients_By_PkArgs = {
  _inc?: InputMaybe<Couples_Patients_Inc_Input>;
  _set?: InputMaybe<Couples_Patients_Set_Input>;
  pk_columns: Couples_Patients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_Patients_ManyArgs = {
  updates: Array<Couples_Patients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_TherapistsArgs = {
  _inc?: InputMaybe<Couples_Therapists_Inc_Input>;
  _set?: InputMaybe<Couples_Therapists_Set_Input>;
  where: Couples_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_Therapists_By_PkArgs = {
  _inc?: InputMaybe<Couples_Therapists_Inc_Input>;
  _set?: InputMaybe<Couples_Therapists_Set_Input>;
  pk_columns: Couples_Therapists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Couples_Therapists_ManyArgs = {
  updates: Array<Couples_Therapists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_AttachmentsArgs = {
  _inc?: InputMaybe<Ed_Attachments_Inc_Input>;
  _set?: InputMaybe<Ed_Attachments_Set_Input>;
  where: Ed_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Attachments_By_PkArgs = {
  _inc?: InputMaybe<Ed_Attachments_Inc_Input>;
  _set?: InputMaybe<Ed_Attachments_Set_Input>;
  pk_columns: Ed_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Attachments_ManyArgs = {
  updates: Array<Ed_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Group_PresenceArgs = {
  _set?: InputMaybe<Ed_Group_Presence_Set_Input>;
  where: Ed_Group_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Group_Presence_By_PkArgs = {
  _set?: InputMaybe<Ed_Group_Presence_Set_Input>;
  pk_columns: Ed_Group_Presence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Group_Presence_ManyArgs = {
  updates: Array<Ed_Group_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_ParticipantsArgs = {
  _inc?: InputMaybe<Ed_Participants_Inc_Input>;
  _set?: InputMaybe<Ed_Participants_Set_Input>;
  where: Ed_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Participants_By_PkArgs = {
  _inc?: InputMaybe<Ed_Participants_Inc_Input>;
  _set?: InputMaybe<Ed_Participants_Set_Input>;
  pk_columns: Ed_Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Participants_ManyArgs = {
  updates: Array<Ed_Participants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Payment_MethodArgs = {
  _set?: InputMaybe<Ed_Payment_Method_Set_Input>;
  where: Ed_Payment_Method_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Payment_Method_By_PkArgs = {
  _set?: InputMaybe<Ed_Payment_Method_Set_Input>;
  pk_columns: Ed_Payment_Method_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Payment_Method_ManyArgs = {
  updates: Array<Ed_Payment_Method_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_PresenceArgs = {
  _set?: InputMaybe<Ed_Presence_Set_Input>;
  where: Ed_Presence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Presence_By_PkArgs = {
  _set?: InputMaybe<Ed_Presence_Set_Input>;
  pk_columns: Ed_Presence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ed_Presence_ManyArgs = {
  updates: Array<Ed_Presence_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeesArgs = {
  _inc?: InputMaybe<Employees_Inc_Input>;
  _set?: InputMaybe<Employees_Set_Input>;
  where: Employees_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_By_PkArgs = {
  _inc?: InputMaybe<Employees_Inc_Input>;
  _set?: InputMaybe<Employees_Set_Input>;
  pk_columns: Employees_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Favorite_TherapistsArgs = {
  _inc?: InputMaybe<Employees_Favorite_Therapists_Inc_Input>;
  _set?: InputMaybe<Employees_Favorite_Therapists_Set_Input>;
  where: Employees_Favorite_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Favorite_Therapists_By_PkArgs = {
  _inc?: InputMaybe<Employees_Favorite_Therapists_Inc_Input>;
  _set?: InputMaybe<Employees_Favorite_Therapists_Set_Input>;
  pk_columns: Employees_Favorite_Therapists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Favorite_Therapists_ManyArgs = {
  updates: Array<Employees_Favorite_Therapists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_ManyArgs = {
  updates: Array<Employees_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Preferred_RoomArgs = {
  _inc?: InputMaybe<Employees_Preferred_Room_Inc_Input>;
  _set?: InputMaybe<Employees_Preferred_Room_Set_Input>;
  where: Employees_Preferred_Room_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Preferred_Room_By_PkArgs = {
  _inc?: InputMaybe<Employees_Preferred_Room_Inc_Input>;
  _set?: InputMaybe<Employees_Preferred_Room_Set_Input>;
  pk_columns: Employees_Preferred_Room_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Preferred_Room_ManyArgs = {
  updates: Array<Employees_Preferred_Room_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_RolesArgs = {
  _inc?: InputMaybe<Employees_Roles_Inc_Input>;
  _set?: InputMaybe<Employees_Roles_Set_Input>;
  where: Employees_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Roles_By_PkArgs = {
  _inc?: InputMaybe<Employees_Roles_Inc_Input>;
  _set?: InputMaybe<Employees_Roles_Set_Input>;
  pk_columns: Employees_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employees_Roles_ManyArgs = {
  updates: Array<Employees_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventArgs = {
  _inc?: InputMaybe<Event_Inc_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_By_PkArgs = {
  _inc?: InputMaybe<Event_Inc_Input>;
  _set?: InputMaybe<Event_Set_Input>;
  pk_columns: Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ManyArgs = {
  updates: Array<Event_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ParticipantsArgs = {
  _inc?: InputMaybe<Event_Participants_Inc_Input>;
  _set?: InputMaybe<Event_Participants_Set_Input>;
  where: Event_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Participants_By_PkArgs = {
  _inc?: InputMaybe<Event_Participants_Inc_Input>;
  _set?: InputMaybe<Event_Participants_Set_Input>;
  pk_columns: Event_Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Participants_ManyArgs = {
  updates: Array<Event_Participants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_PeriodArgs = {
  _append?: InputMaybe<Event_Period_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Period_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Period_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Period_Delete_Key_Input>;
  _inc?: InputMaybe<Event_Period_Inc_Input>;
  _prepend?: InputMaybe<Event_Period_Prepend_Input>;
  _set?: InputMaybe<Event_Period_Set_Input>;
  where: Event_Period_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_By_PkArgs = {
  _append?: InputMaybe<Event_Period_Append_Input>;
  _delete_at_path?: InputMaybe<Event_Period_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Event_Period_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Event_Period_Delete_Key_Input>;
  _inc?: InputMaybe<Event_Period_Inc_Input>;
  _prepend?: InputMaybe<Event_Period_Prepend_Input>;
  _set?: InputMaybe<Event_Period_Set_Input>;
  pk_columns: Event_Period_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_ManyArgs = {
  updates: Array<Event_Period_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_ParticipantsArgs = {
  _inc?: InputMaybe<Event_Period_Participants_Inc_Input>;
  _set?: InputMaybe<Event_Period_Participants_Set_Input>;
  where: Event_Period_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Participants_By_PkArgs = {
  _inc?: InputMaybe<Event_Period_Participants_Inc_Input>;
  _set?: InputMaybe<Event_Period_Participants_Set_Input>;
  pk_columns: Event_Period_Participants_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Participants_ManyArgs = {
  updates: Array<Event_Period_Participants_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_ServicesArgs = {
  _inc?: InputMaybe<Event_Period_Services_Inc_Input>;
  _set?: InputMaybe<Event_Period_Services_Set_Input>;
  where: Event_Period_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Services_By_PkArgs = {
  _inc?: InputMaybe<Event_Period_Services_Inc_Input>;
  _set?: InputMaybe<Event_Period_Services_Set_Input>;
  pk_columns: Event_Period_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Services_ManyArgs = {
  updates: Array<Event_Period_Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_TherapistsArgs = {
  _inc?: InputMaybe<Event_Period_Therapists_Inc_Input>;
  _set?: InputMaybe<Event_Period_Therapists_Set_Input>;
  where: Event_Period_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Therapists_By_PkArgs = {
  _inc?: InputMaybe<Event_Period_Therapists_Inc_Input>;
  _set?: InputMaybe<Event_Period_Therapists_Set_Input>;
  pk_columns: Event_Period_Therapists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Period_Therapists_ManyArgs = {
  updates: Array<Event_Period_Therapists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ServicesArgs = {
  _inc?: InputMaybe<Event_Services_Inc_Input>;
  _set?: InputMaybe<Event_Services_Set_Input>;
  where: Event_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Services_By_PkArgs = {
  _inc?: InputMaybe<Event_Services_Inc_Input>;
  _set?: InputMaybe<Event_Services_Set_Input>;
  pk_columns: Event_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Services_ManyArgs = {
  updates: Array<Event_Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Event_TherapistsArgs = {
  _inc?: InputMaybe<Event_Therapists_Inc_Input>;
  _set?: InputMaybe<Event_Therapists_Set_Input>;
  where: Event_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Therapists_By_PkArgs = {
  _inc?: InputMaybe<Event_Therapists_Inc_Input>;
  _set?: InputMaybe<Event_Therapists_Set_Input>;
  pk_columns: Event_Therapists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Therapists_ManyArgs = {
  updates: Array<Event_Therapists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_GdprconfigArgs = {
  _inc?: InputMaybe<Gdpr_Gdprconfig_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprconfig_Set_Input>;
  where: Gdpr_Gdprconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprconfig_By_PkArgs = {
  _inc?: InputMaybe<Gdpr_Gdprconfig_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprconfig_Set_Input>;
  pk_columns: Gdpr_Gdprconfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprconfig_ManyArgs = {
  updates: Array<Gdpr_Gdprconfig_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_GdprdocumentArgs = {
  _inc?: InputMaybe<Gdpr_Gdprdocument_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprdocument_Set_Input>;
  where: Gdpr_Gdprdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprdocument_By_PkArgs = {
  _inc?: InputMaybe<Gdpr_Gdprdocument_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprdocument_Set_Input>;
  pk_columns: Gdpr_Gdprdocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprdocument_ManyArgs = {
  updates: Array<Gdpr_Gdprdocument_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_GdprdocumenttypeArgs = {
  _inc?: InputMaybe<Gdpr_Gdprdocumenttype_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprdocumenttype_Set_Input>;
  where: Gdpr_Gdprdocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprdocumenttype_By_PkArgs = {
  _inc?: InputMaybe<Gdpr_Gdprdocumenttype_Inc_Input>;
  _set?: InputMaybe<Gdpr_Gdprdocumenttype_Set_Input>;
  pk_columns: Gdpr_Gdprdocumenttype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Gdprdocumenttype_ManyArgs = {
  updates: Array<Gdpr_Gdprdocumenttype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_MembersArgs = {
  _inc?: InputMaybe<Group_Members_Inc_Input>;
  _set?: InputMaybe<Group_Members_Set_Input>;
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_By_PkArgs = {
  _inc?: InputMaybe<Group_Members_Inc_Input>;
  _set?: InputMaybe<Group_Members_Set_Input>;
  pk_columns: Group_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_ManyArgs = {
  updates: Array<Group_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _inc?: InputMaybe<Groups_Inc_Input>;
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _inc?: InputMaybe<Groups_Inc_Input>;
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Holiday_ReasonsArgs = {
  _inc?: InputMaybe<Holiday_Reasons_Inc_Input>;
  _set?: InputMaybe<Holiday_Reasons_Set_Input>;
  where: Holiday_Reasons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Holiday_Reasons_By_PkArgs = {
  _inc?: InputMaybe<Holiday_Reasons_Inc_Input>;
  _set?: InputMaybe<Holiday_Reasons_Set_Input>;
  pk_columns: Holiday_Reasons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Holiday_Reasons_ManyArgs = {
  updates: Array<Holiday_Reasons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_HolidaysArgs = {
  _inc?: InputMaybe<Holidays_Inc_Input>;
  _set?: InputMaybe<Holidays_Set_Input>;
  where: Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Holidays_By_PkArgs = {
  _inc?: InputMaybe<Holidays_Inc_Input>;
  _set?: InputMaybe<Holidays_Set_Input>;
  pk_columns: Holidays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Holidays_ManyArgs = {
  updates: Array<Holidays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_BillinginfoArgs = {
  _inc?: InputMaybe<Invoices_V2_Billinginfo_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Billinginfo_Set_Input>;
  where: Invoices_V2_Billinginfo_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Billinginfo_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Billinginfo_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Billinginfo_Set_Input>;
  pk_columns: Invoices_V2_Billinginfo_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Billinginfo_ManyArgs = {
  updates: Array<Invoices_V2_Billinginfo_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_InvoiceArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoice_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoice_Set_Input>;
  where: Invoices_V2_Invoice_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoice_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoice_Set_Input>;
  pk_columns: Invoices_V2_Invoice_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoice_ManyArgs = {
  updates: Array<Invoices_V2_Invoice_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_InvoiceconfigArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoiceconfig_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoiceconfig_Set_Input>;
  where: Invoices_V2_Invoiceconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoiceconfig_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoiceconfig_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoiceconfig_Set_Input>;
  pk_columns: Invoices_V2_Invoiceconfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoiceconfig_ManyArgs = {
  updates: Array<Invoices_V2_Invoiceconfig_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_InvoicelineitemArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Set_Input>;
  where: Invoices_V2_Invoicelineitem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicelineitem_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Set_Input>;
  pk_columns: Invoices_V2_Invoicelineitem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicelineitem_EventsArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Set_Input>;
  where: Invoices_V2_Invoicelineitem_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicelineitem_Events_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Set_Input>;
  pk_columns: Invoices_V2_Invoicelineitem_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicelineitem_Events_ManyArgs = {
  updates: Array<Invoices_V2_Invoicelineitem_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicelineitem_ManyArgs = {
  updates: Array<Invoices_V2_Invoicelineitem_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_InvoicesenttoArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicesentto_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicesentto_Set_Input>;
  where: Invoices_V2_Invoicesentto_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicesentto_By_PkArgs = {
  _inc?: InputMaybe<Invoices_V2_Invoicesentto_Inc_Input>;
  _set?: InputMaybe<Invoices_V2_Invoicesentto_Set_Input>;
  pk_columns: Invoices_V2_Invoicesentto_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Invoices_V2_Invoicesentto_ManyArgs = {
  updates: Array<Invoices_V2_Invoicesentto_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_National_HolidaysArgs = {
  _inc?: InputMaybe<National_Holidays_Inc_Input>;
  _set?: InputMaybe<National_Holidays_Set_Input>;
  where: National_Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_National_Holidays_By_PkArgs = {
  _inc?: InputMaybe<National_Holidays_Inc_Input>;
  _set?: InputMaybe<National_Holidays_Set_Input>;
  pk_columns: National_Holidays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_National_Holidays_ManyArgs = {
  updates: Array<National_Holidays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_AddressesArgs = {
  _inc?: InputMaybe<Patient_Addresses_Inc_Input>;
  _set?: InputMaybe<Patient_Addresses_Set_Input>;
  where: Patient_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Addresses_By_PkArgs = {
  _inc?: InputMaybe<Patient_Addresses_Inc_Input>;
  _set?: InputMaybe<Patient_Addresses_Set_Input>;
  pk_columns: Patient_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Addresses_ManyArgs = {
  updates: Array<Patient_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_EducatorArgs = {
  _inc?: InputMaybe<Patient_Educator_Inc_Input>;
  _set?: InputMaybe<Patient_Educator_Set_Input>;
  where: Patient_Educator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Educator_By_PkArgs = {
  _inc?: InputMaybe<Patient_Educator_Inc_Input>;
  _set?: InputMaybe<Patient_Educator_Set_Input>;
  pk_columns: Patient_Educator_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Educator_ManyArgs = {
  updates: Array<Patient_Educator_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Guardian_AddressesArgs = {
  _inc?: InputMaybe<Patient_Guardian_Addresses_Inc_Input>;
  _set?: InputMaybe<Patient_Guardian_Addresses_Set_Input>;
  where: Patient_Guardian_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Guardian_Addresses_By_PkArgs = {
  _inc?: InputMaybe<Patient_Guardian_Addresses_Inc_Input>;
  _set?: InputMaybe<Patient_Guardian_Addresses_Set_Input>;
  pk_columns: Patient_Guardian_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Guardian_Addresses_ManyArgs = {
  updates: Array<Patient_Guardian_Addresses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_GuardiansArgs = {
  _inc?: InputMaybe<Patient_Guardians_Inc_Input>;
  _set?: InputMaybe<Patient_Guardians_Set_Input>;
  where: Patient_Guardians_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Guardians_By_PkArgs = {
  _inc?: InputMaybe<Patient_Guardians_Inc_Input>;
  _set?: InputMaybe<Patient_Guardians_Set_Input>;
  pk_columns: Patient_Guardians_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Guardians_ManyArgs = {
  updates: Array<Patient_Guardians_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_PsychotherapiesArgs = {
  _inc?: InputMaybe<Patient_Psychotherapies_Inc_Input>;
  _set?: InputMaybe<Patient_Psychotherapies_Set_Input>;
  where: Patient_Psychotherapies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Psychotherapies_By_PkArgs = {
  _inc?: InputMaybe<Patient_Psychotherapies_Inc_Input>;
  _set?: InputMaybe<Patient_Psychotherapies_Set_Input>;
  pk_columns: Patient_Psychotherapies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Psychotherapies_ManyArgs = {
  updates: Array<Patient_Psychotherapies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_SiArgs = {
  _inc?: InputMaybe<Patient_Si_Inc_Input>;
  _set?: InputMaybe<Patient_Si_Set_Input>;
  where: Patient_Si_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Si_By_PkArgs = {
  _inc?: InputMaybe<Patient_Si_Inc_Input>;
  _set?: InputMaybe<Patient_Si_Set_Input>;
  pk_columns: Patient_Si_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Si_ManyArgs = {
  updates: Array<Patient_Si_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Speech_TherapiesArgs = {
  _inc?: InputMaybe<Patient_Speech_Therapies_Inc_Input>;
  _set?: InputMaybe<Patient_Speech_Therapies_Set_Input>;
  where: Patient_Speech_Therapies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Speech_Therapies_By_PkArgs = {
  _inc?: InputMaybe<Patient_Speech_Therapies_Inc_Input>;
  _set?: InputMaybe<Patient_Speech_Therapies_Set_Input>;
  pk_columns: Patient_Speech_Therapies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Speech_Therapies_ManyArgs = {
  updates: Array<Patient_Speech_Therapies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PatientsArgs = {
  _inc?: InputMaybe<Patients_Inc_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_By_PkArgs = {
  _inc?: InputMaybe<Patients_Inc_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  pk_columns: Patients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_ManyArgs = {
  updates: Array<Patients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_TherapistsArgs = {
  _inc?: InputMaybe<Patients_Therapists_Inc_Input>;
  _set?: InputMaybe<Patients_Therapists_Set_Input>;
  where: Patients_Therapists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_Therapists_By_PkArgs = {
  _inc?: InputMaybe<Patients_Therapists_Inc_Input>;
  _set?: InputMaybe<Patients_Therapists_Set_Input>;
  pk_columns: Patients_Therapists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_Therapists_ManyArgs = {
  updates: Array<Patients_Therapists_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_BranchesArgs = {
  _inc?: InputMaybe<Practice_Branches_Inc_Input>;
  _set?: InputMaybe<Practice_Branches_Set_Input>;
  where: Practice_Branches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_Branches_By_PkArgs = {
  _inc?: InputMaybe<Practice_Branches_Inc_Input>;
  _set?: InputMaybe<Practice_Branches_Set_Input>;
  pk_columns: Practice_Branches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_Branches_ManyArgs = {
  updates: Array<Practice_Branches_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_LogosArgs = {
  _inc?: InputMaybe<Practice_Logos_Inc_Input>;
  _set?: InputMaybe<Practice_Logos_Set_Input>;
  where: Practice_Logos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_Logos_By_PkArgs = {
  _inc?: InputMaybe<Practice_Logos_Inc_Input>;
  _set?: InputMaybe<Practice_Logos_Set_Input>;
  pk_columns: Practice_Logos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Practice_Logos_ManyArgs = {
  updates: Array<Practice_Logos_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PracticesArgs = {
  _inc?: InputMaybe<Practices_Inc_Input>;
  _set?: InputMaybe<Practices_Set_Input>;
  where: Practices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practices_By_PkArgs = {
  _inc?: InputMaybe<Practices_Inc_Input>;
  _set?: InputMaybe<Practices_Set_Input>;
  pk_columns: Practices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Practices_ManyArgs = {
  updates: Array<Practices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_AuthsmsconfirmationArgs = {
  _set?: InputMaybe<Registration_Authsmsconfirmation_Set_Input>;
  where: Registration_Authsmsconfirmation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Authsmsconfirmation_By_PkArgs = {
  _set?: InputMaybe<Registration_Authsmsconfirmation_Set_Input>;
  pk_columns: Registration_Authsmsconfirmation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Authsmsconfirmation_ManyArgs = {
  updates: Array<Registration_Authsmsconfirmation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_MeetingrequestArgs = {
  _inc?: InputMaybe<Registration_Meetingrequest_Inc_Input>;
  _set?: InputMaybe<Registration_Meetingrequest_Set_Input>;
  where: Registration_Meetingrequest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Meetingrequest_By_PkArgs = {
  _inc?: InputMaybe<Registration_Meetingrequest_Inc_Input>;
  _set?: InputMaybe<Registration_Meetingrequest_Set_Input>;
  pk_columns: Registration_Meetingrequest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Meetingrequest_ManyArgs = {
  updates: Array<Registration_Meetingrequest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_OnlineregistrationconfigArgs = {
  _inc?: InputMaybe<Registration_Onlineregistrationconfig_Inc_Input>;
  _set?: InputMaybe<Registration_Onlineregistrationconfig_Set_Input>;
  where: Registration_Onlineregistrationconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Onlineregistrationconfig_By_PkArgs = {
  _inc?: InputMaybe<Registration_Onlineregistrationconfig_Inc_Input>;
  _set?: InputMaybe<Registration_Onlineregistrationconfig_Set_Input>;
  pk_columns: Registration_Onlineregistrationconfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Registration_Onlineregistrationconfig_ManyArgs = {
  updates: Array<Registration_Onlineregistrationconfig_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Request_ConsiderationsArgs = {
  _inc?: InputMaybe<Request_Considerations_Inc_Input>;
  _set?: InputMaybe<Request_Considerations_Set_Input>;
  where: Request_Considerations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Request_Considerations_By_PkArgs = {
  _inc?: InputMaybe<Request_Considerations_Inc_Input>;
  _set?: InputMaybe<Request_Considerations_Set_Input>;
  pk_columns: Request_Considerations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Request_Considerations_ManyArgs = {
  updates: Array<Request_Considerations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoomsArgs = {
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  where: Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_By_PkArgs = {
  _inc?: InputMaybe<Rooms_Inc_Input>;
  _set?: InputMaybe<Rooms_Set_Input>;
  pk_columns: Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rooms_ManyArgs = {
  updates: Array<Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServicesArgs = {
  _inc?: InputMaybe<Services_Inc_Input>;
  _set?: InputMaybe<Services_Set_Input>;
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Assigned_ToArgs = {
  _inc?: InputMaybe<Services_Assigned_To_Inc_Input>;
  _set?: InputMaybe<Services_Assigned_To_Set_Input>;
  where: Services_Assigned_To_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Assigned_To_By_PkArgs = {
  _inc?: InputMaybe<Services_Assigned_To_Inc_Input>;
  _set?: InputMaybe<Services_Assigned_To_Set_Input>;
  pk_columns: Services_Assigned_To_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Assigned_To_ManyArgs = {
  updates: Array<Services_Assigned_To_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Services_By_PkArgs = {
  _inc?: InputMaybe<Services_Inc_Input>;
  _set?: InputMaybe<Services_Set_Input>;
  pk_columns: Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Services_ManyArgs = {
  updates: Array<Services_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Restricted_RoomsArgs = {
  _inc?: InputMaybe<Services_Restricted_Rooms_Inc_Input>;
  _set?: InputMaybe<Services_Restricted_Rooms_Set_Input>;
  where: Services_Restricted_Rooms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Restricted_Rooms_By_PkArgs = {
  _inc?: InputMaybe<Services_Restricted_Rooms_Inc_Input>;
  _set?: InputMaybe<Services_Restricted_Rooms_Set_Input>;
  pk_columns: Services_Restricted_Rooms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Services_Restricted_Rooms_ManyArgs = {
  updates: Array<Services_Restricted_Rooms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_DefaultsmstemplateArgs = {
  _inc?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Set_Input>;
  where: Sms_Notifications_Defaultsmstemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Defaultsmstemplate_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Set_Input>;
  pk_columns: Sms_Notifications_Defaultsmstemplate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Defaultsmstemplate_ManyArgs = {
  updates: Array<Sms_Notifications_Defaultsmstemplate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_SentsmsArgs = {
  _inc?: InputMaybe<Sms_Notifications_Sentsms_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Sentsms_Set_Input>;
  where: Sms_Notifications_Sentsms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Sentsms_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Sentsms_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Sentsms_Set_Input>;
  pk_columns: Sms_Notifications_Sentsms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Sentsms_ManyArgs = {
  updates: Array<Sms_Notifications_Sentsms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_SmsconfigArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smsconfig_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smsconfig_Set_Input>;
  where: Sms_Notifications_Smsconfig_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smsconfig_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smsconfig_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smsconfig_Set_Input>;
  pk_columns: Sms_Notifications_Smsconfig_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smsconfig_ManyArgs = {
  updates: Array<Sms_Notifications_Smsconfig_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_SmspacketpurchaseArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smspacketpurchase_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smspacketpurchase_Set_Input>;
  where: Sms_Notifications_Smspacketpurchase_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smspacketpurchase_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smspacketpurchase_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smspacketpurchase_Set_Input>;
  pk_columns: Sms_Notifications_Smspacketpurchase_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smspacketpurchase_ManyArgs = {
  updates: Array<Sms_Notifications_Smspacketpurchase_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_SmstemplateArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smstemplate_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smstemplate_Set_Input>;
  where: Sms_Notifications_Smstemplate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smstemplate_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smstemplate_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smstemplate_Set_Input>;
  pk_columns: Sms_Notifications_Smstemplate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smstemplate_ManyArgs = {
  updates: Array<Sms_Notifications_Smstemplate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_SmstemplatevariableArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smstemplatevariable_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smstemplatevariable_Set_Input>;
  where: Sms_Notifications_Smstemplatevariable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smstemplatevariable_By_PkArgs = {
  _inc?: InputMaybe<Sms_Notifications_Smstemplatevariable_Inc_Input>;
  _set?: InputMaybe<Sms_Notifications_Smstemplatevariable_Set_Input>;
  pk_columns: Sms_Notifications_Smstemplatevariable_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Notifications_Smstemplatevariable_ManyArgs = {
  updates: Array<Sms_Notifications_Smstemplatevariable_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_GroupsArgs = {
  _inc?: InputMaybe<User_Groups_Inc_Input>;
  _set?: InputMaybe<User_Groups_Set_Input>;
  where: User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Groups_By_PkArgs = {
  _inc?: InputMaybe<User_Groups_Inc_Input>;
  _set?: InputMaybe<User_Groups_Set_Input>;
  pk_columns: User_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Groups_ManyArgs = {
  updates: Array<User_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_IdentityArgs = {
  _set?: InputMaybe<User_Identity_Set_Input>;
  where: User_Identity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Identity_By_PkArgs = {
  _set?: InputMaybe<User_Identity_Set_Input>;
  pk_columns: User_Identity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Identity_ManyArgs = {
  updates: Array<User_Identity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_User_PermissionsArgs = {
  _inc?: InputMaybe<User_User_Permissions_Inc_Input>;
  _set?: InputMaybe<User_User_Permissions_Set_Input>;
  where: User_User_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_User_Permissions_By_PkArgs = {
  _inc?: InputMaybe<User_User_Permissions_Inc_Input>;
  _set?: InputMaybe<User_User_Permissions_Set_Input>;
  pk_columns: User_User_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_User_Permissions_ManyArgs = {
  updates: Array<User_User_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkdaysArgs = {
  _inc?: InputMaybe<Workdays_Inc_Input>;
  _set?: InputMaybe<Workdays_Set_Input>;
  where: Workdays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workdays_By_PkArgs = {
  _inc?: InputMaybe<Workdays_Inc_Input>;
  _set?: InputMaybe<Workdays_Set_Input>;
  pk_columns: Workdays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workdays_ManyArgs = {
  updates: Array<Workdays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_ConfigsArgs = {
  _inc?: InputMaybe<Worktime_Configs_Inc_Input>;
  _set?: InputMaybe<Worktime_Configs_Set_Input>;
  where: Worktime_Configs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Configs_By_PkArgs = {
  _inc?: InputMaybe<Worktime_Configs_Inc_Input>;
  _set?: InputMaybe<Worktime_Configs_Set_Input>;
  pk_columns: Worktime_Configs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Configs_ManyArgs = {
  updates: Array<Worktime_Configs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_ExceptionsArgs = {
  _inc?: InputMaybe<Worktime_Exceptions_Inc_Input>;
  _set?: InputMaybe<Worktime_Exceptions_Set_Input>;
  where: Worktime_Exceptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Exceptions_By_PkArgs = {
  _inc?: InputMaybe<Worktime_Exceptions_Inc_Input>;
  _set?: InputMaybe<Worktime_Exceptions_Set_Input>;
  pk_columns: Worktime_Exceptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Exceptions_ManyArgs = {
  updates: Array<Worktime_Exceptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Exceptions_Work_TimesArgs = {
  _inc?: InputMaybe<Worktime_Exceptions_Work_Times_Inc_Input>;
  _set?: InputMaybe<Worktime_Exceptions_Work_Times_Set_Input>;
  where: Worktime_Exceptions_Work_Times_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Exceptions_Work_Times_By_PkArgs = {
  _inc?: InputMaybe<Worktime_Exceptions_Work_Times_Inc_Input>;
  _set?: InputMaybe<Worktime_Exceptions_Work_Times_Set_Input>;
  pk_columns: Worktime_Exceptions_Work_Times_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Exceptions_Work_Times_ManyArgs = {
  updates: Array<Worktime_Exceptions_Work_Times_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_PresetsArgs = {
  _inc?: InputMaybe<Worktime_Presets_Inc_Input>;
  _set?: InputMaybe<Worktime_Presets_Set_Input>;
  where: Worktime_Presets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Presets_By_PkArgs = {
  _inc?: InputMaybe<Worktime_Presets_Inc_Input>;
  _set?: InputMaybe<Worktime_Presets_Set_Input>;
  pk_columns: Worktime_Presets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Worktime_Presets_ManyArgs = {
  updates: Array<Worktime_Presets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorktimesArgs = {
  _inc?: InputMaybe<Worktimes_Inc_Input>;
  _set?: InputMaybe<Worktimes_Set_Input>;
  where: Worktimes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Worktimes_By_PkArgs = {
  _inc?: InputMaybe<Worktimes_Inc_Input>;
  _set?: InputMaybe<Worktimes_Set_Input>;
  pk_columns: Worktimes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Worktimes_ManyArgs = {
  updates: Array<Worktimes_Updates>;
};

/** columns and relationships of "national_holidays" */
export type National_Holidays = {
  __typename?: 'national_holidays';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end: Scalars['timestamptz'];
  /** An object relationship */
  holiday_reason: Holiday_Reasons;
  id: Scalars['bigint'];
  reason_id: Scalars['bigint'];
  start: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "national_holidays" */
export type National_Holidays_Aggregate = {
  __typename?: 'national_holidays_aggregate';
  aggregate?: Maybe<National_Holidays_Aggregate_Fields>;
  nodes: Array<National_Holidays>;
};

export type National_Holidays_Aggregate_Bool_Exp = {
  count?: InputMaybe<National_Holidays_Aggregate_Bool_Exp_Count>;
};

export type National_Holidays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<National_Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<National_Holidays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "national_holidays" */
export type National_Holidays_Aggregate_Fields = {
  __typename?: 'national_holidays_aggregate_fields';
  avg?: Maybe<National_Holidays_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<National_Holidays_Max_Fields>;
  min?: Maybe<National_Holidays_Min_Fields>;
  stddev?: Maybe<National_Holidays_Stddev_Fields>;
  stddev_pop?: Maybe<National_Holidays_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<National_Holidays_Stddev_Samp_Fields>;
  sum?: Maybe<National_Holidays_Sum_Fields>;
  var_pop?: Maybe<National_Holidays_Var_Pop_Fields>;
  var_samp?: Maybe<National_Holidays_Var_Samp_Fields>;
  variance?: Maybe<National_Holidays_Variance_Fields>;
};


/** aggregate fields of "national_holidays" */
export type National_Holidays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<National_Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "national_holidays" */
export type National_Holidays_Aggregate_Order_By = {
  avg?: InputMaybe<National_Holidays_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<National_Holidays_Max_Order_By>;
  min?: InputMaybe<National_Holidays_Min_Order_By>;
  stddev?: InputMaybe<National_Holidays_Stddev_Order_By>;
  stddev_pop?: InputMaybe<National_Holidays_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<National_Holidays_Stddev_Samp_Order_By>;
  sum?: InputMaybe<National_Holidays_Sum_Order_By>;
  var_pop?: InputMaybe<National_Holidays_Var_Pop_Order_By>;
  var_samp?: InputMaybe<National_Holidays_Var_Samp_Order_By>;
  variance?: InputMaybe<National_Holidays_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "national_holidays" */
export type National_Holidays_Arr_Rel_Insert_Input = {
  data: Array<National_Holidays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<National_Holidays_On_Conflict>;
};

/** aggregate avg on columns */
export type National_Holidays_Avg_Fields = {
  __typename?: 'national_holidays_avg_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "national_holidays" */
export type National_Holidays_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "national_holidays". All fields are combined with a logical 'AND'. */
export type National_Holidays_Bool_Exp = {
  _and?: InputMaybe<Array<National_Holidays_Bool_Exp>>;
  _not?: InputMaybe<National_Holidays_Bool_Exp>;
  _or?: InputMaybe<Array<National_Holidays_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end?: InputMaybe<Timestamptz_Comparison_Exp>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reason_id?: InputMaybe<Bigint_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "national_holidays" */
export enum National_Holidays_Constraint {
  /** unique or primary key constraint on columns "id" */
  NationalHolidaysPkey = 'national_holidays_pkey'
}

/** input type for incrementing numeric columns in table "national_holidays" */
export type National_Holidays_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "national_holidays" */
export type National_Holidays_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type National_Holidays_Max_Fields = {
  __typename?: 'national_holidays_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "national_holidays" */
export type National_Holidays_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type National_Holidays_Min_Fields = {
  __typename?: 'national_holidays_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "national_holidays" */
export type National_Holidays_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "national_holidays" */
export type National_Holidays_Mutation_Response = {
  __typename?: 'national_holidays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<National_Holidays>;
};

/** on_conflict condition type for table "national_holidays" */
export type National_Holidays_On_Conflict = {
  constraint: National_Holidays_Constraint;
  update_columns?: Array<National_Holidays_Update_Column>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};

/** Ordering options when selecting data from "national_holidays". */
export type National_Holidays_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end?: InputMaybe<Order_By>;
  holiday_reason?: InputMaybe<Holiday_Reasons_Order_By>;
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: national_holidays */
export type National_Holidays_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "national_holidays" */
export enum National_Holidays_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  ReasonId = 'reason_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "national_holidays" */
export type National_Holidays_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type National_Holidays_Stddev_Fields = {
  __typename?: 'national_holidays_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "national_holidays" */
export type National_Holidays_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type National_Holidays_Stddev_Pop_Fields = {
  __typename?: 'national_holidays_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "national_holidays" */
export type National_Holidays_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type National_Holidays_Stddev_Samp_Fields = {
  __typename?: 'national_holidays_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "national_holidays" */
export type National_Holidays_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "national_holidays" */
export type National_Holidays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: National_Holidays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type National_Holidays_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  reason_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type National_Holidays_Sum_Fields = {
  __typename?: 'national_holidays_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  reason_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "national_holidays" */
export type National_Holidays_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** update columns of table "national_holidays" */
export enum National_Holidays_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  ReasonId = 'reason_id',
  /** column name */
  Start = 'start',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type National_Holidays_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<National_Holidays_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<National_Holidays_Set_Input>;
  /** filter the rows which have to be updated */
  where: National_Holidays_Bool_Exp;
};

/** aggregate var_pop on columns */
export type National_Holidays_Var_Pop_Fields = {
  __typename?: 'national_holidays_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "national_holidays" */
export type National_Holidays_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type National_Holidays_Var_Samp_Fields = {
  __typename?: 'national_holidays_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "national_holidays" */
export type National_Holidays_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type National_Holidays_Variance_Fields = {
  __typename?: 'national_holidays_variance_fields';
  id?: Maybe<Scalars['Float']>;
  reason_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "national_holidays" */
export type National_Holidays_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reason_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "patient_addresses" */
export type Patient_Addresses = {
  __typename?: 'patient_addresses';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  street?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_addresses" */
export type Patient_Addresses_Aggregate = {
  __typename?: 'patient_addresses_aggregate';
  aggregate?: Maybe<Patient_Addresses_Aggregate_Fields>;
  nodes: Array<Patient_Addresses>;
};

/** aggregate fields of "patient_addresses" */
export type Patient_Addresses_Aggregate_Fields = {
  __typename?: 'patient_addresses_aggregate_fields';
  avg?: Maybe<Patient_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Addresses_Max_Fields>;
  min?: Maybe<Patient_Addresses_Min_Fields>;
  stddev?: Maybe<Patient_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Addresses_Sum_Fields>;
  var_pop?: Maybe<Patient_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Patient_Addresses_Variance_Fields>;
};


/** aggregate fields of "patient_addresses" */
export type Patient_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Addresses_Avg_Fields = {
  __typename?: 'patient_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_addresses". All fields are combined with a logical 'AND'. */
export type Patient_Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Patient_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Addresses_Bool_Exp>>;
  apartment_number?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  county?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  house_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_addresses" */
export enum Patient_Addresses_Constraint {
  /** unique or primary key constraint on columns "patient_id" */
  PatientAddressesPatientIdKey = 'patient_addresses_patient_id_key',
  /** unique or primary key constraint on columns "id" */
  PatientAddressesPkey = 'patient_addresses_pkey'
}

/** input type for incrementing numeric columns in table "patient_addresses" */
export type Patient_Addresses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_addresses" */
export type Patient_Addresses_Insert_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patient_Addresses_Max_Fields = {
  __typename?: 'patient_addresses_max_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Addresses_Min_Fields = {
  __typename?: 'patient_addresses_min_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "patient_addresses" */
export type Patient_Addresses_Mutation_Response = {
  __typename?: 'patient_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Addresses>;
};

/** input type for inserting object relation for remote table "patient_addresses" */
export type Patient_Addresses_Obj_Rel_Insert_Input = {
  data: Patient_Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Addresses_On_Conflict>;
};

/** on_conflict condition type for table "patient_addresses" */
export type Patient_Addresses_On_Conflict = {
  constraint: Patient_Addresses_Constraint;
  update_columns?: Array<Patient_Addresses_Update_Column>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_addresses". */
export type Patient_Addresses_Order_By = {
  apartment_number?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  house_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_addresses */
export type Patient_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "patient_addresses" */
export enum Patient_Addresses_Select_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "patient_addresses" */
export type Patient_Addresses_Set_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Patient_Addresses_Stddev_Fields = {
  __typename?: 'patient_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Addresses_Stddev_Pop_Fields = {
  __typename?: 'patient_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Addresses_Stddev_Samp_Fields = {
  __typename?: 'patient_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_addresses" */
export type Patient_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Addresses_Stream_Cursor_Value_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Patient_Addresses_Sum_Fields = {
  __typename?: 'patient_addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_addresses" */
export enum Patient_Addresses_Update_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

export type Patient_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Addresses_Var_Pop_Fields = {
  __typename?: 'patient_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Addresses_Var_Samp_Fields = {
  __typename?: 'patient_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Addresses_Variance_Fields = {
  __typename?: 'patient_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_educator" */
export type Patient_Educator = {
  __typename?: 'patient_educator';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_educator" */
export type Patient_Educator_Aggregate = {
  __typename?: 'patient_educator_aggregate';
  aggregate?: Maybe<Patient_Educator_Aggregate_Fields>;
  nodes: Array<Patient_Educator>;
};

/** aggregate fields of "patient_educator" */
export type Patient_Educator_Aggregate_Fields = {
  __typename?: 'patient_educator_aggregate_fields';
  avg?: Maybe<Patient_Educator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Educator_Max_Fields>;
  min?: Maybe<Patient_Educator_Min_Fields>;
  stddev?: Maybe<Patient_Educator_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Educator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Educator_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Educator_Sum_Fields>;
  var_pop?: Maybe<Patient_Educator_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Educator_Var_Samp_Fields>;
  variance?: Maybe<Patient_Educator_Variance_Fields>;
};


/** aggregate fields of "patient_educator" */
export type Patient_Educator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Educator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Educator_Avg_Fields = {
  __typename?: 'patient_educator_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_educator". All fields are combined with a logical 'AND'. */
export type Patient_Educator_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Educator_Bool_Exp>>;
  _not?: InputMaybe<Patient_Educator_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Educator_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  diagnosis_therapy_plan?: InputMaybe<String_Comparison_Exp>;
  general_aim?: InputMaybe<String_Comparison_Exp>;
  general_info?: InputMaybe<String_Comparison_Exp>;
  medical_history?: InputMaybe<String_Comparison_Exp>;
  other_notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_educator" */
export enum Patient_Educator_Constraint {
  /** unique or primary key constraint on columns "patient_id" */
  PatientEducatorPkey = 'patient_educator_pkey'
}

/** input type for incrementing numeric columns in table "patient_educator" */
export type Patient_Educator_Inc_Input = {
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_educator" */
export type Patient_Educator_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Educator_Max_Fields = {
  __typename?: 'patient_educator_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Patient_Educator_Min_Fields = {
  __typename?: 'patient_educator_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "patient_educator" */
export type Patient_Educator_Mutation_Response = {
  __typename?: 'patient_educator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Educator>;
};

/** input type for inserting object relation for remote table "patient_educator" */
export type Patient_Educator_Obj_Rel_Insert_Input = {
  data: Patient_Educator_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Educator_On_Conflict>;
};

/** on_conflict condition type for table "patient_educator" */
export type Patient_Educator_On_Conflict = {
  constraint: Patient_Educator_Constraint;
  update_columns?: Array<Patient_Educator_Update_Column>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_educator". */
export type Patient_Educator_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  diagnosis_therapy_plan?: InputMaybe<Order_By>;
  general_aim?: InputMaybe<Order_By>;
  general_info?: InputMaybe<Order_By>;
  medical_history?: InputMaybe<Order_By>;
  other_notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_educator */
export type Patient_Educator_Pk_Columns_Input = {
  patient_id: Scalars['bigint'];
};

/** select columns of table "patient_educator" */
export enum Patient_Educator_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_educator" */
export type Patient_Educator_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Educator_Stddev_Fields = {
  __typename?: 'patient_educator_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Educator_Stddev_Pop_Fields = {
  __typename?: 'patient_educator_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Educator_Stddev_Samp_Fields = {
  __typename?: 'patient_educator_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_educator" */
export type Patient_Educator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Educator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Educator_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Educator_Sum_Fields = {
  __typename?: 'patient_educator_sum_fields';
  patient_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_educator" */
export enum Patient_Educator_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patient_Educator_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Educator_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Educator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Educator_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Educator_Var_Pop_Fields = {
  __typename?: 'patient_educator_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Educator_Var_Samp_Fields = {
  __typename?: 'patient_educator_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Educator_Variance_Fields = {
  __typename?: 'patient_educator_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_guardian_addresses" */
export type Patient_Guardian_Addresses = {
  __typename?: 'patient_guardian_addresses';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  patient_guardian: Patient_Guardians;
  patient_guardian_id: Scalars['bigint'];
  street?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Aggregate = {
  __typename?: 'patient_guardian_addresses_aggregate';
  aggregate?: Maybe<Patient_Guardian_Addresses_Aggregate_Fields>;
  nodes: Array<Patient_Guardian_Addresses>;
};

/** aggregate fields of "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Aggregate_Fields = {
  __typename?: 'patient_guardian_addresses_aggregate_fields';
  avg?: Maybe<Patient_Guardian_Addresses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Guardian_Addresses_Max_Fields>;
  min?: Maybe<Patient_Guardian_Addresses_Min_Fields>;
  stddev?: Maybe<Patient_Guardian_Addresses_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Guardian_Addresses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Guardian_Addresses_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Guardian_Addresses_Sum_Fields>;
  var_pop?: Maybe<Patient_Guardian_Addresses_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Guardian_Addresses_Var_Samp_Fields>;
  variance?: Maybe<Patient_Guardian_Addresses_Variance_Fields>;
};


/** aggregate fields of "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Guardian_Addresses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Guardian_Addresses_Avg_Fields = {
  __typename?: 'patient_guardian_addresses_avg_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_guardian_addresses". All fields are combined with a logical 'AND'. */
export type Patient_Guardian_Addresses_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Guardian_Addresses_Bool_Exp>>;
  _not?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Guardian_Addresses_Bool_Exp>>;
  apartment_number?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  county?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  house_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient_guardian?: InputMaybe<Patient_Guardians_Bool_Exp>;
  patient_guardian_id?: InputMaybe<Bigint_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_guardian_addresses" */
export enum Patient_Guardian_Addresses_Constraint {
  /** unique or primary key constraint on columns "patient_guardian_id" */
  PatientGuardianAddressesPatientGuardianIdKey = 'patient_guardian_addresses_patient_guardian_id_key',
  /** unique or primary key constraint on columns "id" */
  PatientGuardianAddressesPkey = 'patient_guardian_addresses_pkey'
}

/** input type for incrementing numeric columns in table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  patient_guardian_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Insert_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_guardian?: InputMaybe<Patient_Guardians_Obj_Rel_Insert_Input>;
  patient_guardian_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patient_Guardian_Addresses_Max_Fields = {
  __typename?: 'patient_guardian_addresses_max_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  patient_guardian_id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Patient_Guardian_Addresses_Min_Fields = {
  __typename?: 'patient_guardian_addresses_min_fields';
  apartment_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  house_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  patient_guardian_id?: Maybe<Scalars['bigint']>;
  street?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Mutation_Response = {
  __typename?: 'patient_guardian_addresses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Guardian_Addresses>;
};

/** input type for inserting object relation for remote table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Obj_Rel_Insert_Input = {
  data: Patient_Guardian_Addresses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Guardian_Addresses_On_Conflict>;
};

/** on_conflict condition type for table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_On_Conflict = {
  constraint: Patient_Guardian_Addresses_Constraint;
  update_columns?: Array<Patient_Guardian_Addresses_Update_Column>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_guardian_addresses". */
export type Patient_Guardian_Addresses_Order_By = {
  apartment_number?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  county?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  house_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_guardian?: InputMaybe<Patient_Guardians_Order_By>;
  patient_guardian_id?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_guardian_addresses */
export type Patient_Guardian_Addresses_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "patient_guardian_addresses" */
export enum Patient_Guardian_Addresses_Select_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientGuardianId = 'patient_guardian_id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Set_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_guardian_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Patient_Guardian_Addresses_Stddev_Fields = {
  __typename?: 'patient_guardian_addresses_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Guardian_Addresses_Stddev_Pop_Fields = {
  __typename?: 'patient_guardian_addresses_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Guardian_Addresses_Stddev_Samp_Fields = {
  __typename?: 'patient_guardian_addresses_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_guardian_addresses" */
export type Patient_Guardian_Addresses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Guardian_Addresses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Guardian_Addresses_Stream_Cursor_Value_Input = {
  apartment_number?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  house_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_guardian_id?: InputMaybe<Scalars['bigint']>;
  street?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Patient_Guardian_Addresses_Sum_Fields = {
  __typename?: 'patient_guardian_addresses_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  patient_guardian_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_guardian_addresses" */
export enum Patient_Guardian_Addresses_Update_Column {
  /** column name */
  ApartmentNumber = 'apartment_number',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  County = 'county',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HouseNumber = 'house_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientGuardianId = 'patient_guardian_id',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

export type Patient_Guardian_Addresses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Guardian_Addresses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Guardian_Addresses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Guardian_Addresses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Guardian_Addresses_Var_Pop_Fields = {
  __typename?: 'patient_guardian_addresses_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Guardian_Addresses_Var_Samp_Fields = {
  __typename?: 'patient_guardian_addresses_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Guardian_Addresses_Variance_Fields = {
  __typename?: 'patient_guardian_addresses_variance_fields';
  id?: Maybe<Scalars['Float']>;
  patient_guardian_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_guardians" */
export type Patient_Guardians = {
  __typename?: 'patient_guardians';
  /** An object relationship */
  address?: Maybe<Patient_Guardian_Addresses>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  phone_number?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_guardians" */
export type Patient_Guardians_Aggregate = {
  __typename?: 'patient_guardians_aggregate';
  aggregate?: Maybe<Patient_Guardians_Aggregate_Fields>;
  nodes: Array<Patient_Guardians>;
};

export type Patient_Guardians_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Guardians_Aggregate_Bool_Exp_Count>;
};

export type Patient_Guardians_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Guardians_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patient_guardians" */
export type Patient_Guardians_Aggregate_Fields = {
  __typename?: 'patient_guardians_aggregate_fields';
  avg?: Maybe<Patient_Guardians_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Guardians_Max_Fields>;
  min?: Maybe<Patient_Guardians_Min_Fields>;
  stddev?: Maybe<Patient_Guardians_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Guardians_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Guardians_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Guardians_Sum_Fields>;
  var_pop?: Maybe<Patient_Guardians_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Guardians_Var_Samp_Fields>;
  variance?: Maybe<Patient_Guardians_Variance_Fields>;
};


/** aggregate fields of "patient_guardians" */
export type Patient_Guardians_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_guardians" */
export type Patient_Guardians_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Guardians_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Guardians_Max_Order_By>;
  min?: InputMaybe<Patient_Guardians_Min_Order_By>;
  stddev?: InputMaybe<Patient_Guardians_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Guardians_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Guardians_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Guardians_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Guardians_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Guardians_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Guardians_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patient_guardians" */
export type Patient_Guardians_Arr_Rel_Insert_Input = {
  data: Array<Patient_Guardians_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Guardians_On_Conflict>;
};

/** aggregate avg on columns */
export type Patient_Guardians_Avg_Fields = {
  __typename?: 'patient_guardians_avg_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patient_guardians" */
export type Patient_Guardians_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_guardians". All fields are combined with a logical 'AND'. */
export type Patient_Guardians_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Guardians_Bool_Exp>>;
  _not?: InputMaybe<Patient_Guardians_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Guardians_Bool_Exp>>;
  address?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_guardians" */
export enum Patient_Guardians_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientGuardiansPkey = 'patient_guardians_pkey'
}

/** input type for incrementing numeric columns in table "patient_guardians" */
export type Patient_Guardians_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_guardians" */
export type Patient_Guardians_Insert_Input = {
  address?: InputMaybe<Patient_Guardian_Addresses_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Guardians_Max_Fields = {
  __typename?: 'patient_guardians_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patient_guardians" */
export type Patient_Guardians_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Guardians_Min_Fields = {
  __typename?: 'patient_guardians_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patient_guardians" */
export type Patient_Guardians_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patient_guardians" */
export type Patient_Guardians_Mutation_Response = {
  __typename?: 'patient_guardians_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Guardians>;
};

/** input type for inserting object relation for remote table "patient_guardians" */
export type Patient_Guardians_Obj_Rel_Insert_Input = {
  data: Patient_Guardians_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Guardians_On_Conflict>;
};

/** on_conflict condition type for table "patient_guardians" */
export type Patient_Guardians_On_Conflict = {
  constraint: Patient_Guardians_Constraint;
  update_columns?: Array<Patient_Guardians_Update_Column>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_guardians". */
export type Patient_Guardians_Order_By = {
  address?: InputMaybe<Patient_Guardian_Addresses_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_guardians */
export type Patient_Guardians_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "patient_guardians" */
export enum Patient_Guardians_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_guardians" */
export type Patient_Guardians_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Guardians_Stddev_Fields = {
  __typename?: 'patient_guardians_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patient_guardians" */
export type Patient_Guardians_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patient_Guardians_Stddev_Pop_Fields = {
  __typename?: 'patient_guardians_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patient_guardians" */
export type Patient_Guardians_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patient_Guardians_Stddev_Samp_Fields = {
  __typename?: 'patient_guardians_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patient_guardians" */
export type Patient_Guardians_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient_guardians" */
export type Patient_Guardians_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Guardians_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Guardians_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Guardians_Sum_Fields = {
  __typename?: 'patient_guardians_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "patient_guardians" */
export type Patient_Guardians_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "patient_guardians" */
export enum Patient_Guardians_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patient_Guardians_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Guardians_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Guardians_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Guardians_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Guardians_Var_Pop_Fields = {
  __typename?: 'patient_guardians_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patient_guardians" */
export type Patient_Guardians_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patient_Guardians_Var_Samp_Fields = {
  __typename?: 'patient_guardians_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patient_guardians" */
export type Patient_Guardians_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Patient_Guardians_Variance_Fields = {
  __typename?: 'patient_guardians_variance_fields';
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patient_guardians" */
export type Patient_Guardians_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "patient_psychotherapies" */
export type Patient_Psychotherapies = {
  __typename?: 'patient_psychotherapies';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis?: Maybe<Scalars['String']>;
  expectations?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  trigger_for_therapy?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_psychotherapies" */
export type Patient_Psychotherapies_Aggregate = {
  __typename?: 'patient_psychotherapies_aggregate';
  aggregate?: Maybe<Patient_Psychotherapies_Aggregate_Fields>;
  nodes: Array<Patient_Psychotherapies>;
};

/** aggregate fields of "patient_psychotherapies" */
export type Patient_Psychotherapies_Aggregate_Fields = {
  __typename?: 'patient_psychotherapies_aggregate_fields';
  avg?: Maybe<Patient_Psychotherapies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Psychotherapies_Max_Fields>;
  min?: Maybe<Patient_Psychotherapies_Min_Fields>;
  stddev?: Maybe<Patient_Psychotherapies_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Psychotherapies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Psychotherapies_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Psychotherapies_Sum_Fields>;
  var_pop?: Maybe<Patient_Psychotherapies_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Psychotherapies_Var_Samp_Fields>;
  variance?: Maybe<Patient_Psychotherapies_Variance_Fields>;
};


/** aggregate fields of "patient_psychotherapies" */
export type Patient_Psychotherapies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Psychotherapies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Psychotherapies_Avg_Fields = {
  __typename?: 'patient_psychotherapies_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_psychotherapies". All fields are combined with a logical 'AND'. */
export type Patient_Psychotherapies_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Psychotherapies_Bool_Exp>>;
  _not?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Psychotherapies_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  diagnosis?: InputMaybe<String_Comparison_Exp>;
  expectations?: InputMaybe<String_Comparison_Exp>;
  medical_history?: InputMaybe<String_Comparison_Exp>;
  occupation?: InputMaybe<String_Comparison_Exp>;
  other_notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  trigger_for_therapy?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_psychotherapies" */
export enum Patient_Psychotherapies_Constraint {
  /** unique or primary key constraint on columns "patient_id" */
  PatientPsychotherapiesPkey = 'patient_psychotherapies_pkey'
}

/** input type for incrementing numeric columns in table "patient_psychotherapies" */
export type Patient_Psychotherapies_Inc_Input = {
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_psychotherapies" */
export type Patient_Psychotherapies_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis?: InputMaybe<Scalars['String']>;
  expectations?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  trigger_for_therapy?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Psychotherapies_Max_Fields = {
  __typename?: 'patient_psychotherapies_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis?: Maybe<Scalars['String']>;
  expectations?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  trigger_for_therapy?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Patient_Psychotherapies_Min_Fields = {
  __typename?: 'patient_psychotherapies_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis?: Maybe<Scalars['String']>;
  expectations?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  trigger_for_therapy?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "patient_psychotherapies" */
export type Patient_Psychotherapies_Mutation_Response = {
  __typename?: 'patient_psychotherapies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Psychotherapies>;
};

/** input type for inserting object relation for remote table "patient_psychotherapies" */
export type Patient_Psychotherapies_Obj_Rel_Insert_Input = {
  data: Patient_Psychotherapies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Psychotherapies_On_Conflict>;
};

/** on_conflict condition type for table "patient_psychotherapies" */
export type Patient_Psychotherapies_On_Conflict = {
  constraint: Patient_Psychotherapies_Constraint;
  update_columns?: Array<Patient_Psychotherapies_Update_Column>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_psychotherapies". */
export type Patient_Psychotherapies_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  diagnosis?: InputMaybe<Order_By>;
  expectations?: InputMaybe<Order_By>;
  medical_history?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  other_notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  trigger_for_therapy?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_psychotherapies */
export type Patient_Psychotherapies_Pk_Columns_Input = {
  patient_id: Scalars['bigint'];
};

/** select columns of table "patient_psychotherapies" */
export enum Patient_Psychotherapies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Diagnosis = 'diagnosis',
  /** column name */
  Expectations = 'expectations',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  TriggerForTherapy = 'trigger_for_therapy',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_psychotherapies" */
export type Patient_Psychotherapies_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis?: InputMaybe<Scalars['String']>;
  expectations?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  trigger_for_therapy?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Psychotherapies_Stddev_Fields = {
  __typename?: 'patient_psychotherapies_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Psychotherapies_Stddev_Pop_Fields = {
  __typename?: 'patient_psychotherapies_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Psychotherapies_Stddev_Samp_Fields = {
  __typename?: 'patient_psychotherapies_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_psychotherapies" */
export type Patient_Psychotherapies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Psychotherapies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Psychotherapies_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis?: InputMaybe<Scalars['String']>;
  expectations?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  trigger_for_therapy?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Psychotherapies_Sum_Fields = {
  __typename?: 'patient_psychotherapies_sum_fields';
  patient_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_psychotherapies" */
export enum Patient_Psychotherapies_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Diagnosis = 'diagnosis',
  /** column name */
  Expectations = 'expectations',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  TriggerForTherapy = 'trigger_for_therapy',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patient_Psychotherapies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Psychotherapies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Psychotherapies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Psychotherapies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Psychotherapies_Var_Pop_Fields = {
  __typename?: 'patient_psychotherapies_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Psychotherapies_Var_Samp_Fields = {
  __typename?: 'patient_psychotherapies_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Psychotherapies_Variance_Fields = {
  __typename?: 'patient_psychotherapies_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_si" */
export type Patient_Si = {
  __typename?: 'patient_si';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  sensoric_diet?: Maybe<Scalars['String']>;
  si_diagnosis?: Maybe<Scalars['String']>;
  therapy_hints?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_si" */
export type Patient_Si_Aggregate = {
  __typename?: 'patient_si_aggregate';
  aggregate?: Maybe<Patient_Si_Aggregate_Fields>;
  nodes: Array<Patient_Si>;
};

/** aggregate fields of "patient_si" */
export type Patient_Si_Aggregate_Fields = {
  __typename?: 'patient_si_aggregate_fields';
  avg?: Maybe<Patient_Si_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Si_Max_Fields>;
  min?: Maybe<Patient_Si_Min_Fields>;
  stddev?: Maybe<Patient_Si_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Si_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Si_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Si_Sum_Fields>;
  var_pop?: Maybe<Patient_Si_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Si_Var_Samp_Fields>;
  variance?: Maybe<Patient_Si_Variance_Fields>;
};


/** aggregate fields of "patient_si" */
export type Patient_Si_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Si_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Si_Avg_Fields = {
  __typename?: 'patient_si_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_si". All fields are combined with a logical 'AND'. */
export type Patient_Si_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Si_Bool_Exp>>;
  _not?: InputMaybe<Patient_Si_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Si_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  diagnosis_therapy_plan?: InputMaybe<String_Comparison_Exp>;
  general_aim?: InputMaybe<String_Comparison_Exp>;
  general_info?: InputMaybe<String_Comparison_Exp>;
  medical_history?: InputMaybe<String_Comparison_Exp>;
  other_notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  sensoric_diet?: InputMaybe<String_Comparison_Exp>;
  si_diagnosis?: InputMaybe<String_Comparison_Exp>;
  therapy_hints?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_si" */
export enum Patient_Si_Constraint {
  /** unique or primary key constraint on columns "patient_id" */
  PatientSiPkey = 'patient_si_pkey'
}

/** input type for incrementing numeric columns in table "patient_si" */
export type Patient_Si_Inc_Input = {
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_si" */
export type Patient_Si_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  sensoric_diet?: InputMaybe<Scalars['String']>;
  si_diagnosis?: InputMaybe<Scalars['String']>;
  therapy_hints?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Si_Max_Fields = {
  __typename?: 'patient_si_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  sensoric_diet?: Maybe<Scalars['String']>;
  si_diagnosis?: Maybe<Scalars['String']>;
  therapy_hints?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Patient_Si_Min_Fields = {
  __typename?: 'patient_si_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  sensoric_diet?: Maybe<Scalars['String']>;
  si_diagnosis?: Maybe<Scalars['String']>;
  therapy_hints?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "patient_si" */
export type Patient_Si_Mutation_Response = {
  __typename?: 'patient_si_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Si>;
};

/** input type for inserting object relation for remote table "patient_si" */
export type Patient_Si_Obj_Rel_Insert_Input = {
  data: Patient_Si_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Si_On_Conflict>;
};

/** on_conflict condition type for table "patient_si" */
export type Patient_Si_On_Conflict = {
  constraint: Patient_Si_Constraint;
  update_columns?: Array<Patient_Si_Update_Column>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_si". */
export type Patient_Si_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  diagnosis_therapy_plan?: InputMaybe<Order_By>;
  general_aim?: InputMaybe<Order_By>;
  general_info?: InputMaybe<Order_By>;
  medical_history?: InputMaybe<Order_By>;
  other_notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  sensoric_diet?: InputMaybe<Order_By>;
  si_diagnosis?: InputMaybe<Order_By>;
  therapy_hints?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_si */
export type Patient_Si_Pk_Columns_Input = {
  patient_id: Scalars['bigint'];
};

/** select columns of table "patient_si" */
export enum Patient_Si_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  SensoricDiet = 'sensoric_diet',
  /** column name */
  SiDiagnosis = 'si_diagnosis',
  /** column name */
  TherapyHints = 'therapy_hints',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_si" */
export type Patient_Si_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  sensoric_diet?: InputMaybe<Scalars['String']>;
  si_diagnosis?: InputMaybe<Scalars['String']>;
  therapy_hints?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Si_Stddev_Fields = {
  __typename?: 'patient_si_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Si_Stddev_Pop_Fields = {
  __typename?: 'patient_si_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Si_Stddev_Samp_Fields = {
  __typename?: 'patient_si_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_si" */
export type Patient_Si_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Si_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Si_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  sensoric_diet?: InputMaybe<Scalars['String']>;
  si_diagnosis?: InputMaybe<Scalars['String']>;
  therapy_hints?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Si_Sum_Fields = {
  __typename?: 'patient_si_sum_fields';
  patient_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_si" */
export enum Patient_Si_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  SensoricDiet = 'sensoric_diet',
  /** column name */
  SiDiagnosis = 'si_diagnosis',
  /** column name */
  TherapyHints = 'therapy_hints',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patient_Si_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Si_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Si_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Si_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Si_Var_Pop_Fields = {
  __typename?: 'patient_si_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Si_Var_Samp_Fields = {
  __typename?: 'patient_si_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Si_Variance_Fields = {
  __typename?: 'patient_si_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patient_speech_therapies" */
export type Patient_Speech_Therapies = {
  __typename?: 'patient_speech_therapies';
  articulation_examination?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
  speech_examination?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "patient_speech_therapies" */
export type Patient_Speech_Therapies_Aggregate = {
  __typename?: 'patient_speech_therapies_aggregate';
  aggregate?: Maybe<Patient_Speech_Therapies_Aggregate_Fields>;
  nodes: Array<Patient_Speech_Therapies>;
};

/** aggregate fields of "patient_speech_therapies" */
export type Patient_Speech_Therapies_Aggregate_Fields = {
  __typename?: 'patient_speech_therapies_aggregate_fields';
  avg?: Maybe<Patient_Speech_Therapies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patient_Speech_Therapies_Max_Fields>;
  min?: Maybe<Patient_Speech_Therapies_Min_Fields>;
  stddev?: Maybe<Patient_Speech_Therapies_Stddev_Fields>;
  stddev_pop?: Maybe<Patient_Speech_Therapies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patient_Speech_Therapies_Stddev_Samp_Fields>;
  sum?: Maybe<Patient_Speech_Therapies_Sum_Fields>;
  var_pop?: Maybe<Patient_Speech_Therapies_Var_Pop_Fields>;
  var_samp?: Maybe<Patient_Speech_Therapies_Var_Samp_Fields>;
  variance?: Maybe<Patient_Speech_Therapies_Variance_Fields>;
};


/** aggregate fields of "patient_speech_therapies" */
export type Patient_Speech_Therapies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patient_Speech_Therapies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Patient_Speech_Therapies_Avg_Fields = {
  __typename?: 'patient_speech_therapies_avg_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "patient_speech_therapies". All fields are combined with a logical 'AND'. */
export type Patient_Speech_Therapies_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Speech_Therapies_Bool_Exp>>;
  _not?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Speech_Therapies_Bool_Exp>>;
  articulation_examination?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  diagnosis_therapy_plan?: InputMaybe<String_Comparison_Exp>;
  general_aim?: InputMaybe<String_Comparison_Exp>;
  general_info?: InputMaybe<String_Comparison_Exp>;
  medical_history?: InputMaybe<String_Comparison_Exp>;
  other_notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  speech_examination?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_speech_therapies" */
export enum Patient_Speech_Therapies_Constraint {
  /** unique or primary key constraint on columns "patient_id" */
  PatientSpeechTherapiesPkey = 'patient_speech_therapies_pkey'
}

/** input type for incrementing numeric columns in table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Inc_Input = {
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Insert_Input = {
  articulation_examination?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  speech_examination?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Speech_Therapies_Max_Fields = {
  __typename?: 'patient_speech_therapies_max_fields';
  articulation_examination?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  speech_examination?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Patient_Speech_Therapies_Min_Fields = {
  __typename?: 'patient_speech_therapies_min_fields';
  articulation_examination?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: Maybe<Scalars['String']>;
  general_aim?: Maybe<Scalars['String']>;
  general_info?: Maybe<Scalars['String']>;
  medical_history?: Maybe<Scalars['String']>;
  other_notes?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['bigint']>;
  speech_examination?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Mutation_Response = {
  __typename?: 'patient_speech_therapies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patient_Speech_Therapies>;
};

/** input type for inserting object relation for remote table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Obj_Rel_Insert_Input = {
  data: Patient_Speech_Therapies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Speech_Therapies_On_Conflict>;
};

/** on_conflict condition type for table "patient_speech_therapies" */
export type Patient_Speech_Therapies_On_Conflict = {
  constraint: Patient_Speech_Therapies_Constraint;
  update_columns?: Array<Patient_Speech_Therapies_Update_Column>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_speech_therapies". */
export type Patient_Speech_Therapies_Order_By = {
  articulation_examination?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  diagnosis_therapy_plan?: InputMaybe<Order_By>;
  general_aim?: InputMaybe<Order_By>;
  general_info?: InputMaybe<Order_By>;
  medical_history?: InputMaybe<Order_By>;
  other_notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  speech_examination?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_speech_therapies */
export type Patient_Speech_Therapies_Pk_Columns_Input = {
  patient_id: Scalars['bigint'];
};

/** select columns of table "patient_speech_therapies" */
export enum Patient_Speech_Therapies_Select_Column {
  /** column name */
  ArticulationExamination = 'articulation_examination',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  SpeechExamination = 'speech_examination',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Set_Input = {
  articulation_examination?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  speech_examination?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patient_Speech_Therapies_Stddev_Fields = {
  __typename?: 'patient_speech_therapies_stddev_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Patient_Speech_Therapies_Stddev_Pop_Fields = {
  __typename?: 'patient_speech_therapies_stddev_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Patient_Speech_Therapies_Stddev_Samp_Fields = {
  __typename?: 'patient_speech_therapies_stddev_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "patient_speech_therapies" */
export type Patient_Speech_Therapies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Speech_Therapies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Speech_Therapies_Stream_Cursor_Value_Input = {
  articulation_examination?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosis_therapy_plan?: InputMaybe<Scalars['String']>;
  general_aim?: InputMaybe<Scalars['String']>;
  general_info?: InputMaybe<Scalars['String']>;
  medical_history?: InputMaybe<Scalars['String']>;
  other_notes?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  speech_examination?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patient_Speech_Therapies_Sum_Fields = {
  __typename?: 'patient_speech_therapies_sum_fields';
  patient_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "patient_speech_therapies" */
export enum Patient_Speech_Therapies_Update_Column {
  /** column name */
  ArticulationExamination = 'articulation_examination',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DiagnosisTherapyPlan = 'diagnosis_therapy_plan',
  /** column name */
  GeneralAim = 'general_aim',
  /** column name */
  GeneralInfo = 'general_info',
  /** column name */
  MedicalHistory = 'medical_history',
  /** column name */
  OtherNotes = 'other_notes',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  SpeechExamination = 'speech_examination',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patient_Speech_Therapies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Speech_Therapies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Speech_Therapies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Speech_Therapies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patient_Speech_Therapies_Var_Pop_Fields = {
  __typename?: 'patient_speech_therapies_var_pop_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Patient_Speech_Therapies_Var_Samp_Fields = {
  __typename?: 'patient_speech_therapies_var_samp_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Patient_Speech_Therapies_Variance_Fields = {
  __typename?: 'patient_speech_therapies_variance_fields';
  patient_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "patients" */
export type Patients = {
  __typename?: 'patients';
  /** A computed field, executes function "is_patient_active" */
  active?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  address?: Maybe<Patient_Addresses>;
  adult?: Maybe<Scalars['Boolean']>;
  billing_info_id?: Maybe<Scalars['bigint']>;
  birthday?: Maybe<Scalars['timestamptz']>;
  color?: Maybe<Scalars['String']>;
  /** An array relationship */
  couples_patients: Array<Couples_Patients>;
  /** An aggregate relationship */
  couples_patients_aggregate: Couples_Patients_Aggregate;
  created_at: Scalars['timestamptz'];
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  ed_participants: Array<Ed_Participants>;
  /** An aggregate relationship */
  ed_participants_aggregate: Ed_Participants_Aggregate;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  full_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  gdpr_gdprdocuments: Array<Gdpr_Gdprdocument>;
  /** An aggregate relationship */
  gdpr_gdprdocuments_aggregate: Gdpr_Gdprdocument_Aggregate;
  gender?: Maybe<Scalars['String']>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  /** An array relationship */
  guardians: Array<Patient_Guardians>;
  /** An aggregate relationship */
  guardians_aggregate: Patient_Guardians_Aggregate;
  id: Scalars['bigint'];
  id_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  invoices_v2_billinginfo?: Maybe<Invoices_V2_Billinginfo>;
  /** An object relationship */
  last_edited_by?: Maybe<User>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  /** An object relationship */
  patient_educator?: Maybe<Patient_Educator>;
  /** An object relationship */
  patient_si?: Maybe<Patient_Si>;
  pesel?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  /** An object relationship */
  psychotherapy?: Maybe<Patient_Psychotherapies>;
  second_phone_number?: Maybe<Scalars['String']>;
  sms_notifications_active: Scalars['Boolean'];
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  /** An object relationship */
  speech_therapy?: Maybe<Patient_Speech_Therapies>;
  /** An object relationship */
  therapist?: Maybe<Employees>;
  therapist_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  therapists: Array<Patients_Therapists>;
  /** An aggregate relationship */
  therapists_aggregate: Patients_Therapists_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "patients" */
export type PatientsCouples_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsCouples_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsEd_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsEd_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGdpr_GdprdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGdpr_Gdprdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGuardiansArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsGuardians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsTherapistsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsTherapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};

/** aggregated selection of "patients" */
export type Patients_Aggregate = {
  __typename?: 'patients_aggregate';
  aggregate?: Maybe<Patients_Aggregate_Fields>;
  nodes: Array<Patients>;
};

export type Patients_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Patients_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Patients_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Patients_Aggregate_Bool_Exp_Count>;
};

export type Patients_Aggregate_Bool_Exp_Bool_And = {
  arguments: Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patients_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_Fields = {
  __typename?: 'patients_aggregate_fields';
  avg?: Maybe<Patients_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patients_Max_Fields>;
  min?: Maybe<Patients_Min_Fields>;
  stddev?: Maybe<Patients_Stddev_Fields>;
  stddev_pop?: Maybe<Patients_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patients_Stddev_Samp_Fields>;
  sum?: Maybe<Patients_Sum_Fields>;
  var_pop?: Maybe<Patients_Var_Pop_Fields>;
  var_samp?: Maybe<Patients_Var_Samp_Fields>;
  variance?: Maybe<Patients_Variance_Fields>;
};


/** aggregate fields of "patients" */
export type Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients" */
export type Patients_Aggregate_Order_By = {
  avg?: InputMaybe<Patients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patients_Max_Order_By>;
  min?: InputMaybe<Patients_Min_Order_By>;
  stddev?: InputMaybe<Patients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patients_Sum_Order_By>;
  var_pop?: InputMaybe<Patients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patients_Var_Samp_Order_By>;
  variance?: InputMaybe<Patients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patients" */
export type Patients_Arr_Rel_Insert_Input = {
  data: Array<Patients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** aggregate avg on columns */
export type Patients_Avg_Fields = {
  __typename?: 'patients_avg_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patients" */
export type Patients_Avg_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients". All fields are combined with a logical 'AND'. */
export type Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Bool_Exp>>;
  _not?: InputMaybe<Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  address?: InputMaybe<Patient_Addresses_Bool_Exp>;
  adult?: InputMaybe<Boolean_Comparison_Exp>;
  billing_info_id?: InputMaybe<Bigint_Comparison_Exp>;
  birthday?: InputMaybe<Timestamptz_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  couples_patients?: InputMaybe<Couples_Patients_Bool_Exp>;
  couples_patients_aggregate?: InputMaybe<Couples_Patients_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deactivated_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ed_participants?: InputMaybe<Ed_Participants_Bool_Exp>;
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  group_members?: InputMaybe<Group_Members_Bool_Exp>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Bool_Exp>;
  guardians?: InputMaybe<Patient_Guardians_Bool_Exp>;
  guardians_aggregate?: InputMaybe<Patient_Guardians_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  id_number?: InputMaybe<String_Comparison_Exp>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
  last_edited_by?: InputMaybe<User_Bool_Exp>;
  last_edited_by_id?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  patient_educator?: InputMaybe<Patient_Educator_Bool_Exp>;
  patient_si?: InputMaybe<Patient_Si_Bool_Exp>;
  pesel?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  psychotherapy?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
  second_phone_number?: InputMaybe<String_Comparison_Exp>;
  sms_notifications_active?: InputMaybe<Boolean_Comparison_Exp>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Bool_Exp>;
  speech_therapy?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
  therapist?: InputMaybe<Employees_Bool_Exp>;
  therapist_id?: InputMaybe<Bigint_Comparison_Exp>;
  therapists?: InputMaybe<Patients_Therapists_Bool_Exp>;
  therapists_aggregate?: InputMaybe<Patients_Therapists_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients" */
export enum Patients_Constraint {
  /** unique or primary key constraint on columns "id" */
  PatientsPkey = 'patients_pkey'
}

/** input type for incrementing numeric columns in table "patients" */
export type Patients_Inc_Input = {
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patients" */
export type Patients_Insert_Input = {
  address?: InputMaybe<Patient_Addresses_Obj_Rel_Insert_Input>;
  adult?: InputMaybe<Scalars['Boolean']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  birthday?: InputMaybe<Scalars['timestamptz']>;
  color?: InputMaybe<Scalars['String']>;
  couples_patients?: InputMaybe<Couples_Patients_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ed_participants?: InputMaybe<Ed_Participants_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Arr_Rel_Insert_Input>;
  gender?: InputMaybe<Scalars['String']>;
  group_members?: InputMaybe<Group_Members_Arr_Rel_Insert_Input>;
  guardians?: InputMaybe<Patient_Guardians_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  id_number?: InputMaybe<Scalars['String']>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Obj_Rel_Insert_Input>;
  last_edited_by?: InputMaybe<User_Obj_Rel_Insert_Input>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  patient_educator?: InputMaybe<Patient_Educator_Obj_Rel_Insert_Input>;
  patient_si?: InputMaybe<Patient_Si_Obj_Rel_Insert_Input>;
  pesel?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  psychotherapy?: InputMaybe<Patient_Psychotherapies_Obj_Rel_Insert_Input>;
  second_phone_number?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Arr_Rel_Insert_Input>;
  speech_therapy?: InputMaybe<Patient_Speech_Therapies_Obj_Rel_Insert_Input>;
  therapist?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  therapists?: InputMaybe<Patients_Therapists_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patients_Max_Fields = {
  __typename?: 'patients_max_fields';
  billing_info_id?: Maybe<Scalars['bigint']>;
  birthday?: Maybe<Scalars['timestamptz']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_number?: Maybe<Scalars['String']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  pesel?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['bigint']>;
  second_phone_number?: Maybe<Scalars['String']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patients" */
export type Patients_Max_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_number?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  second_phone_number?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Min_Fields = {
  __typename?: 'patients_min_fields';
  billing_info_id?: Maybe<Scalars['bigint']>;
  birthday?: Maybe<Scalars['timestamptz']>;
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deactivated_on?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_number?: Maybe<Scalars['String']>;
  last_edited_by_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  pesel?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['bigint']>;
  second_phone_number?: Maybe<Scalars['String']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patients" */
export type Patients_Min_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  id_number?: InputMaybe<Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  pesel?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  second_phone_number?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients" */
export type Patients_Mutation_Response = {
  __typename?: 'patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients>;
};

/** input type for inserting object relation for remote table "patients" */
export type Patients_Obj_Rel_Insert_Input = {
  data: Patients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** on_conflict condition type for table "patients" */
export type Patients_On_Conflict = {
  constraint: Patients_Constraint;
  update_columns?: Array<Patients_Update_Column>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "patients". */
export type Patients_Order_By = {
  active?: InputMaybe<Order_By>;
  address?: InputMaybe<Patient_Addresses_Order_By>;
  adult?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  couples_patients_aggregate?: InputMaybe<Couples_Patients_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deactivated_on?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ed_participants_aggregate?: InputMaybe<Ed_Participants_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Order_By>;
  gender?: InputMaybe<Order_By>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Order_By>;
  guardians_aggregate?: InputMaybe<Patient_Guardians_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  id_number?: InputMaybe<Order_By>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Order_By>;
  last_edited_by?: InputMaybe<User_Order_By>;
  last_edited_by_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  patient_educator?: InputMaybe<Patient_Educator_Order_By>;
  patient_si?: InputMaybe<Patient_Si_Order_By>;
  pesel?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  psychotherapy?: InputMaybe<Patient_Psychotherapies_Order_By>;
  second_phone_number?: InputMaybe<Order_By>;
  sms_notifications_active?: InputMaybe<Order_By>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Order_By>;
  speech_therapy?: InputMaybe<Patient_Speech_Therapies_Order_By>;
  therapist?: InputMaybe<Employees_Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  therapists_aggregate?: InputMaybe<Patients_Therapists_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patients */
export type Patients_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "patients" */
export enum Patients_Select_Column {
  /** column name */
  Adult = 'adult',
  /** column name */
  BillingInfoId = 'billing_info_id',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedOn = 'deactivated_on',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Pesel = 'pesel',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SecondPhoneNumber = 'second_phone_number',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "patients_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patients" */
export enum Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Adult = 'adult',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active'
}

/** select "patients_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patients" */
export enum Patients_Select_Column_Patients_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Adult = 'adult',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active'
}

/** input type for updating data in table "patients" */
export type Patients_Set_Input = {
  adult?: InputMaybe<Scalars['Boolean']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  birthday?: InputMaybe<Scalars['timestamptz']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  id_number?: InputMaybe<Scalars['String']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  second_phone_number?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Patients_Stddev_Fields = {
  __typename?: 'patients_stddev_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patients" */
export type Patients_Stddev_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patients_Stddev_Pop_Fields = {
  __typename?: 'patients_stddev_pop_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patients" */
export type Patients_Stddev_Pop_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patients_Stddev_Samp_Fields = {
  __typename?: 'patients_stddev_samp_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patients" */
export type Patients_Stddev_Samp_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patients" */
export type Patients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patients_Stream_Cursor_Value_Input = {
  adult?: InputMaybe<Scalars['Boolean']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  birthday?: InputMaybe<Scalars['timestamptz']>;
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deactivated_on?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  id_number?: InputMaybe<Scalars['String']>;
  last_edited_by_id?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  pesel?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  second_phone_number?: InputMaybe<Scalars['String']>;
  sms_notifications_active?: InputMaybe<Scalars['Boolean']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Patients_Sum_Fields = {
  __typename?: 'patients_sum_fields';
  billing_info_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  therapist_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "patients" */
export type Patients_Sum_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "patients_therapists" */
export type Patients_Therapists = {
  __typename?: 'patients_therapists';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['bigint'];
};

/** aggregated selection of "patients_therapists" */
export type Patients_Therapists_Aggregate = {
  __typename?: 'patients_therapists_aggregate';
  aggregate?: Maybe<Patients_Therapists_Aggregate_Fields>;
  nodes: Array<Patients_Therapists>;
};

export type Patients_Therapists_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patients_Therapists_Aggregate_Bool_Exp_Count>;
};

export type Patients_Therapists_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Therapists_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patients_therapists" */
export type Patients_Therapists_Aggregate_Fields = {
  __typename?: 'patients_therapists_aggregate_fields';
  avg?: Maybe<Patients_Therapists_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Patients_Therapists_Max_Fields>;
  min?: Maybe<Patients_Therapists_Min_Fields>;
  stddev?: Maybe<Patients_Therapists_Stddev_Fields>;
  stddev_pop?: Maybe<Patients_Therapists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patients_Therapists_Stddev_Samp_Fields>;
  sum?: Maybe<Patients_Therapists_Sum_Fields>;
  var_pop?: Maybe<Patients_Therapists_Var_Pop_Fields>;
  var_samp?: Maybe<Patients_Therapists_Var_Samp_Fields>;
  variance?: Maybe<Patients_Therapists_Variance_Fields>;
};


/** aggregate fields of "patients_therapists" */
export type Patients_Therapists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients_therapists" */
export type Patients_Therapists_Aggregate_Order_By = {
  avg?: InputMaybe<Patients_Therapists_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patients_Therapists_Max_Order_By>;
  min?: InputMaybe<Patients_Therapists_Min_Order_By>;
  stddev?: InputMaybe<Patients_Therapists_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patients_Therapists_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patients_Therapists_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patients_Therapists_Sum_Order_By>;
  var_pop?: InputMaybe<Patients_Therapists_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patients_Therapists_Var_Samp_Order_By>;
  variance?: InputMaybe<Patients_Therapists_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patients_therapists" */
export type Patients_Therapists_Arr_Rel_Insert_Input = {
  data: Array<Patients_Therapists_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_Therapists_On_Conflict>;
};

/** aggregate avg on columns */
export type Patients_Therapists_Avg_Fields = {
  __typename?: 'patients_therapists_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patients_therapists" */
export type Patients_Therapists_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients_therapists". All fields are combined with a logical 'AND'. */
export type Patients_Therapists_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Therapists_Bool_Exp>>;
  _not?: InputMaybe<Patients_Therapists_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Therapists_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients_therapists" */
export enum Patients_Therapists_Constraint {
  /** unique or primary key constraint on columns "patient_id", "employee_id" */
  PatientsTherapistsPatientIdEmployeeIdD626e9beUniq = 'patients_therapists_patient_id_employee_id_d626e9be_uniq',
  /** unique or primary key constraint on columns "id" */
  PatientsTherapistsPkey = 'patients_therapists_pkey'
}

/** input type for incrementing numeric columns in table "patients_therapists" */
export type Patients_Therapists_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patients_therapists" */
export type Patients_Therapists_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Patients_Therapists_Max_Fields = {
  __typename?: 'patients_therapists_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "patients_therapists" */
export type Patients_Therapists_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Therapists_Min_Fields = {
  __typename?: 'patients_therapists_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "patients_therapists" */
export type Patients_Therapists_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients_therapists" */
export type Patients_Therapists_Mutation_Response = {
  __typename?: 'patients_therapists_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients_Therapists>;
};

/** on_conflict condition type for table "patients_therapists" */
export type Patients_Therapists_On_Conflict = {
  constraint: Patients_Therapists_Constraint;
  update_columns?: Array<Patients_Therapists_Update_Column>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};

/** Ordering options when selecting data from "patients_therapists". */
export type Patients_Therapists_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patients_therapists */
export type Patients_Therapists_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "patients_therapists" */
export enum Patients_Therapists_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id'
}

/** input type for updating data in table "patients_therapists" */
export type Patients_Therapists_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Patients_Therapists_Stddev_Fields = {
  __typename?: 'patients_therapists_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patients_therapists" */
export type Patients_Therapists_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patients_Therapists_Stddev_Pop_Fields = {
  __typename?: 'patients_therapists_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patients_therapists" */
export type Patients_Therapists_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patients_Therapists_Stddev_Samp_Fields = {
  __typename?: 'patients_therapists_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patients_therapists" */
export type Patients_Therapists_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patients_therapists" */
export type Patients_Therapists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patients_Therapists_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patients_Therapists_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Patients_Therapists_Sum_Fields = {
  __typename?: 'patients_therapists_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "patients_therapists" */
export type Patients_Therapists_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "patients_therapists" */
export enum Patients_Therapists_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id'
}

export type Patients_Therapists_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patients_Therapists_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patients_Therapists_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patients_Therapists_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patients_Therapists_Var_Pop_Fields = {
  __typename?: 'patients_therapists_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patients_therapists" */
export type Patients_Therapists_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patients_Therapists_Var_Samp_Fields = {
  __typename?: 'patients_therapists_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patients_therapists" */
export type Patients_Therapists_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Patients_Therapists_Variance_Fields = {
  __typename?: 'patients_therapists_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patients_therapists" */
export type Patients_Therapists_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
};

/** update columns of table "patients" */
export enum Patients_Update_Column {
  /** column name */
  Adult = 'adult',
  /** column name */
  BillingInfoId = 'billing_info_id',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeactivatedOn = 'deactivated_on',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  LastEditedById = 'last_edited_by_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Pesel = 'pesel',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SecondPhoneNumber = 'second_phone_number',
  /** column name */
  SmsNotificationsActive = 'sms_notifications_active',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Patients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patients_Var_Pop_Fields = {
  __typename?: 'patients_var_pop_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patients" */
export type Patients_Var_Pop_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patients_Var_Samp_Fields = {
  __typename?: 'patients_var_samp_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patients" */
export type Patients_Var_Samp_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Patients_Variance_Fields = {
  __typename?: 'patients_variance_fields';
  billing_info_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patients" */
export type Patients_Variance_Order_By = {
  billing_info_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "practice_branches" */
export type Practice_Branches = {
  __typename?: 'practice_branches';
  /** An array relationship */
  accounting_practiceexpenses: Array<Accounting_Practiceexpense>;
  /** An aggregate relationship */
  accounting_practiceexpenses_aggregate: Accounting_Practiceexpense_Aggregate;
  /** An object relationship */
  address?: Maybe<Addresses>;
  branch_address_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  contact_detail?: Maybe<Contact_Details>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: Employees_Aggregate;
  /** An object relationship */
  gdpr_gdprconfig?: Maybe<Gdpr_Gdprconfig>;
  /** An array relationship */
  gdpr_gdprdocuments: Array<Gdpr_Gdprdocument>;
  /** An aggregate relationship */
  gdpr_gdprdocuments_aggregate: Gdpr_Gdprdocument_Aggregate;
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  invoices_v2_invoices: Array<Invoices_V2_Invoice>;
  /** An aggregate relationship */
  invoices_v2_invoices_aggregate: Invoices_V2_Invoice_Aggregate;
  name: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  /** An array relationship */
  registration_meetingrequests: Array<Registration_Meetingrequest>;
  /** An aggregate relationship */
  registration_meetingrequests_aggregate: Registration_Meetingrequest_Aggregate;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** An array relationship */
  services: Array<Services>;
  /** An aggregate relationship */
  services_aggregate: Services_Aggregate;
  short_name: Scalars['String'];
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  /** An array relationship */
  sms_notifications_smstemplates: Array<Sms_Notifications_Smstemplate>;
  /** An aggregate relationship */
  sms_notifications_smstemplates_aggregate: Sms_Notifications_Smstemplate_Aggregate;
  updated_at: Scalars['timestamptz'];
  vat_percentage: Scalars['numeric'];
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesAccounting_PracticeexpensesArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesAccounting_Practiceexpenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesEmployees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesGdpr_GdprdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesGdpr_Gdprdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesInvoices_V2_InvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesInvoices_V2_Invoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesRegistration_MeetingrequestsArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesRegistration_Meetingrequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesSms_Notifications_SmstemplatesArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


/** columns and relationships of "practice_branches" */
export type Practice_BranchesSms_Notifications_Smstemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};

/** aggregated selection of "practice_branches" */
export type Practice_Branches_Aggregate = {
  __typename?: 'practice_branches_aggregate';
  aggregate?: Maybe<Practice_Branches_Aggregate_Fields>;
  nodes: Array<Practice_Branches>;
};

export type Practice_Branches_Aggregate_Bool_Exp = {
  count?: InputMaybe<Practice_Branches_Aggregate_Bool_Exp_Count>;
};

export type Practice_Branches_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Practice_Branches_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "practice_branches" */
export type Practice_Branches_Aggregate_Fields = {
  __typename?: 'practice_branches_aggregate_fields';
  avg?: Maybe<Practice_Branches_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Practice_Branches_Max_Fields>;
  min?: Maybe<Practice_Branches_Min_Fields>;
  stddev?: Maybe<Practice_Branches_Stddev_Fields>;
  stddev_pop?: Maybe<Practice_Branches_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practice_Branches_Stddev_Samp_Fields>;
  sum?: Maybe<Practice_Branches_Sum_Fields>;
  var_pop?: Maybe<Practice_Branches_Var_Pop_Fields>;
  var_samp?: Maybe<Practice_Branches_Var_Samp_Fields>;
  variance?: Maybe<Practice_Branches_Variance_Fields>;
};


/** aggregate fields of "practice_branches" */
export type Practice_Branches_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "practice_branches" */
export type Practice_Branches_Aggregate_Order_By = {
  avg?: InputMaybe<Practice_Branches_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Practice_Branches_Max_Order_By>;
  min?: InputMaybe<Practice_Branches_Min_Order_By>;
  stddev?: InputMaybe<Practice_Branches_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Practice_Branches_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Practice_Branches_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Practice_Branches_Sum_Order_By>;
  var_pop?: InputMaybe<Practice_Branches_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Practice_Branches_Var_Samp_Order_By>;
  variance?: InputMaybe<Practice_Branches_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practice_branches" */
export type Practice_Branches_Arr_Rel_Insert_Input = {
  data: Array<Practice_Branches_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Practice_Branches_On_Conflict>;
};

/** aggregate avg on columns */
export type Practice_Branches_Avg_Fields = {
  __typename?: 'practice_branches_avg_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "practice_branches" */
export type Practice_Branches_Avg_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "practice_branches". All fields are combined with a logical 'AND'. */
export type Practice_Branches_Bool_Exp = {
  _and?: InputMaybe<Array<Practice_Branches_Bool_Exp>>;
  _not?: InputMaybe<Practice_Branches_Bool_Exp>;
  _or?: InputMaybe<Array<Practice_Branches_Bool_Exp>>;
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Bool_Exp>;
  address?: InputMaybe<Addresses_Bool_Exp>;
  branch_address_id?: InputMaybe<Bigint_Comparison_Exp>;
  contact_detail?: InputMaybe<Contact_Details_Bool_Exp>;
  contact_details_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employees?: InputMaybe<Employees_Bool_Exp>;
  employees_aggregate?: InputMaybe<Employees_Aggregate_Bool_Exp>;
  gdpr_gdprconfig?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Bool_Exp>;
  holidays?: InputMaybe<Holidays_Bool_Exp>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_invoices?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
  invoices_v2_invoices_aggregate?: InputMaybe<Invoices_V2_Invoice_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Bool_Exp>;
  rooms?: InputMaybe<Rooms_Bool_Exp>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Bool_Exp>;
  services?: InputMaybe<Services_Bool_Exp>;
  services_aggregate?: InputMaybe<Services_Aggregate_Bool_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Bool_Exp>;
  sms_notifications_smstemplates?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
  sms_notifications_smstemplates_aggregate?: InputMaybe<Sms_Notifications_Smstemplate_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vat_percentage?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "practice_branches" */
export enum Practice_Branches_Constraint {
  /** unique or primary key constraint on columns "id" */
  PracticeBranchesPkey = 'practice_branches_pkey'
}

/** input type for incrementing numeric columns in table "practice_branches" */
export type Practice_Branches_Inc_Input = {
  branch_address_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  vat_percentage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "practice_branches" */
export type Practice_Branches_Insert_Input = {
  accounting_practiceexpenses?: InputMaybe<Accounting_Practiceexpense_Arr_Rel_Insert_Input>;
  address?: InputMaybe<Addresses_Obj_Rel_Insert_Input>;
  branch_address_id?: InputMaybe<Scalars['bigint']>;
  contact_detail?: InputMaybe<Contact_Details_Obj_Rel_Insert_Input>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employees?: InputMaybe<Employees_Arr_Rel_Insert_Input>;
  gdpr_gdprconfig?: InputMaybe<Gdpr_Gdprconfig_Obj_Rel_Insert_Input>;
  gdpr_gdprdocuments?: InputMaybe<Gdpr_Gdprdocument_Arr_Rel_Insert_Input>;
  holidays?: InputMaybe<Holidays_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_invoices?: InputMaybe<Invoices_V2_Invoice_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Arr_Rel_Insert_Input>;
  rooms?: InputMaybe<Rooms_Arr_Rel_Insert_Input>;
  services?: InputMaybe<Services_Arr_Rel_Insert_Input>;
  short_name?: InputMaybe<Scalars['String']>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Arr_Rel_Insert_Input>;
  sms_notifications_smstemplates?: InputMaybe<Sms_Notifications_Smstemplate_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vat_percentage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Practice_Branches_Max_Fields = {
  __typename?: 'practice_branches_max_fields';
  branch_address_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['bigint']>;
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vat_percentage?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "practice_branches" */
export type Practice_Branches_Max_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Practice_Branches_Min_Fields = {
  __typename?: 'practice_branches_min_fields';
  branch_address_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['bigint']>;
  short_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vat_percentage?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "practice_branches" */
export type Practice_Branches_Min_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "practice_branches" */
export type Practice_Branches_Mutation_Response = {
  __typename?: 'practice_branches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practice_Branches>;
};

/** input type for inserting object relation for remote table "practice_branches" */
export type Practice_Branches_Obj_Rel_Insert_Input = {
  data: Practice_Branches_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Practice_Branches_On_Conflict>;
};

/** on_conflict condition type for table "practice_branches" */
export type Practice_Branches_On_Conflict = {
  constraint: Practice_Branches_Constraint;
  update_columns?: Array<Practice_Branches_Update_Column>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};

/** Ordering options when selecting data from "practice_branches". */
export type Practice_Branches_Order_By = {
  accounting_practiceexpenses_aggregate?: InputMaybe<Accounting_Practiceexpense_Aggregate_Order_By>;
  address?: InputMaybe<Addresses_Order_By>;
  branch_address_id?: InputMaybe<Order_By>;
  contact_detail?: InputMaybe<Contact_Details_Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employees_aggregate?: InputMaybe<Employees_Aggregate_Order_By>;
  gdpr_gdprconfig?: InputMaybe<Gdpr_Gdprconfig_Order_By>;
  gdpr_gdprdocuments_aggregate?: InputMaybe<Gdpr_Gdprdocument_Aggregate_Order_By>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_v2_invoices_aggregate?: InputMaybe<Invoices_V2_Invoice_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Order_By>;
  rooms_aggregate?: InputMaybe<Rooms_Aggregate_Order_By>;
  services_aggregate?: InputMaybe<Services_Aggregate_Order_By>;
  short_name?: InputMaybe<Order_By>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Order_By>;
  sms_notifications_smstemplates_aggregate?: InputMaybe<Sms_Notifications_Smstemplate_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: practice_branches */
export type Practice_Branches_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "practice_branches" */
export enum Practice_Branches_Select_Column {
  /** column name */
  BranchAddressId = 'branch_address_id',
  /** column name */
  ContactDetailsId = 'contact_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VatPercentage = 'vat_percentage'
}

/** input type for updating data in table "practice_branches" */
export type Practice_Branches_Set_Input = {
  branch_address_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  short_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vat_percentage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Practice_Branches_Stddev_Fields = {
  __typename?: 'practice_branches_stddev_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "practice_branches" */
export type Practice_Branches_Stddev_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Practice_Branches_Stddev_Pop_Fields = {
  __typename?: 'practice_branches_stddev_pop_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "practice_branches" */
export type Practice_Branches_Stddev_Pop_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Practice_Branches_Stddev_Samp_Fields = {
  __typename?: 'practice_branches_stddev_samp_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "practice_branches" */
export type Practice_Branches_Stddev_Samp_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "practice_branches" */
export type Practice_Branches_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Practice_Branches_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Practice_Branches_Stream_Cursor_Value_Input = {
  branch_address_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  short_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vat_percentage?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Practice_Branches_Sum_Fields = {
  __typename?: 'practice_branches_sum_fields';
  branch_address_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  vat_percentage?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "practice_branches" */
export type Practice_Branches_Sum_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** update columns of table "practice_branches" */
export enum Practice_Branches_Update_Column {
  /** column name */
  BranchAddressId = 'branch_address_id',
  /** column name */
  ContactDetailsId = 'contact_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VatPercentage = 'vat_percentage'
}

export type Practice_Branches_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Practice_Branches_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Practice_Branches_Set_Input>;
  /** filter the rows which have to be updated */
  where: Practice_Branches_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Practice_Branches_Var_Pop_Fields = {
  __typename?: 'practice_branches_var_pop_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "practice_branches" */
export type Practice_Branches_Var_Pop_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Practice_Branches_Var_Samp_Fields = {
  __typename?: 'practice_branches_var_samp_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "practice_branches" */
export type Practice_Branches_Var_Samp_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Practice_Branches_Variance_Fields = {
  __typename?: 'practice_branches_variance_fields';
  branch_address_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "practice_branches" */
export type Practice_Branches_Variance_Order_By = {
  branch_address_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  vat_percentage?: InputMaybe<Order_By>;
};

/** columns and relationships of "practice_logos" */
export type Practice_Logos = {
  __typename?: 'practice_logos';
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file: Scalars['String'];
  id: Scalars['bigint'];
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  updated_at: Scalars['timestamptz'];
  uploaded_at: Scalars['timestamptz'];
};


/** columns and relationships of "practice_logos" */
export type Practice_LogosPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


/** columns and relationships of "practice_logos" */
export type Practice_LogosPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};

/** aggregated selection of "practice_logos" */
export type Practice_Logos_Aggregate = {
  __typename?: 'practice_logos_aggregate';
  aggregate?: Maybe<Practice_Logos_Aggregate_Fields>;
  nodes: Array<Practice_Logos>;
};

/** aggregate fields of "practice_logos" */
export type Practice_Logos_Aggregate_Fields = {
  __typename?: 'practice_logos_aggregate_fields';
  avg?: Maybe<Practice_Logos_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Practice_Logos_Max_Fields>;
  min?: Maybe<Practice_Logos_Min_Fields>;
  stddev?: Maybe<Practice_Logos_Stddev_Fields>;
  stddev_pop?: Maybe<Practice_Logos_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practice_Logos_Stddev_Samp_Fields>;
  sum?: Maybe<Practice_Logos_Sum_Fields>;
  var_pop?: Maybe<Practice_Logos_Var_Pop_Fields>;
  var_samp?: Maybe<Practice_Logos_Var_Samp_Fields>;
  variance?: Maybe<Practice_Logos_Variance_Fields>;
};


/** aggregate fields of "practice_logos" */
export type Practice_Logos_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Practice_Logos_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Practice_Logos_Avg_Fields = {
  __typename?: 'practice_logos_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "practice_logos". All fields are combined with a logical 'AND'. */
export type Practice_Logos_Bool_Exp = {
  _and?: InputMaybe<Array<Practice_Logos_Bool_Exp>>;
  _not?: InputMaybe<Practice_Logos_Bool_Exp>;
  _or?: InputMaybe<Array<Practice_Logos_Bool_Exp>>;
  content_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  practices?: InputMaybe<Practices_Bool_Exp>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploaded_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "practice_logos" */
export enum Practice_Logos_Constraint {
  /** unique or primary key constraint on columns "id" */
  PracticeLogosPkey = 'practice_logos_pkey'
}

/** input type for incrementing numeric columns in table "practice_logos" */
export type Practice_Logos_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "practice_logos" */
export type Practice_Logos_Insert_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  practices?: InputMaybe<Practices_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Practice_Logos_Max_Fields = {
  __typename?: 'practice_logos_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Practice_Logos_Min_Fields = {
  __typename?: 'practice_logos_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uploaded_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "practice_logos" */
export type Practice_Logos_Mutation_Response = {
  __typename?: 'practice_logos_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practice_Logos>;
};

/** input type for inserting object relation for remote table "practice_logos" */
export type Practice_Logos_Obj_Rel_Insert_Input = {
  data: Practice_Logos_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Practice_Logos_On_Conflict>;
};

/** on_conflict condition type for table "practice_logos" */
export type Practice_Logos_On_Conflict = {
  constraint: Practice_Logos_Constraint;
  update_columns?: Array<Practice_Logos_Update_Column>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};

/** Ordering options when selecting data from "practice_logos". */
export type Practice_Logos_Order_By = {
  content_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practices_aggregate?: InputMaybe<Practices_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploaded_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: practice_logos */
export type Practice_Logos_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "practice_logos" */
export enum Practice_Logos_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at'
}

/** input type for updating data in table "practice_logos" */
export type Practice_Logos_Set_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Practice_Logos_Stddev_Fields = {
  __typename?: 'practice_logos_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Practice_Logos_Stddev_Pop_Fields = {
  __typename?: 'practice_logos_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Practice_Logos_Stddev_Samp_Fields = {
  __typename?: 'practice_logos_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "practice_logos" */
export type Practice_Logos_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Practice_Logos_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Practice_Logos_Stream_Cursor_Value_Input = {
  content_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploaded_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Practice_Logos_Sum_Fields = {
  __typename?: 'practice_logos_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "practice_logos" */
export enum Practice_Logos_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at'
}

export type Practice_Logos_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Practice_Logos_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Practice_Logos_Set_Input>;
  /** filter the rows which have to be updated */
  where: Practice_Logos_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Practice_Logos_Var_Pop_Fields = {
  __typename?: 'practice_logos_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Practice_Logos_Var_Samp_Fields = {
  __typename?: 'practice_logos_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Practice_Logos_Variance_Fields = {
  __typename?: 'practice_logos_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "practices" */
export type Practices = {
  __typename?: 'practices';
  /** An object relationship */
  address?: Maybe<Addresses>;
  allow_reception_to_see_event_notes: Scalars['Boolean'];
  billing_address_id?: Maybe<Scalars['bigint']>;
  billing_info_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  contact_detail?: Maybe<Contact_Details>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hide_employee_info: Scalars['Boolean'];
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  id: Scalars['bigint'];
  /** An object relationship */
  invoices_v2_billinginfo?: Maybe<Invoices_V2_Billinginfo>;
  /** An object relationship */
  invoices_v2_invoiceconfig?: Maybe<Invoices_V2_Invoiceconfig>;
  is_no_p24_config_email_sent: Scalars['Boolean'];
  logo_id?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** An array relationship */
  practice_branches: Array<Practice_Branches>;
  /** An aggregate relationship */
  practice_branches_aggregate: Practice_Branches_Aggregate;
  /** An object relationship */
  practice_logo?: Maybe<Practice_Logos>;
  /** An object relationship */
  registration_onlineregistrationconfig?: Maybe<Registration_Onlineregistrationconfig>;
  restricted_access?: Maybe<Scalars['Boolean']>;
  services_per_therapist?: Maybe<Scalars['Boolean']>;
  short_name: Scalars['String'];
  show_only_personal_events_per_therapist: Scalars['Boolean'];
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  /** An object relationship */
  sms_notifications_smsconfig?: Maybe<Sms_Notifications_Smsconfig>;
  /** An array relationship */
  sms_notifications_smspacketpurchases: Array<Sms_Notifications_Smspacketpurchase>;
  /** An aggregate relationship */
  sms_notifications_smspacketpurchases_aggregate: Sms_Notifications_Smspacketpurchase_Aggregate;
  /** An array relationship */
  sms_notifications_smstemplates: Array<Sms_Notifications_Smstemplate>;
  /** An aggregate relationship */
  sms_notifications_smstemplates_aggregate: Sms_Notifications_Smstemplate_Aggregate;
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  worktime_config?: Maybe<Worktime_Configs>;
};


/** columns and relationships of "practices" */
export type PracticesHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPractice_BranchesArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPractice_Branches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_SmspacketpurchasesArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_Smspacketpurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_SmstemplatesArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesSms_Notifications_Smstemplates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};

/** aggregated selection of "practices" */
export type Practices_Aggregate = {
  __typename?: 'practices_aggregate';
  aggregate?: Maybe<Practices_Aggregate_Fields>;
  nodes: Array<Practices>;
};

export type Practices_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Practices_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Practices_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Practices_Aggregate_Bool_Exp_Count>;
};

export type Practices_Aggregate_Bool_Exp_Bool_And = {
  arguments: Practices_Select_Column_Practices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Practices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Practices_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Practices_Select_Column_Practices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Practices_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Practices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Practices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Practices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "practices" */
export type Practices_Aggregate_Fields = {
  __typename?: 'practices_aggregate_fields';
  avg?: Maybe<Practices_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Practices_Max_Fields>;
  min?: Maybe<Practices_Min_Fields>;
  stddev?: Maybe<Practices_Stddev_Fields>;
  stddev_pop?: Maybe<Practices_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Practices_Stddev_Samp_Fields>;
  sum?: Maybe<Practices_Sum_Fields>;
  var_pop?: Maybe<Practices_Var_Pop_Fields>;
  var_samp?: Maybe<Practices_Var_Samp_Fields>;
  variance?: Maybe<Practices_Variance_Fields>;
};


/** aggregate fields of "practices" */
export type Practices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Practices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "practices" */
export type Practices_Aggregate_Order_By = {
  avg?: InputMaybe<Practices_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Practices_Max_Order_By>;
  min?: InputMaybe<Practices_Min_Order_By>;
  stddev?: InputMaybe<Practices_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Practices_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Practices_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Practices_Sum_Order_By>;
  var_pop?: InputMaybe<Practices_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Practices_Var_Samp_Order_By>;
  variance?: InputMaybe<Practices_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "practices" */
export type Practices_Arr_Rel_Insert_Input = {
  data: Array<Practices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Practices_On_Conflict>;
};

/** aggregate avg on columns */
export type Practices_Avg_Fields = {
  __typename?: 'practices_avg_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "practices" */
export type Practices_Avg_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "practices". All fields are combined with a logical 'AND'. */
export type Practices_Bool_Exp = {
  _and?: InputMaybe<Array<Practices_Bool_Exp>>;
  _not?: InputMaybe<Practices_Bool_Exp>;
  _or?: InputMaybe<Array<Practices_Bool_Exp>>;
  address?: InputMaybe<Addresses_Bool_Exp>;
  allow_reception_to_see_event_notes?: InputMaybe<Boolean_Comparison_Exp>;
  billing_address_id?: InputMaybe<Bigint_Comparison_Exp>;
  billing_info_id?: InputMaybe<Bigint_Comparison_Exp>;
  contact_detail?: InputMaybe<Contact_Details_Bool_Exp>;
  contact_details_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hide_employee_info?: InputMaybe<Boolean_Comparison_Exp>;
  holidays?: InputMaybe<Holidays_Bool_Exp>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
  invoices_v2_invoiceconfig?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
  is_no_p24_config_email_sent?: InputMaybe<Boolean_Comparison_Exp>;
  logo_id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  practice_branches?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Bool_Exp>;
  practice_logo?: InputMaybe<Practice_Logos_Bool_Exp>;
  registration_onlineregistrationconfig?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
  restricted_access?: InputMaybe<Boolean_Comparison_Exp>;
  services_per_therapist?: InputMaybe<Boolean_Comparison_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  show_only_personal_events_per_therapist?: InputMaybe<Boolean_Comparison_Exp>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Bool_Exp>;
  sms_notifications_smsconfig?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
  sms_notifications_smspacketpurchases?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
  sms_notifications_smspacketpurchases_aggregate?: InputMaybe<Sms_Notifications_Smspacketpurchase_Aggregate_Bool_Exp>;
  sms_notifications_smstemplates?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
  sms_notifications_smstemplates_aggregate?: InputMaybe<Sms_Notifications_Smstemplate_Aggregate_Bool_Exp>;
  timezone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worktime_config?: InputMaybe<Worktime_Configs_Bool_Exp>;
};

/** unique or primary key constraints on table "practices" */
export enum Practices_Constraint {
  /** unique or primary key constraint on columns "id" */
  PracticesPkey = 'practices_pkey'
}

/** input type for incrementing numeric columns in table "practices" */
export type Practices_Inc_Input = {
  billing_address_id?: InputMaybe<Scalars['bigint']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  logo_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "practices" */
export type Practices_Insert_Input = {
  address?: InputMaybe<Addresses_Obj_Rel_Insert_Input>;
  allow_reception_to_see_event_notes?: InputMaybe<Scalars['Boolean']>;
  billing_address_id?: InputMaybe<Scalars['bigint']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  contact_detail?: InputMaybe<Contact_Details_Obj_Rel_Insert_Input>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hide_employee_info?: InputMaybe<Scalars['Boolean']>;
  holidays?: InputMaybe<Holidays_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Obj_Rel_Insert_Input>;
  invoices_v2_invoiceconfig?: InputMaybe<Invoices_V2_Invoiceconfig_Obj_Rel_Insert_Input>;
  is_no_p24_config_email_sent?: InputMaybe<Scalars['Boolean']>;
  logo_id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  practice_branches?: InputMaybe<Practice_Branches_Arr_Rel_Insert_Input>;
  practice_logo?: InputMaybe<Practice_Logos_Obj_Rel_Insert_Input>;
  registration_onlineregistrationconfig?: InputMaybe<Registration_Onlineregistrationconfig_Obj_Rel_Insert_Input>;
  restricted_access?: InputMaybe<Scalars['Boolean']>;
  services_per_therapist?: InputMaybe<Scalars['Boolean']>;
  short_name?: InputMaybe<Scalars['String']>;
  show_only_personal_events_per_therapist?: InputMaybe<Scalars['Boolean']>;
  sms_notifications_sentsms?: InputMaybe<Sms_Notifications_Sentsms_Arr_Rel_Insert_Input>;
  sms_notifications_smsconfig?: InputMaybe<Sms_Notifications_Smsconfig_Obj_Rel_Insert_Input>;
  sms_notifications_smspacketpurchases?: InputMaybe<Sms_Notifications_Smspacketpurchase_Arr_Rel_Insert_Input>;
  sms_notifications_smstemplates?: InputMaybe<Sms_Notifications_Smstemplate_Arr_Rel_Insert_Input>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worktime_config?: InputMaybe<Worktime_Configs_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Practices_Max_Fields = {
  __typename?: 'practices_max_fields';
  billing_address_id?: Maybe<Scalars['bigint']>;
  billing_info_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  logo_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "practices" */
export type Practices_Max_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Practices_Min_Fields = {
  __typename?: 'practices_min_fields';
  billing_address_id?: Maybe<Scalars['bigint']>;
  billing_info_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  logo_id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "practices" */
export type Practices_Min_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "practices" */
export type Practices_Mutation_Response = {
  __typename?: 'practices_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Practices>;
};

/** input type for inserting object relation for remote table "practices" */
export type Practices_Obj_Rel_Insert_Input = {
  data: Practices_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Practices_On_Conflict>;
};

/** on_conflict condition type for table "practices" */
export type Practices_On_Conflict = {
  constraint: Practices_Constraint;
  update_columns?: Array<Practices_Update_Column>;
  where?: InputMaybe<Practices_Bool_Exp>;
};

/** Ordering options when selecting data from "practices". */
export type Practices_Order_By = {
  address?: InputMaybe<Addresses_Order_By>;
  allow_reception_to_see_event_notes?: InputMaybe<Order_By>;
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_detail?: InputMaybe<Contact_Details_Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  hide_employee_info?: InputMaybe<Order_By>;
  holidays_aggregate?: InputMaybe<Holidays_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invoices_v2_billinginfo?: InputMaybe<Invoices_V2_Billinginfo_Order_By>;
  invoices_v2_invoiceconfig?: InputMaybe<Invoices_V2_Invoiceconfig_Order_By>;
  is_no_p24_config_email_sent?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  practice_branches_aggregate?: InputMaybe<Practice_Branches_Aggregate_Order_By>;
  practice_logo?: InputMaybe<Practice_Logos_Order_By>;
  registration_onlineregistrationconfig?: InputMaybe<Registration_Onlineregistrationconfig_Order_By>;
  restricted_access?: InputMaybe<Order_By>;
  services_per_therapist?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  show_only_personal_events_per_therapist?: InputMaybe<Order_By>;
  sms_notifications_sentsms_aggregate?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Order_By>;
  sms_notifications_smsconfig?: InputMaybe<Sms_Notifications_Smsconfig_Order_By>;
  sms_notifications_smspacketpurchases_aggregate?: InputMaybe<Sms_Notifications_Smspacketpurchase_Aggregate_Order_By>;
  sms_notifications_smstemplates_aggregate?: InputMaybe<Sms_Notifications_Smstemplate_Aggregate_Order_By>;
  timezone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worktime_config?: InputMaybe<Worktime_Configs_Order_By>;
};

/** primary key columns input for table: practices */
export type Practices_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "practices" */
export enum Practices_Select_Column {
  /** column name */
  AllowReceptionToSeeEventNotes = 'allow_reception_to_see_event_notes',
  /** column name */
  BillingAddressId = 'billing_address_id',
  /** column name */
  BillingInfoId = 'billing_info_id',
  /** column name */
  ContactDetailsId = 'contact_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HideEmployeeInfo = 'hide_employee_info',
  /** column name */
  Id = 'id',
  /** column name */
  IsNoP24ConfigEmailSent = 'is_no_p24_config_email_sent',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  RestrictedAccess = 'restricted_access',
  /** column name */
  ServicesPerTherapist = 'services_per_therapist',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShowOnlyPersonalEventsPerTherapist = 'show_only_personal_events_per_therapist',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "practices_aggregate_bool_exp_bool_and_arguments_columns" columns of table "practices" */
export enum Practices_Select_Column_Practices_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowReceptionToSeeEventNotes = 'allow_reception_to_see_event_notes',
  /** column name */
  HideEmployeeInfo = 'hide_employee_info',
  /** column name */
  IsNoP24ConfigEmailSent = 'is_no_p24_config_email_sent',
  /** column name */
  RestrictedAccess = 'restricted_access',
  /** column name */
  ServicesPerTherapist = 'services_per_therapist',
  /** column name */
  ShowOnlyPersonalEventsPerTherapist = 'show_only_personal_events_per_therapist'
}

/** select "practices_aggregate_bool_exp_bool_or_arguments_columns" columns of table "practices" */
export enum Practices_Select_Column_Practices_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowReceptionToSeeEventNotes = 'allow_reception_to_see_event_notes',
  /** column name */
  HideEmployeeInfo = 'hide_employee_info',
  /** column name */
  IsNoP24ConfigEmailSent = 'is_no_p24_config_email_sent',
  /** column name */
  RestrictedAccess = 'restricted_access',
  /** column name */
  ServicesPerTherapist = 'services_per_therapist',
  /** column name */
  ShowOnlyPersonalEventsPerTherapist = 'show_only_personal_events_per_therapist'
}

/** input type for updating data in table "practices" */
export type Practices_Set_Input = {
  allow_reception_to_see_event_notes?: InputMaybe<Scalars['Boolean']>;
  billing_address_id?: InputMaybe<Scalars['bigint']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hide_employee_info?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_no_p24_config_email_sent?: InputMaybe<Scalars['Boolean']>;
  logo_id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  restricted_access?: InputMaybe<Scalars['Boolean']>;
  services_per_therapist?: InputMaybe<Scalars['Boolean']>;
  short_name?: InputMaybe<Scalars['String']>;
  show_only_personal_events_per_therapist?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Practices_Stddev_Fields = {
  __typename?: 'practices_stddev_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "practices" */
export type Practices_Stddev_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Practices_Stddev_Pop_Fields = {
  __typename?: 'practices_stddev_pop_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "practices" */
export type Practices_Stddev_Pop_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Practices_Stddev_Samp_Fields = {
  __typename?: 'practices_stddev_samp_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "practices" */
export type Practices_Stddev_Samp_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "practices" */
export type Practices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Practices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Practices_Stream_Cursor_Value_Input = {
  allow_reception_to_see_event_notes?: InputMaybe<Scalars['Boolean']>;
  billing_address_id?: InputMaybe<Scalars['bigint']>;
  billing_info_id?: InputMaybe<Scalars['bigint']>;
  contact_details_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hide_employee_info?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_no_p24_config_email_sent?: InputMaybe<Scalars['Boolean']>;
  logo_id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  restricted_access?: InputMaybe<Scalars['Boolean']>;
  services_per_therapist?: InputMaybe<Scalars['Boolean']>;
  short_name?: InputMaybe<Scalars['String']>;
  show_only_personal_events_per_therapist?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Practices_Sum_Fields = {
  __typename?: 'practices_sum_fields';
  billing_address_id?: Maybe<Scalars['bigint']>;
  billing_info_id?: Maybe<Scalars['bigint']>;
  contact_details_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  logo_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "practices" */
export type Practices_Sum_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** update columns of table "practices" */
export enum Practices_Update_Column {
  /** column name */
  AllowReceptionToSeeEventNotes = 'allow_reception_to_see_event_notes',
  /** column name */
  BillingAddressId = 'billing_address_id',
  /** column name */
  BillingInfoId = 'billing_info_id',
  /** column name */
  ContactDetailsId = 'contact_details_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HideEmployeeInfo = 'hide_employee_info',
  /** column name */
  Id = 'id',
  /** column name */
  IsNoP24ConfigEmailSent = 'is_no_p24_config_email_sent',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  RestrictedAccess = 'restricted_access',
  /** column name */
  ServicesPerTherapist = 'services_per_therapist',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  ShowOnlyPersonalEventsPerTherapist = 'show_only_personal_events_per_therapist',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Practices_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Practices_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Practices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Practices_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Practices_Var_Pop_Fields = {
  __typename?: 'practices_var_pop_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "practices" */
export type Practices_Var_Pop_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Practices_Var_Samp_Fields = {
  __typename?: 'practices_var_samp_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "practices" */
export type Practices_Var_Samp_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Practices_Variance_Fields = {
  __typename?: 'practices_variance_fields';
  billing_address_id?: Maybe<Scalars['Float']>;
  billing_info_id?: Maybe<Scalars['Float']>;
  contact_details_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logo_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "practices" */
export type Practices_Variance_Order_By = {
  billing_address_id?: InputMaybe<Order_By>;
  billing_info_id?: InputMaybe<Order_By>;
  contact_details_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo_id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "accounting_practiceexpense" */
  accounting_practiceexpense: Array<Accounting_Practiceexpense>;
  /** fetch aggregated fields from the table: "accounting_practiceexpense" */
  accounting_practiceexpense_aggregate: Accounting_Practiceexpense_Aggregate;
  /** fetch data from the table: "accounting_practiceexpense" using primary key columns */
  accounting_practiceexpense_by_pk?: Maybe<Accounting_Practiceexpense>;
  /** fetch data from the table: "accounting_recurringexpense" */
  accounting_recurringexpense: Array<Accounting_Recurringexpense>;
  /** fetch aggregated fields from the table: "accounting_recurringexpense" */
  accounting_recurringexpense_aggregate: Accounting_Recurringexpense_Aggregate;
  /** fetch data from the table: "accounting_recurringexpense" using primary key columns */
  accounting_recurringexpense_by_pk?: Maybe<Accounting_Recurringexpense>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table: "avatars" */
  avatars: Array<Avatars>;
  /** fetch aggregated fields from the table: "avatars" */
  avatars_aggregate: Avatars_Aggregate;
  /** fetch data from the table: "avatars" using primary key columns */
  avatars_by_pk?: Maybe<Avatars>;
  /** fetch data from the table: "contact_details" */
  contact_details: Array<Contact_Details>;
  /** fetch aggregated fields from the table: "contact_details" */
  contact_details_aggregate: Contact_Details_Aggregate;
  /** fetch data from the table: "contact_details" using primary key columns */
  contact_details_by_pk?: Maybe<Contact_Details>;
  /** An array relationship */
  couples: Array<Couples>;
  /** An aggregate relationship */
  couples_aggregate: Couples_Aggregate;
  /** fetch data from the table: "couples" using primary key columns */
  couples_by_pk?: Maybe<Couples>;
  /** An array relationship */
  couples_patients: Array<Couples_Patients>;
  /** An aggregate relationship */
  couples_patients_aggregate: Couples_Patients_Aggregate;
  /** fetch data from the table: "couples_patients" using primary key columns */
  couples_patients_by_pk?: Maybe<Couples_Patients>;
  /** An array relationship */
  couples_therapists: Array<Couples_Therapists>;
  /** An aggregate relationship */
  couples_therapists_aggregate: Couples_Therapists_Aggregate;
  /** fetch data from the table: "couples_therapists" using primary key columns */
  couples_therapists_by_pk?: Maybe<Couples_Therapists>;
  /** An array relationship */
  ed_attachments: Array<Ed_Attachments>;
  /** An aggregate relationship */
  ed_attachments_aggregate: Ed_Attachments_Aggregate;
  /** fetch data from the table: "ed_attachments" using primary key columns */
  ed_attachments_by_pk?: Maybe<Ed_Attachments>;
  /** fetch data from the table: "ed_group_presence" */
  ed_group_presence: Array<Ed_Group_Presence>;
  /** fetch aggregated fields from the table: "ed_group_presence" */
  ed_group_presence_aggregate: Ed_Group_Presence_Aggregate;
  /** fetch data from the table: "ed_group_presence" using primary key columns */
  ed_group_presence_by_pk?: Maybe<Ed_Group_Presence>;
  /** An array relationship */
  ed_participants: Array<Ed_Participants>;
  /** An aggregate relationship */
  ed_participants_aggregate: Ed_Participants_Aggregate;
  /** fetch data from the table: "ed_participants" using primary key columns */
  ed_participants_by_pk?: Maybe<Ed_Participants>;
  /** fetch data from the table: "ed_payment_method" */
  ed_payment_method: Array<Ed_Payment_Method>;
  /** fetch aggregated fields from the table: "ed_payment_method" */
  ed_payment_method_aggregate: Ed_Payment_Method_Aggregate;
  /** fetch data from the table: "ed_payment_method" using primary key columns */
  ed_payment_method_by_pk?: Maybe<Ed_Payment_Method>;
  /** fetch data from the table: "ed_presence" */
  ed_presence: Array<Ed_Presence>;
  /** fetch aggregated fields from the table: "ed_presence" */
  ed_presence_aggregate: Ed_Presence_Aggregate;
  /** fetch data from the table: "ed_presence" using primary key columns */
  ed_presence_by_pk?: Maybe<Ed_Presence>;
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: Employees_Aggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** An array relationship */
  employees_favorite_therapists: Array<Employees_Favorite_Therapists>;
  /** An aggregate relationship */
  employees_favorite_therapists_aggregate: Employees_Favorite_Therapists_Aggregate;
  /** fetch data from the table: "employees_favorite_therapists" using primary key columns */
  employees_favorite_therapists_by_pk?: Maybe<Employees_Favorite_Therapists>;
  /** fetch data from the table: "employees_preferred_room" */
  employees_preferred_room: Array<Employees_Preferred_Room>;
  /** fetch aggregated fields from the table: "employees_preferred_room" */
  employees_preferred_room_aggregate: Employees_Preferred_Room_Aggregate;
  /** fetch data from the table: "employees_preferred_room" using primary key columns */
  employees_preferred_room_by_pk?: Maybe<Employees_Preferred_Room>;
  /** An array relationship */
  employees_roles: Array<Employees_Roles>;
  /** An aggregate relationship */
  employees_roles_aggregate: Employees_Roles_Aggregate;
  /** fetch data from the table: "employees_roles" using primary key columns */
  employees_roles_by_pk?: Maybe<Employees_Roles>;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** An array relationship */
  event_participants: Array<Event_Participants>;
  /** An aggregate relationship */
  event_participants_aggregate: Event_Participants_Aggregate;
  /** fetch data from the table: "event_participants" using primary key columns */
  event_participants_by_pk?: Maybe<Event_Participants>;
  /** fetch data from the table: "event_period" */
  event_period: Array<Event_Period>;
  /** fetch aggregated fields from the table: "event_period" */
  event_period_aggregate: Event_Period_Aggregate;
  /** fetch data from the table: "event_period" using primary key columns */
  event_period_by_pk?: Maybe<Event_Period>;
  /** An array relationship */
  event_period_participants: Array<Event_Period_Participants>;
  /** An aggregate relationship */
  event_period_participants_aggregate: Event_Period_Participants_Aggregate;
  /** fetch data from the table: "event_period_participants" using primary key columns */
  event_period_participants_by_pk?: Maybe<Event_Period_Participants>;
  /** An array relationship */
  event_period_services: Array<Event_Period_Services>;
  /** An aggregate relationship */
  event_period_services_aggregate: Event_Period_Services_Aggregate;
  /** fetch data from the table: "event_period_services" using primary key columns */
  event_period_services_by_pk?: Maybe<Event_Period_Services>;
  /** An array relationship */
  event_period_therapists: Array<Event_Period_Therapists>;
  /** An aggregate relationship */
  event_period_therapists_aggregate: Event_Period_Therapists_Aggregate;
  /** fetch data from the table: "event_period_therapists" using primary key columns */
  event_period_therapists_by_pk?: Maybe<Event_Period_Therapists>;
  /** An array relationship */
  event_services: Array<Event_Services>;
  /** An aggregate relationship */
  event_services_aggregate: Event_Services_Aggregate;
  /** fetch data from the table: "event_services" using primary key columns */
  event_services_by_pk?: Maybe<Event_Services>;
  /** An array relationship */
  event_therapists: Array<Event_Therapists>;
  /** An aggregate relationship */
  event_therapists_aggregate: Event_Therapists_Aggregate;
  /** fetch data from the table: "event_therapists" using primary key columns */
  event_therapists_by_pk?: Maybe<Event_Therapists>;
  /** fetch data from the table: "gdpr_gdprconfig" */
  gdpr_gdprconfig: Array<Gdpr_Gdprconfig>;
  /** fetch aggregated fields from the table: "gdpr_gdprconfig" */
  gdpr_gdprconfig_aggregate: Gdpr_Gdprconfig_Aggregate;
  /** fetch data from the table: "gdpr_gdprconfig" using primary key columns */
  gdpr_gdprconfig_by_pk?: Maybe<Gdpr_Gdprconfig>;
  /** fetch data from the table: "gdpr_gdprdocument" */
  gdpr_gdprdocument: Array<Gdpr_Gdprdocument>;
  /** fetch aggregated fields from the table: "gdpr_gdprdocument" */
  gdpr_gdprdocument_aggregate: Gdpr_Gdprdocument_Aggregate;
  /** fetch data from the table: "gdpr_gdprdocument" using primary key columns */
  gdpr_gdprdocument_by_pk?: Maybe<Gdpr_Gdprdocument>;
  /** fetch data from the table: "gdpr_gdprdocumenttype" */
  gdpr_gdprdocumenttype: Array<Gdpr_Gdprdocumenttype>;
  /** fetch aggregated fields from the table: "gdpr_gdprdocumenttype" */
  gdpr_gdprdocumenttype_aggregate: Gdpr_Gdprdocumenttype_Aggregate;
  /** fetch data from the table: "gdpr_gdprdocumenttype" using primary key columns */
  gdpr_gdprdocumenttype_by_pk?: Maybe<Gdpr_Gdprdocumenttype>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "holiday_reasons" */
  holiday_reasons: Array<Holiday_Reasons>;
  /** fetch aggregated fields from the table: "holiday_reasons" */
  holiday_reasons_aggregate: Holiday_Reasons_Aggregate;
  /** fetch data from the table: "holiday_reasons" using primary key columns */
  holiday_reasons_by_pk?: Maybe<Holiday_Reasons>;
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  /** fetch data from the table: "holidays" using primary key columns */
  holidays_by_pk?: Maybe<Holidays>;
  /** fetch data from the table: "invoices_v2_billinginfo" */
  invoices_v2_billinginfo: Array<Invoices_V2_Billinginfo>;
  /** fetch aggregated fields from the table: "invoices_v2_billinginfo" */
  invoices_v2_billinginfo_aggregate: Invoices_V2_Billinginfo_Aggregate;
  /** fetch data from the table: "invoices_v2_billinginfo" using primary key columns */
  invoices_v2_billinginfo_by_pk?: Maybe<Invoices_V2_Billinginfo>;
  /** fetch data from the table: "invoices_v2_invoice" */
  invoices_v2_invoice: Array<Invoices_V2_Invoice>;
  /** fetch aggregated fields from the table: "invoices_v2_invoice" */
  invoices_v2_invoice_aggregate: Invoices_V2_Invoice_Aggregate;
  /** fetch data from the table: "invoices_v2_invoice" using primary key columns */
  invoices_v2_invoice_by_pk?: Maybe<Invoices_V2_Invoice>;
  /** fetch data from the table: "invoices_v2_invoiceconfig" */
  invoices_v2_invoiceconfig: Array<Invoices_V2_Invoiceconfig>;
  /** fetch aggregated fields from the table: "invoices_v2_invoiceconfig" */
  invoices_v2_invoiceconfig_aggregate: Invoices_V2_Invoiceconfig_Aggregate;
  /** fetch data from the table: "invoices_v2_invoiceconfig" using primary key columns */
  invoices_v2_invoiceconfig_by_pk?: Maybe<Invoices_V2_Invoiceconfig>;
  /** fetch data from the table: "invoices_v2_invoicelineitem" */
  invoices_v2_invoicelineitem: Array<Invoices_V2_Invoicelineitem>;
  /** fetch aggregated fields from the table: "invoices_v2_invoicelineitem" */
  invoices_v2_invoicelineitem_aggregate: Invoices_V2_Invoicelineitem_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicelineitem" using primary key columns */
  invoices_v2_invoicelineitem_by_pk?: Maybe<Invoices_V2_Invoicelineitem>;
  /** An array relationship */
  invoices_v2_invoicelineitem_events: Array<Invoices_V2_Invoicelineitem_Events>;
  /** An aggregate relationship */
  invoices_v2_invoicelineitem_events_aggregate: Invoices_V2_Invoicelineitem_Events_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicelineitem_events" using primary key columns */
  invoices_v2_invoicelineitem_events_by_pk?: Maybe<Invoices_V2_Invoicelineitem_Events>;
  /** fetch data from the table: "invoices_v2_invoicesentto" */
  invoices_v2_invoicesentto: Array<Invoices_V2_Invoicesentto>;
  /** fetch aggregated fields from the table: "invoices_v2_invoicesentto" */
  invoices_v2_invoicesentto_aggregate: Invoices_V2_Invoicesentto_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicesentto" using primary key columns */
  invoices_v2_invoicesentto_by_pk?: Maybe<Invoices_V2_Invoicesentto>;
  /** An array relationship */
  national_holidays: Array<National_Holidays>;
  /** An aggregate relationship */
  national_holidays_aggregate: National_Holidays_Aggregate;
  /** fetch data from the table: "national_holidays" using primary key columns */
  national_holidays_by_pk?: Maybe<National_Holidays>;
  /** fetch data from the table: "patient_addresses" */
  patient_addresses: Array<Patient_Addresses>;
  /** fetch aggregated fields from the table: "patient_addresses" */
  patient_addresses_aggregate: Patient_Addresses_Aggregate;
  /** fetch data from the table: "patient_addresses" using primary key columns */
  patient_addresses_by_pk?: Maybe<Patient_Addresses>;
  /** fetch data from the table: "patient_educator" */
  patient_educator: Array<Patient_Educator>;
  /** fetch aggregated fields from the table: "patient_educator" */
  patient_educator_aggregate: Patient_Educator_Aggregate;
  /** fetch data from the table: "patient_educator" using primary key columns */
  patient_educator_by_pk?: Maybe<Patient_Educator>;
  /** fetch data from the table: "patient_guardian_addresses" */
  patient_guardian_addresses: Array<Patient_Guardian_Addresses>;
  /** fetch aggregated fields from the table: "patient_guardian_addresses" */
  patient_guardian_addresses_aggregate: Patient_Guardian_Addresses_Aggregate;
  /** fetch data from the table: "patient_guardian_addresses" using primary key columns */
  patient_guardian_addresses_by_pk?: Maybe<Patient_Guardian_Addresses>;
  /** fetch data from the table: "patient_guardians" */
  patient_guardians: Array<Patient_Guardians>;
  /** fetch aggregated fields from the table: "patient_guardians" */
  patient_guardians_aggregate: Patient_Guardians_Aggregate;
  /** fetch data from the table: "patient_guardians" using primary key columns */
  patient_guardians_by_pk?: Maybe<Patient_Guardians>;
  /** fetch data from the table: "patient_psychotherapies" */
  patient_psychotherapies: Array<Patient_Psychotherapies>;
  /** fetch aggregated fields from the table: "patient_psychotherapies" */
  patient_psychotherapies_aggregate: Patient_Psychotherapies_Aggregate;
  /** fetch data from the table: "patient_psychotherapies" using primary key columns */
  patient_psychotherapies_by_pk?: Maybe<Patient_Psychotherapies>;
  /** fetch data from the table: "patient_si" */
  patient_si: Array<Patient_Si>;
  /** fetch aggregated fields from the table: "patient_si" */
  patient_si_aggregate: Patient_Si_Aggregate;
  /** fetch data from the table: "patient_si" using primary key columns */
  patient_si_by_pk?: Maybe<Patient_Si>;
  /** fetch data from the table: "patient_speech_therapies" */
  patient_speech_therapies: Array<Patient_Speech_Therapies>;
  /** fetch aggregated fields from the table: "patient_speech_therapies" */
  patient_speech_therapies_aggregate: Patient_Speech_Therapies_Aggregate;
  /** fetch data from the table: "patient_speech_therapies" using primary key columns */
  patient_speech_therapies_by_pk?: Maybe<Patient_Speech_Therapies>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table: "patients_therapists" */
  patients_therapists: Array<Patients_Therapists>;
  /** fetch aggregated fields from the table: "patients_therapists" */
  patients_therapists_aggregate: Patients_Therapists_Aggregate;
  /** fetch data from the table: "patients_therapists" using primary key columns */
  patients_therapists_by_pk?: Maybe<Patients_Therapists>;
  /** An array relationship */
  practice_branches: Array<Practice_Branches>;
  /** An aggregate relationship */
  practice_branches_aggregate: Practice_Branches_Aggregate;
  /** fetch data from the table: "practice_branches" using primary key columns */
  practice_branches_by_pk?: Maybe<Practice_Branches>;
  /** fetch data from the table: "practice_logos" */
  practice_logos: Array<Practice_Logos>;
  /** fetch aggregated fields from the table: "practice_logos" */
  practice_logos_aggregate: Practice_Logos_Aggregate;
  /** fetch data from the table: "practice_logos" using primary key columns */
  practice_logos_by_pk?: Maybe<Practice_Logos>;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  /** fetch data from the table: "practices" using primary key columns */
  practices_by_pk?: Maybe<Practices>;
  /** fetch data from the table: "registration_authsmsconfirmation" */
  registration_authsmsconfirmation: Array<Registration_Authsmsconfirmation>;
  /** fetch aggregated fields from the table: "registration_authsmsconfirmation" */
  registration_authsmsconfirmation_aggregate: Registration_Authsmsconfirmation_Aggregate;
  /** fetch data from the table: "registration_authsmsconfirmation" using primary key columns */
  registration_authsmsconfirmation_by_pk?: Maybe<Registration_Authsmsconfirmation>;
  /** fetch data from the table: "registration_meetingrequest" */
  registration_meetingrequest: Array<Registration_Meetingrequest>;
  /** fetch aggregated fields from the table: "registration_meetingrequest" */
  registration_meetingrequest_aggregate: Registration_Meetingrequest_Aggregate;
  /** fetch data from the table: "registration_meetingrequest" using primary key columns */
  registration_meetingrequest_by_pk?: Maybe<Registration_Meetingrequest>;
  /** fetch data from the table: "registration_onlineregistrationconfig" */
  registration_onlineregistrationconfig: Array<Registration_Onlineregistrationconfig>;
  /** fetch aggregated fields from the table: "registration_onlineregistrationconfig" */
  registration_onlineregistrationconfig_aggregate: Registration_Onlineregistrationconfig_Aggregate;
  /** fetch data from the table: "registration_onlineregistrationconfig" using primary key columns */
  registration_onlineregistrationconfig_by_pk?: Maybe<Registration_Onlineregistrationconfig>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** An array relationship */
  request_considerations: Array<Request_Considerations>;
  /** An aggregate relationship */
  request_considerations_aggregate: Request_Considerations_Aggregate;
  /** fetch data from the table: "request_considerations" using primary key columns */
  request_considerations_by_pk?: Maybe<Request_Considerations>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** An array relationship */
  services: Array<Services>;
  /** An aggregate relationship */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services_assigned_to" */
  services_assigned_to: Array<Services_Assigned_To>;
  /** fetch aggregated fields from the table: "services_assigned_to" */
  services_assigned_to_aggregate: Services_Assigned_To_Aggregate;
  /** fetch data from the table: "services_assigned_to" using primary key columns */
  services_assigned_to_by_pk?: Maybe<Services_Assigned_To>;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** An array relationship */
  services_restricted_rooms: Array<Services_Restricted_Rooms>;
  /** An aggregate relationship */
  services_restricted_rooms_aggregate: Services_Restricted_Rooms_Aggregate;
  /** fetch data from the table: "services_restricted_rooms" using primary key columns */
  services_restricted_rooms_by_pk?: Maybe<Services_Restricted_Rooms>;
  /** fetch data from the table: "sms_notifications_defaultsmstemplate" */
  sms_notifications_defaultsmstemplate: Array<Sms_Notifications_Defaultsmstemplate>;
  /** fetch aggregated fields from the table: "sms_notifications_defaultsmstemplate" */
  sms_notifications_defaultsmstemplate_aggregate: Sms_Notifications_Defaultsmstemplate_Aggregate;
  /** fetch data from the table: "sms_notifications_defaultsmstemplate" using primary key columns */
  sms_notifications_defaultsmstemplate_by_pk?: Maybe<Sms_Notifications_Defaultsmstemplate>;
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  /** fetch data from the table: "sms_notifications_sentsms" using primary key columns */
  sms_notifications_sentsms_by_pk?: Maybe<Sms_Notifications_Sentsms>;
  /** fetch data from the table: "sms_notifications_smsconfig" */
  sms_notifications_smsconfig: Array<Sms_Notifications_Smsconfig>;
  /** fetch aggregated fields from the table: "sms_notifications_smsconfig" */
  sms_notifications_smsconfig_aggregate: Sms_Notifications_Smsconfig_Aggregate;
  /** fetch data from the table: "sms_notifications_smsconfig" using primary key columns */
  sms_notifications_smsconfig_by_pk?: Maybe<Sms_Notifications_Smsconfig>;
  /** fetch data from the table: "sms_notifications_smspacketpurchase" */
  sms_notifications_smspacketpurchase: Array<Sms_Notifications_Smspacketpurchase>;
  /** fetch aggregated fields from the table: "sms_notifications_smspacketpurchase" */
  sms_notifications_smspacketpurchase_aggregate: Sms_Notifications_Smspacketpurchase_Aggregate;
  /** fetch data from the table: "sms_notifications_smspacketpurchase" using primary key columns */
  sms_notifications_smspacketpurchase_by_pk?: Maybe<Sms_Notifications_Smspacketpurchase>;
  /** fetch data from the table: "sms_notifications_smstemplate" */
  sms_notifications_smstemplate: Array<Sms_Notifications_Smstemplate>;
  /** fetch aggregated fields from the table: "sms_notifications_smstemplate" */
  sms_notifications_smstemplate_aggregate: Sms_Notifications_Smstemplate_Aggregate;
  /** fetch data from the table: "sms_notifications_smstemplate" using primary key columns */
  sms_notifications_smstemplate_by_pk?: Maybe<Sms_Notifications_Smstemplate>;
  /** fetch data from the table: "sms_notifications_smstemplatevariable" */
  sms_notifications_smstemplatevariable: Array<Sms_Notifications_Smstemplatevariable>;
  /** fetch aggregated fields from the table: "sms_notifications_smstemplatevariable" */
  sms_notifications_smstemplatevariable_aggregate: Sms_Notifications_Smstemplatevariable_Aggregate;
  /** fetch data from the table: "sms_notifications_smstemplatevariable" using primary key columns */
  sms_notifications_smstemplatevariable_by_pk?: Maybe<Sms_Notifications_Smstemplatevariable>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** An array relationship */
  user_groups: Array<User_Groups>;
  /** An aggregate relationship */
  user_groups_aggregate: User_Groups_Aggregate;
  /** fetch data from the table: "user_groups" using primary key columns */
  user_groups_by_pk?: Maybe<User_Groups>;
  /** fetch data from the table: "user_identity" */
  user_identity: Array<User_Identity>;
  /** fetch aggregated fields from the table: "user_identity" */
  user_identity_aggregate: User_Identity_Aggregate;
  /** fetch data from the table: "user_identity" using primary key columns */
  user_identity_by_pk?: Maybe<User_Identity>;
  /** An array relationship */
  user_user_permissions: Array<User_User_Permissions>;
  /** An aggregate relationship */
  user_user_permissions_aggregate: User_User_Permissions_Aggregate;
  /** fetch data from the table: "user_user_permissions" using primary key columns */
  user_user_permissions_by_pk?: Maybe<User_User_Permissions>;
  /** An array relationship */
  workdays: Array<Workdays>;
  /** An aggregate relationship */
  workdays_aggregate: Workdays_Aggregate;
  /** fetch data from the table: "workdays" using primary key columns */
  workdays_by_pk?: Maybe<Workdays>;
  /** fetch data from the table: "worktime_configs" */
  worktime_configs: Array<Worktime_Configs>;
  /** fetch aggregated fields from the table: "worktime_configs" */
  worktime_configs_aggregate: Worktime_Configs_Aggregate;
  /** fetch data from the table: "worktime_configs" using primary key columns */
  worktime_configs_by_pk?: Maybe<Worktime_Configs>;
  /** An array relationship */
  worktime_exceptions: Array<Worktime_Exceptions>;
  /** An aggregate relationship */
  worktime_exceptions_aggregate: Worktime_Exceptions_Aggregate;
  /** fetch data from the table: "worktime_exceptions" using primary key columns */
  worktime_exceptions_by_pk?: Maybe<Worktime_Exceptions>;
  /** An array relationship */
  worktime_exceptions_work_times: Array<Worktime_Exceptions_Work_Times>;
  /** An aggregate relationship */
  worktime_exceptions_work_times_aggregate: Worktime_Exceptions_Work_Times_Aggregate;
  /** fetch data from the table: "worktime_exceptions_work_times" using primary key columns */
  worktime_exceptions_work_times_by_pk?: Maybe<Worktime_Exceptions_Work_Times>;
  /** An array relationship */
  worktime_presets: Array<Worktime_Presets>;
  /** An aggregate relationship */
  worktime_presets_aggregate: Worktime_Presets_Aggregate;
  /** fetch data from the table: "worktime_presets" using primary key columns */
  worktime_presets_by_pk?: Maybe<Worktime_Presets>;
  /** fetch data from the table: "worktimes" */
  worktimes: Array<Worktimes>;
  /** fetch aggregated fields from the table: "worktimes" */
  worktimes_aggregate: Worktimes_Aggregate;
  /** fetch data from the table: "worktimes" using primary key columns */
  worktimes_by_pk?: Maybe<Worktimes>;
};


export type Query_RootAccounting_PracticeexpenseArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


export type Query_RootAccounting_Practiceexpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


export type Query_RootAccounting_Practiceexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccounting_RecurringexpenseArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Recurringexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Recurringexpense_Order_By>>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};


export type Query_RootAccounting_Recurringexpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Recurringexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Recurringexpense_Order_By>>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};


export type Query_RootAccounting_Recurringexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Query_RootAddresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAvatarsArgs = {
  distinct_on?: InputMaybe<Array<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Query_RootAvatars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Query_RootAvatars_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};


export type Query_RootContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};


export type Query_RootContact_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCouplesArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


export type Query_RootCouples_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


export type Query_RootCouples_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCouples_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


export type Query_RootCouples_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


export type Query_RootCouples_Patients_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCouples_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


export type Query_RootCouples_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


export type Query_RootCouples_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEd_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


export type Query_RootEd_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


export type Query_RootEd_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEd_Group_PresenceArgs = {
  distinct_on?: InputMaybe<Array<Ed_Group_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Group_Presence_Order_By>>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};


export type Query_RootEd_Group_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Group_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Group_Presence_Order_By>>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};


export type Query_RootEd_Group_Presence_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootEd_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


export type Query_RootEd_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


export type Query_RootEd_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEd_Payment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Ed_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Payment_Method_Order_By>>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};


export type Query_RootEd_Payment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Payment_Method_Order_By>>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};


export type Query_RootEd_Payment_Method_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootEd_PresenceArgs = {
  distinct_on?: InputMaybe<Array<Ed_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Presence_Order_By>>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};


export type Query_RootEd_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Presence_Order_By>>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};


export type Query_RootEd_Presence_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


export type Query_RootEmployees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


export type Query_RootEmployees_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmployees_Favorite_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


export type Query_RootEmployees_Favorite_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


export type Query_RootEmployees_Favorite_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmployees_Preferred_RoomArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


export type Query_RootEmployees_Preferred_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


export type Query_RootEmployees_Preferred_Room_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEmployees_RolesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


export type Query_RootEmployees_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


export type Query_RootEmployees_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


export type Query_RootEvent_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


export type Query_RootEvent_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


export type Query_RootEvent_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


export type Query_RootEvent_Period_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_Period_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


export type Query_RootEvent_Period_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


export type Query_RootEvent_Period_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_Period_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


export type Query_RootEvent_Period_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


export type Query_RootEvent_Period_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_Period_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


export type Query_RootEvent_Period_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


export type Query_RootEvent_Period_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


export type Query_RootEvent_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


export type Query_RootEvent_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootEvent_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


export type Query_RootEvent_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


export type Query_RootEvent_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGdpr_GdprconfigArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprconfig_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};


export type Query_RootGdpr_Gdprconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprconfig_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};


export type Query_RootGdpr_Gdprconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGdpr_GdprdocumentArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


export type Query_RootGdpr_Gdprdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


export type Query_RootGdpr_Gdprdocument_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGdpr_GdprdocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};


export type Query_RootGdpr_Gdprdocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};


export type Query_RootGdpr_Gdprdocumenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootHoliday_ReasonsArgs = {
  distinct_on?: InputMaybe<Array<Holiday_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holiday_Reasons_Order_By>>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};


export type Query_RootHoliday_Reasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holiday_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holiday_Reasons_Order_By>>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};


export type Query_RootHoliday_Reasons_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


export type Query_RootHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


export type Query_RootHolidays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_BillinginfoArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Billinginfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Billinginfo_Order_By>>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};


export type Query_RootInvoices_V2_Billinginfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Billinginfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Billinginfo_Order_By>>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};


export type Query_RootInvoices_V2_Billinginfo_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_InvoiceconfigArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoiceconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoiceconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_InvoicelineitemArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicelineitem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicelineitem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_Invoicelineitem_EventsArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicelineitem_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicelineitem_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootInvoices_V2_InvoicesenttoArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicesentto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


export type Query_RootInvoices_V2_Invoicesentto_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootNational_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


export type Query_RootNational_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


export type Query_RootNational_Holidays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatient_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};


export type Query_RootPatient_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};


export type Query_RootPatient_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatient_EducatorArgs = {
  distinct_on?: InputMaybe<Array<Patient_Educator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Educator_Order_By>>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};


export type Query_RootPatient_Educator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Educator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Educator_Order_By>>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};


export type Query_RootPatient_Educator_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Query_RootPatient_Guardian_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardian_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardian_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};


export type Query_RootPatient_Guardian_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardian_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardian_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};


export type Query_RootPatient_Guardian_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatient_GuardiansArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


export type Query_RootPatient_Guardians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


export type Query_RootPatient_Guardians_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatient_PsychotherapiesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Psychotherapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Psychotherapies_Order_By>>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};


export type Query_RootPatient_Psychotherapies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Psychotherapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Psychotherapies_Order_By>>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};


export type Query_RootPatient_Psychotherapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Query_RootPatient_SiArgs = {
  distinct_on?: InputMaybe<Array<Patient_Si_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Si_Order_By>>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};


export type Query_RootPatient_Si_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Si_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Si_Order_By>>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};


export type Query_RootPatient_Si_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Query_RootPatient_Speech_TherapiesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Speech_Therapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Speech_Therapies_Order_By>>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};


export type Query_RootPatient_Speech_Therapies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Speech_Therapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Speech_Therapies_Order_By>>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};


export type Query_RootPatient_Speech_Therapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Query_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Query_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Query_RootPatients_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPatients_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


export type Query_RootPatients_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


export type Query_RootPatients_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPractice_BranchesArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


export type Query_RootPractice_Branches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


export type Query_RootPractice_Branches_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPractice_LogosArgs = {
  distinct_on?: InputMaybe<Array<Practice_Logos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Logos_Order_By>>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};


export type Query_RootPractice_Logos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Logos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Logos_Order_By>>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};


export type Query_RootPractice_Logos_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


export type Query_RootPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


export type Query_RootPractices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRegistration_AuthsmsconfirmationArgs = {
  distinct_on?: InputMaybe<Array<Registration_Authsmsconfirmation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Authsmsconfirmation_Order_By>>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};


export type Query_RootRegistration_Authsmsconfirmation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Authsmsconfirmation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Authsmsconfirmation_Order_By>>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};


export type Query_RootRegistration_Authsmsconfirmation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRegistration_MeetingrequestArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


export type Query_RootRegistration_Meetingrequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


export type Query_RootRegistration_Meetingrequest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRegistration_OnlineregistrationconfigArgs = {
  distinct_on?: InputMaybe<Array<Registration_Onlineregistrationconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Onlineregistrationconfig_Order_By>>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};


export type Query_RootRegistration_Onlineregistrationconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Onlineregistrationconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Onlineregistrationconfig_Order_By>>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};


export type Query_RootRegistration_Onlineregistrationconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRequest_ConsiderationsArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


export type Query_RootRequest_Considerations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


export type Query_RootRequest_Considerations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  slug: Scalars['String'];
};


export type Query_RootRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Query_RootRooms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Query_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Query_RootServices_Assigned_ToArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


export type Query_RootServices_Assigned_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


export type Query_RootServices_Assigned_To_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootServices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootServices_Restricted_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


export type Query_RootServices_Restricted_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


export type Query_RootServices_Restricted_Rooms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_DefaultsmstemplateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};


export type Query_RootSms_Notifications_Defaultsmstemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};


export type Query_RootSms_Notifications_Defaultsmstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


export type Query_RootSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


export type Query_RootSms_Notifications_Sentsms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_SmsconfigArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smsconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smsconfig_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smsconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smsconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smsconfig_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smsconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_SmspacketpurchaseArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smspacketpurchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smspacketpurchase_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_SmstemplateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smstemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootSms_Notifications_SmstemplatevariableArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smstemplatevariable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};


export type Query_RootSms_Notifications_Smstemplatevariable_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_GroupsArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


export type Query_RootUser_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


export type Query_RootUser_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootUser_IdentityArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


export type Query_RootUser_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


export type Query_RootUser_Identity_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


export type Query_RootUser_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


export type Query_RootUser_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorkdaysArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


export type Query_RootWorkdays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


export type Query_RootWorkdays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorktime_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Configs_Order_By>>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};


export type Query_RootWorktime_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Configs_Order_By>>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};


export type Query_RootWorktime_Configs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorktime_ExceptionsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


export type Query_RootWorktime_Exceptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


export type Query_RootWorktime_Exceptions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorktime_Exceptions_Work_TimesArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


export type Query_RootWorktime_Exceptions_Work_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


export type Query_RootWorktime_Exceptions_Work_Times_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorktime_PresetsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


export type Query_RootWorktime_Presets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


export type Query_RootWorktime_Presets_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootWorktimesArgs = {
  distinct_on?: InputMaybe<Array<Worktimes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktimes_Order_By>>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};


export type Query_RootWorktimes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktimes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktimes_Order_By>>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};


export type Query_RootWorktimes_By_PkArgs = {
  id: Scalars['bigint'];
};

/** columns and relationships of "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation = {
  __typename?: 'registration_authsmsconfirmation';
  confirmation_pin: Scalars['String'];
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  message: Scalars['String'];
  phone_number: Scalars['String'];
  sent_at?: Maybe<Scalars['timestamptz']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Aggregate = {
  __typename?: 'registration_authsmsconfirmation_aggregate';
  aggregate?: Maybe<Registration_Authsmsconfirmation_Aggregate_Fields>;
  nodes: Array<Registration_Authsmsconfirmation>;
};

/** aggregate fields of "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Aggregate_Fields = {
  __typename?: 'registration_authsmsconfirmation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Registration_Authsmsconfirmation_Max_Fields>;
  min?: Maybe<Registration_Authsmsconfirmation_Min_Fields>;
};


/** aggregate fields of "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Registration_Authsmsconfirmation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "registration_authsmsconfirmation". All fields are combined with a logical 'AND'. */
export type Registration_Authsmsconfirmation_Bool_Exp = {
  _and?: InputMaybe<Array<Registration_Authsmsconfirmation_Bool_Exp>>;
  _not?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
  _or?: InputMaybe<Array<Registration_Authsmsconfirmation_Bool_Exp>>;
  confirmation_pin?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "registration_authsmsconfirmation" */
export enum Registration_Authsmsconfirmation_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationAuthsmsconfirmationPkey = 'registration_authsmsconfirmation_pkey'
}

/** input type for inserting data into table "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Insert_Input = {
  confirmation_pin?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Registration_Authsmsconfirmation_Max_Fields = {
  __typename?: 'registration_authsmsconfirmation_max_fields';
  confirmation_pin?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Registration_Authsmsconfirmation_Min_Fields = {
  __typename?: 'registration_authsmsconfirmation_min_fields';
  confirmation_pin?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Mutation_Response = {
  __typename?: 'registration_authsmsconfirmation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Registration_Authsmsconfirmation>;
};

/** on_conflict condition type for table "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_On_Conflict = {
  constraint: Registration_Authsmsconfirmation_Constraint;
  update_columns?: Array<Registration_Authsmsconfirmation_Update_Column>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};

/** Ordering options when selecting data from "registration_authsmsconfirmation". */
export type Registration_Authsmsconfirmation_Order_By = {
  confirmation_pin?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: registration_authsmsconfirmation */
export type Registration_Authsmsconfirmation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "registration_authsmsconfirmation" */
export enum Registration_Authsmsconfirmation_Select_Column {
  /** column name */
  ConfirmationPin = 'confirmation_pin',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Set_Input = {
  confirmation_pin?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "registration_authsmsconfirmation" */
export type Registration_Authsmsconfirmation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Registration_Authsmsconfirmation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Registration_Authsmsconfirmation_Stream_Cursor_Value_Input = {
  confirmation_pin?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "registration_authsmsconfirmation" */
export enum Registration_Authsmsconfirmation_Update_Column {
  /** column name */
  ConfirmationPin = 'confirmation_pin',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Registration_Authsmsconfirmation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Registration_Authsmsconfirmation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Registration_Authsmsconfirmation_Bool_Exp;
};

/** columns and relationships of "registration_meetingrequest" */
export type Registration_Meetingrequest = {
  __typename?: 'registration_meetingrequest';
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An object relationship */
  employee?: Maybe<Employees>;
  /** An object relationship */
  employeeByTherapistId: Employees;
  event_id?: Maybe<Scalars['bigint']>;
  first_name: Scalars['String'];
  id: Scalars['bigint'];
  last_name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  old_start?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number: Scalars['String'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  service: Services;
  service_id: Scalars['bigint'];
  start: Scalars['timestamptz'];
  therapist_id: Scalars['bigint'];
  token: Scalars['uuid'];
  transaction_id?: Maybe<Scalars['bigint']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "registration_meetingrequest" */
export type Registration_Meetingrequest_Aggregate = {
  __typename?: 'registration_meetingrequest_aggregate';
  aggregate?: Maybe<Registration_Meetingrequest_Aggregate_Fields>;
  nodes: Array<Registration_Meetingrequest>;
};

export type Registration_Meetingrequest_Aggregate_Bool_Exp = {
  count?: InputMaybe<Registration_Meetingrequest_Aggregate_Bool_Exp_Count>;
};

export type Registration_Meetingrequest_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "registration_meetingrequest" */
export type Registration_Meetingrequest_Aggregate_Fields = {
  __typename?: 'registration_meetingrequest_aggregate_fields';
  avg?: Maybe<Registration_Meetingrequest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Registration_Meetingrequest_Max_Fields>;
  min?: Maybe<Registration_Meetingrequest_Min_Fields>;
  stddev?: Maybe<Registration_Meetingrequest_Stddev_Fields>;
  stddev_pop?: Maybe<Registration_Meetingrequest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registration_Meetingrequest_Stddev_Samp_Fields>;
  sum?: Maybe<Registration_Meetingrequest_Sum_Fields>;
  var_pop?: Maybe<Registration_Meetingrequest_Var_Pop_Fields>;
  var_samp?: Maybe<Registration_Meetingrequest_Var_Samp_Fields>;
  variance?: Maybe<Registration_Meetingrequest_Variance_Fields>;
};


/** aggregate fields of "registration_meetingrequest" */
export type Registration_Meetingrequest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Aggregate_Order_By = {
  avg?: InputMaybe<Registration_Meetingrequest_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Registration_Meetingrequest_Max_Order_By>;
  min?: InputMaybe<Registration_Meetingrequest_Min_Order_By>;
  stddev?: InputMaybe<Registration_Meetingrequest_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Registration_Meetingrequest_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Registration_Meetingrequest_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Registration_Meetingrequest_Sum_Order_By>;
  var_pop?: InputMaybe<Registration_Meetingrequest_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Registration_Meetingrequest_Var_Samp_Order_By>;
  variance?: InputMaybe<Registration_Meetingrequest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "registration_meetingrequest" */
export type Registration_Meetingrequest_Arr_Rel_Insert_Input = {
  data: Array<Registration_Meetingrequest_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Registration_Meetingrequest_On_Conflict>;
};

/** aggregate avg on columns */
export type Registration_Meetingrequest_Avg_Fields = {
  __typename?: 'registration_meetingrequest_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "registration_meetingrequest". All fields are combined with a logical 'AND'. */
export type Registration_Meetingrequest_Bool_Exp = {
  _and?: InputMaybe<Array<Registration_Meetingrequest_Bool_Exp>>;
  _not?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  _or?: InputMaybe<Array<Registration_Meetingrequest_Bool_Exp>>;
  confirmed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employeeByTherapistId?: InputMaybe<Employees_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  old_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  rejected_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  rejected_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
  start?: InputMaybe<Timestamptz_Comparison_Exp>;
  therapist_id?: InputMaybe<Bigint_Comparison_Exp>;
  token?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "registration_meetingrequest" */
export enum Registration_Meetingrequest_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegistrationMeetingrequestPkey = 'registration_meetingrequest_pkey',
  /** unique or primary key constraint on columns "transaction_id" */
  RegistrationMeetingrequestTransactionIdKey = 'registration_meetingrequest_transaction_id_key'
}

/** input type for incrementing numeric columns in table "registration_meetingrequest" */
export type Registration_Meetingrequest_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  rejected_by_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "registration_meetingrequest" */
export type Registration_Meetingrequest_Insert_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employeeByTherapistId?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  old_start?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by_id?: InputMaybe<Scalars['bigint']>;
  service?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  token?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Registration_Meetingrequest_Max_Fields = {
  __typename?: 'registration_meetingrequest_max_fields';
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  old_start?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Max_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  old_start?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Registration_Meetingrequest_Min_Fields = {
  __typename?: 'registration_meetingrequest_min_fields';
  confirmed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  old_start?: Maybe<Scalars['timestamptz']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  rejected_at?: Maybe<Scalars['timestamptz']>;
  rejected_by_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
  start?: Maybe<Scalars['timestamptz']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  token?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Min_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  old_start?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "registration_meetingrequest" */
export type Registration_Meetingrequest_Mutation_Response = {
  __typename?: 'registration_meetingrequest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Registration_Meetingrequest>;
};

/** on_conflict condition type for table "registration_meetingrequest" */
export type Registration_Meetingrequest_On_Conflict = {
  constraint: Registration_Meetingrequest_Constraint;
  update_columns?: Array<Registration_Meetingrequest_Update_Column>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};

/** Ordering options when selecting data from "registration_meetingrequest". */
export type Registration_Meetingrequest_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  employeeByTherapistId?: InputMaybe<Employees_Order_By>;
  event_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  old_start?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
  start?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: registration_meetingrequest */
export type Registration_Meetingrequest_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "registration_meetingrequest" */
export enum Registration_Meetingrequest_Select_Column {
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventId = 'event_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OldStart = 'old_start',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedById = 'rejected_by_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Start = 'start',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  Token = 'token',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "registration_meetingrequest" */
export type Registration_Meetingrequest_Set_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  old_start?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  token?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Registration_Meetingrequest_Stddev_Fields = {
  __typename?: 'registration_meetingrequest_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Registration_Meetingrequest_Stddev_Pop_Fields = {
  __typename?: 'registration_meetingrequest_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Registration_Meetingrequest_Stddev_Samp_Fields = {
  __typename?: 'registration_meetingrequest_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "registration_meetingrequest" */
export type Registration_Meetingrequest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Registration_Meetingrequest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Registration_Meetingrequest_Stream_Cursor_Value_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  last_name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  old_start?: InputMaybe<Scalars['timestamptz']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
  start?: InputMaybe<Scalars['timestamptz']>;
  therapist_id?: InputMaybe<Scalars['bigint']>;
  token?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Registration_Meetingrequest_Sum_Fields = {
  __typename?: 'registration_meetingrequest_sum_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  rejected_by_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
  therapist_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** update columns of table "registration_meetingrequest" */
export enum Registration_Meetingrequest_Update_Column {
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventId = 'event_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Note = 'note',
  /** column name */
  OldStart = 'old_start',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedById = 'rejected_by_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  Start = 'start',
  /** column name */
  TherapistId = 'therapist_id',
  /** column name */
  Token = 'token',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Registration_Meetingrequest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Registration_Meetingrequest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Registration_Meetingrequest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Registration_Meetingrequest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Registration_Meetingrequest_Var_Pop_Fields = {
  __typename?: 'registration_meetingrequest_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Registration_Meetingrequest_Var_Samp_Fields = {
  __typename?: 'registration_meetingrequest_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Registration_Meetingrequest_Variance_Fields = {
  __typename?: 'registration_meetingrequest_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  rejected_by_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
  therapist_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "registration_meetingrequest" */
export type Registration_Meetingrequest_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  rejected_by_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  therapist_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig = {
  __typename?: 'registration_onlineregistrationconfig';
  automatic_change_time_confirm_by_patient: Scalars['Boolean'];
  automatic_confirm_new_meeting_request: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  exact_slot_proposal: Scalars['Int'];
  id: Scalars['bigint'];
  is_cancelled_meeting_free_slot: Scalars['Boolean'];
  is_precise_search: Scalars['Boolean'];
  is_registration_enabled: Scalars['Boolean'];
  is_special_meeting_free_slot: Scalars['Boolean'];
  maximum_meeting_request_cancellation_time: Scalars['Int'];
  maximum_meeting_request_change_time: Scalars['Int'];
  maximum_preceding_minutes: Scalars['Int'];
  minimum_preceding_minutes: Scalars['Int'];
  minutes_between_meetings: Scalars['Int'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  practice_slug: Scalars['String'];
  privacy_policy_url?: Maybe<Scalars['String']>;
  round_slot_time_to: Scalars['Int'];
  send_admin_new_meeting_request_email: Scalars['Boolean'];
  send_admin_new_meeting_request_mobile_notification: Scalars['Boolean'];
  send_reception_new_meeting_request_email: Scalars['Boolean'];
  send_reception_new_meeting_request_mobile_notification: Scalars['Boolean'];
  terms_of_use_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Aggregate = {
  __typename?: 'registration_onlineregistrationconfig_aggregate';
  aggregate?: Maybe<Registration_Onlineregistrationconfig_Aggregate_Fields>;
  nodes: Array<Registration_Onlineregistrationconfig>;
};

/** aggregate fields of "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Aggregate_Fields = {
  __typename?: 'registration_onlineregistrationconfig_aggregate_fields';
  avg?: Maybe<Registration_Onlineregistrationconfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Registration_Onlineregistrationconfig_Max_Fields>;
  min?: Maybe<Registration_Onlineregistrationconfig_Min_Fields>;
  stddev?: Maybe<Registration_Onlineregistrationconfig_Stddev_Fields>;
  stddev_pop?: Maybe<Registration_Onlineregistrationconfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Registration_Onlineregistrationconfig_Stddev_Samp_Fields>;
  sum?: Maybe<Registration_Onlineregistrationconfig_Sum_Fields>;
  var_pop?: Maybe<Registration_Onlineregistrationconfig_Var_Pop_Fields>;
  var_samp?: Maybe<Registration_Onlineregistrationconfig_Var_Samp_Fields>;
  variance?: Maybe<Registration_Onlineregistrationconfig_Variance_Fields>;
};


/** aggregate fields of "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Registration_Onlineregistrationconfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Registration_Onlineregistrationconfig_Avg_Fields = {
  __typename?: 'registration_onlineregistrationconfig_avg_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "registration_onlineregistrationconfig". All fields are combined with a logical 'AND'. */
export type Registration_Onlineregistrationconfig_Bool_Exp = {
  _and?: InputMaybe<Array<Registration_Onlineregistrationconfig_Bool_Exp>>;
  _not?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
  _or?: InputMaybe<Array<Registration_Onlineregistrationconfig_Bool_Exp>>;
  automatic_change_time_confirm_by_patient?: InputMaybe<Boolean_Comparison_Exp>;
  automatic_confirm_new_meeting_request?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exact_slot_proposal?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_cancelled_meeting_free_slot?: InputMaybe<Boolean_Comparison_Exp>;
  is_precise_search?: InputMaybe<Boolean_Comparison_Exp>;
  is_registration_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_special_meeting_free_slot?: InputMaybe<Boolean_Comparison_Exp>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Int_Comparison_Exp>;
  maximum_meeting_request_change_time?: InputMaybe<Int_Comparison_Exp>;
  maximum_preceding_minutes?: InputMaybe<Int_Comparison_Exp>;
  minimum_preceding_minutes?: InputMaybe<Int_Comparison_Exp>;
  minutes_between_meetings?: InputMaybe<Int_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_slug?: InputMaybe<String_Comparison_Exp>;
  privacy_policy_url?: InputMaybe<String_Comparison_Exp>;
  round_slot_time_to?: InputMaybe<Int_Comparison_Exp>;
  send_admin_new_meeting_request_email?: InputMaybe<Boolean_Comparison_Exp>;
  send_admin_new_meeting_request_mobile_notification?: InputMaybe<Boolean_Comparison_Exp>;
  send_reception_new_meeting_request_email?: InputMaybe<Boolean_Comparison_Exp>;
  send_reception_new_meeting_request_mobile_notification?: InputMaybe<Boolean_Comparison_Exp>;
  terms_of_use_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "registration_onlineregistrationconfig" */
export enum Registration_Onlineregistrationconfig_Constraint {
  /** unique or primary key constraint on columns "practice_slug" */
  RegistrationOnlineregisPracticeSlugA714dc88Uniq = 'registration_onlineregis_practice_slug_a714dc88_uniq',
  /** unique or primary key constraint on columns "id" */
  RegistrationOnlineregistrationconfigPkey = 'registration_onlineregistrationconfig_pkey',
  /** unique or primary key constraint on columns "practice_id" */
  RegistrationOnlineregistrationconfigPracticeIdKey = 'registration_onlineregistrationconfig_practice_id_key'
}

/** input type for incrementing numeric columns in table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Inc_Input = {
  exact_slot_proposal?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: InputMaybe<Scalars['Int']>;
  maximum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minimum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minutes_between_meetings?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  round_slot_time_to?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Insert_Input = {
  automatic_change_time_confirm_by_patient?: InputMaybe<Scalars['Boolean']>;
  automatic_confirm_new_meeting_request?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  exact_slot_proposal?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_cancelled_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  is_precise_search?: InputMaybe<Scalars['Boolean']>;
  is_registration_enabled?: InputMaybe<Scalars['Boolean']>;
  is_special_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: InputMaybe<Scalars['Int']>;
  maximum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minimum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minutes_between_meetings?: InputMaybe<Scalars['Int']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  practice_slug?: InputMaybe<Scalars['String']>;
  privacy_policy_url?: InputMaybe<Scalars['String']>;
  round_slot_time_to?: InputMaybe<Scalars['Int']>;
  send_admin_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_admin_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  terms_of_use_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Registration_Onlineregistrationconfig_Max_Fields = {
  __typename?: 'registration_onlineregistrationconfig_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  exact_slot_proposal?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Int']>;
  maximum_preceding_minutes?: Maybe<Scalars['Int']>;
  minimum_preceding_minutes?: Maybe<Scalars['Int']>;
  minutes_between_meetings?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  practice_slug?: Maybe<Scalars['String']>;
  privacy_policy_url?: Maybe<Scalars['String']>;
  round_slot_time_to?: Maybe<Scalars['Int']>;
  terms_of_use_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Registration_Onlineregistrationconfig_Min_Fields = {
  __typename?: 'registration_onlineregistrationconfig_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  exact_slot_proposal?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Int']>;
  maximum_preceding_minutes?: Maybe<Scalars['Int']>;
  minimum_preceding_minutes?: Maybe<Scalars['Int']>;
  minutes_between_meetings?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  practice_slug?: Maybe<Scalars['String']>;
  privacy_policy_url?: Maybe<Scalars['String']>;
  round_slot_time_to?: Maybe<Scalars['Int']>;
  terms_of_use_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Mutation_Response = {
  __typename?: 'registration_onlineregistrationconfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Registration_Onlineregistrationconfig>;
};

/** input type for inserting object relation for remote table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Obj_Rel_Insert_Input = {
  data: Registration_Onlineregistrationconfig_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Registration_Onlineregistrationconfig_On_Conflict>;
};

/** on_conflict condition type for table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_On_Conflict = {
  constraint: Registration_Onlineregistrationconfig_Constraint;
  update_columns?: Array<Registration_Onlineregistrationconfig_Update_Column>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};

/** Ordering options when selecting data from "registration_onlineregistrationconfig". */
export type Registration_Onlineregistrationconfig_Order_By = {
  automatic_change_time_confirm_by_patient?: InputMaybe<Order_By>;
  automatic_confirm_new_meeting_request?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  exact_slot_proposal?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_cancelled_meeting_free_slot?: InputMaybe<Order_By>;
  is_precise_search?: InputMaybe<Order_By>;
  is_registration_enabled?: InputMaybe<Order_By>;
  is_special_meeting_free_slot?: InputMaybe<Order_By>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Order_By>;
  maximum_meeting_request_change_time?: InputMaybe<Order_By>;
  maximum_preceding_minutes?: InputMaybe<Order_By>;
  minimum_preceding_minutes?: InputMaybe<Order_By>;
  minutes_between_meetings?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  practice_slug?: InputMaybe<Order_By>;
  privacy_policy_url?: InputMaybe<Order_By>;
  round_slot_time_to?: InputMaybe<Order_By>;
  send_admin_new_meeting_request_email?: InputMaybe<Order_By>;
  send_admin_new_meeting_request_mobile_notification?: InputMaybe<Order_By>;
  send_reception_new_meeting_request_email?: InputMaybe<Order_By>;
  send_reception_new_meeting_request_mobile_notification?: InputMaybe<Order_By>;
  terms_of_use_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: registration_onlineregistrationconfig */
export type Registration_Onlineregistrationconfig_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "registration_onlineregistrationconfig" */
export enum Registration_Onlineregistrationconfig_Select_Column {
  /** column name */
  AutomaticChangeTimeConfirmByPatient = 'automatic_change_time_confirm_by_patient',
  /** column name */
  AutomaticConfirmNewMeetingRequest = 'automatic_confirm_new_meeting_request',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExactSlotProposal = 'exact_slot_proposal',
  /** column name */
  Id = 'id',
  /** column name */
  IsCancelledMeetingFreeSlot = 'is_cancelled_meeting_free_slot',
  /** column name */
  IsPreciseSearch = 'is_precise_search',
  /** column name */
  IsRegistrationEnabled = 'is_registration_enabled',
  /** column name */
  IsSpecialMeetingFreeSlot = 'is_special_meeting_free_slot',
  /** column name */
  MaximumMeetingRequestCancellationTime = 'maximum_meeting_request_cancellation_time',
  /** column name */
  MaximumMeetingRequestChangeTime = 'maximum_meeting_request_change_time',
  /** column name */
  MaximumPrecedingMinutes = 'maximum_preceding_minutes',
  /** column name */
  MinimumPrecedingMinutes = 'minimum_preceding_minutes',
  /** column name */
  MinutesBetweenMeetings = 'minutes_between_meetings',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeSlug = 'practice_slug',
  /** column name */
  PrivacyPolicyUrl = 'privacy_policy_url',
  /** column name */
  RoundSlotTimeTo = 'round_slot_time_to',
  /** column name */
  SendAdminNewMeetingRequestEmail = 'send_admin_new_meeting_request_email',
  /** column name */
  SendAdminNewMeetingRequestMobileNotification = 'send_admin_new_meeting_request_mobile_notification',
  /** column name */
  SendReceptionNewMeetingRequestEmail = 'send_reception_new_meeting_request_email',
  /** column name */
  SendReceptionNewMeetingRequestMobileNotification = 'send_reception_new_meeting_request_mobile_notification',
  /** column name */
  TermsOfUseUrl = 'terms_of_use_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Set_Input = {
  automatic_change_time_confirm_by_patient?: InputMaybe<Scalars['Boolean']>;
  automatic_confirm_new_meeting_request?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  exact_slot_proposal?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_cancelled_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  is_precise_search?: InputMaybe<Scalars['Boolean']>;
  is_registration_enabled?: InputMaybe<Scalars['Boolean']>;
  is_special_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: InputMaybe<Scalars['Int']>;
  maximum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minimum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minutes_between_meetings?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  practice_slug?: InputMaybe<Scalars['String']>;
  privacy_policy_url?: InputMaybe<Scalars['String']>;
  round_slot_time_to?: InputMaybe<Scalars['Int']>;
  send_admin_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_admin_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  terms_of_use_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Registration_Onlineregistrationconfig_Stddev_Fields = {
  __typename?: 'registration_onlineregistrationconfig_stddev_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Registration_Onlineregistrationconfig_Stddev_Pop_Fields = {
  __typename?: 'registration_onlineregistrationconfig_stddev_pop_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Registration_Onlineregistrationconfig_Stddev_Samp_Fields = {
  __typename?: 'registration_onlineregistrationconfig_stddev_samp_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "registration_onlineregistrationconfig" */
export type Registration_Onlineregistrationconfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Registration_Onlineregistrationconfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Registration_Onlineregistrationconfig_Stream_Cursor_Value_Input = {
  automatic_change_time_confirm_by_patient?: InputMaybe<Scalars['Boolean']>;
  automatic_confirm_new_meeting_request?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  exact_slot_proposal?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_cancelled_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  is_precise_search?: InputMaybe<Scalars['Boolean']>;
  is_registration_enabled?: InputMaybe<Scalars['Boolean']>;
  is_special_meeting_free_slot?: InputMaybe<Scalars['Boolean']>;
  maximum_meeting_request_cancellation_time?: InputMaybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: InputMaybe<Scalars['Int']>;
  maximum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minimum_preceding_minutes?: InputMaybe<Scalars['Int']>;
  minutes_between_meetings?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  practice_slug?: InputMaybe<Scalars['String']>;
  privacy_policy_url?: InputMaybe<Scalars['String']>;
  round_slot_time_to?: InputMaybe<Scalars['Int']>;
  send_admin_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_admin_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_email?: InputMaybe<Scalars['Boolean']>;
  send_reception_new_meeting_request_mobile_notification?: InputMaybe<Scalars['Boolean']>;
  terms_of_use_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Registration_Onlineregistrationconfig_Sum_Fields = {
  __typename?: 'registration_onlineregistrationconfig_sum_fields';
  exact_slot_proposal?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Int']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Int']>;
  maximum_preceding_minutes?: Maybe<Scalars['Int']>;
  minimum_preceding_minutes?: Maybe<Scalars['Int']>;
  minutes_between_meetings?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  round_slot_time_to?: Maybe<Scalars['Int']>;
};

/** update columns of table "registration_onlineregistrationconfig" */
export enum Registration_Onlineregistrationconfig_Update_Column {
  /** column name */
  AutomaticChangeTimeConfirmByPatient = 'automatic_change_time_confirm_by_patient',
  /** column name */
  AutomaticConfirmNewMeetingRequest = 'automatic_confirm_new_meeting_request',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExactSlotProposal = 'exact_slot_proposal',
  /** column name */
  Id = 'id',
  /** column name */
  IsCancelledMeetingFreeSlot = 'is_cancelled_meeting_free_slot',
  /** column name */
  IsPreciseSearch = 'is_precise_search',
  /** column name */
  IsRegistrationEnabled = 'is_registration_enabled',
  /** column name */
  IsSpecialMeetingFreeSlot = 'is_special_meeting_free_slot',
  /** column name */
  MaximumMeetingRequestCancellationTime = 'maximum_meeting_request_cancellation_time',
  /** column name */
  MaximumMeetingRequestChangeTime = 'maximum_meeting_request_change_time',
  /** column name */
  MaximumPrecedingMinutes = 'maximum_preceding_minutes',
  /** column name */
  MinimumPrecedingMinutes = 'minimum_preceding_minutes',
  /** column name */
  MinutesBetweenMeetings = 'minutes_between_meetings',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeSlug = 'practice_slug',
  /** column name */
  PrivacyPolicyUrl = 'privacy_policy_url',
  /** column name */
  RoundSlotTimeTo = 'round_slot_time_to',
  /** column name */
  SendAdminNewMeetingRequestEmail = 'send_admin_new_meeting_request_email',
  /** column name */
  SendAdminNewMeetingRequestMobileNotification = 'send_admin_new_meeting_request_mobile_notification',
  /** column name */
  SendReceptionNewMeetingRequestEmail = 'send_reception_new_meeting_request_email',
  /** column name */
  SendReceptionNewMeetingRequestMobileNotification = 'send_reception_new_meeting_request_mobile_notification',
  /** column name */
  TermsOfUseUrl = 'terms_of_use_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Registration_Onlineregistrationconfig_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Registration_Onlineregistrationconfig_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Registration_Onlineregistrationconfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: Registration_Onlineregistrationconfig_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Registration_Onlineregistrationconfig_Var_Pop_Fields = {
  __typename?: 'registration_onlineregistrationconfig_var_pop_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Registration_Onlineregistrationconfig_Var_Samp_Fields = {
  __typename?: 'registration_onlineregistrationconfig_var_samp_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Registration_Onlineregistrationconfig_Variance_Fields = {
  __typename?: 'registration_onlineregistrationconfig_variance_fields';
  exact_slot_proposal?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maximum_meeting_request_cancellation_time?: Maybe<Scalars['Float']>;
  maximum_meeting_request_change_time?: Maybe<Scalars['Float']>;
  maximum_preceding_minutes?: Maybe<Scalars['Float']>;
  minimum_preceding_minutes?: Maybe<Scalars['Float']>;
  minutes_between_meetings?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  round_slot_time_to?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'reports';
  created_at: Scalars['timestamptz'];
  file: Scalars['String'];
  id: Scalars['uuid'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reports" */
export type Reports_Aggregate = {
  __typename?: 'reports_aggregate';
  aggregate?: Maybe<Reports_Aggregate_Fields>;
  nodes: Array<Reports>;
};

export type Reports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reports_Aggregate_Bool_Exp_Count>;
};

export type Reports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Reports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_Fields = {
  __typename?: 'reports_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Reports_Max_Fields>;
  min?: Maybe<Reports_Min_Fields>;
};


/** aggregate fields of "reports" */
export type Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reports" */
export type Reports_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reports_Max_Order_By>;
  min?: InputMaybe<Reports_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reports" */
export type Reports_Arr_Rel_Insert_Input = {
  data: Array<Reports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey'
}

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reports_Max_Fields = {
  __typename?: 'reports_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reports" */
export type Reports_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reports_Min_Fields = {
  __typename?: 'reports_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reports" */
export type Reports_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Reports_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

/** columns and relationships of "request_considerations" */
export type Request_Considerations = {
  __typename?: 'request_considerations';
  consideration_note: Scalars['String'];
  considered_at?: Maybe<Scalars['timestamptz']>;
  considered_by_id?: Maybe<Scalars['bigint']>;
  content_type_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employees>;
  id: Scalars['bigint'];
  object_id: Scalars['Int'];
  request_type: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "request_considerations" */
export type Request_Considerations_Aggregate = {
  __typename?: 'request_considerations_aggregate';
  aggregate?: Maybe<Request_Considerations_Aggregate_Fields>;
  nodes: Array<Request_Considerations>;
};

export type Request_Considerations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Request_Considerations_Aggregate_Bool_Exp_Count>;
};

export type Request_Considerations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Request_Considerations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "request_considerations" */
export type Request_Considerations_Aggregate_Fields = {
  __typename?: 'request_considerations_aggregate_fields';
  avg?: Maybe<Request_Considerations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Request_Considerations_Max_Fields>;
  min?: Maybe<Request_Considerations_Min_Fields>;
  stddev?: Maybe<Request_Considerations_Stddev_Fields>;
  stddev_pop?: Maybe<Request_Considerations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Request_Considerations_Stddev_Samp_Fields>;
  sum?: Maybe<Request_Considerations_Sum_Fields>;
  var_pop?: Maybe<Request_Considerations_Var_Pop_Fields>;
  var_samp?: Maybe<Request_Considerations_Var_Samp_Fields>;
  variance?: Maybe<Request_Considerations_Variance_Fields>;
};


/** aggregate fields of "request_considerations" */
export type Request_Considerations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "request_considerations" */
export type Request_Considerations_Aggregate_Order_By = {
  avg?: InputMaybe<Request_Considerations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Request_Considerations_Max_Order_By>;
  min?: InputMaybe<Request_Considerations_Min_Order_By>;
  stddev?: InputMaybe<Request_Considerations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Request_Considerations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Request_Considerations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Request_Considerations_Sum_Order_By>;
  var_pop?: InputMaybe<Request_Considerations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Request_Considerations_Var_Samp_Order_By>;
  variance?: InputMaybe<Request_Considerations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "request_considerations" */
export type Request_Considerations_Arr_Rel_Insert_Input = {
  data: Array<Request_Considerations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Request_Considerations_On_Conflict>;
};

/** aggregate avg on columns */
export type Request_Considerations_Avg_Fields = {
  __typename?: 'request_considerations_avg_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "request_considerations" */
export type Request_Considerations_Avg_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "request_considerations". All fields are combined with a logical 'AND'. */
export type Request_Considerations_Bool_Exp = {
  _and?: InputMaybe<Array<Request_Considerations_Bool_Exp>>;
  _not?: InputMaybe<Request_Considerations_Bool_Exp>;
  _or?: InputMaybe<Array<Request_Considerations_Bool_Exp>>;
  consideration_note?: InputMaybe<String_Comparison_Exp>;
  considered_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  considered_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  content_type_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  object_id?: InputMaybe<Int_Comparison_Exp>;
  request_type?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "request_considerations" */
export enum Request_Considerations_Constraint {
  /** unique or primary key constraint on columns "id" */
  RequestConsiderationsPkey = 'request_considerations_pkey'
}

/** input type for incrementing numeric columns in table "request_considerations" */
export type Request_Considerations_Inc_Input = {
  considered_by_id?: InputMaybe<Scalars['bigint']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  object_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "request_considerations" */
export type Request_Considerations_Insert_Input = {
  consideration_note?: InputMaybe<Scalars['String']>;
  considered_at?: InputMaybe<Scalars['timestamptz']>;
  considered_by_id?: InputMaybe<Scalars['bigint']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  object_id?: InputMaybe<Scalars['Int']>;
  request_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Request_Considerations_Max_Fields = {
  __typename?: 'request_considerations_max_fields';
  consideration_note?: Maybe<Scalars['String']>;
  considered_at?: Maybe<Scalars['timestamptz']>;
  considered_by_id?: Maybe<Scalars['bigint']>;
  content_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  object_id?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "request_considerations" */
export type Request_Considerations_Max_Order_By = {
  consideration_note?: InputMaybe<Order_By>;
  considered_at?: InputMaybe<Order_By>;
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Request_Considerations_Min_Fields = {
  __typename?: 'request_considerations_min_fields';
  consideration_note?: Maybe<Scalars['String']>;
  considered_at?: Maybe<Scalars['timestamptz']>;
  considered_by_id?: Maybe<Scalars['bigint']>;
  content_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  object_id?: Maybe<Scalars['Int']>;
  request_type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "request_considerations" */
export type Request_Considerations_Min_Order_By = {
  consideration_note?: InputMaybe<Order_By>;
  considered_at?: InputMaybe<Order_By>;
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "request_considerations" */
export type Request_Considerations_Mutation_Response = {
  __typename?: 'request_considerations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Request_Considerations>;
};

/** on_conflict condition type for table "request_considerations" */
export type Request_Considerations_On_Conflict = {
  constraint: Request_Considerations_Constraint;
  update_columns?: Array<Request_Considerations_Update_Column>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};

/** Ordering options when selecting data from "request_considerations". */
export type Request_Considerations_Order_By = {
  consideration_note?: InputMaybe<Order_By>;
  considered_at?: InputMaybe<Order_By>;
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  request_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: request_considerations */
export type Request_Considerations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "request_considerations" */
export enum Request_Considerations_Select_Column {
  /** column name */
  ConsiderationNote = 'consideration_note',
  /** column name */
  ConsideredAt = 'considered_at',
  /** column name */
  ConsideredById = 'considered_by_id',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "request_considerations" */
export type Request_Considerations_Set_Input = {
  consideration_note?: InputMaybe<Scalars['String']>;
  considered_at?: InputMaybe<Scalars['timestamptz']>;
  considered_by_id?: InputMaybe<Scalars['bigint']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  object_id?: InputMaybe<Scalars['Int']>;
  request_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Request_Considerations_Stddev_Fields = {
  __typename?: 'request_considerations_stddev_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "request_considerations" */
export type Request_Considerations_Stddev_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Request_Considerations_Stddev_Pop_Fields = {
  __typename?: 'request_considerations_stddev_pop_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "request_considerations" */
export type Request_Considerations_Stddev_Pop_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Request_Considerations_Stddev_Samp_Fields = {
  __typename?: 'request_considerations_stddev_samp_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "request_considerations" */
export type Request_Considerations_Stddev_Samp_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "request_considerations" */
export type Request_Considerations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Request_Considerations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Request_Considerations_Stream_Cursor_Value_Input = {
  consideration_note?: InputMaybe<Scalars['String']>;
  considered_at?: InputMaybe<Scalars['timestamptz']>;
  considered_by_id?: InputMaybe<Scalars['bigint']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  object_id?: InputMaybe<Scalars['Int']>;
  request_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Request_Considerations_Sum_Fields = {
  __typename?: 'request_considerations_sum_fields';
  considered_by_id?: Maybe<Scalars['bigint']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  object_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "request_considerations" */
export type Request_Considerations_Sum_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** update columns of table "request_considerations" */
export enum Request_Considerations_Update_Column {
  /** column name */
  ConsiderationNote = 'consideration_note',
  /** column name */
  ConsideredAt = 'considered_at',
  /** column name */
  ConsideredById = 'considered_by_id',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  RequestType = 'request_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Request_Considerations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Request_Considerations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Request_Considerations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Request_Considerations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Request_Considerations_Var_Pop_Fields = {
  __typename?: 'request_considerations_var_pop_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "request_considerations" */
export type Request_Considerations_Var_Pop_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Request_Considerations_Var_Samp_Fields = {
  __typename?: 'request_considerations_var_samp_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "request_considerations" */
export type Request_Considerations_Var_Samp_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Request_Considerations_Variance_Fields = {
  __typename?: 'request_considerations_variance_fields';
  considered_by_id?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  object_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "request_considerations" */
export type Request_Considerations_Variance_Order_By = {
  considered_by_id?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  /** An array relationship */
  employees_roles: Array<Employees_Roles>;
  /** An aggregate relationship */
  employees_roles_aggregate: Employees_Roles_Aggregate;
  name: Scalars['String'];
  slug: Scalars['String'];
};


/** columns and relationships of "roles" */
export type RolesEmployees_RolesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesEmployees_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  employees_roles?: InputMaybe<Employees_Roles_Bool_Exp>;
  employees_roles_aggregate?: InputMaybe<Employees_Roles_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "slug" */
  RolesPkey = 'roles_pkey'
}

export enum Roles_Enum {
  /** Admin */
  Adm = 'adm',
  /** Employee */
  Emp = 'emp',
  /** Financial */
  Fin = 'fin',
  /** Reception */
  Rec = 'rec',
  /** Technical Staff */
  Tech = 'tech'
}

/** Boolean expression to compare columns of type "roles_enum". All fields are combined with logical 'AND'. */
export type Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Roles_Enum>;
  _in?: InputMaybe<Array<Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Roles_Enum>;
  _nin?: InputMaybe<Array<Roles_Enum>>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  employees_roles?: InputMaybe<Employees_Roles_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  employees_roles_aggregate?: InputMaybe<Employees_Roles_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  slug: Scalars['String'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "rooms" */
export type Rooms = {
  __typename?: 'rooms';
  available_online: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  employees_preferred_rooms: Array<Employees_Preferred_Room>;
  /** An aggregate relationship */
  employees_preferred_rooms_aggregate: Employees_Preferred_Room_Aggregate;
  /** An array relationship */
  event_periods: Array<Event_Period>;
  /** An aggregate relationship */
  event_periods_aggregate: Event_Period_Aggregate;
  /** An array relationship */
  events: Array<Event>;
  /** An aggregate relationship */
  events_aggregate: Event_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  /** An array relationship */
  services_restricted_rooms: Array<Services_Restricted_Rooms>;
  /** An aggregate relationship */
  services_restricted_rooms_aggregate: Services_Restricted_Rooms_Aggregate;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "rooms" */
export type RoomsEmployees_Preferred_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsEmployees_Preferred_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsEvent_PeriodsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsEvent_Periods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsEventsArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsServices_Restricted_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


/** columns and relationships of "rooms" */
export type RoomsServices_Restricted_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};

/** aggregated selection of "rooms" */
export type Rooms_Aggregate = {
  __typename?: 'rooms_aggregate';
  aggregate?: Maybe<Rooms_Aggregate_Fields>;
  nodes: Array<Rooms>;
};

export type Rooms_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Rooms_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Rooms_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Rooms_Aggregate_Bool_Exp_Count>;
};

export type Rooms_Aggregate_Bool_Exp_Bool_And = {
  arguments: Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rooms_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Rooms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Rooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rooms" */
export type Rooms_Aggregate_Fields = {
  __typename?: 'rooms_aggregate_fields';
  avg?: Maybe<Rooms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rooms_Max_Fields>;
  min?: Maybe<Rooms_Min_Fields>;
  stddev?: Maybe<Rooms_Stddev_Fields>;
  stddev_pop?: Maybe<Rooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rooms_Stddev_Samp_Fields>;
  sum?: Maybe<Rooms_Sum_Fields>;
  var_pop?: Maybe<Rooms_Var_Pop_Fields>;
  var_samp?: Maybe<Rooms_Var_Samp_Fields>;
  variance?: Maybe<Rooms_Variance_Fields>;
};


/** aggregate fields of "rooms" */
export type Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rooms" */
export type Rooms_Aggregate_Order_By = {
  avg?: InputMaybe<Rooms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rooms_Max_Order_By>;
  min?: InputMaybe<Rooms_Min_Order_By>;
  stddev?: InputMaybe<Rooms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rooms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rooms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rooms_Sum_Order_By>;
  var_pop?: InputMaybe<Rooms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rooms_Var_Samp_Order_By>;
  variance?: InputMaybe<Rooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rooms" */
export type Rooms_Arr_Rel_Insert_Input = {
  data: Array<Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** aggregate avg on columns */
export type Rooms_Avg_Fields = {
  __typename?: 'rooms_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rooms" */
export type Rooms_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rooms". All fields are combined with a logical 'AND'. */
export type Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Rooms_Bool_Exp>>;
  _not?: InputMaybe<Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Rooms_Bool_Exp>>;
  available_online?: InputMaybe<Boolean_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employees_preferred_rooms?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
  employees_preferred_rooms_aggregate?: InputMaybe<Employees_Preferred_Room_Aggregate_Bool_Exp>;
  event_periods?: InputMaybe<Event_Period_Bool_Exp>;
  event_periods_aggregate?: InputMaybe<Event_Period_Aggregate_Bool_Exp>;
  events?: InputMaybe<Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Event_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  services_restricted_rooms?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
  services_restricted_rooms_aggregate?: InputMaybe<Services_Restricted_Rooms_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rooms" */
export enum Rooms_Constraint {
  /** unique or primary key constraint on columns "id" */
  RoomsPkey = 'rooms_pkey'
}

/** input type for incrementing numeric columns in table "rooms" */
export type Rooms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rooms" */
export type Rooms_Insert_Input = {
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employees_preferred_rooms?: InputMaybe<Employees_Preferred_Room_Arr_Rel_Insert_Input>;
  event_periods?: InputMaybe<Event_Period_Arr_Rel_Insert_Input>;
  events?: InputMaybe<Event_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  services_restricted_rooms?: InputMaybe<Services_Restricted_Rooms_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Rooms_Max_Fields = {
  __typename?: 'rooms_max_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "rooms" */
export type Rooms_Max_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rooms_Min_Fields = {
  __typename?: 'rooms_min_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "rooms" */
export type Rooms_Min_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rooms" */
export type Rooms_Mutation_Response = {
  __typename?: 'rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rooms>;
};

/** input type for inserting object relation for remote table "rooms" */
export type Rooms_Obj_Rel_Insert_Input = {
  data: Rooms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rooms_On_Conflict>;
};

/** on_conflict condition type for table "rooms" */
export type Rooms_On_Conflict = {
  constraint: Rooms_Constraint;
  update_columns?: Array<Rooms_Update_Column>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "rooms". */
export type Rooms_Order_By = {
  available_online?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employees_preferred_rooms_aggregate?: InputMaybe<Employees_Preferred_Room_Aggregate_Order_By>;
  event_periods_aggregate?: InputMaybe<Event_Period_Aggregate_Order_By>;
  events_aggregate?: InputMaybe<Event_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  services_restricted_rooms_aggregate?: InputMaybe<Services_Restricted_Rooms_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rooms */
export type Rooms_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "rooms" */
export enum Rooms_Select_Column {
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "rooms_aggregate_bool_exp_bool_and_arguments_columns" columns of table "rooms" */
export enum Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AvailableOnline = 'available_online'
}

/** select "rooms_aggregate_bool_exp_bool_or_arguments_columns" columns of table "rooms" */
export enum Rooms_Select_Column_Rooms_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AvailableOnline = 'available_online'
}

/** input type for updating data in table "rooms" */
export type Rooms_Set_Input = {
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Rooms_Stddev_Fields = {
  __typename?: 'rooms_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rooms" */
export type Rooms_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rooms_Stddev_Pop_Fields = {
  __typename?: 'rooms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rooms" */
export type Rooms_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rooms_Stddev_Samp_Fields = {
  __typename?: 'rooms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rooms" */
export type Rooms_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "rooms" */
export type Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Rooms_Stream_Cursor_Value_Input = {
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Rooms_Sum_Fields = {
  __typename?: 'rooms_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rooms" */
export type Rooms_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** update columns of table "rooms" */
export enum Rooms_Update_Column {
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Rooms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rooms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rooms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rooms_Var_Pop_Fields = {
  __typename?: 'rooms_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rooms" */
export type Rooms_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rooms_Var_Samp_Fields = {
  __typename?: 'rooms_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rooms" */
export type Rooms_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rooms_Variance_Fields = {
  __typename?: 'rooms_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rooms" */
export type Rooms_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "services" */
export type Services = {
  __typename?: 'services';
  available_in_online_registration: Scalars['Boolean'];
  available_online: Scalars['Boolean'];
  comments?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  duration: Scalars['interval'];
  /** An array relationship */
  event_period_services: Array<Event_Period_Services>;
  /** An aggregate relationship */
  event_period_services_aggregate: Event_Period_Services_Aggregate;
  /** An array relationship */
  event_services: Array<Event_Services>;
  /** An aggregate relationship */
  event_services_aggregate: Event_Services_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An object relationship */
  practice_branch: Practice_Branches;
  practice_branch_id: Scalars['bigint'];
  price: Scalars['numeric'];
  price_per_participant: Scalars['Boolean'];
  /** An array relationship */
  registration_meetingrequests: Array<Registration_Meetingrequest>;
  /** An aggregate relationship */
  registration_meetingrequests_aggregate: Registration_Meetingrequest_Aggregate;
  /** An array relationship */
  services_assigned_tos: Array<Services_Assigned_To>;
  /** An aggregate relationship */
  services_assigned_tos_aggregate: Services_Assigned_To_Aggregate;
  /** An array relationship */
  services_restricted_rooms: Array<Services_Restricted_Rooms>;
  /** An aggregate relationship */
  services_restricted_rooms_aggregate: Services_Restricted_Rooms_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "services" */
export type ServicesEvent_Period_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesEvent_Period_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesEvent_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesEvent_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesRegistration_MeetingrequestsArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesRegistration_Meetingrequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesServices_Assigned_TosArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesServices_Assigned_Tos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesServices_Restricted_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesServices_Restricted_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};

/** aggregated selection of "services" */
export type Services_Aggregate = {
  __typename?: 'services_aggregate';
  aggregate?: Maybe<Services_Aggregate_Fields>;
  nodes: Array<Services>;
};

export type Services_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Services_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Services_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Services_Aggregate_Bool_Exp_Count>;
};

export type Services_Aggregate_Bool_Exp_Bool_And = {
  arguments: Services_Select_Column_Services_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Services_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Services_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Services_Select_Column_Services_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Services_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Services_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Services_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "services" */
export type Services_Aggregate_Fields = {
  __typename?: 'services_aggregate_fields';
  avg?: Maybe<Services_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Services_Max_Fields>;
  min?: Maybe<Services_Min_Fields>;
  stddev?: Maybe<Services_Stddev_Fields>;
  stddev_pop?: Maybe<Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Services_Stddev_Samp_Fields>;
  sum?: Maybe<Services_Sum_Fields>;
  var_pop?: Maybe<Services_Var_Pop_Fields>;
  var_samp?: Maybe<Services_Var_Samp_Fields>;
  variance?: Maybe<Services_Variance_Fields>;
};


/** aggregate fields of "services" */
export type Services_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "services" */
export type Services_Aggregate_Order_By = {
  avg?: InputMaybe<Services_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Services_Max_Order_By>;
  min?: InputMaybe<Services_Min_Order_By>;
  stddev?: InputMaybe<Services_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Services_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Services_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Services_Sum_Order_By>;
  var_pop?: InputMaybe<Services_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Services_Var_Samp_Order_By>;
  variance?: InputMaybe<Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "services" */
export type Services_Arr_Rel_Insert_Input = {
  data: Array<Services_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Services_On_Conflict>;
};

/** columns and relationships of "services_assigned_to" */
export type Services_Assigned_To = {
  __typename?: 'services_assigned_to';
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['bigint'];
};

/** aggregated selection of "services_assigned_to" */
export type Services_Assigned_To_Aggregate = {
  __typename?: 'services_assigned_to_aggregate';
  aggregate?: Maybe<Services_Assigned_To_Aggregate_Fields>;
  nodes: Array<Services_Assigned_To>;
};

export type Services_Assigned_To_Aggregate_Bool_Exp = {
  count?: InputMaybe<Services_Assigned_To_Aggregate_Bool_Exp_Count>;
};

export type Services_Assigned_To_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Services_Assigned_To_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "services_assigned_to" */
export type Services_Assigned_To_Aggregate_Fields = {
  __typename?: 'services_assigned_to_aggregate_fields';
  avg?: Maybe<Services_Assigned_To_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Services_Assigned_To_Max_Fields>;
  min?: Maybe<Services_Assigned_To_Min_Fields>;
  stddev?: Maybe<Services_Assigned_To_Stddev_Fields>;
  stddev_pop?: Maybe<Services_Assigned_To_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Services_Assigned_To_Stddev_Samp_Fields>;
  sum?: Maybe<Services_Assigned_To_Sum_Fields>;
  var_pop?: Maybe<Services_Assigned_To_Var_Pop_Fields>;
  var_samp?: Maybe<Services_Assigned_To_Var_Samp_Fields>;
  variance?: Maybe<Services_Assigned_To_Variance_Fields>;
};


/** aggregate fields of "services_assigned_to" */
export type Services_Assigned_To_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "services_assigned_to" */
export type Services_Assigned_To_Aggregate_Order_By = {
  avg?: InputMaybe<Services_Assigned_To_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Services_Assigned_To_Max_Order_By>;
  min?: InputMaybe<Services_Assigned_To_Min_Order_By>;
  stddev?: InputMaybe<Services_Assigned_To_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Services_Assigned_To_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Services_Assigned_To_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Services_Assigned_To_Sum_Order_By>;
  var_pop?: InputMaybe<Services_Assigned_To_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Services_Assigned_To_Var_Samp_Order_By>;
  variance?: InputMaybe<Services_Assigned_To_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "services_assigned_to" */
export type Services_Assigned_To_Arr_Rel_Insert_Input = {
  data: Array<Services_Assigned_To_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Services_Assigned_To_On_Conflict>;
};

/** aggregate avg on columns */
export type Services_Assigned_To_Avg_Fields = {
  __typename?: 'services_assigned_to_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "services_assigned_to". All fields are combined with a logical 'AND'. */
export type Services_Assigned_To_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Assigned_To_Bool_Exp>>;
  _not?: InputMaybe<Services_Assigned_To_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Assigned_To_Bool_Exp>>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "services_assigned_to" */
export enum Services_Assigned_To_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesAssignedToPkey = 'services_assigned_to_pkey',
  /** unique or primary key constraint on columns "employee_id", "service_id" */
  ServicesAssignedToServiceIdEmployeeId_32cf2c7dUniq = 'services_assigned_to_service_id_employee_id_32cf2c7d_uniq'
}

/** input type for incrementing numeric columns in table "services_assigned_to" */
export type Services_Assigned_To_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "services_assigned_to" */
export type Services_Assigned_To_Insert_Input = {
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Services_Assigned_To_Max_Fields = {
  __typename?: 'services_assigned_to_max_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Max_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Services_Assigned_To_Min_Fields = {
  __typename?: 'services_assigned_to_min_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Min_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "services_assigned_to" */
export type Services_Assigned_To_Mutation_Response = {
  __typename?: 'services_assigned_to_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services_Assigned_To>;
};

/** on_conflict condition type for table "services_assigned_to" */
export type Services_Assigned_To_On_Conflict = {
  constraint: Services_Assigned_To_Constraint;
  update_columns?: Array<Services_Assigned_To_Update_Column>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};

/** Ordering options when selecting data from "services_assigned_to". */
export type Services_Assigned_To_Order_By = {
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service?: InputMaybe<Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services_assigned_to */
export type Services_Assigned_To_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "services_assigned_to" */
export enum Services_Assigned_To_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "services_assigned_to" */
export type Services_Assigned_To_Set_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Services_Assigned_To_Stddev_Fields = {
  __typename?: 'services_assigned_to_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Services_Assigned_To_Stddev_Pop_Fields = {
  __typename?: 'services_assigned_to_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Services_Assigned_To_Stddev_Samp_Fields = {
  __typename?: 'services_assigned_to_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "services_assigned_to" */
export type Services_Assigned_To_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Assigned_To_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Assigned_To_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Services_Assigned_To_Sum_Fields = {
  __typename?: 'services_assigned_to_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** update columns of table "services_assigned_to" */
export enum Services_Assigned_To_Update_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

export type Services_Assigned_To_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Services_Assigned_To_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Assigned_To_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Assigned_To_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Services_Assigned_To_Var_Pop_Fields = {
  __typename?: 'services_assigned_to_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Services_Assigned_To_Var_Samp_Fields = {
  __typename?: 'services_assigned_to_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Services_Assigned_To_Variance_Fields = {
  __typename?: 'services_assigned_to_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "services_assigned_to" */
export type Services_Assigned_To_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Services_Avg_Fields = {
  __typename?: 'services_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "services" */
export type Services_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Services_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Bool_Exp>>;
  _not?: InputMaybe<Services_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Bool_Exp>>;
  available_in_online_registration?: InputMaybe<Boolean_Comparison_Exp>;
  available_online?: InputMaybe<Boolean_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  duration?: InputMaybe<Interval_Comparison_Exp>;
  event_period_services?: InputMaybe<Event_Period_Services_Bool_Exp>;
  event_period_services_aggregate?: InputMaybe<Event_Period_Services_Aggregate_Bool_Exp>;
  event_services?: InputMaybe<Event_Services_Bool_Exp>;
  event_services_aggregate?: InputMaybe<Event_Services_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  price_per_participant?: InputMaybe<Boolean_Comparison_Exp>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Bool_Exp>;
  services_assigned_tos?: InputMaybe<Services_Assigned_To_Bool_Exp>;
  services_assigned_tos_aggregate?: InputMaybe<Services_Assigned_To_Aggregate_Bool_Exp>;
  services_restricted_rooms?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
  services_restricted_rooms_aggregate?: InputMaybe<Services_Restricted_Rooms_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "services" */
export enum Services_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesPkey = 'services_pkey'
}

/** input type for incrementing numeric columns in table "services" */
export type Services_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "services" */
export type Services_Insert_Input = {
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  event_period_services?: InputMaybe<Event_Period_Services_Arr_Rel_Insert_Input>;
  event_services?: InputMaybe<Event_Services_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  price_per_participant?: InputMaybe<Scalars['Boolean']>;
  registration_meetingrequests?: InputMaybe<Registration_Meetingrequest_Arr_Rel_Insert_Input>;
  services_assigned_tos?: InputMaybe<Services_Assigned_To_Arr_Rel_Insert_Input>;
  services_restricted_rooms?: InputMaybe<Services_Restricted_Rooms_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Services_Max_Fields = {
  __typename?: 'services_max_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "services" */
export type Services_Max_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Services_Min_Fields = {
  __typename?: 'services_min_fields';
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "services" */
export type Services_Min_Order_By = {
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "services" */
export type Services_Mutation_Response = {
  __typename?: 'services_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services>;
};

/** input type for inserting object relation for remote table "services" */
export type Services_Obj_Rel_Insert_Input = {
  data: Services_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Services_On_Conflict>;
};

/** on_conflict condition type for table "services" */
export type Services_On_Conflict = {
  constraint: Services_Constraint;
  update_columns?: Array<Services_Update_Column>;
  where?: InputMaybe<Services_Bool_Exp>;
};

/** Ordering options when selecting data from "services". */
export type Services_Order_By = {
  available_in_online_registration?: InputMaybe<Order_By>;
  available_online?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  event_period_services_aggregate?: InputMaybe<Event_Period_Services_Aggregate_Order_By>;
  event_services_aggregate?: InputMaybe<Event_Services_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  price_per_participant?: InputMaybe<Order_By>;
  registration_meetingrequests_aggregate?: InputMaybe<Registration_Meetingrequest_Aggregate_Order_By>;
  services_assigned_tos_aggregate?: InputMaybe<Services_Assigned_To_Aggregate_Order_By>;
  services_restricted_rooms_aggregate?: InputMaybe<Services_Restricted_Rooms_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services */
export type Services_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "services_restricted_rooms" */
export type Services_Restricted_Rooms = {
  __typename?: 'services_restricted_rooms';
  id: Scalars['bigint'];
  /** An object relationship */
  room: Rooms;
  room_id: Scalars['bigint'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['bigint'];
};

/** aggregated selection of "services_restricted_rooms" */
export type Services_Restricted_Rooms_Aggregate = {
  __typename?: 'services_restricted_rooms_aggregate';
  aggregate?: Maybe<Services_Restricted_Rooms_Aggregate_Fields>;
  nodes: Array<Services_Restricted_Rooms>;
};

export type Services_Restricted_Rooms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Services_Restricted_Rooms_Aggregate_Bool_Exp_Count>;
};

export type Services_Restricted_Rooms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "services_restricted_rooms" */
export type Services_Restricted_Rooms_Aggregate_Fields = {
  __typename?: 'services_restricted_rooms_aggregate_fields';
  avg?: Maybe<Services_Restricted_Rooms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Services_Restricted_Rooms_Max_Fields>;
  min?: Maybe<Services_Restricted_Rooms_Min_Fields>;
  stddev?: Maybe<Services_Restricted_Rooms_Stddev_Fields>;
  stddev_pop?: Maybe<Services_Restricted_Rooms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Services_Restricted_Rooms_Stddev_Samp_Fields>;
  sum?: Maybe<Services_Restricted_Rooms_Sum_Fields>;
  var_pop?: Maybe<Services_Restricted_Rooms_Var_Pop_Fields>;
  var_samp?: Maybe<Services_Restricted_Rooms_Var_Samp_Fields>;
  variance?: Maybe<Services_Restricted_Rooms_Variance_Fields>;
};


/** aggregate fields of "services_restricted_rooms" */
export type Services_Restricted_Rooms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Aggregate_Order_By = {
  avg?: InputMaybe<Services_Restricted_Rooms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Services_Restricted_Rooms_Max_Order_By>;
  min?: InputMaybe<Services_Restricted_Rooms_Min_Order_By>;
  stddev?: InputMaybe<Services_Restricted_Rooms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Services_Restricted_Rooms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Services_Restricted_Rooms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Services_Restricted_Rooms_Sum_Order_By>;
  var_pop?: InputMaybe<Services_Restricted_Rooms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Services_Restricted_Rooms_Var_Samp_Order_By>;
  variance?: InputMaybe<Services_Restricted_Rooms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Arr_Rel_Insert_Input = {
  data: Array<Services_Restricted_Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Services_Restricted_Rooms_On_Conflict>;
};

/** aggregate avg on columns */
export type Services_Restricted_Rooms_Avg_Fields = {
  __typename?: 'services_restricted_rooms_avg_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "services_restricted_rooms". All fields are combined with a logical 'AND'. */
export type Services_Restricted_Rooms_Bool_Exp = {
  _and?: InputMaybe<Array<Services_Restricted_Rooms_Bool_Exp>>;
  _not?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
  _or?: InputMaybe<Array<Services_Restricted_Rooms_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  room?: InputMaybe<Rooms_Bool_Exp>;
  room_id?: InputMaybe<Bigint_Comparison_Exp>;
  service?: InputMaybe<Services_Bool_Exp>;
  service_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "services_restricted_rooms" */
export enum Services_Restricted_Rooms_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServicesRestrictedRoomsPkey = 'services_restricted_rooms_pkey',
  /** unique or primary key constraint on columns "service_id", "room_id" */
  ServicesRestrictedRoomsServiceIdRoomId_9be669d0Uniq = 'services_restricted_rooms_service_id_room_id_9be669d0_uniq'
}

/** input type for incrementing numeric columns in table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room?: InputMaybe<Rooms_Obj_Rel_Insert_Input>;
  room_id?: InputMaybe<Scalars['bigint']>;
  service?: InputMaybe<Services_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Services_Restricted_Rooms_Max_Fields = {
  __typename?: 'services_restricted_rooms_max_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Services_Restricted_Rooms_Min_Fields = {
  __typename?: 'services_restricted_rooms_min_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Mutation_Response = {
  __typename?: 'services_restricted_rooms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Services_Restricted_Rooms>;
};

/** on_conflict condition type for table "services_restricted_rooms" */
export type Services_Restricted_Rooms_On_Conflict = {
  constraint: Services_Restricted_Rooms_Constraint;
  update_columns?: Array<Services_Restricted_Rooms_Update_Column>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "services_restricted_rooms". */
export type Services_Restricted_Rooms_Order_By = {
  id?: InputMaybe<Order_By>;
  room?: InputMaybe<Rooms_Order_By>;
  room_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Services_Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: services_restricted_rooms */
export type Services_Restricted_Rooms_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "services_restricted_rooms" */
export enum Services_Restricted_Rooms_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Services_Restricted_Rooms_Stddev_Fields = {
  __typename?: 'services_restricted_rooms_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Services_Restricted_Rooms_Stddev_Pop_Fields = {
  __typename?: 'services_restricted_rooms_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Services_Restricted_Rooms_Stddev_Samp_Fields = {
  __typename?: 'services_restricted_rooms_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Restricted_Rooms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Restricted_Rooms_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  room_id?: InputMaybe<Scalars['bigint']>;
  service_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Services_Restricted_Rooms_Sum_Fields = {
  __typename?: 'services_restricted_rooms_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  room_id?: Maybe<Scalars['bigint']>;
  service_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** update columns of table "services_restricted_rooms" */
export enum Services_Restricted_Rooms_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoomId = 'room_id',
  /** column name */
  ServiceId = 'service_id'
}

export type Services_Restricted_Rooms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Services_Restricted_Rooms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Restricted_Rooms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Restricted_Rooms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Services_Restricted_Rooms_Var_Pop_Fields = {
  __typename?: 'services_restricted_rooms_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Services_Restricted_Rooms_Var_Samp_Fields = {
  __typename?: 'services_restricted_rooms_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Services_Restricted_Rooms_Variance_Fields = {
  __typename?: 'services_restricted_rooms_variance_fields';
  id?: Maybe<Scalars['Float']>;
  room_id?: Maybe<Scalars['Float']>;
  service_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "services_restricted_rooms" */
export type Services_Restricted_Rooms_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  room_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
};

/** select columns of table "services" */
export enum Services_Select_Column {
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerParticipant = 'price_per_participant',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "services_aggregate_bool_exp_bool_and_arguments_columns" columns of table "services" */
export enum Services_Select_Column_Services_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  PricePerParticipant = 'price_per_participant'
}

/** select "services_aggregate_bool_exp_bool_or_arguments_columns" columns of table "services" */
export enum Services_Select_Column_Services_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  PricePerParticipant = 'price_per_participant'
}

/** input type for updating data in table "services" */
export type Services_Set_Input = {
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  price_per_participant?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Services_Stddev_Fields = {
  __typename?: 'services_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "services" */
export type Services_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Services_Stddev_Pop_Fields = {
  __typename?: 'services_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "services" */
export type Services_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Services_Stddev_Samp_Fields = {
  __typename?: 'services_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "services" */
export type Services_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "services" */
export type Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Services_Stream_Cursor_Value_Input = {
  available_in_online_registration?: InputMaybe<Scalars['Boolean']>;
  available_online?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['interval']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  price?: InputMaybe<Scalars['numeric']>;
  price_per_participant?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Services_Sum_Fields = {
  __typename?: 'services_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "services" */
export type Services_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** update columns of table "services" */
export enum Services_Update_Column {
  /** column name */
  AvailableInOnlineRegistration = 'available_in_online_registration',
  /** column name */
  AvailableOnline = 'available_online',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerParticipant = 'price_per_participant',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Services_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Services_Var_Pop_Fields = {
  __typename?: 'services_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "services" */
export type Services_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Services_Var_Samp_Fields = {
  __typename?: 'services_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "services" */
export type Services_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Services_Variance_Fields = {
  __typename?: 'services_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "services" */
export type Services_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate = {
  __typename?: 'sms_notifications_defaultsmstemplate';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  language: Scalars['String'];
  template_type: Scalars['String'];
  text: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Aggregate = {
  __typename?: 'sms_notifications_defaultsmstemplate_aggregate';
  aggregate?: Maybe<Sms_Notifications_Defaultsmstemplate_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Defaultsmstemplate>;
};

/** aggregate fields of "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Aggregate_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Defaultsmstemplate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Defaultsmstemplate_Max_Fields>;
  min?: Maybe<Sms_Notifications_Defaultsmstemplate_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Defaultsmstemplate_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Defaultsmstemplate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Defaultsmstemplate_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Defaultsmstemplate_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Defaultsmstemplate_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Defaultsmstemplate_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Defaultsmstemplate_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Defaultsmstemplate_Avg_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sms_notifications_defaultsmstemplate". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Defaultsmstemplate_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  template_type?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_defaultsmstemplate" */
export enum Sms_Notifications_Defaultsmstemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsDefaultsmstemplatePkey = 'sms_notifications_defaultsmstemplate_pkey',
  /** unique or primary key constraint on columns "template_type", "language" */
  UniqueDefaultSmsTemplatePerLanguageAndTemplateType = 'unique_default_sms_template_per_language_and_template_type'
}

/** input type for incrementing numeric columns in table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Defaultsmstemplate_Max_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  template_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sms_Notifications_Defaultsmstemplate_Min_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  template_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Mutation_Response = {
  __typename?: 'sms_notifications_defaultsmstemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Defaultsmstemplate>;
};

/** on_conflict condition type for table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_On_Conflict = {
  constraint: Sms_Notifications_Defaultsmstemplate_Constraint;
  update_columns?: Array<Sms_Notifications_Defaultsmstemplate_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_defaultsmstemplate". */
export type Sms_Notifications_Defaultsmstemplate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  template_type?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_defaultsmstemplate */
export type Sms_Notifications_Defaultsmstemplate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_defaultsmstemplate" */
export enum Sms_Notifications_Defaultsmstemplate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Defaultsmstemplate_Stddev_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Defaultsmstemplate_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Defaultsmstemplate_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sms_notifications_defaultsmstemplate" */
export type Sms_Notifications_Defaultsmstemplate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Defaultsmstemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Defaultsmstemplate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Defaultsmstemplate_Sum_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "sms_notifications_defaultsmstemplate" */
export enum Sms_Notifications_Defaultsmstemplate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sms_Notifications_Defaultsmstemplate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Defaultsmstemplate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Defaultsmstemplate_Var_Pop_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Defaultsmstemplate_Var_Samp_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Defaultsmstemplate_Variance_Fields = {
  __typename?: 'sms_notifications_defaultsmstemplate_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms = {
  __typename?: 'sms_notifications_sentsms';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  /** An object relationship */
  event?: Maybe<Event>;
  event_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An object relationship */
  patient?: Maybe<Patients>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice: Practices;
  /** An object relationship */
  practice_branch?: Maybe<Practice_Branches>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id: Scalars['bigint'];
  quota: Scalars['Int'];
  sent_at: Scalars['timestamptz'];
  status: Scalars['String'];
  text: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vendor_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Aggregate = {
  __typename?: 'sms_notifications_sentsms_aggregate';
  aggregate?: Maybe<Sms_Notifications_Sentsms_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Sentsms>;
};

export type Sms_Notifications_Sentsms_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sms_Notifications_Sentsms_Aggregate_Bool_Exp_Count>;
};

export type Sms_Notifications_Sentsms_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Aggregate_Fields = {
  __typename?: 'sms_notifications_sentsms_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Sentsms_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Sentsms_Max_Fields>;
  min?: Maybe<Sms_Notifications_Sentsms_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Sentsms_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Sentsms_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Sentsms_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Sentsms_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Sentsms_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Sentsms_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Sentsms_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Aggregate_Order_By = {
  avg?: InputMaybe<Sms_Notifications_Sentsms_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sms_Notifications_Sentsms_Max_Order_By>;
  min?: InputMaybe<Sms_Notifications_Sentsms_Min_Order_By>;
  stddev?: InputMaybe<Sms_Notifications_Sentsms_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sms_Notifications_Sentsms_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sms_Notifications_Sentsms_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sms_Notifications_Sentsms_Sum_Order_By>;
  var_pop?: InputMaybe<Sms_Notifications_Sentsms_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sms_Notifications_Sentsms_Var_Samp_Order_By>;
  variance?: InputMaybe<Sms_Notifications_Sentsms_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Arr_Rel_Insert_Input = {
  data: Array<Sms_Notifications_Sentsms_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sms_Notifications_Sentsms_On_Conflict>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Sentsms_Avg_Fields = {
  __typename?: 'sms_notifications_sentsms_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sms_notifications_sentsms". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Sentsms_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Sentsms_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Sentsms_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<Event_Bool_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patient_id?: InputMaybe<Bigint_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  quota?: InputMaybe<Int_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_sentsms" */
export enum Sms_Notifications_Sentsms_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsSentsmsPkey = 'sms_notifications_sentsms_pkey'
}

/** input type for incrementing numeric columns in table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  quota?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Event_Obj_Rel_Insert_Input>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  quota?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Sentsms_Max_Fields = {
  __typename?: 'sms_notifications_sentsms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  quota?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Notifications_Sentsms_Min_Fields = {
  __typename?: 'sms_notifications_sentsms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  phone_number?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  quota?: Maybe<Scalars['Int']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vendor_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Mutation_Response = {
  __typename?: 'sms_notifications_sentsms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Sentsms>;
};

/** on_conflict condition type for table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_On_Conflict = {
  constraint: Sms_Notifications_Sentsms_Constraint;
  update_columns?: Array<Sms_Notifications_Sentsms_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_sentsms". */
export type Sms_Notifications_Sentsms_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  event?: InputMaybe<Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patient_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_sentsms */
export type Sms_Notifications_Sentsms_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_sentsms" */
export enum Sms_Notifications_Sentsms_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Quota = 'quota',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

/** input type for updating data in table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  quota?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Sentsms_Stddev_Fields = {
  __typename?: 'sms_notifications_sentsms_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Sentsms_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_sentsms_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Sentsms_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_sentsms_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Sentsms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Sentsms_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  patient_id?: InputMaybe<Scalars['bigint']>;
  phone_number?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  quota?: InputMaybe<Scalars['Int']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vendor_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Sentsms_Sum_Fields = {
  __typename?: 'sms_notifications_sentsms_sum_fields';
  event_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  quota?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** update columns of table "sms_notifications_sentsms" */
export enum Sms_Notifications_Sentsms_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Quota = 'quota',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id'
}

export type Sms_Notifications_Sentsms_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Sentsms_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Sentsms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Sentsms_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Sentsms_Var_Pop_Fields = {
  __typename?: 'sms_notifications_sentsms_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Sentsms_Var_Samp_Fields = {
  __typename?: 'sms_notifications_sentsms_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Sentsms_Variance_Fields = {
  __typename?: 'sms_notifications_sentsms_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  patient_id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  quota?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sms_notifications_sentsms" */
export type Sms_Notifications_Sentsms_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  quota?: InputMaybe<Order_By>;
};

/** columns and relationships of "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig = {
  __typename?: 'sms_notifications_smsconfig';
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_quota: Scalars['Int'];
  days_before: Scalars['smallint'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hours_before: Scalars['smallint'];
  id: Scalars['bigint'];
  is_approved: Scalars['Boolean'];
  meeting_canceled_active: Scalars['Boolean'];
  new_meeting_active: Scalars['Boolean'];
  non_therapist_notifying_templates: Array<Scalars['String']>;
  only_first_period_session_is_reminded: Scalars['Boolean'];
  per_period_quota: Scalars['Int'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  reminder_per_branch_template: Scalars['Boolean'];
  reminder_sending_time: Scalars['String'];
  sender_name?: Maybe<Scalars['String']>;
  sending_time: Scalars['time'];
  time_change_active: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Aggregate = {
  __typename?: 'sms_notifications_smsconfig_aggregate';
  aggregate?: Maybe<Sms_Notifications_Smsconfig_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Smsconfig>;
};

/** aggregate fields of "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Aggregate_Fields = {
  __typename?: 'sms_notifications_smsconfig_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Smsconfig_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Smsconfig_Max_Fields>;
  min?: Maybe<Sms_Notifications_Smsconfig_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Smsconfig_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Smsconfig_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Smsconfig_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Smsconfig_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Smsconfig_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Smsconfig_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Smsconfig_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Smsconfig_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Smsconfig_Avg_Fields = {
  __typename?: 'sms_notifications_smsconfig_avg_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sms_notifications_smsconfig". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Smsconfig_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Smsconfig_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Smsconfig_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_quota?: InputMaybe<Int_Comparison_Exp>;
  days_before?: InputMaybe<Smallint_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  hours_before?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_approved?: InputMaybe<Boolean_Comparison_Exp>;
  meeting_canceled_active?: InputMaybe<Boolean_Comparison_Exp>;
  new_meeting_active?: InputMaybe<Boolean_Comparison_Exp>;
  non_therapist_notifying_templates?: InputMaybe<String_Array_Comparison_Exp>;
  only_first_period_session_is_reminded?: InputMaybe<Boolean_Comparison_Exp>;
  per_period_quota?: InputMaybe<Int_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  reminder_per_branch_template?: InputMaybe<Boolean_Comparison_Exp>;
  reminder_sending_time?: InputMaybe<String_Comparison_Exp>;
  sender_name?: InputMaybe<String_Comparison_Exp>;
  sending_time?: InputMaybe<Time_Comparison_Exp>;
  time_change_active?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_smsconfig" */
export enum Sms_Notifications_Smsconfig_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsSmsconfigPkey = 'sms_notifications_smsconfig_pkey',
  /** unique or primary key constraint on columns "practice_id" */
  SmsNotificationsSmsconfigPracticeIdKey = 'sms_notifications_smsconfig_practice_id_key'
}

/** input type for incrementing numeric columns in table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Inc_Input = {
  custom_quota?: InputMaybe<Scalars['Int']>;
  days_before?: InputMaybe<Scalars['smallint']>;
  hours_before?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  per_period_quota?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quota?: InputMaybe<Scalars['Int']>;
  days_before?: InputMaybe<Scalars['smallint']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hours_before?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  meeting_canceled_active?: InputMaybe<Scalars['Boolean']>;
  new_meeting_active?: InputMaybe<Scalars['Boolean']>;
  non_therapist_notifying_templates?: InputMaybe<Array<Scalars['String']>>;
  only_first_period_session_is_reminded?: InputMaybe<Scalars['Boolean']>;
  per_period_quota?: InputMaybe<Scalars['Int']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reminder_per_branch_template?: InputMaybe<Scalars['Boolean']>;
  reminder_sending_time?: InputMaybe<Scalars['String']>;
  sender_name?: InputMaybe<Scalars['String']>;
  sending_time?: InputMaybe<Scalars['time']>;
  time_change_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Smsconfig_Max_Fields = {
  __typename?: 'sms_notifications_smsconfig_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_quota?: Maybe<Scalars['Int']>;
  days_before?: Maybe<Scalars['smallint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hours_before?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  non_therapist_notifying_templates?: Maybe<Array<Scalars['String']>>;
  per_period_quota?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  reminder_sending_time?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sms_Notifications_Smsconfig_Min_Fields = {
  __typename?: 'sms_notifications_smsconfig_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_quota?: Maybe<Scalars['Int']>;
  days_before?: Maybe<Scalars['smallint']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  hours_before?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  non_therapist_notifying_templates?: Maybe<Array<Scalars['String']>>;
  per_period_quota?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  reminder_sending_time?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Mutation_Response = {
  __typename?: 'sms_notifications_smsconfig_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Smsconfig>;
};

/** input type for inserting object relation for remote table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Obj_Rel_Insert_Input = {
  data: Sms_Notifications_Smsconfig_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sms_Notifications_Smsconfig_On_Conflict>;
};

/** on_conflict condition type for table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_On_Conflict = {
  constraint: Sms_Notifications_Smsconfig_Constraint;
  update_columns?: Array<Sms_Notifications_Smsconfig_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_smsconfig". */
export type Sms_Notifications_Smsconfig_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_quota?: InputMaybe<Order_By>;
  days_before?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  hours_before?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_approved?: InputMaybe<Order_By>;
  meeting_canceled_active?: InputMaybe<Order_By>;
  new_meeting_active?: InputMaybe<Order_By>;
  non_therapist_notifying_templates?: InputMaybe<Order_By>;
  only_first_period_session_is_reminded?: InputMaybe<Order_By>;
  per_period_quota?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  reminder_per_branch_template?: InputMaybe<Order_By>;
  reminder_sending_time?: InputMaybe<Order_By>;
  sender_name?: InputMaybe<Order_By>;
  sending_time?: InputMaybe<Order_By>;
  time_change_active?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_smsconfig */
export type Sms_Notifications_Smsconfig_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_smsconfig" */
export enum Sms_Notifications_Smsconfig_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomQuota = 'custom_quota',
  /** column name */
  DaysBefore = 'days_before',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HoursBefore = 'hours_before',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  MeetingCanceledActive = 'meeting_canceled_active',
  /** column name */
  NewMeetingActive = 'new_meeting_active',
  /** column name */
  NonTherapistNotifyingTemplates = 'non_therapist_notifying_templates',
  /** column name */
  OnlyFirstPeriodSessionIsReminded = 'only_first_period_session_is_reminded',
  /** column name */
  PerPeriodQuota = 'per_period_quota',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ReminderPerBranchTemplate = 'reminder_per_branch_template',
  /** column name */
  ReminderSendingTime = 'reminder_sending_time',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  SendingTime = 'sending_time',
  /** column name */
  TimeChangeActive = 'time_change_active',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quota?: InputMaybe<Scalars['Int']>;
  days_before?: InputMaybe<Scalars['smallint']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hours_before?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  meeting_canceled_active?: InputMaybe<Scalars['Boolean']>;
  new_meeting_active?: InputMaybe<Scalars['Boolean']>;
  non_therapist_notifying_templates?: InputMaybe<Array<Scalars['String']>>;
  only_first_period_session_is_reminded?: InputMaybe<Scalars['Boolean']>;
  per_period_quota?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reminder_per_branch_template?: InputMaybe<Scalars['Boolean']>;
  reminder_sending_time?: InputMaybe<Scalars['String']>;
  sender_name?: InputMaybe<Scalars['String']>;
  sending_time?: InputMaybe<Scalars['time']>;
  time_change_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Smsconfig_Stddev_Fields = {
  __typename?: 'sms_notifications_smsconfig_stddev_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Smsconfig_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_smsconfig_stddev_pop_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Smsconfig_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_smsconfig_stddev_samp_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sms_notifications_smsconfig" */
export type Sms_Notifications_Smsconfig_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Smsconfig_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Smsconfig_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_quota?: InputMaybe<Scalars['Int']>;
  days_before?: InputMaybe<Scalars['smallint']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  hours_before?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_approved?: InputMaybe<Scalars['Boolean']>;
  meeting_canceled_active?: InputMaybe<Scalars['Boolean']>;
  new_meeting_active?: InputMaybe<Scalars['Boolean']>;
  non_therapist_notifying_templates?: InputMaybe<Array<Scalars['String']>>;
  only_first_period_session_is_reminded?: InputMaybe<Scalars['Boolean']>;
  per_period_quota?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  reminder_per_branch_template?: InputMaybe<Scalars['Boolean']>;
  reminder_sending_time?: InputMaybe<Scalars['String']>;
  sender_name?: InputMaybe<Scalars['String']>;
  sending_time?: InputMaybe<Scalars['time']>;
  time_change_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Smsconfig_Sum_Fields = {
  __typename?: 'sms_notifications_smsconfig_sum_fields';
  custom_quota?: Maybe<Scalars['Int']>;
  days_before?: Maybe<Scalars['smallint']>;
  hours_before?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  per_period_quota?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "sms_notifications_smsconfig" */
export enum Sms_Notifications_Smsconfig_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomQuota = 'custom_quota',
  /** column name */
  DaysBefore = 'days_before',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HoursBefore = 'hours_before',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'is_approved',
  /** column name */
  MeetingCanceledActive = 'meeting_canceled_active',
  /** column name */
  NewMeetingActive = 'new_meeting_active',
  /** column name */
  NonTherapistNotifyingTemplates = 'non_therapist_notifying_templates',
  /** column name */
  OnlyFirstPeriodSessionIsReminded = 'only_first_period_session_is_reminded',
  /** column name */
  PerPeriodQuota = 'per_period_quota',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ReminderPerBranchTemplate = 'reminder_per_branch_template',
  /** column name */
  ReminderSendingTime = 'reminder_sending_time',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  SendingTime = 'sending_time',
  /** column name */
  TimeChangeActive = 'time_change_active',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sms_Notifications_Smsconfig_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Smsconfig_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Smsconfig_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Smsconfig_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Smsconfig_Var_Pop_Fields = {
  __typename?: 'sms_notifications_smsconfig_var_pop_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Smsconfig_Var_Samp_Fields = {
  __typename?: 'sms_notifications_smsconfig_var_samp_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Smsconfig_Variance_Fields = {
  __typename?: 'sms_notifications_smsconfig_variance_fields';
  custom_quota?: Maybe<Scalars['Float']>;
  days_before?: Maybe<Scalars['Float']>;
  hours_before?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  per_period_quota?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase = {
  __typename?: 'sms_notifications_smspacketpurchase';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employees>;
  id: Scalars['bigint'];
  legacy_packet_number?: Maybe<Scalars['Int']>;
  paid_on?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  practice?: Maybe<Practices>;
  practice_id?: Maybe<Scalars['bigint']>;
  przelewy24_order_id?: Maybe<Scalars['bigint']>;
  requested_by_id?: Maybe<Scalars['bigint']>;
  sms_packet_id?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Aggregate = {
  __typename?: 'sms_notifications_smspacketpurchase_aggregate';
  aggregate?: Maybe<Sms_Notifications_Smspacketpurchase_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Smspacketpurchase>;
};

export type Sms_Notifications_Smspacketpurchase_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sms_Notifications_Smspacketpurchase_Aggregate_Bool_Exp_Count>;
};

export type Sms_Notifications_Smspacketpurchase_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Aggregate_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Smspacketpurchase_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Smspacketpurchase_Max_Fields>;
  min?: Maybe<Sms_Notifications_Smspacketpurchase_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Smspacketpurchase_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Smspacketpurchase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Smspacketpurchase_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Smspacketpurchase_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Smspacketpurchase_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Smspacketpurchase_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Smspacketpurchase_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Aggregate_Order_By = {
  avg?: InputMaybe<Sms_Notifications_Smspacketpurchase_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sms_Notifications_Smspacketpurchase_Max_Order_By>;
  min?: InputMaybe<Sms_Notifications_Smspacketpurchase_Min_Order_By>;
  stddev?: InputMaybe<Sms_Notifications_Smspacketpurchase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sms_Notifications_Smspacketpurchase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sms_Notifications_Smspacketpurchase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sms_Notifications_Smspacketpurchase_Sum_Order_By>;
  var_pop?: InputMaybe<Sms_Notifications_Smspacketpurchase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sms_Notifications_Smspacketpurchase_Var_Samp_Order_By>;
  variance?: InputMaybe<Sms_Notifications_Smspacketpurchase_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Arr_Rel_Insert_Input = {
  data: Array<Sms_Notifications_Smspacketpurchase_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sms_Notifications_Smspacketpurchase_On_Conflict>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Smspacketpurchase_Avg_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_avg_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sms_notifications_smspacketpurchase". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Smspacketpurchase_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  legacy_packet_number?: InputMaybe<Int_Comparison_Exp>;
  paid_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  przelewy24_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  requested_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  sms_packet_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_smspacketpurchase" */
export enum Sms_Notifications_Smspacketpurchase_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsSmspacketpurchasePkey = 'sms_notifications_smspacketpurchase_pkey',
  /** unique or primary key constraint on columns "przelewy24_order_id" */
  SmsNotificationsSmspacketpurchasePrzelewy24OrderIdKey = 'sms_notifications_smspacketpurchase_przelewy24_order_id_key'
}

/** input type for incrementing numeric columns in table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  legacy_packet_number?: InputMaybe<Scalars['Int']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  przelewy24_order_id?: InputMaybe<Scalars['bigint']>;
  requested_by_id?: InputMaybe<Scalars['bigint']>;
  sms_packet_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  legacy_packet_number?: InputMaybe<Scalars['Int']>;
  paid_on?: InputMaybe<Scalars['timestamptz']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  przelewy24_order_id?: InputMaybe<Scalars['bigint']>;
  requested_by_id?: InputMaybe<Scalars['bigint']>;
  sms_packet_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Smspacketpurchase_Max_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  legacy_packet_number?: Maybe<Scalars['Int']>;
  paid_on?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['bigint']>;
  przelewy24_order_id?: Maybe<Scalars['bigint']>;
  requested_by_id?: Maybe<Scalars['bigint']>;
  sms_packet_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Notifications_Smspacketpurchase_Min_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  legacy_packet_number?: Maybe<Scalars['Int']>;
  paid_on?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['bigint']>;
  przelewy24_order_id?: Maybe<Scalars['bigint']>;
  requested_by_id?: Maybe<Scalars['bigint']>;
  sms_packet_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Mutation_Response = {
  __typename?: 'sms_notifications_smspacketpurchase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Smspacketpurchase>;
};

/** on_conflict condition type for table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_On_Conflict = {
  constraint: Sms_Notifications_Smspacketpurchase_Constraint;
  update_columns?: Array<Sms_Notifications_Smspacketpurchase_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_smspacketpurchase". */
export type Sms_Notifications_Smspacketpurchase_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  paid_on?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_smspacketpurchase */
export type Sms_Notifications_Smspacketpurchase_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_smspacketpurchase" */
export enum Sms_Notifications_Smspacketpurchase_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyPacketNumber = 'legacy_packet_number',
  /** column name */
  PaidOn = 'paid_on',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Przelewy24OrderId = 'przelewy24_order_id',
  /** column name */
  RequestedById = 'requested_by_id',
  /** column name */
  SmsPacketId = 'sms_packet_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  legacy_packet_number?: InputMaybe<Scalars['Int']>;
  paid_on?: InputMaybe<Scalars['timestamptz']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  przelewy24_order_id?: InputMaybe<Scalars['bigint']>;
  requested_by_id?: InputMaybe<Scalars['bigint']>;
  sms_packet_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Smspacketpurchase_Stddev_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Smspacketpurchase_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Smspacketpurchase_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Smspacketpurchase_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Smspacketpurchase_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  legacy_packet_number?: InputMaybe<Scalars['Int']>;
  paid_on?: InputMaybe<Scalars['timestamptz']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  przelewy24_order_id?: InputMaybe<Scalars['bigint']>;
  requested_by_id?: InputMaybe<Scalars['bigint']>;
  sms_packet_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Smspacketpurchase_Sum_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  legacy_packet_number?: Maybe<Scalars['Int']>;
  practice_id?: Maybe<Scalars['bigint']>;
  przelewy24_order_id?: Maybe<Scalars['bigint']>;
  requested_by_id?: Maybe<Scalars['bigint']>;
  sms_packet_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** update columns of table "sms_notifications_smspacketpurchase" */
export enum Sms_Notifications_Smspacketpurchase_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyPacketNumber = 'legacy_packet_number',
  /** column name */
  PaidOn = 'paid_on',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  Przelewy24OrderId = 'przelewy24_order_id',
  /** column name */
  RequestedById = 'requested_by_id',
  /** column name */
  SmsPacketId = 'sms_packet_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sms_Notifications_Smspacketpurchase_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Smspacketpurchase_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Smspacketpurchase_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Smspacketpurchase_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Smspacketpurchase_Var_Pop_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Smspacketpurchase_Var_Samp_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Smspacketpurchase_Variance_Fields = {
  __typename?: 'sms_notifications_smspacketpurchase_variance_fields';
  id?: Maybe<Scalars['Float']>;
  legacy_packet_number?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
  przelewy24_order_id?: Maybe<Scalars['Float']>;
  requested_by_id?: Maybe<Scalars['Float']>;
  sms_packet_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sms_notifications_smspacketpurchase" */
export type Sms_Notifications_Smspacketpurchase_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  legacy_packet_number?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  przelewy24_order_id?: InputMaybe<Order_By>;
  requested_by_id?: InputMaybe<Order_By>;
  sms_packet_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate = {
  __typename?: 'sms_notifications_smstemplate';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  language: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  /** An object relationship */
  practice_branch?: Maybe<Practice_Branches>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id: Scalars['bigint'];
  template_type: Scalars['String'];
  text: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Aggregate = {
  __typename?: 'sms_notifications_smstemplate_aggregate';
  aggregate?: Maybe<Sms_Notifications_Smstemplate_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Smstemplate>;
};

export type Sms_Notifications_Smstemplate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sms_Notifications_Smstemplate_Aggregate_Bool_Exp_Count>;
};

export type Sms_Notifications_Smstemplate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Aggregate_Fields = {
  __typename?: 'sms_notifications_smstemplate_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Smstemplate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Smstemplate_Max_Fields>;
  min?: Maybe<Sms_Notifications_Smstemplate_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Smstemplate_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Smstemplate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Smstemplate_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Smstemplate_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Smstemplate_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Smstemplate_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Smstemplate_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Aggregate_Order_By = {
  avg?: InputMaybe<Sms_Notifications_Smstemplate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sms_Notifications_Smstemplate_Max_Order_By>;
  min?: InputMaybe<Sms_Notifications_Smstemplate_Min_Order_By>;
  stddev?: InputMaybe<Sms_Notifications_Smstemplate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sms_Notifications_Smstemplate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sms_Notifications_Smstemplate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sms_Notifications_Smstemplate_Sum_Order_By>;
  var_pop?: InputMaybe<Sms_Notifications_Smstemplate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sms_Notifications_Smstemplate_Var_Samp_Order_By>;
  variance?: InputMaybe<Sms_Notifications_Smstemplate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Arr_Rel_Insert_Input = {
  data: Array<Sms_Notifications_Smstemplate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sms_Notifications_Smstemplate_On_Conflict>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Smstemplate_Avg_Fields = {
  __typename?: 'sms_notifications_smstemplate_avg_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sms_notifications_smstemplate". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Smstemplate_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Smstemplate_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Smstemplate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_branch?: InputMaybe<Practice_Branches_Bool_Exp>;
  practice_branch_id?: InputMaybe<Bigint_Comparison_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  template_type?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_smstemplate" */
export enum Sms_Notifications_Smstemplate_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsSmstemplatePkey = 'sms_notifications_smstemplate_pkey',
  /** unique or primary key constraint on columns "template_type", "practice_id", "language", "practice_branch_id" */
  UniquePracticeBranchSmsTemplatePerLanguageAndTemplateT = 'unique_practice_branch_sms_template_per_language_and_template_t'
}

/** input type for incrementing numeric columns in table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_branch?: InputMaybe<Practice_Branches_Obj_Rel_Insert_Input>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Smstemplate_Max_Fields = {
  __typename?: 'sms_notifications_smstemplate_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  template_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  template_type?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Notifications_Smstemplate_Min_Fields = {
  __typename?: 'sms_notifications_smstemplate_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  template_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  template_type?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Mutation_Response = {
  __typename?: 'sms_notifications_smstemplate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Smstemplate>;
};

/** on_conflict condition type for table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_On_Conflict = {
  constraint: Sms_Notifications_Smstemplate_Constraint;
  update_columns?: Array<Sms_Notifications_Smstemplate_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_smstemplate". */
export type Sms_Notifications_Smstemplate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_branch?: InputMaybe<Practice_Branches_Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
  template_type?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_smstemplate */
export type Sms_Notifications_Smstemplate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_smstemplate" */
export enum Sms_Notifications_Smstemplate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Smstemplate_Stddev_Fields = {
  __typename?: 'sms_notifications_smstemplate_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Smstemplate_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_smstemplate_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Smstemplate_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_smstemplate_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Smstemplate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Smstemplate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  language?: InputMaybe<Scalars['String']>;
  practice_branch_id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  template_type?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Smstemplate_Sum_Fields = {
  __typename?: 'sms_notifications_smstemplate_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  practice_branch_id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** update columns of table "sms_notifications_smstemplate" */
export enum Sms_Notifications_Smstemplate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  PracticeBranchId = 'practice_branch_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sms_Notifications_Smstemplate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Smstemplate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Smstemplate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Smstemplate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Smstemplate_Var_Pop_Fields = {
  __typename?: 'sms_notifications_smstemplate_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Smstemplate_Var_Samp_Fields = {
  __typename?: 'sms_notifications_smstemplate_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Smstemplate_Variance_Fields = {
  __typename?: 'sms_notifications_smstemplate_variance_fields';
  id?: Maybe<Scalars['Float']>;
  practice_branch_id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sms_notifications_smstemplate" */
export type Sms_Notifications_Smstemplate_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  practice_branch_id?: InputMaybe<Order_By>;
  practice_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable = {
  __typename?: 'sms_notifications_smstemplatevariable';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Aggregate = {
  __typename?: 'sms_notifications_smstemplatevariable_aggregate';
  aggregate?: Maybe<Sms_Notifications_Smstemplatevariable_Aggregate_Fields>;
  nodes: Array<Sms_Notifications_Smstemplatevariable>;
};

/** aggregate fields of "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Aggregate_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_aggregate_fields';
  avg?: Maybe<Sms_Notifications_Smstemplatevariable_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Sms_Notifications_Smstemplatevariable_Max_Fields>;
  min?: Maybe<Sms_Notifications_Smstemplatevariable_Min_Fields>;
  stddev?: Maybe<Sms_Notifications_Smstemplatevariable_Stddev_Fields>;
  stddev_pop?: Maybe<Sms_Notifications_Smstemplatevariable_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sms_Notifications_Smstemplatevariable_Stddev_Samp_Fields>;
  sum?: Maybe<Sms_Notifications_Smstemplatevariable_Sum_Fields>;
  var_pop?: Maybe<Sms_Notifications_Smstemplatevariable_Var_Pop_Fields>;
  var_samp?: Maybe<Sms_Notifications_Smstemplatevariable_Var_Samp_Fields>;
  variance?: Maybe<Sms_Notifications_Smstemplatevariable_Variance_Fields>;
};


/** aggregate fields of "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Sms_Notifications_Smstemplatevariable_Avg_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "sms_notifications_smstemplatevariable". All fields are combined with a logical 'AND'. */
export type Sms_Notifications_Smstemplatevariable_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Bool_Exp>>;
  _not?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  description_en?: InputMaybe<String_Comparison_Exp>;
  description_pl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_pl?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_notifications_smstemplatevariable" */
export enum Sms_Notifications_Smstemplatevariable_Constraint {
  /** unique or primary key constraint on columns "id" */
  SmsNotificationsSmstemplatevariablePkey = 'sms_notifications_smstemplatevariable_pkey'
}

/** input type for incrementing numeric columns in table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Notifications_Smstemplatevariable_Max_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Sms_Notifications_Smstemplatevariable_Min_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description_pl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_pl?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Mutation_Response = {
  __typename?: 'sms_notifications_smstemplatevariable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Sms_Notifications_Smstemplatevariable>;
};

/** on_conflict condition type for table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_On_Conflict = {
  constraint: Sms_Notifications_Smstemplatevariable_Constraint;
  update_columns?: Array<Sms_Notifications_Smstemplatevariable_Update_Column>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};

/** Ordering options when selecting data from "sms_notifications_smstemplatevariable". */
export type Sms_Notifications_Smstemplatevariable_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  description_en?: InputMaybe<Order_By>;
  description_pl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_pl?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sms_notifications_smstemplatevariable */
export type Sms_Notifications_Smstemplatevariable_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "sms_notifications_smstemplatevariable" */
export enum Sms_Notifications_Smstemplatevariable_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionPl = 'description_pl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NamePl = 'name_pl',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Sms_Notifications_Smstemplatevariable_Stddev_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Sms_Notifications_Smstemplatevariable_Stddev_Pop_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Sms_Notifications_Smstemplatevariable_Stddev_Samp_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "sms_notifications_smstemplatevariable" */
export type Sms_Notifications_Smstemplatevariable_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Notifications_Smstemplatevariable_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Notifications_Smstemplatevariable_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_pl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_pl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Sms_Notifications_Smstemplatevariable_Sum_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "sms_notifications_smstemplatevariable" */
export enum Sms_Notifications_Smstemplatevariable_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  DescriptionEn = 'description_en',
  /** column name */
  DescriptionPl = 'description_pl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NamePl = 'name_pl',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Sms_Notifications_Smstemplatevariable_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sms_Notifications_Smstemplatevariable_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sms_Notifications_Smstemplatevariable_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sms_Notifications_Smstemplatevariable_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sms_Notifications_Smstemplatevariable_Var_Pop_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Sms_Notifications_Smstemplatevariable_Var_Samp_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Sms_Notifications_Smstemplatevariable_Variance_Fields = {
  __typename?: 'sms_notifications_smstemplatevariable_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accounting_practiceexpense" */
  accounting_practiceexpense: Array<Accounting_Practiceexpense>;
  /** fetch aggregated fields from the table: "accounting_practiceexpense" */
  accounting_practiceexpense_aggregate: Accounting_Practiceexpense_Aggregate;
  /** fetch data from the table: "accounting_practiceexpense" using primary key columns */
  accounting_practiceexpense_by_pk?: Maybe<Accounting_Practiceexpense>;
  /** fetch data from the table in a streaming manner: "accounting_practiceexpense" */
  accounting_practiceexpense_stream: Array<Accounting_Practiceexpense>;
  /** fetch data from the table: "accounting_recurringexpense" */
  accounting_recurringexpense: Array<Accounting_Recurringexpense>;
  /** fetch aggregated fields from the table: "accounting_recurringexpense" */
  accounting_recurringexpense_aggregate: Accounting_Recurringexpense_Aggregate;
  /** fetch data from the table: "accounting_recurringexpense" using primary key columns */
  accounting_recurringexpense_by_pk?: Maybe<Accounting_Recurringexpense>;
  /** fetch data from the table in a streaming manner: "accounting_recurringexpense" */
  accounting_recurringexpense_stream: Array<Accounting_Recurringexpense>;
  /** fetch data from the table: "addresses" */
  addresses: Array<Addresses>;
  /** fetch aggregated fields from the table: "addresses" */
  addresses_aggregate: Addresses_Aggregate;
  /** fetch data from the table: "addresses" using primary key columns */
  addresses_by_pk?: Maybe<Addresses>;
  /** fetch data from the table in a streaming manner: "addresses" */
  addresses_stream: Array<Addresses>;
  /** fetch data from the table: "avatars" */
  avatars: Array<Avatars>;
  /** fetch aggregated fields from the table: "avatars" */
  avatars_aggregate: Avatars_Aggregate;
  /** fetch data from the table: "avatars" using primary key columns */
  avatars_by_pk?: Maybe<Avatars>;
  /** fetch data from the table in a streaming manner: "avatars" */
  avatars_stream: Array<Avatars>;
  /** fetch data from the table: "contact_details" */
  contact_details: Array<Contact_Details>;
  /** fetch aggregated fields from the table: "contact_details" */
  contact_details_aggregate: Contact_Details_Aggregate;
  /** fetch data from the table: "contact_details" using primary key columns */
  contact_details_by_pk?: Maybe<Contact_Details>;
  /** fetch data from the table in a streaming manner: "contact_details" */
  contact_details_stream: Array<Contact_Details>;
  /** An array relationship */
  couples: Array<Couples>;
  /** An aggregate relationship */
  couples_aggregate: Couples_Aggregate;
  /** fetch data from the table: "couples" using primary key columns */
  couples_by_pk?: Maybe<Couples>;
  /** An array relationship */
  couples_patients: Array<Couples_Patients>;
  /** An aggregate relationship */
  couples_patients_aggregate: Couples_Patients_Aggregate;
  /** fetch data from the table: "couples_patients" using primary key columns */
  couples_patients_by_pk?: Maybe<Couples_Patients>;
  /** fetch data from the table in a streaming manner: "couples_patients" */
  couples_patients_stream: Array<Couples_Patients>;
  /** fetch data from the table in a streaming manner: "couples" */
  couples_stream: Array<Couples>;
  /** An array relationship */
  couples_therapists: Array<Couples_Therapists>;
  /** An aggregate relationship */
  couples_therapists_aggregate: Couples_Therapists_Aggregate;
  /** fetch data from the table: "couples_therapists" using primary key columns */
  couples_therapists_by_pk?: Maybe<Couples_Therapists>;
  /** fetch data from the table in a streaming manner: "couples_therapists" */
  couples_therapists_stream: Array<Couples_Therapists>;
  /** An array relationship */
  ed_attachments: Array<Ed_Attachments>;
  /** An aggregate relationship */
  ed_attachments_aggregate: Ed_Attachments_Aggregate;
  /** fetch data from the table: "ed_attachments" using primary key columns */
  ed_attachments_by_pk?: Maybe<Ed_Attachments>;
  /** fetch data from the table in a streaming manner: "ed_attachments" */
  ed_attachments_stream: Array<Ed_Attachments>;
  /** fetch data from the table: "ed_group_presence" */
  ed_group_presence: Array<Ed_Group_Presence>;
  /** fetch aggregated fields from the table: "ed_group_presence" */
  ed_group_presence_aggregate: Ed_Group_Presence_Aggregate;
  /** fetch data from the table: "ed_group_presence" using primary key columns */
  ed_group_presence_by_pk?: Maybe<Ed_Group_Presence>;
  /** fetch data from the table in a streaming manner: "ed_group_presence" */
  ed_group_presence_stream: Array<Ed_Group_Presence>;
  /** An array relationship */
  ed_participants: Array<Ed_Participants>;
  /** An aggregate relationship */
  ed_participants_aggregate: Ed_Participants_Aggregate;
  /** fetch data from the table: "ed_participants" using primary key columns */
  ed_participants_by_pk?: Maybe<Ed_Participants>;
  /** fetch data from the table in a streaming manner: "ed_participants" */
  ed_participants_stream: Array<Ed_Participants>;
  /** fetch data from the table: "ed_payment_method" */
  ed_payment_method: Array<Ed_Payment_Method>;
  /** fetch aggregated fields from the table: "ed_payment_method" */
  ed_payment_method_aggregate: Ed_Payment_Method_Aggregate;
  /** fetch data from the table: "ed_payment_method" using primary key columns */
  ed_payment_method_by_pk?: Maybe<Ed_Payment_Method>;
  /** fetch data from the table in a streaming manner: "ed_payment_method" */
  ed_payment_method_stream: Array<Ed_Payment_Method>;
  /** fetch data from the table: "ed_presence" */
  ed_presence: Array<Ed_Presence>;
  /** fetch aggregated fields from the table: "ed_presence" */
  ed_presence_aggregate: Ed_Presence_Aggregate;
  /** fetch data from the table: "ed_presence" using primary key columns */
  ed_presence_by_pk?: Maybe<Ed_Presence>;
  /** fetch data from the table in a streaming manner: "ed_presence" */
  ed_presence_stream: Array<Ed_Presence>;
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: Employees_Aggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** An array relationship */
  employees_favorite_therapists: Array<Employees_Favorite_Therapists>;
  /** An aggregate relationship */
  employees_favorite_therapists_aggregate: Employees_Favorite_Therapists_Aggregate;
  /** fetch data from the table: "employees_favorite_therapists" using primary key columns */
  employees_favorite_therapists_by_pk?: Maybe<Employees_Favorite_Therapists>;
  /** fetch data from the table in a streaming manner: "employees_favorite_therapists" */
  employees_favorite_therapists_stream: Array<Employees_Favorite_Therapists>;
  /** fetch data from the table: "employees_preferred_room" */
  employees_preferred_room: Array<Employees_Preferred_Room>;
  /** fetch aggregated fields from the table: "employees_preferred_room" */
  employees_preferred_room_aggregate: Employees_Preferred_Room_Aggregate;
  /** fetch data from the table: "employees_preferred_room" using primary key columns */
  employees_preferred_room_by_pk?: Maybe<Employees_Preferred_Room>;
  /** fetch data from the table in a streaming manner: "employees_preferred_room" */
  employees_preferred_room_stream: Array<Employees_Preferred_Room>;
  /** An array relationship */
  employees_roles: Array<Employees_Roles>;
  /** An aggregate relationship */
  employees_roles_aggregate: Employees_Roles_Aggregate;
  /** fetch data from the table: "employees_roles" using primary key columns */
  employees_roles_by_pk?: Maybe<Employees_Roles>;
  /** fetch data from the table in a streaming manner: "employees_roles" */
  employees_roles_stream: Array<Employees_Roles>;
  /** fetch data from the table in a streaming manner: "employees" */
  employees_stream: Array<Employees>;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** An array relationship */
  event_participants: Array<Event_Participants>;
  /** An aggregate relationship */
  event_participants_aggregate: Event_Participants_Aggregate;
  /** fetch data from the table: "event_participants" using primary key columns */
  event_participants_by_pk?: Maybe<Event_Participants>;
  /** fetch data from the table in a streaming manner: "event_participants" */
  event_participants_stream: Array<Event_Participants>;
  /** fetch data from the table: "event_period" */
  event_period: Array<Event_Period>;
  /** fetch aggregated fields from the table: "event_period" */
  event_period_aggregate: Event_Period_Aggregate;
  /** fetch data from the table: "event_period" using primary key columns */
  event_period_by_pk?: Maybe<Event_Period>;
  /** An array relationship */
  event_period_participants: Array<Event_Period_Participants>;
  /** An aggregate relationship */
  event_period_participants_aggregate: Event_Period_Participants_Aggregate;
  /** fetch data from the table: "event_period_participants" using primary key columns */
  event_period_participants_by_pk?: Maybe<Event_Period_Participants>;
  /** fetch data from the table in a streaming manner: "event_period_participants" */
  event_period_participants_stream: Array<Event_Period_Participants>;
  /** An array relationship */
  event_period_services: Array<Event_Period_Services>;
  /** An aggregate relationship */
  event_period_services_aggregate: Event_Period_Services_Aggregate;
  /** fetch data from the table: "event_period_services" using primary key columns */
  event_period_services_by_pk?: Maybe<Event_Period_Services>;
  /** fetch data from the table in a streaming manner: "event_period_services" */
  event_period_services_stream: Array<Event_Period_Services>;
  /** fetch data from the table in a streaming manner: "event_period" */
  event_period_stream: Array<Event_Period>;
  /** An array relationship */
  event_period_therapists: Array<Event_Period_Therapists>;
  /** An aggregate relationship */
  event_period_therapists_aggregate: Event_Period_Therapists_Aggregate;
  /** fetch data from the table: "event_period_therapists" using primary key columns */
  event_period_therapists_by_pk?: Maybe<Event_Period_Therapists>;
  /** fetch data from the table in a streaming manner: "event_period_therapists" */
  event_period_therapists_stream: Array<Event_Period_Therapists>;
  /** An array relationship */
  event_services: Array<Event_Services>;
  /** An aggregate relationship */
  event_services_aggregate: Event_Services_Aggregate;
  /** fetch data from the table: "event_services" using primary key columns */
  event_services_by_pk?: Maybe<Event_Services>;
  /** fetch data from the table in a streaming manner: "event_services" */
  event_services_stream: Array<Event_Services>;
  /** fetch data from the table in a streaming manner: "event" */
  event_stream: Array<Event>;
  /** An array relationship */
  event_therapists: Array<Event_Therapists>;
  /** An aggregate relationship */
  event_therapists_aggregate: Event_Therapists_Aggregate;
  /** fetch data from the table: "event_therapists" using primary key columns */
  event_therapists_by_pk?: Maybe<Event_Therapists>;
  /** fetch data from the table in a streaming manner: "event_therapists" */
  event_therapists_stream: Array<Event_Therapists>;
  /** fetch data from the table: "gdpr_gdprconfig" */
  gdpr_gdprconfig: Array<Gdpr_Gdprconfig>;
  /** fetch aggregated fields from the table: "gdpr_gdprconfig" */
  gdpr_gdprconfig_aggregate: Gdpr_Gdprconfig_Aggregate;
  /** fetch data from the table: "gdpr_gdprconfig" using primary key columns */
  gdpr_gdprconfig_by_pk?: Maybe<Gdpr_Gdprconfig>;
  /** fetch data from the table in a streaming manner: "gdpr_gdprconfig" */
  gdpr_gdprconfig_stream: Array<Gdpr_Gdprconfig>;
  /** fetch data from the table: "gdpr_gdprdocument" */
  gdpr_gdprdocument: Array<Gdpr_Gdprdocument>;
  /** fetch aggregated fields from the table: "gdpr_gdprdocument" */
  gdpr_gdprdocument_aggregate: Gdpr_Gdprdocument_Aggregate;
  /** fetch data from the table: "gdpr_gdprdocument" using primary key columns */
  gdpr_gdprdocument_by_pk?: Maybe<Gdpr_Gdprdocument>;
  /** fetch data from the table in a streaming manner: "gdpr_gdprdocument" */
  gdpr_gdprdocument_stream: Array<Gdpr_Gdprdocument>;
  /** fetch data from the table: "gdpr_gdprdocumenttype" */
  gdpr_gdprdocumenttype: Array<Gdpr_Gdprdocumenttype>;
  /** fetch aggregated fields from the table: "gdpr_gdprdocumenttype" */
  gdpr_gdprdocumenttype_aggregate: Gdpr_Gdprdocumenttype_Aggregate;
  /** fetch data from the table: "gdpr_gdprdocumenttype" using primary key columns */
  gdpr_gdprdocumenttype_by_pk?: Maybe<Gdpr_Gdprdocumenttype>;
  /** fetch data from the table in a streaming manner: "gdpr_gdprdocumenttype" */
  gdpr_gdprdocumenttype_stream: Array<Gdpr_Gdprdocumenttype>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** fetch data from the table in a streaming manner: "group_members" */
  group_members_stream: Array<Group_Members>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>;
  /** fetch data from the table: "holiday_reasons" */
  holiday_reasons: Array<Holiday_Reasons>;
  /** fetch aggregated fields from the table: "holiday_reasons" */
  holiday_reasons_aggregate: Holiday_Reasons_Aggregate;
  /** fetch data from the table: "holiday_reasons" using primary key columns */
  holiday_reasons_by_pk?: Maybe<Holiday_Reasons>;
  /** fetch data from the table in a streaming manner: "holiday_reasons" */
  holiday_reasons_stream: Array<Holiday_Reasons>;
  /** An array relationship */
  holidays: Array<Holidays>;
  /** An aggregate relationship */
  holidays_aggregate: Holidays_Aggregate;
  /** fetch data from the table: "holidays" using primary key columns */
  holidays_by_pk?: Maybe<Holidays>;
  /** fetch data from the table in a streaming manner: "holidays" */
  holidays_stream: Array<Holidays>;
  /** fetch data from the table: "invoices_v2_billinginfo" */
  invoices_v2_billinginfo: Array<Invoices_V2_Billinginfo>;
  /** fetch aggregated fields from the table: "invoices_v2_billinginfo" */
  invoices_v2_billinginfo_aggregate: Invoices_V2_Billinginfo_Aggregate;
  /** fetch data from the table: "invoices_v2_billinginfo" using primary key columns */
  invoices_v2_billinginfo_by_pk?: Maybe<Invoices_V2_Billinginfo>;
  /** fetch data from the table in a streaming manner: "invoices_v2_billinginfo" */
  invoices_v2_billinginfo_stream: Array<Invoices_V2_Billinginfo>;
  /** fetch data from the table: "invoices_v2_invoice" */
  invoices_v2_invoice: Array<Invoices_V2_Invoice>;
  /** fetch aggregated fields from the table: "invoices_v2_invoice" */
  invoices_v2_invoice_aggregate: Invoices_V2_Invoice_Aggregate;
  /** fetch data from the table: "invoices_v2_invoice" using primary key columns */
  invoices_v2_invoice_by_pk?: Maybe<Invoices_V2_Invoice>;
  /** fetch data from the table in a streaming manner: "invoices_v2_invoice" */
  invoices_v2_invoice_stream: Array<Invoices_V2_Invoice>;
  /** fetch data from the table: "invoices_v2_invoiceconfig" */
  invoices_v2_invoiceconfig: Array<Invoices_V2_Invoiceconfig>;
  /** fetch aggregated fields from the table: "invoices_v2_invoiceconfig" */
  invoices_v2_invoiceconfig_aggregate: Invoices_V2_Invoiceconfig_Aggregate;
  /** fetch data from the table: "invoices_v2_invoiceconfig" using primary key columns */
  invoices_v2_invoiceconfig_by_pk?: Maybe<Invoices_V2_Invoiceconfig>;
  /** fetch data from the table in a streaming manner: "invoices_v2_invoiceconfig" */
  invoices_v2_invoiceconfig_stream: Array<Invoices_V2_Invoiceconfig>;
  /** fetch data from the table: "invoices_v2_invoicelineitem" */
  invoices_v2_invoicelineitem: Array<Invoices_V2_Invoicelineitem>;
  /** fetch aggregated fields from the table: "invoices_v2_invoicelineitem" */
  invoices_v2_invoicelineitem_aggregate: Invoices_V2_Invoicelineitem_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicelineitem" using primary key columns */
  invoices_v2_invoicelineitem_by_pk?: Maybe<Invoices_V2_Invoicelineitem>;
  /** An array relationship */
  invoices_v2_invoicelineitem_events: Array<Invoices_V2_Invoicelineitem_Events>;
  /** An aggregate relationship */
  invoices_v2_invoicelineitem_events_aggregate: Invoices_V2_Invoicelineitem_Events_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicelineitem_events" using primary key columns */
  invoices_v2_invoicelineitem_events_by_pk?: Maybe<Invoices_V2_Invoicelineitem_Events>;
  /** fetch data from the table in a streaming manner: "invoices_v2_invoicelineitem_events" */
  invoices_v2_invoicelineitem_events_stream: Array<Invoices_V2_Invoicelineitem_Events>;
  /** fetch data from the table in a streaming manner: "invoices_v2_invoicelineitem" */
  invoices_v2_invoicelineitem_stream: Array<Invoices_V2_Invoicelineitem>;
  /** fetch data from the table: "invoices_v2_invoicesentto" */
  invoices_v2_invoicesentto: Array<Invoices_V2_Invoicesentto>;
  /** fetch aggregated fields from the table: "invoices_v2_invoicesentto" */
  invoices_v2_invoicesentto_aggregate: Invoices_V2_Invoicesentto_Aggregate;
  /** fetch data from the table: "invoices_v2_invoicesentto" using primary key columns */
  invoices_v2_invoicesentto_by_pk?: Maybe<Invoices_V2_Invoicesentto>;
  /** fetch data from the table in a streaming manner: "invoices_v2_invoicesentto" */
  invoices_v2_invoicesentto_stream: Array<Invoices_V2_Invoicesentto>;
  /** An array relationship */
  national_holidays: Array<National_Holidays>;
  /** An aggregate relationship */
  national_holidays_aggregate: National_Holidays_Aggregate;
  /** fetch data from the table: "national_holidays" using primary key columns */
  national_holidays_by_pk?: Maybe<National_Holidays>;
  /** fetch data from the table in a streaming manner: "national_holidays" */
  national_holidays_stream: Array<National_Holidays>;
  /** fetch data from the table: "patient_addresses" */
  patient_addresses: Array<Patient_Addresses>;
  /** fetch aggregated fields from the table: "patient_addresses" */
  patient_addresses_aggregate: Patient_Addresses_Aggregate;
  /** fetch data from the table: "patient_addresses" using primary key columns */
  patient_addresses_by_pk?: Maybe<Patient_Addresses>;
  /** fetch data from the table in a streaming manner: "patient_addresses" */
  patient_addresses_stream: Array<Patient_Addresses>;
  /** fetch data from the table: "patient_educator" */
  patient_educator: Array<Patient_Educator>;
  /** fetch aggregated fields from the table: "patient_educator" */
  patient_educator_aggregate: Patient_Educator_Aggregate;
  /** fetch data from the table: "patient_educator" using primary key columns */
  patient_educator_by_pk?: Maybe<Patient_Educator>;
  /** fetch data from the table in a streaming manner: "patient_educator" */
  patient_educator_stream: Array<Patient_Educator>;
  /** fetch data from the table: "patient_guardian_addresses" */
  patient_guardian_addresses: Array<Patient_Guardian_Addresses>;
  /** fetch aggregated fields from the table: "patient_guardian_addresses" */
  patient_guardian_addresses_aggregate: Patient_Guardian_Addresses_Aggregate;
  /** fetch data from the table: "patient_guardian_addresses" using primary key columns */
  patient_guardian_addresses_by_pk?: Maybe<Patient_Guardian_Addresses>;
  /** fetch data from the table in a streaming manner: "patient_guardian_addresses" */
  patient_guardian_addresses_stream: Array<Patient_Guardian_Addresses>;
  /** fetch data from the table: "patient_guardians" */
  patient_guardians: Array<Patient_Guardians>;
  /** fetch aggregated fields from the table: "patient_guardians" */
  patient_guardians_aggregate: Patient_Guardians_Aggregate;
  /** fetch data from the table: "patient_guardians" using primary key columns */
  patient_guardians_by_pk?: Maybe<Patient_Guardians>;
  /** fetch data from the table in a streaming manner: "patient_guardians" */
  patient_guardians_stream: Array<Patient_Guardians>;
  /** fetch data from the table: "patient_psychotherapies" */
  patient_psychotherapies: Array<Patient_Psychotherapies>;
  /** fetch aggregated fields from the table: "patient_psychotherapies" */
  patient_psychotherapies_aggregate: Patient_Psychotherapies_Aggregate;
  /** fetch data from the table: "patient_psychotherapies" using primary key columns */
  patient_psychotherapies_by_pk?: Maybe<Patient_Psychotherapies>;
  /** fetch data from the table in a streaming manner: "patient_psychotherapies" */
  patient_psychotherapies_stream: Array<Patient_Psychotherapies>;
  /** fetch data from the table: "patient_si" */
  patient_si: Array<Patient_Si>;
  /** fetch aggregated fields from the table: "patient_si" */
  patient_si_aggregate: Patient_Si_Aggregate;
  /** fetch data from the table: "patient_si" using primary key columns */
  patient_si_by_pk?: Maybe<Patient_Si>;
  /** fetch data from the table in a streaming manner: "patient_si" */
  patient_si_stream: Array<Patient_Si>;
  /** fetch data from the table: "patient_speech_therapies" */
  patient_speech_therapies: Array<Patient_Speech_Therapies>;
  /** fetch aggregated fields from the table: "patient_speech_therapies" */
  patient_speech_therapies_aggregate: Patient_Speech_Therapies_Aggregate;
  /** fetch data from the table: "patient_speech_therapies" using primary key columns */
  patient_speech_therapies_by_pk?: Maybe<Patient_Speech_Therapies>;
  /** fetch data from the table in a streaming manner: "patient_speech_therapies" */
  patient_speech_therapies_stream: Array<Patient_Speech_Therapies>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table in a streaming manner: "patients" */
  patients_stream: Array<Patients>;
  /** fetch data from the table: "patients_therapists" */
  patients_therapists: Array<Patients_Therapists>;
  /** fetch aggregated fields from the table: "patients_therapists" */
  patients_therapists_aggregate: Patients_Therapists_Aggregate;
  /** fetch data from the table: "patients_therapists" using primary key columns */
  patients_therapists_by_pk?: Maybe<Patients_Therapists>;
  /** fetch data from the table in a streaming manner: "patients_therapists" */
  patients_therapists_stream: Array<Patients_Therapists>;
  /** An array relationship */
  practice_branches: Array<Practice_Branches>;
  /** An aggregate relationship */
  practice_branches_aggregate: Practice_Branches_Aggregate;
  /** fetch data from the table: "practice_branches" using primary key columns */
  practice_branches_by_pk?: Maybe<Practice_Branches>;
  /** fetch data from the table in a streaming manner: "practice_branches" */
  practice_branches_stream: Array<Practice_Branches>;
  /** fetch data from the table: "practice_logos" */
  practice_logos: Array<Practice_Logos>;
  /** fetch aggregated fields from the table: "practice_logos" */
  practice_logos_aggregate: Practice_Logos_Aggregate;
  /** fetch data from the table: "practice_logos" using primary key columns */
  practice_logos_by_pk?: Maybe<Practice_Logos>;
  /** fetch data from the table in a streaming manner: "practice_logos" */
  practice_logos_stream: Array<Practice_Logos>;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregate relationship */
  practices_aggregate: Practices_Aggregate;
  /** fetch data from the table: "practices" using primary key columns */
  practices_by_pk?: Maybe<Practices>;
  /** fetch data from the table in a streaming manner: "practices" */
  practices_stream: Array<Practices>;
  /** fetch data from the table: "registration_authsmsconfirmation" */
  registration_authsmsconfirmation: Array<Registration_Authsmsconfirmation>;
  /** fetch aggregated fields from the table: "registration_authsmsconfirmation" */
  registration_authsmsconfirmation_aggregate: Registration_Authsmsconfirmation_Aggregate;
  /** fetch data from the table: "registration_authsmsconfirmation" using primary key columns */
  registration_authsmsconfirmation_by_pk?: Maybe<Registration_Authsmsconfirmation>;
  /** fetch data from the table in a streaming manner: "registration_authsmsconfirmation" */
  registration_authsmsconfirmation_stream: Array<Registration_Authsmsconfirmation>;
  /** fetch data from the table: "registration_meetingrequest" */
  registration_meetingrequest: Array<Registration_Meetingrequest>;
  /** fetch aggregated fields from the table: "registration_meetingrequest" */
  registration_meetingrequest_aggregate: Registration_Meetingrequest_Aggregate;
  /** fetch data from the table: "registration_meetingrequest" using primary key columns */
  registration_meetingrequest_by_pk?: Maybe<Registration_Meetingrequest>;
  /** fetch data from the table in a streaming manner: "registration_meetingrequest" */
  registration_meetingrequest_stream: Array<Registration_Meetingrequest>;
  /** fetch data from the table: "registration_onlineregistrationconfig" */
  registration_onlineregistrationconfig: Array<Registration_Onlineregistrationconfig>;
  /** fetch aggregated fields from the table: "registration_onlineregistrationconfig" */
  registration_onlineregistrationconfig_aggregate: Registration_Onlineregistrationconfig_Aggregate;
  /** fetch data from the table: "registration_onlineregistrationconfig" using primary key columns */
  registration_onlineregistrationconfig_by_pk?: Maybe<Registration_Onlineregistrationconfig>;
  /** fetch data from the table in a streaming manner: "registration_onlineregistrationconfig" */
  registration_onlineregistrationconfig_stream: Array<Registration_Onlineregistrationconfig>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** An array relationship */
  request_considerations: Array<Request_Considerations>;
  /** An aggregate relationship */
  request_considerations_aggregate: Request_Considerations_Aggregate;
  /** fetch data from the table: "request_considerations" using primary key columns */
  request_considerations_by_pk?: Maybe<Request_Considerations>;
  /** fetch data from the table in a streaming manner: "request_considerations" */
  request_considerations_stream: Array<Request_Considerations>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** An array relationship */
  rooms: Array<Rooms>;
  /** An aggregate relationship */
  rooms_aggregate: Rooms_Aggregate;
  /** fetch data from the table: "rooms" using primary key columns */
  rooms_by_pk?: Maybe<Rooms>;
  /** fetch data from the table in a streaming manner: "rooms" */
  rooms_stream: Array<Rooms>;
  /** An array relationship */
  services: Array<Services>;
  /** An aggregate relationship */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services_assigned_to" */
  services_assigned_to: Array<Services_Assigned_To>;
  /** fetch aggregated fields from the table: "services_assigned_to" */
  services_assigned_to_aggregate: Services_Assigned_To_Aggregate;
  /** fetch data from the table: "services_assigned_to" using primary key columns */
  services_assigned_to_by_pk?: Maybe<Services_Assigned_To>;
  /** fetch data from the table in a streaming manner: "services_assigned_to" */
  services_assigned_to_stream: Array<Services_Assigned_To>;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** An array relationship */
  services_restricted_rooms: Array<Services_Restricted_Rooms>;
  /** An aggregate relationship */
  services_restricted_rooms_aggregate: Services_Restricted_Rooms_Aggregate;
  /** fetch data from the table: "services_restricted_rooms" using primary key columns */
  services_restricted_rooms_by_pk?: Maybe<Services_Restricted_Rooms>;
  /** fetch data from the table in a streaming manner: "services_restricted_rooms" */
  services_restricted_rooms_stream: Array<Services_Restricted_Rooms>;
  /** fetch data from the table in a streaming manner: "services" */
  services_stream: Array<Services>;
  /** fetch data from the table: "sms_notifications_defaultsmstemplate" */
  sms_notifications_defaultsmstemplate: Array<Sms_Notifications_Defaultsmstemplate>;
  /** fetch aggregated fields from the table: "sms_notifications_defaultsmstemplate" */
  sms_notifications_defaultsmstemplate_aggregate: Sms_Notifications_Defaultsmstemplate_Aggregate;
  /** fetch data from the table: "sms_notifications_defaultsmstemplate" using primary key columns */
  sms_notifications_defaultsmstemplate_by_pk?: Maybe<Sms_Notifications_Defaultsmstemplate>;
  /** fetch data from the table in a streaming manner: "sms_notifications_defaultsmstemplate" */
  sms_notifications_defaultsmstemplate_stream: Array<Sms_Notifications_Defaultsmstemplate>;
  /** An array relationship */
  sms_notifications_sentsms: Array<Sms_Notifications_Sentsms>;
  /** An aggregate relationship */
  sms_notifications_sentsms_aggregate: Sms_Notifications_Sentsms_Aggregate;
  /** fetch data from the table: "sms_notifications_sentsms" using primary key columns */
  sms_notifications_sentsms_by_pk?: Maybe<Sms_Notifications_Sentsms>;
  /** fetch data from the table in a streaming manner: "sms_notifications_sentsms" */
  sms_notifications_sentsms_stream: Array<Sms_Notifications_Sentsms>;
  /** fetch data from the table: "sms_notifications_smsconfig" */
  sms_notifications_smsconfig: Array<Sms_Notifications_Smsconfig>;
  /** fetch aggregated fields from the table: "sms_notifications_smsconfig" */
  sms_notifications_smsconfig_aggregate: Sms_Notifications_Smsconfig_Aggregate;
  /** fetch data from the table: "sms_notifications_smsconfig" using primary key columns */
  sms_notifications_smsconfig_by_pk?: Maybe<Sms_Notifications_Smsconfig>;
  /** fetch data from the table in a streaming manner: "sms_notifications_smsconfig" */
  sms_notifications_smsconfig_stream: Array<Sms_Notifications_Smsconfig>;
  /** fetch data from the table: "sms_notifications_smspacketpurchase" */
  sms_notifications_smspacketpurchase: Array<Sms_Notifications_Smspacketpurchase>;
  /** fetch aggregated fields from the table: "sms_notifications_smspacketpurchase" */
  sms_notifications_smspacketpurchase_aggregate: Sms_Notifications_Smspacketpurchase_Aggregate;
  /** fetch data from the table: "sms_notifications_smspacketpurchase" using primary key columns */
  sms_notifications_smspacketpurchase_by_pk?: Maybe<Sms_Notifications_Smspacketpurchase>;
  /** fetch data from the table in a streaming manner: "sms_notifications_smspacketpurchase" */
  sms_notifications_smspacketpurchase_stream: Array<Sms_Notifications_Smspacketpurchase>;
  /** fetch data from the table: "sms_notifications_smstemplate" */
  sms_notifications_smstemplate: Array<Sms_Notifications_Smstemplate>;
  /** fetch aggregated fields from the table: "sms_notifications_smstemplate" */
  sms_notifications_smstemplate_aggregate: Sms_Notifications_Smstemplate_Aggregate;
  /** fetch data from the table: "sms_notifications_smstemplate" using primary key columns */
  sms_notifications_smstemplate_by_pk?: Maybe<Sms_Notifications_Smstemplate>;
  /** fetch data from the table in a streaming manner: "sms_notifications_smstemplate" */
  sms_notifications_smstemplate_stream: Array<Sms_Notifications_Smstemplate>;
  /** fetch data from the table: "sms_notifications_smstemplatevariable" */
  sms_notifications_smstemplatevariable: Array<Sms_Notifications_Smstemplatevariable>;
  /** fetch aggregated fields from the table: "sms_notifications_smstemplatevariable" */
  sms_notifications_smstemplatevariable_aggregate: Sms_Notifications_Smstemplatevariable_Aggregate;
  /** fetch data from the table: "sms_notifications_smstemplatevariable" using primary key columns */
  sms_notifications_smstemplatevariable_by_pk?: Maybe<Sms_Notifications_Smstemplatevariable>;
  /** fetch data from the table in a streaming manner: "sms_notifications_smstemplatevariable" */
  sms_notifications_smstemplatevariable_stream: Array<Sms_Notifications_Smstemplatevariable>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** An array relationship */
  user_groups: Array<User_Groups>;
  /** An aggregate relationship */
  user_groups_aggregate: User_Groups_Aggregate;
  /** fetch data from the table: "user_groups" using primary key columns */
  user_groups_by_pk?: Maybe<User_Groups>;
  /** fetch data from the table in a streaming manner: "user_groups" */
  user_groups_stream: Array<User_Groups>;
  /** fetch data from the table: "user_identity" */
  user_identity: Array<User_Identity>;
  /** fetch aggregated fields from the table: "user_identity" */
  user_identity_aggregate: User_Identity_Aggregate;
  /** fetch data from the table: "user_identity" using primary key columns */
  user_identity_by_pk?: Maybe<User_Identity>;
  /** fetch data from the table in a streaming manner: "user_identity" */
  user_identity_stream: Array<User_Identity>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** An array relationship */
  user_user_permissions: Array<User_User_Permissions>;
  /** An aggregate relationship */
  user_user_permissions_aggregate: User_User_Permissions_Aggregate;
  /** fetch data from the table: "user_user_permissions" using primary key columns */
  user_user_permissions_by_pk?: Maybe<User_User_Permissions>;
  /** fetch data from the table in a streaming manner: "user_user_permissions" */
  user_user_permissions_stream: Array<User_User_Permissions>;
  /** An array relationship */
  workdays: Array<Workdays>;
  /** An aggregate relationship */
  workdays_aggregate: Workdays_Aggregate;
  /** fetch data from the table: "workdays" using primary key columns */
  workdays_by_pk?: Maybe<Workdays>;
  /** fetch data from the table in a streaming manner: "workdays" */
  workdays_stream: Array<Workdays>;
  /** fetch data from the table: "worktime_configs" */
  worktime_configs: Array<Worktime_Configs>;
  /** fetch aggregated fields from the table: "worktime_configs" */
  worktime_configs_aggregate: Worktime_Configs_Aggregate;
  /** fetch data from the table: "worktime_configs" using primary key columns */
  worktime_configs_by_pk?: Maybe<Worktime_Configs>;
  /** fetch data from the table in a streaming manner: "worktime_configs" */
  worktime_configs_stream: Array<Worktime_Configs>;
  /** An array relationship */
  worktime_exceptions: Array<Worktime_Exceptions>;
  /** An aggregate relationship */
  worktime_exceptions_aggregate: Worktime_Exceptions_Aggregate;
  /** fetch data from the table: "worktime_exceptions" using primary key columns */
  worktime_exceptions_by_pk?: Maybe<Worktime_Exceptions>;
  /** fetch data from the table in a streaming manner: "worktime_exceptions" */
  worktime_exceptions_stream: Array<Worktime_Exceptions>;
  /** An array relationship */
  worktime_exceptions_work_times: Array<Worktime_Exceptions_Work_Times>;
  /** An aggregate relationship */
  worktime_exceptions_work_times_aggregate: Worktime_Exceptions_Work_Times_Aggregate;
  /** fetch data from the table: "worktime_exceptions_work_times" using primary key columns */
  worktime_exceptions_work_times_by_pk?: Maybe<Worktime_Exceptions_Work_Times>;
  /** fetch data from the table in a streaming manner: "worktime_exceptions_work_times" */
  worktime_exceptions_work_times_stream: Array<Worktime_Exceptions_Work_Times>;
  /** An array relationship */
  worktime_presets: Array<Worktime_Presets>;
  /** An aggregate relationship */
  worktime_presets_aggregate: Worktime_Presets_Aggregate;
  /** fetch data from the table: "worktime_presets" using primary key columns */
  worktime_presets_by_pk?: Maybe<Worktime_Presets>;
  /** fetch data from the table in a streaming manner: "worktime_presets" */
  worktime_presets_stream: Array<Worktime_Presets>;
  /** fetch data from the table: "worktimes" */
  worktimes: Array<Worktimes>;
  /** fetch aggregated fields from the table: "worktimes" */
  worktimes_aggregate: Worktimes_Aggregate;
  /** fetch data from the table: "worktimes" using primary key columns */
  worktimes_by_pk?: Maybe<Worktimes>;
  /** fetch data from the table in a streaming manner: "worktimes" */
  worktimes_stream: Array<Worktimes>;
};


export type Subscription_RootAccounting_PracticeexpenseArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


export type Subscription_RootAccounting_Practiceexpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Practiceexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Practiceexpense_Order_By>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


export type Subscription_RootAccounting_Practiceexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccounting_Practiceexpense_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounting_Practiceexpense_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounting_Practiceexpense_Bool_Exp>;
};


export type Subscription_RootAccounting_RecurringexpenseArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Recurringexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Recurringexpense_Order_By>>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};


export type Subscription_RootAccounting_Recurringexpense_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounting_Recurringexpense_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Accounting_Recurringexpense_Order_By>>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};


export type Subscription_RootAccounting_Recurringexpense_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccounting_Recurringexpense_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Accounting_Recurringexpense_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounting_Recurringexpense_Bool_Exp>;
};


export type Subscription_RootAddressesArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Addresses_Order_By>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAddresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAddresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Addresses_Bool_Exp>;
};


export type Subscription_RootAvatarsArgs = {
  distinct_on?: InputMaybe<Array<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootAvatars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Avatars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Avatars_Order_By>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootAvatars_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAvatars_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Avatars_Stream_Cursor_Input>>;
  where?: InputMaybe<Avatars_Bool_Exp>;
};


export type Subscription_RootContact_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};


export type Subscription_RootContact_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Contact_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Contact_Details_Order_By>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};


export type Subscription_RootContact_Details_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootContact_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Contact_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Contact_Details_Bool_Exp>;
};


export type Subscription_RootCouplesArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


export type Subscription_RootCouples_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


export type Subscription_RootCouples_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCouples_PatientsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


export type Subscription_RootCouples_Patients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Patients_Order_By>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


export type Subscription_RootCouples_Patients_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCouples_Patients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Couples_Patients_Stream_Cursor_Input>>;
  where?: InputMaybe<Couples_Patients_Bool_Exp>;
};


export type Subscription_RootCouples_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Couples_Stream_Cursor_Input>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


export type Subscription_RootCouples_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


export type Subscription_RootCouples_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Therapists_Order_By>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


export type Subscription_RootCouples_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCouples_Therapists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Couples_Therapists_Stream_Cursor_Input>>;
  where?: InputMaybe<Couples_Therapists_Bool_Exp>;
};


export type Subscription_RootEd_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


export type Subscription_RootEd_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Attachments_Order_By>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


export type Subscription_RootEd_Attachments_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEd_Attachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ed_Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Ed_Attachments_Bool_Exp>;
};


export type Subscription_RootEd_Group_PresenceArgs = {
  distinct_on?: InputMaybe<Array<Ed_Group_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Group_Presence_Order_By>>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};


export type Subscription_RootEd_Group_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Group_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Group_Presence_Order_By>>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};


export type Subscription_RootEd_Group_Presence_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootEd_Group_Presence_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ed_Group_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<Ed_Group_Presence_Bool_Exp>;
};


export type Subscription_RootEd_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


export type Subscription_RootEd_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Participants_Order_By>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


export type Subscription_RootEd_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEd_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ed_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<Ed_Participants_Bool_Exp>;
};


export type Subscription_RootEd_Payment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Ed_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Payment_Method_Order_By>>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};


export type Subscription_RootEd_Payment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Payment_Method_Order_By>>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};


export type Subscription_RootEd_Payment_Method_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootEd_Payment_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ed_Payment_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Ed_Payment_Method_Bool_Exp>;
};


export type Subscription_RootEd_PresenceArgs = {
  distinct_on?: InputMaybe<Array<Ed_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Presence_Order_By>>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};


export type Subscription_RootEd_Presence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ed_Presence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ed_Presence_Order_By>>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};


export type Subscription_RootEd_Presence_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootEd_Presence_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ed_Presence_Stream_Cursor_Input>>;
  where?: InputMaybe<Ed_Presence_Bool_Exp>;
};


export type Subscription_RootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


export type Subscription_RootEmployees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


export type Subscription_RootEmployees_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmployees_Favorite_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


export type Subscription_RootEmployees_Favorite_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Favorite_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Favorite_Therapists_Order_By>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


export type Subscription_RootEmployees_Favorite_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmployees_Favorite_Therapists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_Favorite_Therapists_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_Favorite_Therapists_Bool_Exp>;
};


export type Subscription_RootEmployees_Preferred_RoomArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


export type Subscription_RootEmployees_Preferred_Room_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Preferred_Room_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Preferred_Room_Order_By>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


export type Subscription_RootEmployees_Preferred_Room_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmployees_Preferred_Room_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_Preferred_Room_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_Preferred_Room_Bool_Exp>;
};


export type Subscription_RootEmployees_RolesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


export type Subscription_RootEmployees_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Roles_Order_By>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


export type Subscription_RootEmployees_Roles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEmployees_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_Roles_Bool_Exp>;
};


export type Subscription_RootEmployees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


export type Subscription_RootEventArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Order_By>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Participants_Order_By>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_PeriodArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


export type Subscription_RootEvent_Period_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Order_By>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


export type Subscription_RootEvent_Period_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Period_ParticipantsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Participants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Participants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Participants_Order_By>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Participants_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Period_Participants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Period_Participants_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Period_Participants_Bool_Exp>;
};


export type Subscription_RootEvent_Period_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Services_Order_By>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Period_Services_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Period_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Period_Services_Bool_Exp>;
};


export type Subscription_RootEvent_Period_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Period_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Period_Bool_Exp>;
};


export type Subscription_RootEvent_Period_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Period_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Period_Therapists_Order_By>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


export type Subscription_RootEvent_Period_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Period_Therapists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Period_Therapists_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Period_Therapists_Bool_Exp>;
};


export type Subscription_RootEvent_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


export type Subscription_RootEvent_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Services_Order_By>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


export type Subscription_RootEvent_Services_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Services_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Services_Bool_Exp>;
};


export type Subscription_RootEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


export type Subscription_RootEvent_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Event_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_Therapists_Order_By>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


export type Subscription_RootEvent_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootEvent_Therapists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_Therapists_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_Therapists_Bool_Exp>;
};


export type Subscription_RootGdpr_GdprconfigArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprconfig_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprconfig_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGdpr_Gdprconfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gdpr_Gdprconfig_Stream_Cursor_Input>>;
  where?: InputMaybe<Gdpr_Gdprconfig_Bool_Exp>;
};


export type Subscription_RootGdpr_GdprdocumentArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocument_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprdocument_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGdpr_Gdprdocument_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gdpr_Gdprdocument_Stream_Cursor_Input>>;
  where?: InputMaybe<Gdpr_Gdprdocument_Bool_Exp>;
};


export type Subscription_RootGdpr_GdprdocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprdocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gdpr_Gdprdocumenttype_Order_By>>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};


export type Subscription_RootGdpr_Gdprdocumenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGdpr_Gdprdocumenttype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gdpr_Gdprdocumenttype_Stream_Cursor_Input>>;
  where?: InputMaybe<Gdpr_Gdprdocumenttype_Bool_Exp>;
};


export type Subscription_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGroup_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootHoliday_ReasonsArgs = {
  distinct_on?: InputMaybe<Array<Holiday_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holiday_Reasons_Order_By>>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};


export type Subscription_RootHoliday_Reasons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holiday_Reasons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holiday_Reasons_Order_By>>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};


export type Subscription_RootHoliday_Reasons_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootHoliday_Reasons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Holiday_Reasons_Stream_Cursor_Input>>;
  where?: InputMaybe<Holiday_Reasons_Bool_Exp>;
};


export type Subscription_RootHolidaysArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


export type Subscription_RootHolidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Holidays_Order_By>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


export type Subscription_RootHolidays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootHolidays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Holidays_Stream_Cursor_Input>>;
  where?: InputMaybe<Holidays_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_BillinginfoArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Billinginfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Billinginfo_Order_By>>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Billinginfo_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Billinginfo_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Billinginfo_Order_By>>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Billinginfo_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Billinginfo_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Billinginfo_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Billinginfo_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoice_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Invoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Invoice_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_InvoiceconfigArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoiceconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoiceconfig_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoiceconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Invoiceconfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Invoiceconfig_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Invoiceconfig_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_InvoicelineitemArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicelineitem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicelineitem_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Invoicelineitem_EventsArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicelineitem_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicelineitem_Events_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicelineitem_Events_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Invoicelineitem_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Invoicelineitem_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Events_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicelineitem_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Invoicelineitem_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Invoicelineitem_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_InvoicesenttoArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicesentto_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoices_V2_Invoicesentto_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoices_V2_Invoicesentto_Order_By>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


export type Subscription_RootInvoices_V2_Invoicesentto_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootInvoices_V2_Invoicesentto_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoices_V2_Invoicesentto_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoices_V2_Invoicesentto_Bool_Exp>;
};


export type Subscription_RootNational_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


export type Subscription_RootNational_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<National_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Holidays_Order_By>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


export type Subscription_RootNational_Holidays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootNational_Holidays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<National_Holidays_Stream_Cursor_Input>>;
  where?: InputMaybe<National_Holidays_Bool_Exp>;
};


export type Subscription_RootPatient_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPatient_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_EducatorArgs = {
  distinct_on?: InputMaybe<Array<Patient_Educator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Educator_Order_By>>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};


export type Subscription_RootPatient_Educator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Educator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Educator_Order_By>>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};


export type Subscription_RootPatient_Educator_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Subscription_RootPatient_Educator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Educator_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Educator_Bool_Exp>;
};


export type Subscription_RootPatient_Guardian_AddressesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardian_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardian_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_Guardian_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardian_Addresses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardian_Addresses_Order_By>>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_Guardian_Addresses_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPatient_Guardian_Addresses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Guardian_Addresses_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Guardian_Addresses_Bool_Exp>;
};


export type Subscription_RootPatient_GuardiansArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


export type Subscription_RootPatient_Guardians_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Guardians_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Guardians_Order_By>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


export type Subscription_RootPatient_Guardians_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPatient_Guardians_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Guardians_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Guardians_Bool_Exp>;
};


export type Subscription_RootPatient_PsychotherapiesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Psychotherapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Psychotherapies_Order_By>>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};


export type Subscription_RootPatient_Psychotherapies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Psychotherapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Psychotherapies_Order_By>>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};


export type Subscription_RootPatient_Psychotherapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Subscription_RootPatient_Psychotherapies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Psychotherapies_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Psychotherapies_Bool_Exp>;
};


export type Subscription_RootPatient_SiArgs = {
  distinct_on?: InputMaybe<Array<Patient_Si_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Si_Order_By>>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};


export type Subscription_RootPatient_Si_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Si_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Si_Order_By>>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};


export type Subscription_RootPatient_Si_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Subscription_RootPatient_Si_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Si_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Si_Bool_Exp>;
};


export type Subscription_RootPatient_Speech_TherapiesArgs = {
  distinct_on?: InputMaybe<Array<Patient_Speech_Therapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Speech_Therapies_Order_By>>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};


export type Subscription_RootPatient_Speech_Therapies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patient_Speech_Therapies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patient_Speech_Therapies_Order_By>>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};


export type Subscription_RootPatient_Speech_Therapies_By_PkArgs = {
  patient_id: Scalars['bigint'];
};


export type Subscription_RootPatient_Speech_Therapies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patient_Speech_Therapies_Stream_Cursor_Input>>;
  where?: InputMaybe<Patient_Speech_Therapies_Bool_Exp>;
};


export type Subscription_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Subscription_RootPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Subscription_RootPatients_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPatients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patients_Stream_Cursor_Input>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


export type Subscription_RootPatients_TherapistsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


export type Subscription_RootPatients_Therapists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Therapists_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Therapists_Order_By>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


export type Subscription_RootPatients_Therapists_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPatients_Therapists_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patients_Therapists_Stream_Cursor_Input>>;
  where?: InputMaybe<Patients_Therapists_Bool_Exp>;
};


export type Subscription_RootPractice_BranchesArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


export type Subscription_RootPractice_Branches_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Branches_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Branches_Order_By>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


export type Subscription_RootPractice_Branches_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPractice_Branches_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Practice_Branches_Stream_Cursor_Input>>;
  where?: InputMaybe<Practice_Branches_Bool_Exp>;
};


export type Subscription_RootPractice_LogosArgs = {
  distinct_on?: InputMaybe<Array<Practice_Logos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Logos_Order_By>>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};


export type Subscription_RootPractice_Logos_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practice_Logos_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practice_Logos_Order_By>>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};


export type Subscription_RootPractice_Logos_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPractice_Logos_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Practice_Logos_Stream_Cursor_Input>>;
  where?: InputMaybe<Practice_Logos_Bool_Exp>;
};


export type Subscription_RootPracticesArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


export type Subscription_RootPractices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Practices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Practices_Order_By>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


export type Subscription_RootPractices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootPractices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Practices_Stream_Cursor_Input>>;
  where?: InputMaybe<Practices_Bool_Exp>;
};


export type Subscription_RootRegistration_AuthsmsconfirmationArgs = {
  distinct_on?: InputMaybe<Array<Registration_Authsmsconfirmation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Authsmsconfirmation_Order_By>>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};


export type Subscription_RootRegistration_Authsmsconfirmation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Authsmsconfirmation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Authsmsconfirmation_Order_By>>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};


export type Subscription_RootRegistration_Authsmsconfirmation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRegistration_Authsmsconfirmation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Registration_Authsmsconfirmation_Stream_Cursor_Input>>;
  where?: InputMaybe<Registration_Authsmsconfirmation_Bool_Exp>;
};


export type Subscription_RootRegistration_MeetingrequestArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


export type Subscription_RootRegistration_Meetingrequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Meetingrequest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Meetingrequest_Order_By>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


export type Subscription_RootRegistration_Meetingrequest_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRegistration_Meetingrequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Registration_Meetingrequest_Stream_Cursor_Input>>;
  where?: InputMaybe<Registration_Meetingrequest_Bool_Exp>;
};


export type Subscription_RootRegistration_OnlineregistrationconfigArgs = {
  distinct_on?: InputMaybe<Array<Registration_Onlineregistrationconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Onlineregistrationconfig_Order_By>>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};


export type Subscription_RootRegistration_Onlineregistrationconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Registration_Onlineregistrationconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Registration_Onlineregistrationconfig_Order_By>>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};


export type Subscription_RootRegistration_Onlineregistrationconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRegistration_Onlineregistrationconfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Registration_Onlineregistrationconfig_Stream_Cursor_Input>>;
  where?: InputMaybe<Registration_Onlineregistrationconfig_Bool_Exp>;
};


export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


export type Subscription_RootRequest_ConsiderationsArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


export type Subscription_RootRequest_Considerations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Considerations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Considerations_Order_By>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


export type Subscription_RootRequest_Considerations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRequest_Considerations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Request_Considerations_Stream_Cursor_Input>>;
  where?: InputMaybe<Request_Considerations_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  slug: Scalars['String'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoomsArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rooms_Order_By>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootRooms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootRooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Rooms_Bool_Exp>;
};


export type Subscription_RootServicesArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootServices_Assigned_ToArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


export type Subscription_RootServices_Assigned_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Assigned_To_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Assigned_To_Order_By>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


export type Subscription_RootServices_Assigned_To_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootServices_Assigned_To_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Assigned_To_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Assigned_To_Bool_Exp>;
};


export type Subscription_RootServices_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootServices_Restricted_RoomsArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


export type Subscription_RootServices_Restricted_Rooms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Services_Restricted_Rooms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Restricted_Rooms_Order_By>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


export type Subscription_RootServices_Restricted_Rooms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootServices_Restricted_Rooms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Restricted_Rooms_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Restricted_Rooms_Bool_Exp>;
};


export type Subscription_RootServices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Services_Stream_Cursor_Input>>;
  where?: InputMaybe<Services_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_DefaultsmstemplateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Defaultsmstemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Defaultsmstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Defaultsmstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Defaultsmstemplate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Defaultsmstemplate_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Defaultsmstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_SentsmsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Sentsms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Sentsms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Sentsms_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Sentsms_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Sentsms_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Sentsms_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Sentsms_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_SmsconfigArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smsconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smsconfig_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smsconfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smsconfig_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smsconfig_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smsconfig_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Smsconfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Smsconfig_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Smsconfig_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_SmspacketpurchaseArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smspacketpurchase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smspacketpurchase_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smspacketpurchase_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Smspacketpurchase_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Smspacketpurchase_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Smspacketpurchase_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_SmstemplateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smstemplate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplate_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smstemplate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Smstemplate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Smstemplate_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Smstemplate_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_SmstemplatevariableArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smstemplatevariable_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sms_Notifications_Smstemplatevariable_Order_By>>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};


export type Subscription_RootSms_Notifications_Smstemplatevariable_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootSms_Notifications_Smstemplatevariable_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sms_Notifications_Smstemplatevariable_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Notifications_Smstemplatevariable_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_GroupsArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


export type Subscription_RootUser_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


export type Subscription_RootUser_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


export type Subscription_RootUser_IdentityArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


export type Subscription_RootUser_Identity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


export type Subscription_RootUser_Identity_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_Identity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Identity_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootUser_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootUser_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootUser_User_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_User_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootWorkdaysArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


export type Subscription_RootWorkdays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


export type Subscription_RootWorkdays_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorkdays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workdays_Stream_Cursor_Input>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


export type Subscription_RootWorktime_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Configs_Order_By>>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};


export type Subscription_RootWorktime_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Configs_Order_By>>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};


export type Subscription_RootWorktime_Configs_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorktime_Configs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Worktime_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};


export type Subscription_RootWorktime_ExceptionsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


export type Subscription_RootWorktime_Exceptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


export type Subscription_RootWorktime_Exceptions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorktime_Exceptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Worktime_Exceptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


export type Subscription_RootWorktime_Exceptions_Work_TimesArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


export type Subscription_RootWorktime_Exceptions_Work_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


export type Subscription_RootWorktime_Exceptions_Work_Times_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorktime_Exceptions_Work_Times_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Worktime_Exceptions_Work_Times_Stream_Cursor_Input>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


export type Subscription_RootWorktime_PresetsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


export type Subscription_RootWorktime_Presets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Presets_Order_By>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


export type Subscription_RootWorktime_Presets_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorktime_Presets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Worktime_Presets_Stream_Cursor_Input>>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};


export type Subscription_RootWorktimesArgs = {
  distinct_on?: InputMaybe<Array<Worktimes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktimes_Order_By>>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};


export type Subscription_RootWorktimes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktimes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktimes_Order_By>>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};


export type Subscription_RootWorktimes_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootWorktimes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Worktimes_Stream_Cursor_Input>>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An object relationship */
  avatar?: Maybe<Avatars>;
  /** An array relationship */
  couples: Array<Couples>;
  /** An aggregate relationship */
  couples_aggregate: Couples_Aggregate;
  creation_date: Scalars['timestamptz'];
  date_joined: Scalars['timestamptz'];
  email: Scalars['String'];
  /** An array relationship */
  employees: Array<Employees>;
  /** An aggregate relationship */
  employees_aggregate: Employees_Aggregate;
  first_name: Scalars['String'];
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  id: Scalars['String'];
  is_active: Scalars['Boolean'];
  is_staff: Scalars['Boolean'];
  is_superuser: Scalars['Boolean'];
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  last_seen?: Maybe<Scalars['timestamptz']>;
  password: Scalars['String'];
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** An array relationship */
  user_groups: Array<User_Groups>;
  /** An aggregate relationship */
  user_groups_aggregate: User_Groups_Aggregate;
  /** An array relationship */
  user_identities: Array<User_Identity>;
  /** An aggregate relationship */
  user_identities_aggregate: User_Identity_Aggregate;
  /** An array relationship */
  user_user_permissions: Array<User_User_Permissions>;
  /** An aggregate relationship */
  user_user_permissions_aggregate: User_User_Permissions_Aggregate;
  username: Scalars['String'];
};


/** columns and relationships of "user" */
export type UserCouplesArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserCouples_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Couples_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Couples_Order_By>>;
  where?: InputMaybe<Couples_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmployees_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_GroupsArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Groups_Order_By>>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_IdentitiesArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Identities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Identity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Identity_Order_By>>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_User_Permissions_Order_By>>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  avatar?: InputMaybe<Avatars_Bool_Exp>;
  couples?: InputMaybe<Couples_Bool_Exp>;
  couples_aggregate?: InputMaybe<Couples_Aggregate_Bool_Exp>;
  creation_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_joined?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employees?: InputMaybe<Employees_Bool_Exp>;
  employees_aggregate?: InputMaybe<Employees_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_staff?: InputMaybe<Boolean_Comparison_Exp>;
  is_superuser?: InputMaybe<Boolean_Comparison_Exp>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
  user_groups?: InputMaybe<User_Groups_Bool_Exp>;
  user_groups_aggregate?: InputMaybe<User_Groups_Aggregate_Bool_Exp>;
  user_identities?: InputMaybe<User_Identity_Bool_Exp>;
  user_identities_aggregate?: InputMaybe<User_Identity_Aggregate_Bool_Exp>;
  user_user_permissions?: InputMaybe<User_User_Permissions_Bool_Exp>;
  user_user_permissions_aggregate?: InputMaybe<User_User_Permissions_Aggregate_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "username" */
  UserUsernameKey = 'user_username_key'
}

/** columns and relationships of "user_groups" */
export type User_Groups = {
  __typename?: 'user_groups';
  group_id: Scalars['Int'];
  id: Scalars['bigint'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_groups" */
export type User_Groups_Aggregate = {
  __typename?: 'user_groups_aggregate';
  aggregate?: Maybe<User_Groups_Aggregate_Fields>;
  nodes: Array<User_Groups>;
};

export type User_Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Groups_Aggregate_Bool_Exp_Count>;
};

export type User_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_groups" */
export type User_Groups_Aggregate_Fields = {
  __typename?: 'user_groups_aggregate_fields';
  avg?: Maybe<User_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Groups_Max_Fields>;
  min?: Maybe<User_Groups_Min_Fields>;
  stddev?: Maybe<User_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<User_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<User_Groups_Sum_Fields>;
  var_pop?: Maybe<User_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<User_Groups_Var_Samp_Fields>;
  variance?: Maybe<User_Groups_Variance_Fields>;
};


/** aggregate fields of "user_groups" */
export type User_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_groups" */
export type User_Groups_Aggregate_Order_By = {
  avg?: InputMaybe<User_Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Groups_Max_Order_By>;
  min?: InputMaybe<User_Groups_Min_Order_By>;
  stddev?: InputMaybe<User_Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Groups_Sum_Order_By>;
  var_pop?: InputMaybe<User_Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_groups" */
export type User_Groups_Arr_Rel_Insert_Input = {
  data: Array<User_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Groups_Avg_Fields = {
  __typename?: 'user_groups_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_groups" */
export type User_Groups_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_groups". All fields are combined with a logical 'AND'. */
export type User_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<User_Groups_Bool_Exp>>;
  _not?: InputMaybe<User_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<User_Groups_Bool_Exp>>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_groups" */
export enum User_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserGroupsPkey = 'user_groups_pkey',
  /** unique or primary key constraint on columns "user_id", "group_id" */
  UserGroupsUserIdGroupId_40beef00Uniq = 'user_groups_user_id_group_id_40beef00_uniq'
}

/** input type for incrementing numeric columns in table "user_groups" */
export type User_Groups_Inc_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_groups" */
export type User_Groups_Insert_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Groups_Max_Fields = {
  __typename?: 'user_groups_max_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_groups" */
export type User_Groups_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Groups_Min_Fields = {
  __typename?: 'user_groups_min_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_groups" */
export type User_Groups_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_groups" */
export type User_Groups_Mutation_Response = {
  __typename?: 'user_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Groups>;
};

/** on_conflict condition type for table "user_groups" */
export type User_Groups_On_Conflict = {
  constraint: User_Groups_Constraint;
  update_columns?: Array<User_Groups_Update_Column>;
  where?: InputMaybe<User_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "user_groups". */
export type User_Groups_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_groups */
export type User_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_groups" */
export enum User_Groups_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_groups" */
export type User_Groups_Set_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Groups_Stddev_Fields = {
  __typename?: 'user_groups_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_groups" */
export type User_Groups_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Groups_Stddev_Pop_Fields = {
  __typename?: 'user_groups_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_groups" */
export type User_Groups_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Groups_Stddev_Samp_Fields = {
  __typename?: 'user_groups_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_groups" */
export type User_Groups_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_groups" */
export type User_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Groups_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_Groups_Sum_Fields = {
  __typename?: 'user_groups_sum_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_groups" */
export type User_Groups_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "user_groups" */
export enum User_Groups_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type User_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Groups_Var_Pop_Fields = {
  __typename?: 'user_groups_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_groups" */
export type User_Groups_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Groups_Var_Samp_Fields = {
  __typename?: 'user_groups_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_groups" */
export type User_Groups_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Groups_Variance_Fields = {
  __typename?: 'user_groups_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_groups" */
export type User_Groups_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_identity" */
export type User_Identity = {
  __typename?: 'user_identity';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  provider: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_identity" */
export type User_Identity_Aggregate = {
  __typename?: 'user_identity_aggregate';
  aggregate?: Maybe<User_Identity_Aggregate_Fields>;
  nodes: Array<User_Identity>;
};

export type User_Identity_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Identity_Aggregate_Bool_Exp_Count>;
};

export type User_Identity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Identity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Identity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_identity" */
export type User_Identity_Aggregate_Fields = {
  __typename?: 'user_identity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Identity_Max_Fields>;
  min?: Maybe<User_Identity_Min_Fields>;
};


/** aggregate fields of "user_identity" */
export type User_Identity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Identity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_identity" */
export type User_Identity_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Identity_Max_Order_By>;
  min?: InputMaybe<User_Identity_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_identity" */
export type User_Identity_Arr_Rel_Insert_Input = {
  data: Array<User_Identity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Identity_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_identity". All fields are combined with a logical 'AND'. */
export type User_Identity_Bool_Exp = {
  _and?: InputMaybe<Array<User_Identity_Bool_Exp>>;
  _not?: InputMaybe<User_Identity_Bool_Exp>;
  _or?: InputMaybe<Array<User_Identity_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_identity" */
export enum User_Identity_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserIdentityPkey = 'user_identity_pkey'
}

/** input type for inserting data into table "user_identity" */
export type User_Identity_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Identity_Max_Fields = {
  __typename?: 'user_identity_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_identity" */
export type User_Identity_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Identity_Min_Fields = {
  __typename?: 'user_identity_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_identity" */
export type User_Identity_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_identity" */
export type User_Identity_Mutation_Response = {
  __typename?: 'user_identity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Identity>;
};

/** on_conflict condition type for table "user_identity" */
export type User_Identity_On_Conflict = {
  constraint: User_Identity_Constraint;
  update_columns?: Array<User_Identity_Update_Column>;
  where?: InputMaybe<User_Identity_Bool_Exp>;
};

/** Ordering options when selecting data from "user_identity". */
export type User_Identity_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_identity */
export type User_Identity_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user_identity" */
export enum User_Identity_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_identity" */
export type User_Identity_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_identity" */
export type User_Identity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Identity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Identity_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_identity" */
export enum User_Identity_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Identity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Identity_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Identity_Bool_Exp;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  avatar?: InputMaybe<Avatars_Obj_Rel_Insert_Input>;
  couples?: InputMaybe<Couples_Arr_Rel_Insert_Input>;
  creation_date?: InputMaybe<Scalars['timestamptz']>;
  date_joined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employees?: InputMaybe<Employees_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  password?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
  user_groups?: InputMaybe<User_Groups_Arr_Rel_Insert_Input>;
  user_identities?: InputMaybe<User_Identity_Arr_Rel_Insert_Input>;
  user_user_permissions?: InputMaybe<User_User_Permissions_Arr_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  creation_date?: Maybe<Scalars['timestamptz']>;
  date_joined?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  creation_date?: Maybe<Scalars['timestamptz']>;
  date_joined?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_full_name" */
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  avatar?: InputMaybe<Avatars_Order_By>;
  couples_aggregate?: InputMaybe<Couples_Aggregate_Order_By>;
  creation_date?: InputMaybe<Order_By>;
  date_joined?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employees_aggregate?: InputMaybe<Employees_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_staff?: InputMaybe<Order_By>;
  is_superuser?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  user_groups_aggregate?: InputMaybe<User_Groups_Aggregate_Order_By>;
  user_identities_aggregate?: InputMaybe<User_Identity_Aggregate_Order_By>;
  user_user_permissions_aggregate?: InputMaybe<User_User_Permissions_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreationDate = 'creation_date',
  /** column name */
  DateJoined = 'date_joined',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Password = 'password',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  creation_date?: InputMaybe<Scalars['timestamptz']>;
  date_joined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  creation_date?: InputMaybe<Scalars['timestamptz']>;
  date_joined?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreationDate = 'creation_date',
  /** column name */
  DateJoined = 'date_joined',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Password = 'password',
  /** column name */
  Username = 'username'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** columns and relationships of "user_user_permissions" */
export type User_User_Permissions = {
  __typename?: 'user_user_permissions';
  id: Scalars['bigint'];
  permission_id: Scalars['Int'];
  /** An object relationship */
  user: User;
  user_id: Scalars['String'];
};

/** aggregated selection of "user_user_permissions" */
export type User_User_Permissions_Aggregate = {
  __typename?: 'user_user_permissions_aggregate';
  aggregate?: Maybe<User_User_Permissions_Aggregate_Fields>;
  nodes: Array<User_User_Permissions>;
};

export type User_User_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_User_Permissions_Aggregate_Bool_Exp_Count>;
};

export type User_User_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_User_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_user_permissions" */
export type User_User_Permissions_Aggregate_Fields = {
  __typename?: 'user_user_permissions_aggregate_fields';
  avg?: Maybe<User_User_Permissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_User_Permissions_Max_Fields>;
  min?: Maybe<User_User_Permissions_Min_Fields>;
  stddev?: Maybe<User_User_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<User_User_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_User_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<User_User_Permissions_Sum_Fields>;
  var_pop?: Maybe<User_User_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<User_User_Permissions_Var_Samp_Fields>;
  variance?: Maybe<User_User_Permissions_Variance_Fields>;
};


/** aggregate fields of "user_user_permissions" */
export type User_User_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_User_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_user_permissions" */
export type User_User_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<User_User_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_User_Permissions_Max_Order_By>;
  min?: InputMaybe<User_User_Permissions_Min_Order_By>;
  stddev?: InputMaybe<User_User_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_User_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_User_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_User_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<User_User_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_User_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<User_User_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_user_permissions" */
export type User_User_Permissions_Arr_Rel_Insert_Input = {
  data: Array<User_User_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_User_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type User_User_Permissions_Avg_Fields = {
  __typename?: 'user_user_permissions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_user_permissions" */
export type User_User_Permissions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_user_permissions". All fields are combined with a logical 'AND'. */
export type User_User_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<User_User_Permissions_Bool_Exp>>;
  _not?: InputMaybe<User_User_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<User_User_Permissions_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_user_permissions" */
export enum User_User_Permissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserUserPermissionsPkey = 'user_user_permissions_pkey',
  /** unique or primary key constraint on columns "user_id", "permission_id" */
  UserUserPermissionsUserIdPermissionId_7dc6e2e0Uniq = 'user_user_permissions_user_id_permission_id_7dc6e2e0_uniq'
}

/** input type for incrementing numeric columns in table "user_user_permissions" */
export type User_User_Permissions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_user_permissions" */
export type User_User_Permissions_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_User_Permissions_Max_Fields = {
  __typename?: 'user_user_permissions_max_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_user_permissions" */
export type User_User_Permissions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_User_Permissions_Min_Fields = {
  __typename?: 'user_user_permissions_min_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_user_permissions" */
export type User_User_Permissions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_user_permissions" */
export type User_User_Permissions_Mutation_Response = {
  __typename?: 'user_user_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_User_Permissions>;
};

/** on_conflict condition type for table "user_user_permissions" */
export type User_User_Permissions_On_Conflict = {
  constraint: User_User_Permissions_Constraint;
  update_columns?: Array<User_User_Permissions_Update_Column>;
  where?: InputMaybe<User_User_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "user_user_permissions". */
export type User_User_Permissions_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_user_permissions */
export type User_User_Permissions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_user_permissions" */
export enum User_User_Permissions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_user_permissions" */
export type User_User_Permissions_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_User_Permissions_Stddev_Fields = {
  __typename?: 'user_user_permissions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_user_permissions" */
export type User_User_Permissions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_User_Permissions_Stddev_Pop_Fields = {
  __typename?: 'user_user_permissions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_user_permissions" */
export type User_User_Permissions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_User_Permissions_Stddev_Samp_Fields = {
  __typename?: 'user_user_permissions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_user_permissions" */
export type User_User_Permissions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_user_permissions" */
export type User_User_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_User_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_User_Permissions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type User_User_Permissions_Sum_Fields = {
  __typename?: 'user_user_permissions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user_user_permissions" */
export type User_User_Permissions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** update columns of table "user_user_permissions" */
export enum User_User_Permissions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

export type User_User_Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_User_Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_User_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_User_Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_User_Permissions_Var_Pop_Fields = {
  __typename?: 'user_user_permissions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_user_permissions" */
export type User_User_Permissions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_User_Permissions_Var_Samp_Fields = {
  __typename?: 'user_user_permissions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_user_permissions" */
export type User_User_Permissions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_User_Permissions_Variance_Fields = {
  __typename?: 'user_user_permissions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_user_permissions" */
export type User_User_Permissions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "workdays" */
export type Workdays = {
  __typename?: 'workdays';
  created_at: Scalars['timestamptz'];
  day: Scalars['Int'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  preset_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  work_time_id: Scalars['bigint'];
  /** An object relationship */
  worktime: Worktimes;
  /** An object relationship */
  worktime_preset: Worktime_Presets;
};

/** aggregated selection of "workdays" */
export type Workdays_Aggregate = {
  __typename?: 'workdays_aggregate';
  aggregate?: Maybe<Workdays_Aggregate_Fields>;
  nodes: Array<Workdays>;
};

export type Workdays_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workdays_Aggregate_Bool_Exp_Count>;
};

export type Workdays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workdays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workdays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workdays" */
export type Workdays_Aggregate_Fields = {
  __typename?: 'workdays_aggregate_fields';
  avg?: Maybe<Workdays_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workdays_Max_Fields>;
  min?: Maybe<Workdays_Min_Fields>;
  stddev?: Maybe<Workdays_Stddev_Fields>;
  stddev_pop?: Maybe<Workdays_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workdays_Stddev_Samp_Fields>;
  sum?: Maybe<Workdays_Sum_Fields>;
  var_pop?: Maybe<Workdays_Var_Pop_Fields>;
  var_samp?: Maybe<Workdays_Var_Samp_Fields>;
  variance?: Maybe<Workdays_Variance_Fields>;
};


/** aggregate fields of "workdays" */
export type Workdays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workdays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workdays" */
export type Workdays_Aggregate_Order_By = {
  avg?: InputMaybe<Workdays_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workdays_Max_Order_By>;
  min?: InputMaybe<Workdays_Min_Order_By>;
  stddev?: InputMaybe<Workdays_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workdays_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workdays_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workdays_Sum_Order_By>;
  var_pop?: InputMaybe<Workdays_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workdays_Var_Samp_Order_By>;
  variance?: InputMaybe<Workdays_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workdays" */
export type Workdays_Arr_Rel_Insert_Input = {
  data: Array<Workdays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workdays_On_Conflict>;
};

/** aggregate avg on columns */
export type Workdays_Avg_Fields = {
  __typename?: 'workdays_avg_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workdays" */
export type Workdays_Avg_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workdays". All fields are combined with a logical 'AND'. */
export type Workdays_Bool_Exp = {
  _and?: InputMaybe<Array<Workdays_Bool_Exp>>;
  _not?: InputMaybe<Workdays_Bool_Exp>;
  _or?: InputMaybe<Array<Workdays_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  preset_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  work_time_id?: InputMaybe<Bigint_Comparison_Exp>;
  worktime?: InputMaybe<Worktimes_Bool_Exp>;
  worktime_preset?: InputMaybe<Worktime_Presets_Bool_Exp>;
};

/** unique or primary key constraints on table "workdays" */
export enum Workdays_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkdaysPkey = 'workdays_pkey'
}

/** input type for incrementing numeric columns in table "workdays" */
export type Workdays_Inc_Input = {
  day?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  work_time_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "workdays" */
export type Workdays_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  work_time_id?: InputMaybe<Scalars['bigint']>;
  worktime?: InputMaybe<Worktimes_Obj_Rel_Insert_Input>;
  worktime_preset?: InputMaybe<Worktime_Presets_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workdays_Max_Fields = {
  __typename?: 'workdays_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_time_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "workdays" */
export type Workdays_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workdays_Min_Fields = {
  __typename?: 'workdays_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['Int']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  work_time_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "workdays" */
export type Workdays_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workdays" */
export type Workdays_Mutation_Response = {
  __typename?: 'workdays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workdays>;
};

/** on_conflict condition type for table "workdays" */
export type Workdays_On_Conflict = {
  constraint: Workdays_Constraint;
  update_columns?: Array<Workdays_Update_Column>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};

/** Ordering options when selecting data from "workdays". */
export type Workdays_Order_By = {
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
  worktime?: InputMaybe<Worktimes_Order_By>;
  worktime_preset?: InputMaybe<Worktime_Presets_Order_By>;
};

/** primary key columns input for table: workdays */
export type Workdays_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "workdays" */
export enum Workdays_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PresetId = 'preset_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkTimeId = 'work_time_id'
}

/** input type for updating data in table "workdays" */
export type Workdays_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  work_time_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Workdays_Stddev_Fields = {
  __typename?: 'workdays_stddev_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workdays" */
export type Workdays_Stddev_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workdays_Stddev_Pop_Fields = {
  __typename?: 'workdays_stddev_pop_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workdays" */
export type Workdays_Stddev_Pop_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workdays_Stddev_Samp_Fields = {
  __typename?: 'workdays_stddev_samp_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workdays" */
export type Workdays_Stddev_Samp_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workdays" */
export type Workdays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workdays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workdays_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  work_time_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Workdays_Sum_Fields = {
  __typename?: 'workdays_sum_fields';
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
  work_time_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "workdays" */
export type Workdays_Sum_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** update columns of table "workdays" */
export enum Workdays_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PresetId = 'preset_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkTimeId = 'work_time_id'
}

export type Workdays_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workdays_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workdays_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workdays_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workdays_Var_Pop_Fields = {
  __typename?: 'workdays_var_pop_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workdays" */
export type Workdays_Var_Pop_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workdays_Var_Samp_Fields = {
  __typename?: 'workdays_var_samp_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workdays" */
export type Workdays_Var_Samp_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workdays_Variance_Fields = {
  __typename?: 'workdays_variance_fields';
  day?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
  work_time_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workdays" */
export type Workdays_Variance_Order_By = {
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  work_time_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "worktime_configs" */
export type Worktime_Configs = {
  __typename?: 'worktime_configs';
  automatic_event_cancellation_time: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  holiday_consideration_required: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "worktime_configs" */
export type Worktime_Configs_Aggregate = {
  __typename?: 'worktime_configs_aggregate';
  aggregate?: Maybe<Worktime_Configs_Aggregate_Fields>;
  nodes: Array<Worktime_Configs>;
};

/** aggregate fields of "worktime_configs" */
export type Worktime_Configs_Aggregate_Fields = {
  __typename?: 'worktime_configs_aggregate_fields';
  avg?: Maybe<Worktime_Configs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worktime_Configs_Max_Fields>;
  min?: Maybe<Worktime_Configs_Min_Fields>;
  stddev?: Maybe<Worktime_Configs_Stddev_Fields>;
  stddev_pop?: Maybe<Worktime_Configs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worktime_Configs_Stddev_Samp_Fields>;
  sum?: Maybe<Worktime_Configs_Sum_Fields>;
  var_pop?: Maybe<Worktime_Configs_Var_Pop_Fields>;
  var_samp?: Maybe<Worktime_Configs_Var_Samp_Fields>;
  variance?: Maybe<Worktime_Configs_Variance_Fields>;
};


/** aggregate fields of "worktime_configs" */
export type Worktime_Configs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Worktime_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Worktime_Configs_Avg_Fields = {
  __typename?: 'worktime_configs_avg_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "worktime_configs". All fields are combined with a logical 'AND'. */
export type Worktime_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Worktime_Configs_Bool_Exp>>;
  _not?: InputMaybe<Worktime_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Worktime_Configs_Bool_Exp>>;
  automatic_event_cancellation_time?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  holiday_consideration_required?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  practice?: InputMaybe<Practices_Bool_Exp>;
  practice_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "worktime_configs" */
export enum Worktime_Configs_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorktimeConfigsPkey = 'worktime_configs_pkey',
  /** unique or primary key constraint on columns "practice_id" */
  WorktimeConfigsPracticeIdKey = 'worktime_configs_practice_id_key'
}

/** input type for incrementing numeric columns in table "worktime_configs" */
export type Worktime_Configs_Inc_Input = {
  automatic_event_cancellation_time?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "worktime_configs" */
export type Worktime_Configs_Insert_Input = {
  automatic_event_cancellation_time?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_consideration_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice?: InputMaybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Worktime_Configs_Max_Fields = {
  __typename?: 'worktime_configs_max_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Worktime_Configs_Min_Fields = {
  __typename?: 'worktime_configs_min_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "worktime_configs" */
export type Worktime_Configs_Mutation_Response = {
  __typename?: 'worktime_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worktime_Configs>;
};

/** input type for inserting object relation for remote table "worktime_configs" */
export type Worktime_Configs_Obj_Rel_Insert_Input = {
  data: Worktime_Configs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Configs_On_Conflict>;
};

/** on_conflict condition type for table "worktime_configs" */
export type Worktime_Configs_On_Conflict = {
  constraint: Worktime_Configs_Constraint;
  update_columns?: Array<Worktime_Configs_Update_Column>;
  where?: InputMaybe<Worktime_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "worktime_configs". */
export type Worktime_Configs_Order_By = {
  automatic_event_cancellation_time?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  holiday_consideration_required?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  practice?: InputMaybe<Practices_Order_By>;
  practice_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: worktime_configs */
export type Worktime_Configs_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "worktime_configs" */
export enum Worktime_Configs_Select_Column {
  /** column name */
  AutomaticEventCancellationTime = 'automatic_event_cancellation_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HolidayConsiderationRequired = 'holiday_consideration_required',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "worktime_configs" */
export type Worktime_Configs_Set_Input = {
  automatic_event_cancellation_time?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_consideration_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Worktime_Configs_Stddev_Fields = {
  __typename?: 'worktime_configs_stddev_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Worktime_Configs_Stddev_Pop_Fields = {
  __typename?: 'worktime_configs_stddev_pop_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Worktime_Configs_Stddev_Samp_Fields = {
  __typename?: 'worktime_configs_stddev_samp_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "worktime_configs" */
export type Worktime_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worktime_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Worktime_Configs_Stream_Cursor_Value_Input = {
  automatic_event_cancellation_time?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  holiday_consideration_required?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  practice_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Worktime_Configs_Sum_Fields = {
  __typename?: 'worktime_configs_sum_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  practice_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "worktime_configs" */
export enum Worktime_Configs_Update_Column {
  /** column name */
  AutomaticEventCancellationTime = 'automatic_event_cancellation_time',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HolidayConsiderationRequired = 'holiday_consideration_required',
  /** column name */
  Id = 'id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Worktime_Configs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Worktime_Configs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Worktime_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Worktime_Configs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Worktime_Configs_Var_Pop_Fields = {
  __typename?: 'worktime_configs_var_pop_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Worktime_Configs_Var_Samp_Fields = {
  __typename?: 'worktime_configs_var_samp_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Worktime_Configs_Variance_Fields = {
  __typename?: 'worktime_configs_variance_fields';
  automatic_event_cancellation_time?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  practice_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "worktime_exceptions" */
export type Worktime_Exceptions = {
  __typename?: 'worktime_exceptions';
  created_at: Scalars['timestamptz'];
  dates?: Maybe<Array<Scalars['date']>>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  preset_id: Scalars['bigint'];
  start_date?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  worktime_exceptions_work_times: Array<Worktime_Exceptions_Work_Times>;
  /** An aggregate relationship */
  worktime_exceptions_work_times_aggregate: Worktime_Exceptions_Work_Times_Aggregate;
  /** An object relationship */
  worktime_preset: Worktime_Presets;
};


/** columns and relationships of "worktime_exceptions" */
export type Worktime_ExceptionsWorktime_Exceptions_Work_TimesArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


/** columns and relationships of "worktime_exceptions" */
export type Worktime_ExceptionsWorktime_Exceptions_Work_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};

/** aggregated selection of "worktime_exceptions" */
export type Worktime_Exceptions_Aggregate = {
  __typename?: 'worktime_exceptions_aggregate';
  aggregate?: Maybe<Worktime_Exceptions_Aggregate_Fields>;
  nodes: Array<Worktime_Exceptions>;
};

export type Worktime_Exceptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Worktime_Exceptions_Aggregate_Bool_Exp_Count>;
};

export type Worktime_Exceptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "worktime_exceptions" */
export type Worktime_Exceptions_Aggregate_Fields = {
  __typename?: 'worktime_exceptions_aggregate_fields';
  avg?: Maybe<Worktime_Exceptions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worktime_Exceptions_Max_Fields>;
  min?: Maybe<Worktime_Exceptions_Min_Fields>;
  stddev?: Maybe<Worktime_Exceptions_Stddev_Fields>;
  stddev_pop?: Maybe<Worktime_Exceptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worktime_Exceptions_Stddev_Samp_Fields>;
  sum?: Maybe<Worktime_Exceptions_Sum_Fields>;
  var_pop?: Maybe<Worktime_Exceptions_Var_Pop_Fields>;
  var_samp?: Maybe<Worktime_Exceptions_Var_Samp_Fields>;
  variance?: Maybe<Worktime_Exceptions_Variance_Fields>;
};


/** aggregate fields of "worktime_exceptions" */
export type Worktime_Exceptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "worktime_exceptions" */
export type Worktime_Exceptions_Aggregate_Order_By = {
  avg?: InputMaybe<Worktime_Exceptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Worktime_Exceptions_Max_Order_By>;
  min?: InputMaybe<Worktime_Exceptions_Min_Order_By>;
  stddev?: InputMaybe<Worktime_Exceptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Worktime_Exceptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Worktime_Exceptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Worktime_Exceptions_Sum_Order_By>;
  var_pop?: InputMaybe<Worktime_Exceptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Worktime_Exceptions_Var_Samp_Order_By>;
  variance?: InputMaybe<Worktime_Exceptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "worktime_exceptions" */
export type Worktime_Exceptions_Arr_Rel_Insert_Input = {
  data: Array<Worktime_Exceptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Exceptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Worktime_Exceptions_Avg_Fields = {
  __typename?: 'worktime_exceptions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "worktime_exceptions". All fields are combined with a logical 'AND'. */
export type Worktime_Exceptions_Bool_Exp = {
  _and?: InputMaybe<Array<Worktime_Exceptions_Bool_Exp>>;
  _not?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
  _or?: InputMaybe<Array<Worktime_Exceptions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dates?: InputMaybe<Date_Array_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  preset_id?: InputMaybe<Bigint_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worktime_exceptions_work_times?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
  worktime_exceptions_work_times_aggregate?: InputMaybe<Worktime_Exceptions_Work_Times_Aggregate_Bool_Exp>;
  worktime_preset?: InputMaybe<Worktime_Presets_Bool_Exp>;
};

/** unique or primary key constraints on table "worktime_exceptions" */
export enum Worktime_Exceptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorktimeExceptionsPkey = 'worktime_exceptions_pkey'
}

/** input type for incrementing numeric columns in table "worktime_exceptions" */
export type Worktime_Exceptions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "worktime_exceptions" */
export type Worktime_Exceptions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dates?: InputMaybe<Array<Scalars['date']>>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worktime_exceptions_work_times?: InputMaybe<Worktime_Exceptions_Work_Times_Arr_Rel_Insert_Input>;
  worktime_preset?: InputMaybe<Worktime_Presets_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Worktime_Exceptions_Max_Fields = {
  __typename?: 'worktime_exceptions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dates?: Maybe<Array<Scalars['date']>>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dates?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Worktime_Exceptions_Min_Fields = {
  __typename?: 'worktime_exceptions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dates?: Maybe<Array<Scalars['date']>>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dates?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "worktime_exceptions" */
export type Worktime_Exceptions_Mutation_Response = {
  __typename?: 'worktime_exceptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worktime_Exceptions>;
};

/** input type for inserting object relation for remote table "worktime_exceptions" */
export type Worktime_Exceptions_Obj_Rel_Insert_Input = {
  data: Worktime_Exceptions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Exceptions_On_Conflict>;
};

/** on_conflict condition type for table "worktime_exceptions" */
export type Worktime_Exceptions_On_Conflict = {
  constraint: Worktime_Exceptions_Constraint;
  update_columns?: Array<Worktime_Exceptions_Update_Column>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};

/** Ordering options when selecting data from "worktime_exceptions". */
export type Worktime_Exceptions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  dates?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worktime_exceptions_work_times_aggregate?: InputMaybe<Worktime_Exceptions_Work_Times_Aggregate_Order_By>;
  worktime_preset?: InputMaybe<Worktime_Presets_Order_By>;
};

/** primary key columns input for table: worktime_exceptions */
export type Worktime_Exceptions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "worktime_exceptions" */
export enum Worktime_Exceptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dates = 'dates',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  PresetId = 'preset_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "worktime_exceptions" */
export type Worktime_Exceptions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dates?: InputMaybe<Array<Scalars['date']>>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Worktime_Exceptions_Stddev_Fields = {
  __typename?: 'worktime_exceptions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Worktime_Exceptions_Stddev_Pop_Fields = {
  __typename?: 'worktime_exceptions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Worktime_Exceptions_Stddev_Samp_Fields = {
  __typename?: 'worktime_exceptions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "worktime_exceptions" */
export type Worktime_Exceptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worktime_Exceptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Worktime_Exceptions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dates?: InputMaybe<Array<Scalars['date']>>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  preset_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Worktime_Exceptions_Sum_Fields = {
  __typename?: 'worktime_exceptions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  preset_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** update columns of table "worktime_exceptions" */
export enum Worktime_Exceptions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dates = 'dates',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  PresetId = 'preset_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Worktime_Exceptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Worktime_Exceptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Worktime_Exceptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Worktime_Exceptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Worktime_Exceptions_Var_Pop_Fields = {
  __typename?: 'worktime_exceptions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Worktime_Exceptions_Var_Samp_Fields = {
  __typename?: 'worktime_exceptions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Worktime_Exceptions_Variance_Fields = {
  __typename?: 'worktime_exceptions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  preset_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "worktime_exceptions" */
export type Worktime_Exceptions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  preset_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times = {
  __typename?: 'worktime_exceptions_work_times';
  id: Scalars['bigint'];
  /** An object relationship */
  worktime: Worktimes;
  /** An object relationship */
  worktime_exception: Worktime_Exceptions;
  worktime_id: Scalars['bigint'];
  worktimeexception_id: Scalars['bigint'];
};

/** aggregated selection of "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Aggregate = {
  __typename?: 'worktime_exceptions_work_times_aggregate';
  aggregate?: Maybe<Worktime_Exceptions_Work_Times_Aggregate_Fields>;
  nodes: Array<Worktime_Exceptions_Work_Times>;
};

export type Worktime_Exceptions_Work_Times_Aggregate_Bool_Exp = {
  count?: InputMaybe<Worktime_Exceptions_Work_Times_Aggregate_Bool_Exp_Count>;
};

export type Worktime_Exceptions_Work_Times_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Aggregate_Fields = {
  __typename?: 'worktime_exceptions_work_times_aggregate_fields';
  avg?: Maybe<Worktime_Exceptions_Work_Times_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worktime_Exceptions_Work_Times_Max_Fields>;
  min?: Maybe<Worktime_Exceptions_Work_Times_Min_Fields>;
  stddev?: Maybe<Worktime_Exceptions_Work_Times_Stddev_Fields>;
  stddev_pop?: Maybe<Worktime_Exceptions_Work_Times_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worktime_Exceptions_Work_Times_Stddev_Samp_Fields>;
  sum?: Maybe<Worktime_Exceptions_Work_Times_Sum_Fields>;
  var_pop?: Maybe<Worktime_Exceptions_Work_Times_Var_Pop_Fields>;
  var_samp?: Maybe<Worktime_Exceptions_Work_Times_Var_Samp_Fields>;
  variance?: Maybe<Worktime_Exceptions_Work_Times_Variance_Fields>;
};


/** aggregate fields of "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Aggregate_Order_By = {
  avg?: InputMaybe<Worktime_Exceptions_Work_Times_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Worktime_Exceptions_Work_Times_Max_Order_By>;
  min?: InputMaybe<Worktime_Exceptions_Work_Times_Min_Order_By>;
  stddev?: InputMaybe<Worktime_Exceptions_Work_Times_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Worktime_Exceptions_Work_Times_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Worktime_Exceptions_Work_Times_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Worktime_Exceptions_Work_Times_Sum_Order_By>;
  var_pop?: InputMaybe<Worktime_Exceptions_Work_Times_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Worktime_Exceptions_Work_Times_Var_Samp_Order_By>;
  variance?: InputMaybe<Worktime_Exceptions_Work_Times_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Arr_Rel_Insert_Input = {
  data: Array<Worktime_Exceptions_Work_Times_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Exceptions_Work_Times_On_Conflict>;
};

/** aggregate avg on columns */
export type Worktime_Exceptions_Work_Times_Avg_Fields = {
  __typename?: 'worktime_exceptions_work_times_avg_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "worktime_exceptions_work_times". All fields are combined with a logical 'AND'. */
export type Worktime_Exceptions_Work_Times_Bool_Exp = {
  _and?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Bool_Exp>>;
  _not?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
  _or?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  worktime?: InputMaybe<Worktimes_Bool_Exp>;
  worktime_exception?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
  worktime_id?: InputMaybe<Bigint_Comparison_Exp>;
  worktimeexception_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "worktime_exceptions_work_times" */
export enum Worktime_Exceptions_Work_Times_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorktimeExceptionsWorkTimesPkey = 'worktime_exceptions_work_times_pkey',
  /** unique or primary key constraint on columns "worktimeexception_id", "worktime_id" */
  WorktimeExceptionsWorkWorktimeexceptionIdWor_896f3aa9Uniq = 'worktime_exceptions_work_worktimeexception_id_wor_896f3aa9_uniq'
}

/** input type for incrementing numeric columns in table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  worktime_id?: InputMaybe<Scalars['bigint']>;
  worktimeexception_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  worktime?: InputMaybe<Worktimes_Obj_Rel_Insert_Input>;
  worktime_exception?: InputMaybe<Worktime_Exceptions_Obj_Rel_Insert_Input>;
  worktime_id?: InputMaybe<Scalars['bigint']>;
  worktimeexception_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Worktime_Exceptions_Work_Times_Max_Fields = {
  __typename?: 'worktime_exceptions_work_times_max_fields';
  id?: Maybe<Scalars['bigint']>;
  worktime_id?: Maybe<Scalars['bigint']>;
  worktimeexception_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Worktime_Exceptions_Work_Times_Min_Fields = {
  __typename?: 'worktime_exceptions_work_times_min_fields';
  id?: Maybe<Scalars['bigint']>;
  worktime_id?: Maybe<Scalars['bigint']>;
  worktimeexception_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Mutation_Response = {
  __typename?: 'worktime_exceptions_work_times_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worktime_Exceptions_Work_Times>;
};

/** on_conflict condition type for table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_On_Conflict = {
  constraint: Worktime_Exceptions_Work_Times_Constraint;
  update_columns?: Array<Worktime_Exceptions_Work_Times_Update_Column>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};

/** Ordering options when selecting data from "worktime_exceptions_work_times". */
export type Worktime_Exceptions_Work_Times_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime?: InputMaybe<Worktimes_Order_By>;
  worktime_exception?: InputMaybe<Worktime_Exceptions_Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: worktime_exceptions_work_times */
export type Worktime_Exceptions_Work_Times_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "worktime_exceptions_work_times" */
export enum Worktime_Exceptions_Work_Times_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  WorktimeId = 'worktime_id',
  /** column name */
  WorktimeexceptionId = 'worktimeexception_id'
}

/** input type for updating data in table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  worktime_id?: InputMaybe<Scalars['bigint']>;
  worktimeexception_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Worktime_Exceptions_Work_Times_Stddev_Fields = {
  __typename?: 'worktime_exceptions_work_times_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Worktime_Exceptions_Work_Times_Stddev_Pop_Fields = {
  __typename?: 'worktime_exceptions_work_times_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Worktime_Exceptions_Work_Times_Stddev_Samp_Fields = {
  __typename?: 'worktime_exceptions_work_times_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worktime_Exceptions_Work_Times_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Worktime_Exceptions_Work_Times_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  worktime_id?: InputMaybe<Scalars['bigint']>;
  worktimeexception_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Worktime_Exceptions_Work_Times_Sum_Fields = {
  __typename?: 'worktime_exceptions_work_times_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  worktime_id?: Maybe<Scalars['bigint']>;
  worktimeexception_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** update columns of table "worktime_exceptions_work_times" */
export enum Worktime_Exceptions_Work_Times_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  WorktimeId = 'worktime_id',
  /** column name */
  WorktimeexceptionId = 'worktimeexception_id'
}

export type Worktime_Exceptions_Work_Times_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Worktime_Exceptions_Work_Times_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Worktime_Exceptions_Work_Times_Set_Input>;
  /** filter the rows which have to be updated */
  where: Worktime_Exceptions_Work_Times_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Worktime_Exceptions_Work_Times_Var_Pop_Fields = {
  __typename?: 'worktime_exceptions_work_times_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Worktime_Exceptions_Work_Times_Var_Samp_Fields = {
  __typename?: 'worktime_exceptions_work_times_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Worktime_Exceptions_Work_Times_Variance_Fields = {
  __typename?: 'worktime_exceptions_work_times_variance_fields';
  id?: Maybe<Scalars['Float']>;
  worktime_id?: Maybe<Scalars['Float']>;
  worktimeexception_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "worktime_exceptions_work_times" */
export type Worktime_Exceptions_Work_Times_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  worktime_id?: InputMaybe<Order_By>;
  worktimeexception_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "worktime_presets" */
export type Worktime_Presets = {
  __typename?: 'worktime_presets';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee: Employees;
  employee_id: Scalars['bigint'];
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  start_date?: Maybe<Scalars['date']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workdays: Array<Workdays>;
  /** An aggregate relationship */
  workdays_aggregate: Workdays_Aggregate;
  /** An array relationship */
  worktime_exceptions: Array<Worktime_Exceptions>;
  /** An aggregate relationship */
  worktime_exceptions_aggregate: Worktime_Exceptions_Aggregate;
};


/** columns and relationships of "worktime_presets" */
export type Worktime_PresetsWorkdaysArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


/** columns and relationships of "worktime_presets" */
export type Worktime_PresetsWorkdays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


/** columns and relationships of "worktime_presets" */
export type Worktime_PresetsWorktime_ExceptionsArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};


/** columns and relationships of "worktime_presets" */
export type Worktime_PresetsWorktime_Exceptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
};

/** aggregated selection of "worktime_presets" */
export type Worktime_Presets_Aggregate = {
  __typename?: 'worktime_presets_aggregate';
  aggregate?: Maybe<Worktime_Presets_Aggregate_Fields>;
  nodes: Array<Worktime_Presets>;
};

export type Worktime_Presets_Aggregate_Bool_Exp = {
  count?: InputMaybe<Worktime_Presets_Aggregate_Bool_Exp_Count>;
};

export type Worktime_Presets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Worktime_Presets_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "worktime_presets" */
export type Worktime_Presets_Aggregate_Fields = {
  __typename?: 'worktime_presets_aggregate_fields';
  avg?: Maybe<Worktime_Presets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worktime_Presets_Max_Fields>;
  min?: Maybe<Worktime_Presets_Min_Fields>;
  stddev?: Maybe<Worktime_Presets_Stddev_Fields>;
  stddev_pop?: Maybe<Worktime_Presets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worktime_Presets_Stddev_Samp_Fields>;
  sum?: Maybe<Worktime_Presets_Sum_Fields>;
  var_pop?: Maybe<Worktime_Presets_Var_Pop_Fields>;
  var_samp?: Maybe<Worktime_Presets_Var_Samp_Fields>;
  variance?: Maybe<Worktime_Presets_Variance_Fields>;
};


/** aggregate fields of "worktime_presets" */
export type Worktime_Presets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Worktime_Presets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "worktime_presets" */
export type Worktime_Presets_Aggregate_Order_By = {
  avg?: InputMaybe<Worktime_Presets_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Worktime_Presets_Max_Order_By>;
  min?: InputMaybe<Worktime_Presets_Min_Order_By>;
  stddev?: InputMaybe<Worktime_Presets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Worktime_Presets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Worktime_Presets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Worktime_Presets_Sum_Order_By>;
  var_pop?: InputMaybe<Worktime_Presets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Worktime_Presets_Var_Samp_Order_By>;
  variance?: InputMaybe<Worktime_Presets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "worktime_presets" */
export type Worktime_Presets_Arr_Rel_Insert_Input = {
  data: Array<Worktime_Presets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Presets_On_Conflict>;
};

/** aggregate avg on columns */
export type Worktime_Presets_Avg_Fields = {
  __typename?: 'worktime_presets_avg_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "worktime_presets" */
export type Worktime_Presets_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "worktime_presets". All fields are combined with a logical 'AND'. */
export type Worktime_Presets_Bool_Exp = {
  _and?: InputMaybe<Array<Worktime_Presets_Bool_Exp>>;
  _not?: InputMaybe<Worktime_Presets_Bool_Exp>;
  _or?: InputMaybe<Array<Worktime_Presets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employees_Bool_Exp>;
  employee_id?: InputMaybe<Bigint_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workdays?: InputMaybe<Workdays_Bool_Exp>;
  workdays_aggregate?: InputMaybe<Workdays_Aggregate_Bool_Exp>;
  worktime_exceptions?: InputMaybe<Worktime_Exceptions_Bool_Exp>;
  worktime_exceptions_aggregate?: InputMaybe<Worktime_Exceptions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "worktime_presets" */
export enum Worktime_Presets_Constraint {
  /** unique or primary key constraint on columns "employee_id", "name" */
  UniqueWorktimePresetNamePerEmployee = 'unique_worktime_preset_name_per_employee',
  /** unique or primary key constraint on columns "id" */
  WorktimePresetsPkey = 'worktime_presets_pkey'
}

/** input type for incrementing numeric columns in table "worktime_presets" */
export type Worktime_Presets_Inc_Input = {
  employee_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "worktime_presets" */
export type Worktime_Presets_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employees_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workdays?: InputMaybe<Workdays_Arr_Rel_Insert_Input>;
  worktime_exceptions?: InputMaybe<Worktime_Exceptions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Worktime_Presets_Max_Fields = {
  __typename?: 'worktime_presets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "worktime_presets" */
export type Worktime_Presets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Worktime_Presets_Min_Fields = {
  __typename?: 'worktime_presets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "worktime_presets" */
export type Worktime_Presets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "worktime_presets" */
export type Worktime_Presets_Mutation_Response = {
  __typename?: 'worktime_presets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worktime_Presets>;
};

/** input type for inserting object relation for remote table "worktime_presets" */
export type Worktime_Presets_Obj_Rel_Insert_Input = {
  data: Worktime_Presets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktime_Presets_On_Conflict>;
};

/** on_conflict condition type for table "worktime_presets" */
export type Worktime_Presets_On_Conflict = {
  constraint: Worktime_Presets_Constraint;
  update_columns?: Array<Worktime_Presets_Update_Column>;
  where?: InputMaybe<Worktime_Presets_Bool_Exp>;
};

/** Ordering options when selecting data from "worktime_presets". */
export type Worktime_Presets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employees_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workdays_aggregate?: InputMaybe<Workdays_Aggregate_Order_By>;
  worktime_exceptions_aggregate?: InputMaybe<Worktime_Exceptions_Aggregate_Order_By>;
};

/** primary key columns input for table: worktime_presets */
export type Worktime_Presets_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "worktime_presets" */
export enum Worktime_Presets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "worktime_presets" */
export type Worktime_Presets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Worktime_Presets_Stddev_Fields = {
  __typename?: 'worktime_presets_stddev_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "worktime_presets" */
export type Worktime_Presets_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Worktime_Presets_Stddev_Pop_Fields = {
  __typename?: 'worktime_presets_stddev_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "worktime_presets" */
export type Worktime_Presets_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Worktime_Presets_Stddev_Samp_Fields = {
  __typename?: 'worktime_presets_stddev_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "worktime_presets" */
export type Worktime_Presets_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "worktime_presets" */
export type Worktime_Presets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worktime_Presets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Worktime_Presets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Worktime_Presets_Sum_Fields = {
  __typename?: 'worktime_presets_sum_fields';
  employee_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "worktime_presets" */
export type Worktime_Presets_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "worktime_presets" */
export enum Worktime_Presets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Worktime_Presets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Worktime_Presets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Worktime_Presets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Worktime_Presets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Worktime_Presets_Var_Pop_Fields = {
  __typename?: 'worktime_presets_var_pop_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "worktime_presets" */
export type Worktime_Presets_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Worktime_Presets_Var_Samp_Fields = {
  __typename?: 'worktime_presets_var_samp_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "worktime_presets" */
export type Worktime_Presets_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Worktime_Presets_Variance_Fields = {
  __typename?: 'worktime_presets_variance_fields';
  employee_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "worktime_presets" */
export type Worktime_Presets_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "worktimes" */
export type Worktimes = {
  __typename?: 'worktimes';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  end_time: Scalars['time'];
  id: Scalars['bigint'];
  start_time: Scalars['time'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workdays: Array<Workdays>;
  /** An aggregate relationship */
  workdays_aggregate: Workdays_Aggregate;
  /** An array relationship */
  worktime_exceptions_work_times: Array<Worktime_Exceptions_Work_Times>;
  /** An aggregate relationship */
  worktime_exceptions_work_times_aggregate: Worktime_Exceptions_Work_Times_Aggregate;
};


/** columns and relationships of "worktimes" */
export type WorktimesWorkdaysArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


/** columns and relationships of "worktimes" */
export type WorktimesWorkdays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workdays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workdays_Order_By>>;
  where?: InputMaybe<Workdays_Bool_Exp>;
};


/** columns and relationships of "worktimes" */
export type WorktimesWorktime_Exceptions_Work_TimesArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};


/** columns and relationships of "worktimes" */
export type WorktimesWorktime_Exceptions_Work_Times_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Worktime_Exceptions_Work_Times_Order_By>>;
  where?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
};

/** aggregated selection of "worktimes" */
export type Worktimes_Aggregate = {
  __typename?: 'worktimes_aggregate';
  aggregate?: Maybe<Worktimes_Aggregate_Fields>;
  nodes: Array<Worktimes>;
};

/** aggregate fields of "worktimes" */
export type Worktimes_Aggregate_Fields = {
  __typename?: 'worktimes_aggregate_fields';
  avg?: Maybe<Worktimes_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Worktimes_Max_Fields>;
  min?: Maybe<Worktimes_Min_Fields>;
  stddev?: Maybe<Worktimes_Stddev_Fields>;
  stddev_pop?: Maybe<Worktimes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Worktimes_Stddev_Samp_Fields>;
  sum?: Maybe<Worktimes_Sum_Fields>;
  var_pop?: Maybe<Worktimes_Var_Pop_Fields>;
  var_samp?: Maybe<Worktimes_Var_Samp_Fields>;
  variance?: Maybe<Worktimes_Variance_Fields>;
};


/** aggregate fields of "worktimes" */
export type Worktimes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Worktimes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Worktimes_Avg_Fields = {
  __typename?: 'worktimes_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "worktimes". All fields are combined with a logical 'AND'. */
export type Worktimes_Bool_Exp = {
  _and?: InputMaybe<Array<Worktimes_Bool_Exp>>;
  _not?: InputMaybe<Worktimes_Bool_Exp>;
  _or?: InputMaybe<Array<Worktimes_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_time?: InputMaybe<Time_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  start_time?: InputMaybe<Time_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workdays?: InputMaybe<Workdays_Bool_Exp>;
  workdays_aggregate?: InputMaybe<Workdays_Aggregate_Bool_Exp>;
  worktime_exceptions_work_times?: InputMaybe<Worktime_Exceptions_Work_Times_Bool_Exp>;
  worktime_exceptions_work_times_aggregate?: InputMaybe<Worktime_Exceptions_Work_Times_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "worktimes" */
export enum Worktimes_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorktimesPkey = 'worktimes_pkey'
}

/** input type for incrementing numeric columns in table "worktimes" */
export type Worktimes_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "worktimes" */
export type Worktimes_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  start_time?: InputMaybe<Scalars['time']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workdays?: InputMaybe<Workdays_Arr_Rel_Insert_Input>;
  worktime_exceptions_work_times?: InputMaybe<Worktime_Exceptions_Work_Times_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Worktimes_Max_Fields = {
  __typename?: 'worktimes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Worktimes_Min_Fields = {
  __typename?: 'worktimes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "worktimes" */
export type Worktimes_Mutation_Response = {
  __typename?: 'worktimes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Worktimes>;
};

/** input type for inserting object relation for remote table "worktimes" */
export type Worktimes_Obj_Rel_Insert_Input = {
  data: Worktimes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Worktimes_On_Conflict>;
};

/** on_conflict condition type for table "worktimes" */
export type Worktimes_On_Conflict = {
  constraint: Worktimes_Constraint;
  update_columns?: Array<Worktimes_Update_Column>;
  where?: InputMaybe<Worktimes_Bool_Exp>;
};

/** Ordering options when selecting data from "worktimes". */
export type Worktimes_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  end_time?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_time?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workdays_aggregate?: InputMaybe<Workdays_Aggregate_Order_By>;
  worktime_exceptions_work_times_aggregate?: InputMaybe<Worktime_Exceptions_Work_Times_Aggregate_Order_By>;
};

/** primary key columns input for table: worktimes */
export type Worktimes_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "worktimes" */
export enum Worktimes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "worktimes" */
export type Worktimes_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  start_time?: InputMaybe<Scalars['time']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Worktimes_Stddev_Fields = {
  __typename?: 'worktimes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Worktimes_Stddev_Pop_Fields = {
  __typename?: 'worktimes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Worktimes_Stddev_Samp_Fields = {
  __typename?: 'worktimes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "worktimes" */
export type Worktimes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worktimes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Worktimes_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  end_time?: InputMaybe<Scalars['time']>;
  id?: InputMaybe<Scalars['bigint']>;
  start_time?: InputMaybe<Scalars['time']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Worktimes_Sum_Fields = {
  __typename?: 'worktimes_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "worktimes" */
export enum Worktimes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Id = 'id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Worktimes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Worktimes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Worktimes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Worktimes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Worktimes_Var_Pop_Fields = {
  __typename?: 'worktimes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Worktimes_Var_Samp_Fields = {
  __typename?: 'worktimes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Worktimes_Variance_Fields = {
  __typename?: 'worktimes_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type GetCoupleDetailsQueryVariables = Exact<{
  coupleId: Scalars['bigint'];
}>;


export type GetCoupleDetailsQuery = { __typename?: 'query_root', couples_by_pk?: { __typename?: 'couples', couple_name: string, deleted_at?: any | null, deactivated_on?: any | null, created_at: any, id: any, sms_notifications_active: boolean, couples_patients: Array<{ __typename?: 'couples_patients', patient: { __typename?: 'patients', id: any, full_name?: string | null, first_name: string, email?: string | null, color?: string | null, phone_number?: string | null, second_phone_number?: string | null } }>, couples_therapists: Array<{ __typename?: 'couples_therapists', employee: { __typename?: 'employees', color?: string | null, acronym?: string | null, full_name?: string | null, id: any, user?: { __typename?: 'user', first_name: string } | null } }> } | null };

export type GetCouplesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchPhrase?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<Couples_Order_By>>;
}>;


export type GetCouplesQuery = { __typename?: 'query_root', couples: Array<{ __typename?: 'couples', couple_name: string, id: any, deleted_at?: any | null, deactivated_on?: any | null, created_at: any, couples_patients: Array<{ __typename?: 'couples_patients', patient: { __typename?: 'patients', first_name: string, last_name: string, email?: string | null, phone_number?: string | null } }>, couples_therapists: Array<{ __typename?: 'couples_therapists', employee: { __typename?: 'employees', id: any, color?: string | null, acronym?: string | null, user?: { __typename?: 'user', first_name: string } | null } }> }>, total_records: { __typename?: 'couples_aggregate', aggregate?: { __typename?: 'couples_aggregate_fields', count: number } | null } };

export type GetEventGroupPresencesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventGroupPresencesQuery = { __typename?: 'query_root', ed_group_presence: Array<{ __typename?: 'ed_group_presence', name: string, value: string }> };

export type GetEventPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventPaymentMethodsQuery = { __typename?: 'query_root', ed_payment_method: Array<{ __typename?: 'ed_payment_method', name: string, value: string }> };

export type GetEventPresencesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEventPresencesQuery = { __typename?: 'query_root', ed_presence: Array<{ __typename?: 'ed_presence', name: string, value: string }> };

export type GetGroupsDetailsQueryVariables = Exact<{
  group_id: Scalars['bigint'];
}>;


export type GetGroupsDetailsQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: any, name: string, active: boolean, created_at: any, therapy_start_date: any, therapy_finish_date?: any | null, therapist?: { __typename?: 'employees', color?: string | null, user?: { __typename?: 'user', first_name: string } | null } | null }> };

export type GetGroupMembersQueryVariables = Exact<{
  groupId: Scalars['bigint'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchPhrase?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<Group_Members_Order_By>>;
}>;


export type GetGroupMembersQuery = { __typename?: 'query_root', group_members: Array<{ __typename?: 'group_members', id: any, membership_added_on: any, membership_ending_on?: any | null, patient_id: any, patient: { __typename?: 'patients', email?: string | null, first_name: string, last_name: string, phone_number?: string | null, second_phone_number?: string | null } }>, total_records: { __typename?: 'group_members_aggregate', aggregate?: { __typename?: 'group_members_aggregate_fields', count: number } | null } };

export type GetGroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchPhrase?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Array<Groups_Order_By>>;
}>;


export type GetGroupsQuery = { __typename?: 'query_root', groups: Array<{ __typename?: 'groups', id: any, name: string, therapy_start_date: any, therapy_finish_date?: any | null, active: boolean, therapist?: { __typename?: 'employees', color?: string | null, user?: { __typename?: 'user', first_name: string } | null } | null }>, total_records: { __typename?: 'groups_aggregate', aggregate?: { __typename?: 'groups_aggregate_fields', count: number } | null } };

export type GetPatientNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPatientNamesQuery = { __typename?: 'query_root', patients: Array<{ __typename?: 'patients', id: any, last_name: string, first_name: string }> };

export type GetPatientsQueryVariables = Exact<{
  active?: InputMaybe<Array<Scalars['Boolean']>>;
  user_id?: InputMaybe<Scalars['String']>;
  searchPhrase?: InputMaybe<Scalars['String']>;
}>;


export type GetPatientsQuery = { __typename?: 'query_root', patients: Array<{ __typename?: 'patients', id: any, first_name: string, last_name: string, full_name?: string | null, phone_number?: string | null, second_phone_number?: string | null, birthday?: any | null, email?: string | null, color?: string | null, deactivated_on?: any | null, active?: boolean | null, id_number?: string | null, therapists: Array<{ __typename?: 'patients_therapists', employee: { __typename?: 'employees', id: any, color?: string | null, user?: { __typename?: 'user', first_name: string, id: string } | null } }> }>, total_records: { __typename?: 'patients_aggregate', aggregate?: { __typename?: 'patients_aggregate_fields', count: number } | null } };

export type UserPracticesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPracticesQuery = { __typename?: 'query_root', practices: Array<{ __typename?: 'practices', restricted_access?: boolean | null, services_per_therapist?: boolean | null, id: any, name: string, deleted_at?: any | null, address?: { __typename?: 'addresses', id: any, apartment_number?: string | null, city: string, country: string, county?: string | null, house_number: string, street: string, zip_code: string } | null, contact_detail?: { __typename?: 'contact_details', id: any, email: string, phone: string } | null, practice_branches: Array<{ __typename?: 'practice_branches', id: any, name: string, practice_id: any, rooms: Array<{ __typename?: 'rooms', id: any, name: string, deleted_at?: any | null }>, employees: Array<{ __typename?: 'employees', acronym?: string | null, phone_number?: string | null, color?: string | null, user_id?: string | null, employees_roles: Array<{ __typename?: 'employees_roles', role: { __typename?: 'roles', name: string, slug: string } }>, employees_preferred_rooms: Array<{ __typename?: 'employees_preferred_room', room: { __typename?: 'rooms', id: any, name: string } }> }> }> }> };

export type RoomsShortQueryVariables = Exact<{ [key: string]: never; }>;


export type RoomsShortQuery = { __typename?: 'query_root', rooms: Array<{ __typename?: 'rooms', id: any, name: string, deleted_at?: any | null, practice_branch_id: any, available_online: boolean, slug: string }> };

export type GetServicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Services_Order_By>>;
  searchPhrase?: InputMaybe<Scalars['String']>;
  practiceBranchId: Scalars['bigint'];
}>;


export type GetServicesQuery = { __typename?: 'query_root', services: Array<{ __typename?: 'services', id: any, name: string, price: any, duration: any, available_online: boolean, comments?: string | null, services_assigned_tos: Array<{ __typename?: 'services_assigned_to', employee: { __typename?: 'employees', id: any, acronym?: string | null, color?: string | null, user?: { __typename?: 'user', first_name: string } | null } }> }>, services_aggregate: { __typename?: 'services_aggregate', aggregate?: { __typename?: 'services_aggregate_fields', count: number } | null } };

export type GetServiceNamesQueryVariables = Exact<{
  practiceBranchId: Scalars['bigint'];
}>;


export type GetServiceNamesQuery = { __typename?: 'query_root', services: Array<{ __typename?: 'services', id: any, name: string }> };

export type GetSmsDefaultTemplateQueryVariables = Exact<{
  language?: InputMaybe<Scalars['String']>;
  templateType?: InputMaybe<Scalars['String']>;
}>;


export type GetSmsDefaultTemplateQuery = { __typename?: 'query_root', sms_notifications_defaultsmstemplate: Array<{ __typename?: 'sms_notifications_defaultsmstemplate', text: string }> };

export type GetSmsTemplateVariablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSmsTemplateVariablesQuery = { __typename?: 'query_root', sms_notifications_smstemplatevariable: Array<{ __typename?: 'sms_notifications_smstemplatevariable', description_en?: string | null, description_pl?: string | null, name_en?: string | null, name_pl?: string | null }> };


export const GetCoupleDetails = gql`
    query GetCoupleDetails($coupleId: bigint!) {
  couples_by_pk(id: $coupleId) {
    couple_name
    deleted_at
    deactivated_on
    created_at
    id
    sms_notifications_active
    couples_patients {
      patient {
        id
        full_name
        first_name
        email
        color
        phone_number
        second_phone_number
      }
    }
    couples_therapists {
      employee {
        color
        acronym
        full_name
        user {
          first_name
        }
        id
      }
    }
  }
}
    `;
export const GetCouples = gql`
    query GetCouples($limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [couples_order_by!] = {}) {
  couples(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: {_and: {deleted_at: {_is_null: true}, couple_name: {_ilike: $searchPhrase}}}
  ) {
    couple_name
    id
    deleted_at
    deactivated_on
    created_at
    couples_patients {
      patient {
        first_name
        last_name
        email
        phone_number
      }
    }
    couples_therapists {
      employee {
        id
        color
        acronym
        user {
          first_name
        }
      }
    }
  }
  total_records: couples_aggregate(
    where: {_and: {deleted_at: {_is_null: true}, couple_name: {_ilike: $searchPhrase}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetEventGroupPresences = gql`
    query GetEventGroupPresences {
  ed_group_presence {
    name
    value
  }
}
    `;
export const GetEventPaymentMethods = gql`
    query GetEventPaymentMethods {
  ed_payment_method {
    name
    value
  }
}
    `;
export const GetEventPresences = gql`
    query GetEventPresences {
  ed_presence {
    name
    value
  }
}
    `;
export const GetGroupsDetails = gql`
    query GetGroupsDetails($group_id: bigint!) {
  groups(where: {id: {_eq: $group_id}}) {
    id
    name
    active
    created_at
    therapy_start_date
    therapy_finish_date
    therapist {
      color
      user {
        first_name
      }
    }
  }
}
    `;
export const GetGroupMembers = gql`
    query GetGroupMembers($groupId: bigint!, $limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [group_members_order_by!] = {}) {
  group_members(
    where: {group_id: {_eq: $groupId}, _or: [{patient: {first_name: {_ilike: $searchPhrase}}}, {patient: {last_name: {_ilike: $searchPhrase}}}]}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    id
    membership_added_on
    membership_ending_on
    patient_id
    patient {
      email
      first_name
      last_name
      phone_number
      second_phone_number
    }
  }
  total_records: group_members_aggregate(
    where: {group_id: {_eq: $groupId}, _or: [{patient: {first_name: {_ilike: $searchPhrase}}}, {patient: {last_name: {_ilike: $searchPhrase}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetGroups = gql`
    query GetGroups($limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [groups_order_by!] = {}) {
  groups(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: {_and: {active: {_eq: true}, name: {_like: $searchPhrase}}}
  ) {
    id
    name
    therapy_start_date
    therapy_finish_date
    therapist {
      color
      user {
        first_name
      }
    }
    active
  }
  total_records: groups_aggregate(
    where: {_and: {active: {_eq: true}, name: {_like: $searchPhrase}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetPatientNames = gql`
    query GetPatientNames {
  patients(where: {deleted_at: {_is_null: true}}) {
    id
    last_name
    first_name
  }
}
    `;
export const GetPatients = gql`
    query GetPatients($active: [Boolean!], $user_id: String = "", $searchPhrase: String = "%%") {
  patients(
    where: {_and: [{active: {_in: $active}}, {_or: [{therapist: {user_id: {_regex: $user_id}}}, {therapists: {employee: {user_id: {_regex: $user_id}}}}]}, {deleted_at: {_is_null: true}}, {_or: [{full_name: {_ilike: $searchPhrase}}, {email: {_ilike: $searchPhrase}}, {phone_number: {_ilike: $searchPhrase}}, {therapist: {_or: [{full_name: {_ilike: $searchPhrase}}]}}]}]}
  ) {
    id
    first_name
    last_name
    full_name
    phone_number
    second_phone_number
    birthday
    email
    color
    deactivated_on
    active
    id_number
    therapists {
      employee {
        id
        user {
          first_name
          id
        }
        color
      }
    }
  }
  total_records: patients_aggregate(
    where: {_and: [{active: {_in: $active}}, {deleted_at: {_is_null: true}}, {_or: [{therapist: {user_id: {_regex: $user_id}}}, {therapists: {employee: {user_id: {_regex: $user_id}}}}]}, {_or: [{full_name: {_ilike: $searchPhrase}}, {email: {_ilike: $searchPhrase}}, {phone_number: {_ilike: $searchPhrase}}, {therapist: {_or: [{full_name: {_ilike: $searchPhrase}}]}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const UserPractices = gql`
    query UserPractices {
  practices(order_by: {name: asc}) {
    address {
      id
      apartment_number
      city
      country
      county
      house_number
      street
      zip_code
    }
    contact_detail {
      id
      email
      phone
    }
    restricted_access
    services_per_therapist
    practice_branches {
      id
      name
      practice_id
      rooms(where: {deleted_at: {_is_null: true}}) {
        id
        name
        deleted_at
      }
      employees {
        employees_roles {
          role {
            name
            slug
          }
        }
        acronym
        phone_number
        color
        user_id
        employees_preferred_rooms(where: {room: {deleted_at: {_is_null: true}}}) {
          room {
            id
            name
          }
        }
      }
    }
    id
    name
    deleted_at
  }
}
    `;
export const RoomsShort = gql`
    query RoomsShort {
  rooms {
    id
    name
    deleted_at
    practice_branch_id
    available_online
    slug
  }
}
    `;
export const GetServices = gql`
    query GetServices($limit: Int = 5, $offset: Int = 0, $order_by: [services_order_by!] = {}, $searchPhrase: String = "%%", $practiceBranchId: bigint!) {
  services(
    where: {_and: [{practice_branch: {id: {_eq: $practiceBranchId}}}, {name: {_ilike: $searchPhrase}}, {deleted_at: {_is_null: true}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    name
    price
    duration
    available_online
    comments
    services_assigned_tos {
      employee {
        id
        acronym
        color
        user {
          first_name
        }
      }
    }
  }
  services_aggregate(
    where: {_and: [{practice_branch: {id: {_eq: $practiceBranchId}}}, {name: {_ilike: $searchPhrase}}, {deleted_at: {_is_null: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetServiceNames = gql`
    query GetServiceNames($practiceBranchId: bigint!) {
  services(
    where: {deleted_at: {_is_null: true}, practice_branch_id: {_eq: $practiceBranchId}}
  ) {
    id
    name
  }
}
    `;
export const GetSmsDefaultTemplate = gql`
    query GetSmsDefaultTemplate($language: String, $templateType: String) {
  sms_notifications_defaultsmstemplate(
    where: {_and: [{language: {_eq: $language}}, {template_type: {_eq: $templateType}}]}
  ) {
    text
  }
}
    `;
export const GetSmsTemplateVariables = gql`
    query GetSmsTemplateVariables {
  sms_notifications_smstemplatevariable {
    description_en
    description_pl
    name_en
    name_pl
  }
}
    `;

export const GetCoupleDetailsDocument = gql`
    query GetCoupleDetails($coupleId: bigint!) {
  couples_by_pk(id: $coupleId) {
    couple_name
    deleted_at
    deactivated_on
    created_at
    id
    sms_notifications_active
    couples_patients {
      patient {
        id
        full_name
        first_name
        email
        color
        phone_number
        second_phone_number
      }
    }
    couples_therapists {
      employee {
        color
        acronym
        full_name
        user {
          first_name
        }
        id
      }
    }
  }
}
    `;

/**
 * __useGetCoupleDetailsQuery__
 *
 * To run a query within a Vue component, call `useGetCoupleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoupleDetailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCoupleDetailsQuery({
 *   coupleId: // value for 'coupleId'
 * });
 */
export function useGetCoupleDetailsQuery(variables: GetCoupleDetailsQueryVariables | VueCompositionApi.Ref<GetCoupleDetailsQueryVariables> | ReactiveFunction<GetCoupleDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>(GetCoupleDetailsDocument, variables, options);
}
export function useGetCoupleDetailsLazyQuery(variables: GetCoupleDetailsQueryVariables | VueCompositionApi.Ref<GetCoupleDetailsQueryVariables> | ReactiveFunction<GetCoupleDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>(GetCoupleDetailsDocument, variables, options);
}
export type GetCoupleDetailsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCoupleDetailsQuery, GetCoupleDetailsQueryVariables>;
export const GetCouplesDocument = gql`
    query GetCouples($limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [couples_order_by!] = {}) {
  couples(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: {_and: {deleted_at: {_is_null: true}, couple_name: {_ilike: $searchPhrase}}}
  ) {
    couple_name
    id
    deleted_at
    deactivated_on
    created_at
    couples_patients {
      patient {
        first_name
        last_name
        email
        phone_number
      }
    }
    couples_therapists {
      employee {
        id
        color
        acronym
        user {
          first_name
        }
      }
    }
  }
  total_records: couples_aggregate(
    where: {_and: {deleted_at: {_is_null: true}, couple_name: {_ilike: $searchPhrase}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCouplesQuery__
 *
 * To run a query within a Vue component, call `useGetCouplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouplesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCouplesQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   searchPhrase: // value for 'searchPhrase'
 *   orderBy: // value for 'orderBy'
 * });
 */
export function useGetCouplesQuery(variables: GetCouplesQueryVariables | VueCompositionApi.Ref<GetCouplesQueryVariables> | ReactiveFunction<GetCouplesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCouplesQuery, GetCouplesQueryVariables>(GetCouplesDocument, variables, options);
}
export function useGetCouplesLazyQuery(variables: GetCouplesQueryVariables | VueCompositionApi.Ref<GetCouplesQueryVariables> | ReactiveFunction<GetCouplesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCouplesQuery, GetCouplesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetCouplesQuery, GetCouplesQueryVariables>(GetCouplesDocument, variables, options);
}
export type GetCouplesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCouplesQuery, GetCouplesQueryVariables>;
export const GetEventGroupPresencesDocument = gql`
    query GetEventGroupPresences {
  ed_group_presence {
    name
    value
  }
}
    `;

/**
 * __useGetEventGroupPresencesQuery__
 *
 * To run a query within a Vue component, call `useGetEventGroupPresencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventGroupPresencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEventGroupPresencesQuery();
 */
export function useGetEventGroupPresencesQuery(options: VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>(GetEventGroupPresencesDocument, {}, options);
}
export function useGetEventGroupPresencesLazyQuery(options: VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>(GetEventGroupPresencesDocument, {}, options);
}
export type GetEventGroupPresencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEventGroupPresencesQuery, GetEventGroupPresencesQueryVariables>;
export const GetEventPaymentMethodsDocument = gql`
    query GetEventPaymentMethods {
  ed_payment_method {
    name
    value
  }
}
    `;

/**
 * __useGetEventPaymentMethodsQuery__
 *
 * To run a query within a Vue component, call `useGetEventPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventPaymentMethodsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEventPaymentMethodsQuery();
 */
export function useGetEventPaymentMethodsQuery(options: VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>(GetEventPaymentMethodsDocument, {}, options);
}
export function useGetEventPaymentMethodsLazyQuery(options: VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>(GetEventPaymentMethodsDocument, {}, options);
}
export type GetEventPaymentMethodsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEventPaymentMethodsQuery, GetEventPaymentMethodsQueryVariables>;
export const GetEventPresencesDocument = gql`
    query GetEventPresences {
  ed_presence {
    name
    value
  }
}
    `;

/**
 * __useGetEventPresencesQuery__
 *
 * To run a query within a Vue component, call `useGetEventPresencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventPresencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEventPresencesQuery();
 */
export function useGetEventPresencesQuery(options: VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetEventPresencesQuery, GetEventPresencesQueryVariables>(GetEventPresencesDocument, {}, options);
}
export function useGetEventPresencesLazyQuery(options: VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetEventPresencesQuery, GetEventPresencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetEventPresencesQuery, GetEventPresencesQueryVariables>(GetEventPresencesDocument, {}, options);
}
export type GetEventPresencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetEventPresencesQuery, GetEventPresencesQueryVariables>;
export const GetGroupsDetailsDocument = gql`
    query GetGroupsDetails($group_id: bigint!) {
  groups(where: {id: {_eq: $group_id}}) {
    id
    name
    active
    created_at
    therapy_start_date
    therapy_finish_date
    therapist {
      color
      user {
        first_name
      }
    }
  }
}
    `;

/**
 * __useGetGroupsDetailsQuery__
 *
 * To run a query within a Vue component, call `useGetGroupsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsDetailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetGroupsDetailsQuery({
 *   group_id: // value for 'group_id'
 * });
 */
export function useGetGroupsDetailsQuery(variables: GetGroupsDetailsQueryVariables | VueCompositionApi.Ref<GetGroupsDetailsQueryVariables> | ReactiveFunction<GetGroupsDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>(GetGroupsDetailsDocument, variables, options);
}
export function useGetGroupsDetailsLazyQuery(variables: GetGroupsDetailsQueryVariables | VueCompositionApi.Ref<GetGroupsDetailsQueryVariables> | ReactiveFunction<GetGroupsDetailsQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>(GetGroupsDetailsDocument, variables, options);
}
export type GetGroupsDetailsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetGroupsDetailsQuery, GetGroupsDetailsQueryVariables>;
export const GetGroupMembersDocument = gql`
    query GetGroupMembers($groupId: bigint!, $limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [group_members_order_by!] = {}) {
  group_members(
    where: {group_id: {_eq: $groupId}, _or: [{patient: {first_name: {_ilike: $searchPhrase}}}, {patient: {last_name: {_ilike: $searchPhrase}}}]}
    limit: $limit
    offset: $offset
    order_by: $orderBy
  ) {
    id
    membership_added_on
    membership_ending_on
    patient_id
    patient {
      email
      first_name
      last_name
      phone_number
      second_phone_number
    }
  }
  total_records: group_members_aggregate(
    where: {group_id: {_eq: $groupId}, _or: [{patient: {first_name: {_ilike: $searchPhrase}}}, {patient: {last_name: {_ilike: $searchPhrase}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetGroupMembersQuery__
 *
 * To run a query within a Vue component, call `useGetGroupMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupMembersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetGroupMembersQuery({
 *   groupId: // value for 'groupId'
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   searchPhrase: // value for 'searchPhrase'
 *   orderBy: // value for 'orderBy'
 * });
 */
export function useGetGroupMembersQuery(variables: GetGroupMembersQueryVariables | VueCompositionApi.Ref<GetGroupMembersQueryVariables> | ReactiveFunction<GetGroupMembersQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, variables, options);
}
export function useGetGroupMembersLazyQuery(variables: GetGroupMembersQueryVariables | VueCompositionApi.Ref<GetGroupMembersQueryVariables> | ReactiveFunction<GetGroupMembersQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupMembersQuery, GetGroupMembersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetGroupMembersQuery, GetGroupMembersQueryVariables>(GetGroupMembersDocument, variables, options);
}
export type GetGroupMembersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetGroupMembersQuery, GetGroupMembersQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($limit: Int = 5, $offset: Int = 0, $searchPhrase: String = "%%", $orderBy: [groups_order_by!] = {}) {
  groups(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: {_and: {active: {_eq: true}, name: {_like: $searchPhrase}}}
  ) {
    id
    name
    therapy_start_date
    therapy_finish_date
    therapist {
      color
      user {
        first_name
      }
    }
    active
  }
  total_records: groups_aggregate(
    where: {_and: {active: {_eq: true}, name: {_like: $searchPhrase}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a Vue component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetGroupsQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   searchPhrase: // value for 'searchPhrase'
 *   orderBy: // value for 'orderBy'
 * });
 */
export function useGetGroupsQuery(variables: GetGroupsQueryVariables | VueCompositionApi.Ref<GetGroupsQueryVariables> | ReactiveFunction<GetGroupsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, variables, options);
}
export function useGetGroupsLazyQuery(variables: GetGroupsQueryVariables | VueCompositionApi.Ref<GetGroupsQueryVariables> | ReactiveFunction<GetGroupsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetGroupsQuery, GetGroupsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, variables, options);
}
export type GetGroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetPatientNamesDocument = gql`
    query GetPatientNames {
  patients(where: {deleted_at: {_is_null: true}}) {
    id
    last_name
    first_name
  }
}
    `;

/**
 * __useGetPatientNamesQuery__
 *
 * To run a query within a Vue component, call `useGetPatientNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientNamesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPatientNamesQuery();
 */
export function useGetPatientNamesQuery(options: VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPatientNamesQuery, GetPatientNamesQueryVariables>(GetPatientNamesDocument, {}, options);
}
export function useGetPatientNamesLazyQuery(options: VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPatientNamesQuery, GetPatientNamesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetPatientNamesQuery, GetPatientNamesQueryVariables>(GetPatientNamesDocument, {}, options);
}
export type GetPatientNamesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPatientNamesQuery, GetPatientNamesQueryVariables>;
export const GetPatientsDocument = gql`
    query GetPatients($active: [Boolean!], $user_id: String = "", $searchPhrase: String = "%%") {
  patients(
    where: {_and: [{active: {_in: $active}}, {_or: [{therapist: {user_id: {_regex: $user_id}}}, {therapists: {employee: {user_id: {_regex: $user_id}}}}]}, {deleted_at: {_is_null: true}}, {_or: [{full_name: {_ilike: $searchPhrase}}, {email: {_ilike: $searchPhrase}}, {phone_number: {_ilike: $searchPhrase}}, {therapist: {_or: [{full_name: {_ilike: $searchPhrase}}]}}]}]}
  ) {
    id
    first_name
    last_name
    full_name
    phone_number
    second_phone_number
    birthday
    email
    color
    deactivated_on
    active
    id_number
    therapists {
      employee {
        id
        user {
          first_name
          id
        }
        color
      }
    }
  }
  total_records: patients_aggregate(
    where: {_and: [{active: {_in: $active}}, {deleted_at: {_is_null: true}}, {_or: [{therapist: {user_id: {_regex: $user_id}}}, {therapists: {employee: {user_id: {_regex: $user_id}}}}]}, {_or: [{full_name: {_ilike: $searchPhrase}}, {email: {_ilike: $searchPhrase}}, {phone_number: {_ilike: $searchPhrase}}, {therapist: {_or: [{full_name: {_ilike: $searchPhrase}}]}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a Vue component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPatientsQuery({
 *   active: // value for 'active'
 *   user_id: // value for 'user_id'
 *   searchPhrase: // value for 'searchPhrase'
 * });
 */
export function useGetPatientsQuery(variables: GetPatientsQueryVariables | VueCompositionApi.Ref<GetPatientsQueryVariables> | ReactiveFunction<GetPatientsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, variables, options);
}
export function useGetPatientsLazyQuery(variables: GetPatientsQueryVariables | VueCompositionApi.Ref<GetPatientsQueryVariables> | ReactiveFunction<GetPatientsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPatientsQuery, GetPatientsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, variables, options);
}
export type GetPatientsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPatientsQuery, GetPatientsQueryVariables>;
export const UserPracticesDocument = gql`
    query UserPractices {
  practices(order_by: {name: asc}) {
    address {
      id
      apartment_number
      city
      country
      county
      house_number
      street
      zip_code
    }
    contact_detail {
      id
      email
      phone
    }
    restricted_access
    services_per_therapist
    practice_branches {
      id
      name
      practice_id
      rooms(where: {deleted_at: {_is_null: true}}) {
        id
        name
        deleted_at
      }
      employees {
        employees_roles {
          role {
            name
            slug
          }
        }
        acronym
        phone_number
        color
        user_id
        employees_preferred_rooms(where: {room: {deleted_at: {_is_null: true}}}) {
          room {
            id
            name
          }
        }
      }
    }
    id
    name
    deleted_at
  }
}
    `;

/**
 * __useUserPracticesQuery__
 *
 * To run a query within a Vue component, call `useUserPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPracticesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserPracticesQuery();
 */
export function useUserPracticesQuery(options: VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserPracticesQuery, UserPracticesQueryVariables>(UserPracticesDocument, {}, options);
}
export function useUserPracticesLazyQuery(options: VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserPracticesQuery, UserPracticesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserPracticesQuery, UserPracticesQueryVariables>(UserPracticesDocument, {}, options);
}
export type UserPracticesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserPracticesQuery, UserPracticesQueryVariables>;
export const RoomsShortDocument = gql`
    query RoomsShort {
  rooms {
    id
    name
    deleted_at
    practice_branch_id
    available_online
    slug
  }
}
    `;

/**
 * __useRoomsShortQuery__
 *
 * To run a query within a Vue component, call `useRoomsShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomsShortQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRoomsShortQuery();
 */
export function useRoomsShortQuery(options: VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<RoomsShortQuery, RoomsShortQueryVariables>(RoomsShortDocument, {}, options);
}
export function useRoomsShortLazyQuery(options: VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RoomsShortQuery, RoomsShortQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<RoomsShortQuery, RoomsShortQueryVariables>(RoomsShortDocument, {}, options);
}
export type RoomsShortQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<RoomsShortQuery, RoomsShortQueryVariables>;
export const GetServicesDocument = gql`
    query GetServices($limit: Int = 5, $offset: Int = 0, $order_by: [services_order_by!] = {}, $searchPhrase: String = "%%", $practiceBranchId: bigint!) {
  services(
    where: {_and: [{practice_branch: {id: {_eq: $practiceBranchId}}}, {name: {_ilike: $searchPhrase}}, {deleted_at: {_is_null: true}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    name
    price
    duration
    available_online
    comments
    services_assigned_tos {
      employee {
        id
        acronym
        color
        user {
          first_name
        }
      }
    }
  }
  services_aggregate(
    where: {_and: [{practice_branch: {id: {_eq: $practiceBranchId}}}, {name: {_ilike: $searchPhrase}}, {deleted_at: {_is_null: true}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a Vue component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetServicesQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   searchPhrase: // value for 'searchPhrase'
 *   practiceBranchId: // value for 'practiceBranchId'
 * });
 */
export function useGetServicesQuery(variables: GetServicesQueryVariables | VueCompositionApi.Ref<GetServicesQueryVariables> | ReactiveFunction<GetServicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, variables, options);
}
export function useGetServicesLazyQuery(variables: GetServicesQueryVariables | VueCompositionApi.Ref<GetServicesQueryVariables> | ReactiveFunction<GetServicesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetServicesQuery, GetServicesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, variables, options);
}
export type GetServicesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetServicesQuery, GetServicesQueryVariables>;
export const GetServiceNamesDocument = gql`
    query GetServiceNames($practiceBranchId: bigint!) {
  services(
    where: {deleted_at: {_is_null: true}, practice_branch_id: {_eq: $practiceBranchId}}
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetServiceNamesQuery__
 *
 * To run a query within a Vue component, call `useGetServiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceNamesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetServiceNamesQuery({
 *   practiceBranchId: // value for 'practiceBranchId'
 * });
 */
export function useGetServiceNamesQuery(variables: GetServiceNamesQueryVariables | VueCompositionApi.Ref<GetServiceNamesQueryVariables> | ReactiveFunction<GetServiceNamesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetServiceNamesQuery, GetServiceNamesQueryVariables>(GetServiceNamesDocument, variables, options);
}
export function useGetServiceNamesLazyQuery(variables: GetServiceNamesQueryVariables | VueCompositionApi.Ref<GetServiceNamesQueryVariables> | ReactiveFunction<GetServiceNamesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetServiceNamesQuery, GetServiceNamesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetServiceNamesQuery, GetServiceNamesQueryVariables>(GetServiceNamesDocument, variables, options);
}
export type GetServiceNamesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetServiceNamesQuery, GetServiceNamesQueryVariables>;
export const GetSmsDefaultTemplateDocument = gql`
    query GetSmsDefaultTemplate($language: String, $templateType: String) {
  sms_notifications_defaultsmstemplate(
    where: {_and: [{language: {_eq: $language}}, {template_type: {_eq: $templateType}}]}
  ) {
    text
  }
}
    `;

/**
 * __useGetSmsDefaultTemplateQuery__
 *
 * To run a query within a Vue component, call `useGetSmsDefaultTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsDefaultTemplateQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSmsDefaultTemplateQuery({
 *   language: // value for 'language'
 *   templateType: // value for 'templateType'
 * });
 */
export function useGetSmsDefaultTemplateQuery(variables: GetSmsDefaultTemplateQueryVariables | VueCompositionApi.Ref<GetSmsDefaultTemplateQueryVariables> | ReactiveFunction<GetSmsDefaultTemplateQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>(GetSmsDefaultTemplateDocument, variables, options);
}
export function useGetSmsDefaultTemplateLazyQuery(variables: GetSmsDefaultTemplateQueryVariables | VueCompositionApi.Ref<GetSmsDefaultTemplateQueryVariables> | ReactiveFunction<GetSmsDefaultTemplateQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>(GetSmsDefaultTemplateDocument, variables, options);
}
export type GetSmsDefaultTemplateQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSmsDefaultTemplateQuery, GetSmsDefaultTemplateQueryVariables>;
export const GetSmsTemplateVariablesDocument = gql`
    query GetSmsTemplateVariables {
  sms_notifications_smstemplatevariable {
    description_en
    description_pl
    name_en
    name_pl
  }
}
    `;

/**
 * __useGetSmsTemplateVariablesQuery__
 *
 * To run a query within a Vue component, call `useGetSmsTemplateVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplateVariablesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSmsTemplateVariablesQuery();
 */
export function useGetSmsTemplateVariablesQuery(options: VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>(GetSmsTemplateVariablesDocument, {}, options);
}
export function useGetSmsTemplateVariablesLazyQuery(options: VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>(GetSmsTemplateVariablesDocument, {}, options);
}
export type GetSmsTemplateVariablesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetSmsTemplateVariablesQuery, GetSmsTemplateVariablesQueryVariables>;